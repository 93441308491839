import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Switch, message, Modal, Button, Form, Input, DatePicker, Tooltip, Select, Tag, Tabs, Upload, Table, Popover } from 'antd';
import moment from "moment";
import qs from "qs";
import locale from 'antd/es/date-picker/locale/vi_VN';

const NoteSupport = (props) => {

    const [data, setData] = useState({
        total: 0,
        listData: []
    });
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 20
    });
    const [loading, setLoading] = useState(false);
    const [loadingStaff, setloadingStaff] = useState(false);
    const [staff, setStaff] = useState([]);

    const formFilter = useRef();
    const timeout = useRef();

    useEffect(() => {
        getListData();
    }, [pagination]);

    useEffect(() => {
        getListStaff();
    }, [])

    const getListData = async () => {
        try {
            setLoading(true);

            let params = {
                page: pagination.page,
                limit: pagination.limit
            }

            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() };

            delete params['time'];

            let response = await props.fetchData({
                url: `api/v1/note_support?${qs.stringify(params)}`
            })

            setData({
                total: response.total,
                listData: response.data
            })

            setLoading(false);
        } catch (err) {
            setLoading(false);
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    const getListStaff = async () => {
        setloadingStaff(true);
        try {
            let res = await props.fetchData({
                url: `api/v1/statistic/staff`
            });
            setStaff(res.data);
        } catch (err) {
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
        setloadingStaff(false);
    }

    return <div className="list-connect content-m d-flex">
        <div className="list-c">
            <div style={{ marginBottom: "15px" }} className="table-header">
                <h3>Lịch chăm sóc: {data.total}</h3>
                <Form
                    initialValues={{
                        status: ""
                    }}
                    onValuesChange={(e) => {
                        if (timeout.current) clearTimeout(timeout.current);
                        let delay = 0;
                        if (e.keyword) delay = 300;

                        timeout.current = setTimeout(() => {
                            getListData();
                        }, delay);
                    }}
                    ref={formFilter}
                    onReset={() => getListData()}
                >
                    <div className="row">
                        <div className="col-md-2">
                            <Form.Item name="created_by">
                                <Select placeholder="Chọn nhân viên" allowClear>
                                    {
                                        staff.map((item) => {
                                            return <Select.Option value={item._id}>{item.fullname}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-3">
                            <Form.Item name="start_time" noStyle><Input type="hidden" /></Form.Item>
                            <Form.Item name="end_time" noStyle><Input type="hidden" /></Form.Item>
                            <Form.Item name="time">
                                <DatePicker.RangePicker 
                                    format={'DD-MM-YYYY'}
                                    ranges={{
                                        'Hôm nay': [moment().startOf("day"), moment().endOf("day")],
                                        'Hôm qua': [moment().startOf("day").add(-1, "day"), moment().endOf("day").add(-1, "day")],
                                        'Tuần này': [moment().startOf("week"), moment().endOf("week")],
                                        'Tuần trước': [moment().add(-1, "week").startOf("week"), moment().add(-1, "week").endOf("week")],
                                        'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                        'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                    }}
                                    allowClear={true}
                                    locale={locale}
                                    className="w-100"
                                    onChange={(values) => {
                                        formFilter.current.setFieldsValue({
                                            start_time: values && values[0] && values[0].startOf("day").unix() * 1000,
                                            end_time: values && values[1] && values[1].endOf("day").unix() * 1000
                                        })
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-auto">
                            <Button type="danger" htmlType="reset">Xóa lọc</Button>
                        </div>
                        <div className="col-auto">
                            <Tooltip title={'Làm mới'}>
                                <Button type="primary" onClick={getListData}><i className="far fa-sync"></i></Button>
                            </Tooltip>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="table-responsive">
                <Table
                    dataSource={data.listData}
                    columns={[
                        {
                            title: "#",
                            index: "#",
                            responsive: ["md"],
                            render: (item, value, index) => {
                                return (pagination.page * pagination.limit - pagination.limit) + index + 1
                            }
                        },
                        {
                            title: "Nhân viên",
                            dataIndex: "from",
                            render: (item) => item.fullname
                        },
                        {
                            title: "Khách hàng",
                            dataIndex: "contact",
                            render: (item) => item.thread_title
                        },
                        {
                            title: "Ngày cần chăm sóc",
                            dataIndex: "notify_time",
                            render: (item) => {
                                return item ? moment(item).format("HH:mm DD-MM-YYYY") : null
                            }
                        },
                        {
                            title: "Ngày thêm",
                            dataIndex: "created_time",
                            responsive: ["md"],
                            render: (item) => {
                                return moment(item).format("HH:mm:ss DD-MM-YYYY")
                            }
                        },
                        {
                            title: "Hành động",
                            className: "text-right",
                            render: (item) => {
                                return <div>
                                    <Button size="small" type="primary" onClick={() => {
                                        props.history.push(`/chat/${item.contact_id}`)
                                    }}><i className="fa fa-eye"></i></Button>
                                </div>
                            }
                        }
                    ]}
                    pagination={{
                        total: data.total,
                        pageSize: pagination.limit,
                        onChange: (current, size) => {
                            pagination.page = current;
                            pagination.limit = size;

                            setPagination({
                                ...pagination
                            });
                        },
                        current: pagination.page,
                        showSizeChanger: false
                    }}
                    rowKey="_id"
                    loading={loading}
                />
            </div>
        </div>
    </div>
}

export default withRouter(NoteSupport);
