import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Select, Tooltip, Row, Col, Result, Radio, Pagination, Spin } from 'antd';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import moment from 'moment';
import { setCookie } from '../../../config/settings'

export default class StaffView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount() {
        this.getListData();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async (option = {}) => {
        try {
            let { type } = option;
            this.setState({ loading: true });
            if(type == 'reset') this.page = 1;
            let params = {
                limit: this.limit,
                page: this.page
            }
            if(this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue()};

            let res = await fetchData({
                url: `api/v1/company/list?${qs.stringify(params)}`
            });
            this.setState({
                listData: res.data,
                total: res.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Danh sách chi nhánh",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    handleFilterData = ({type, input}) => {
        this.page = 1;
        if(type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text'){
            if(this.timeSearch) clearTimeout(this.timeSearch);
                this.timeSearch = setTimeout(() => {
                    this.getListData({ type: 'reset'});
            }, 300);
        } else{
            this.getListData({ type: 'reset'});
        }
    }

    render() {
        if (this.state.loadingData) return null;
        
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý chi nhánh: {this.state.total}</h3>                    
                        <Form
                            ref={(evt) => this.formFilter = evt }
                            initialValues={{
                                role: "",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="company_name">
                                                <Input placeholder="Nhập tên chi nhánh"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Stt</th>
                                        <th>Tên</th>
                                        <th>Email</th>
                                        <th>Quyền</th>
                                        <th>Tham gia CN</th>
                                        <th className="text-right">Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id}>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>{item.company_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.role ? item.role.role_menu.join(', ') : ''}</td>
                                                    <td>{item.role ? moment(item.role.created_time).format('HH:mm DD-MM-YYYY') : ""}</td>
                                                    <td className="text-right">
                                                        <Tooltip title="Join">
                                                            <Button size="small" type="primary" className="mr-2" onClick={() => {
                                                                this.props.selectCompany(item)
                                                            }}><i className="far fa-arrow-alt-from-left"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table> 
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
