import React, { useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Form, Input, Select, Checkbox, Radio, Button, Table, message, DatePicker, TreeSelect, Spin, Image, Modal, Tooltip } from "antd";
import { list_quoctich, list_day, list_month, list_year, list_trieuchung, list_dantoc } from "./data";
import moment from "moment";
import qs from "qs";
import locale from 'antd/es/date-picker/locale/vi_VN';
import cx from "classnames";

import { apis, fetchData } from "../../lib/apis";
import { LoadingComponent, WidgetSwipe } from '../../widgets';
import { ConvertImgBase64, sleep } from "../../lib/helpers";

const FormKhaiBaoYTe = (props) => {
    const { isShowBanner, isFaceView, companyId, userLogin, socket } = props;

    const [setting, setSetting] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [vungdich, setVungDich] = useState(false);
    const [tiepxuc, setTiepXuc] = useState(false);
    const [tiemvaccine, setTiemVaccine] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingInfo, setloadingInfo] = useState(false);
    const [searchType, setSearchType] = useState(null);
    const [loadingFaceId, setloadingFaceId] = useState(false);

    const [list_bs, setListBs] = useState({
        data: [],
        dataMenu: []
    });
    const [list_dv, setListDv] = useState({
        data: [],
        dataMenu: []
    });

    const [province, setProvince] = useState([]);
    const [district, setDistrict] = useState([]);
    const [commune, setCommune] = useState([]);
    const [loadingCity, setLoadingCity] = useState(false);
    const [infoCus, setinfoCus] = useState(null);
    const [faceData, setfaceData] = useState([]);
    const [selectFaceId, setselectFaceId] = useState(null);
    const [fullScreen, setfullScreen] = useState();

    const openFullScreen = () => {
        try{
            setfullScreen(1);
            document.querySelector("#dashboard .dashboard-root .sidebar").classList.add("hide")
            document.querySelector("#dashboard .header").classList.add("hide")
        } catch(err){
            console.warn(err);
        }
    }

    const hideFullScreen = () => {
        try{
            setfullScreen(0);
            document.querySelector("#dashboard .dashboard-root .sidebar").classList.remove("hide")
            document.querySelector("#dashboard .header").classList.remove("hide")
        } catch(err){
            console.warn(err);
        }
    }

    useEffect(() => {
        return () => {
            hideFullScreen();
        }
    }, [])

    const form_khaibao_khambenh = useRef();
    const refTimerFaceId = useRef();
    const refConnectFaceId = useRef();

    const params_search = new URLSearchParams(props.history.location.search);

    const [valueSuccess, setValueSuccess] = useState(params_search.get("booking_code"));

    const company_id = props.match.params.company_id || companyId;

    const initialValues = useRef({
        gioi_tinh: 1,
        quoc_tich: "VIET NAM",
        dichte_tiem_vaccince: true,
        d_birthday: "13",
        m_birthday: "02",
        y_birthday: "1985",
        ngay_sinh: "13-02-1985",
        // tinh: "01",
        // huyen: null,
        // xa: null,
        dia_chi: "",
        dan_toc: "Kinh (Việt)"
    })

    const getSetting = async () => {
        try {
            setLoading(true);
            let response = await fetchData({
                url: `api/v1/public/company/setting?company_id=${company_id}`
            })
            setSetting(response.data);

            setListBs({
                data: response.bac_sy,
                dataMenu: parseMenu(response.bac_sy)
            });

            setListDv({
                dataMenu: parseMenu(response.dich_vu),
                data: response.dich_vu
            });

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const onSubmit = async () => {
        try {
            setLoadingForm(true);

            let values = null;

            try {
                values = await form_khaibao_khambenh.current.validateFields();
            } catch (err) {
                let field_name = err.errorFields[0]['name'][0];
                if (field_name == 'birthday') field_name = "phone";
                document.getElementById(`form_${field_name}`).scrollIntoView({
                    behavior: 'smooth',
                    block: "start"
                });
                throw ({ message: err.errorFields[0]['errors'][0] });
            }

            let khambenh = {};
            let dichte = {};
            let dia_chi_arr = [];

            Object.keys(values).forEach((key) => {
                if (key.indexOf('dichte_') == 0) {
                    dichte[key.replace('dichte_', '')] = values[key];
                } else {
                    khambenh[key] = values[key];
                }
            })

            if (khambenh['tinh']) {
                let find = province.find((item) => item.city_id == khambenh['tinh']);
                khambenh['tinh'] = {
                    id: find.city_id,
                    name: find.name
                }
                dia_chi_arr.push(find.name);
            }
            if (khambenh['huyen']) {
                let find = district.find((item) => item.city_id == khambenh['huyen']);
                khambenh['huyen'] = {
                    id: find.city_id,
                    name: find.name
                }
                dia_chi_arr.push(find.name);
            }
            if (khambenh['xa']) {
                let find = commune.find((item) => item.city_id == khambenh['xa']);
                khambenh['xa'] = {
                    id: find.city_id,
                    name: find.name
                }
                dia_chi_arr.push(find.name);
            }

            if (khambenh['bac_sy']) {
                let find = list_bs.data.find((item) => item.service_id == khambenh['bac_sy']);
                khambenh['bac_sy'] = {
                    id: find.service_id,
                    code_his: find.code_his,
                    name: find.name
                }
            }

            if (khambenh['dich_vu']) {
                let find = list_dv.data.find((item) => item.service_id == khambenh['dich_vu']);
                khambenh['dich_vu'] = {
                    id: find.service_id,
                    code_his: find.code_his,
                    name: find.name
                }
            }

            let data = {
                khambenh,
                company_id: company_id,
                booking_code: values['booking_code'],
                face_data: selectFaceId
            }

            if (Object.keys(dichte).length > 0) {
                data['dichte'] = dichte;
            }

            let response = await fetchData({
                url: `api/v1/company/khaibaoyte`,
                method: "post",
                data: data
            })

            localStorage.setItem("data_khaibaoyte", JSON.stringify({
                values: {
                    ...values,
                    parent_id: response.data.khambenh_id,
                },
                state: {
                    tiemvaccine,
                    tiepxuc,
                    vungdich
                }
            }))

            if (!isFaceView) {
                if (props.match.params.booking_code) {
                    let pathname = props.history.location.pathname.split("/");
                    delete pathname[pathname.length - 1];
                    window.location.href = pathname.join("/") + '?booking_code=' + response.data.booking_code;
                } else {
                    window.location.href = props.history.location.pathname + '?booking_code=' + response.data.booking_code;
                }
            } else {
                if (selectFaceId && selectFaceId.face_id) {
                    try {
                        await fetchData({
                            url: apis.app.face_id.index,
                            method: "put",
                            data: {
                                face_id: selectFaceId.face_id,
                                phone: response.data.so_dien_thoai,
                                face_data: selectFaceId,
                                booking_code: response.data.booking_code
                            }
                        })
                    } catch (err) {
                        Modal.error({
                            title: "Thêm Dữ liệu FaceId thất bại!",
                            content: err.message
                        });
                    }
                }
                Modal.info({
                    title: "Tạo lịch hẹn thành công",
                    content: (
                        <div>
                            <p>SDT: {response.data.so_dien_thoai}</p>
                            <p className="mb-0">Mã lịch hẹn: {response.data.booking_code}</p>
                        </div>
                    ),
                    closable: true,
                    okText: "Khai báo mới",
                    onOk() {
                        resetData();
                    },
                });

                sendInfoFaceIdApp({
                    booking_code: response.data.booking_code,
                    dia_chi_1: dia_chi_arr
                })

                document.querySelector(".dashboard-container").scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                })
            }

        } catch (err) {
            showMessage("error", err.message);
        } finally {
            setLoadingForm(false);
        }
    }

    useEffect(() => {
        getSetting();
        getCity("province");
        try {
            if (params_search.get("type") == 1) {
                let values = JSON.parse(localStorage.getItem("data_khaibaoyte"));
                delete values.values['ho_ten'];
                delete values.values['ngay_sinh'];
                delete values.values['d_birthday'];
                delete values.values['m_birthday'];
                delete values.values['y_birthday'];
                delete values.values['gioi_tinh'];
                delete values.values['bac_sy'];
                delete values.values['dich_vu'];
                delete values.values['bac_sy'];

                Object.keys(values.values).forEach((key) => {
                    if (key.indexOf("dichte_") > -1) {
                        delete values.values[key];
                    }
                })

                console.log(values.values);

                initialValues.current = {
                    ...initialValues.current,
                    ...values.values
                };

                getCity("district", initialValues.current.tinh);
                getCity("commune", initialValues.current.huyen);
            }
        } catch (err) {
            console.log(err)
        }
    }, [])

    const setBirthDay = () => {
        let d = form_khaibao_khambenh.current.getFieldValue("d_birthday");
        let m = form_khaibao_khambenh.current.getFieldValue("m_birthday");
        let y = form_khaibao_khambenh.current.getFieldValue("y_birthday");

        if (d && m && y) {
            let ngay_sinh = `${d}-${m}-${y}`;
            console.log(d, m, y, ngay_sinh);
            form_khaibao_khambenh.current.setFieldsValue({
                ngay_sinh: ngay_sinh
            })
        }
    }

    const getCity = async (type, parent_id = "") => {
        try {
            setLoadingCity(type);

            let response = await fetchData({
                url: `api/v1/geo/list?parent_id=${parent_id}`
            })

            if (type == "commune") {
                setCommune(response.data);
            } else if (type == "district") {
                setDistrict(response.data);
            } else {
                setProvince(response.data);
            }
            return response.data;
        } catch (err) {

        } finally {
            setLoadingCity(false);
        }
    }

    const changeProvince = (e) => {
        if (!e) {
            setDistrict([]);
            setCommune([]);
            form_khaibao_khambenh.current.setFieldsValue({
                district: "",
                commune: ""
            })
        } else {
            getCity("district", e);
        }
    }

    const changeDistrict = (e) => {
        if (!e) {
            setCommune([]);
            form_khaibao_khambenh.current.setFieldsValue({
                commune: ""
            })
        } else {
            getCity("commune", e);
        }
    }

    const showMessage = (type, text) => {
        message[type](text);
    }

    const getInfoBooking = async (values) => {
        setloadingInfo(true);
        const { phone, created_time } = values;
        try {
            let params = {
                company_id: company_id,
                ...values
            }

            let response = await fetchData({
                url: `api/v1/public/company/booking/info?${qs.stringify(params)}`
            })

            setinfoCus(response);

            if (response.data) {
                let data = {
                    booking_code: response.data.booking_code,
                    ho_ten: response.data.fullname,
                    so_dien_thoai: response.data.phone,
                    bac_sy: response.data.bac_sy ? response.data.bac_sy.id : null,
                    dich_vu: response.data.dich_vu ? response.data.dich_vu.id : null,
                    tinh: response.data.tinh,
                    huyen: response.data.huyen,
                    xa: response.data.xa,
                    dia_chi: response.data.dia_chi,
                    dia_chi_1: response.data.dia_chi_1 || []
                };
                if (response.data.thong_tin) {
                    data = { ...data, ...response.data.thong_tin };
                }

                if (data.ngay_sinh) {
                    data['d_birthday'] = moment(data.ngay_sinh, "DD-MM-YYYY").format("DD");
                    data['m_birthday'] = moment(data.ngay_sinh, "DD-MM-YYYY").format("MM");
                    data['y_birthday'] = moment(data.ngay_sinh, "DD-MM-YYYY").format("YYYY");
                } else {
                    delete data['ngay_sinh'];
                }

                if (data.tinh) {
                    await getCity("district", data.tinh);
                }

                if (data.huyen) {
                    await getCity("commune", data.huyen);
                }

                form_khaibao_khambenh.current.setFieldsValue(data);
            } else if (created_time) {
                form_khaibao_khambenh.current.resetFields();
                form_khaibao_khambenh.current.setFieldsValue({
                    so_dien_thoai: phone
                });
            }

            sendInfoFaceIdApp();

        } catch (err) {
            showMessage('error', err.message);
        }
        setloadingInfo(false);
    }

    const parseMenu = (list) => {
        var map = {}, node, i;
        var roots = []

        for (i = 0; i < list.length; i++) {
            map[list[i].service_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.name;
            node['key'] = node.service_id;
            node['value'] = node.service_id;

            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                } catch (err) {
                    // console.log(node);
                }
            } else {
                node['checkable'] = false;
                node['selectable'] = false;
                roots.push(node);
            }
        }
        return roots;
    }

    useEffect(() => {
        if (setting && props.match.params.booking_code && !valueSuccess) {
            getInfoBooking({
                booking_code: props.match.params.booking_code
            })
        }
    }, [setting])

    useEffect(() => {
        if (selectFaceId) {
            checkFaceId(selectFaceId)
        }
    }, [selectFaceId])

    const checkFaceId = async (values) => {
        try {
            let params = {
                face_id: values.face_id
            }

            if (values.face_id == -1) {
                throw ({ message: "FaceId này chưa có trên hệ thống!" })
            }

            let response = await fetchData({
                url: apis.app.face_id.check_phone,
                params
            })

            let phone = response.data.phone;

            form_khaibao_khambenh.current.resetFields();
            form_khaibao_khambenh.current.setFieldsValue({
                phone: phone
            })

            if (!phone) {
                throw ({ message: "FaceId này chưa có trên hệ thống!" })
            } else {
                setSearchType("phone");
                getInfoBooking({
                    phone: phone
                })
            }

        } catch (err) {
            sendInfoFaceIdApp(null, 2);
            showMessage('warning', err.message);
            setloadingInfo(false);
        }
    }

    const resetData = () => {
        form_khaibao_khambenh.current.resetFields();
        setselectFaceId(null);
        sendInfoFaceIdApp(null, 2);
    }

    useEffect(() => {
        if (userLogin && isFaceView && !refConnectFaceId.current) {
            refConnectFaceId.current = true;
            onConnectFaceId();
        }
    }, [])

    const onConnectFaceId = () => {
        socket.emit('join-kbyt-faceid');
        socket.on('connect', function () {
            socket.emit('join-kbyt-faceid');
        })
        socket.on("kbyt-faceid", (values) => {
            console.log("values", values);
            if (values.data) {
                faceData.unshift(values.data);
                setfaceData([...faceData.slice(0, 100).map((item) => {
                    if(values.data.wrong_guest_id && values.data.wrong_guest_id != -1){
                        if(item.id == values.data.wrong_guest_id){
                            item.id = values.data.guest_id;
                            item.guest_id = values.data.guest_id;
                        }
                    }
                    return item
                })])
                setloadingFaceId(true);
                setTimeout(() => {
                    setloadingFaceId(false);
                }, 1000);
            }
        })
        socket.on('join-kbyt-faceid', (values) => {
            if (!values.success) {
                props.setstatusJoin(false);
            } else {
                props.setstatusJoin(true);
            }
        })
    }
    const sendInfoFaceIdApp = (data, type) => {
        console.log("selectFaceId", selectFaceId);
        if (isFaceView && selectFaceId) {

            let values = form_khaibao_khambenh.current.getFieldsValue();
            if (data) {
                values = { ...values, ...data }
            }

            values['gioi_tinh'] = values['gioi_tinh'] == 1 ? "Nam" : values['gioi_tinh'] == 2 ? "Nữ" : "";

            if (values['dia_chi_1']) {
                values['dia_chi_1'] = values['dia_chi_1'].filter((item) => item).join(", ")
            }

            socket.emit('kbyt-faceid-info', {
                _method: "info",
                data: type == 2 ? null : values
            })
        }
    }

    const reloadPageAppFace = () => {
        socket.emit('kbyt-faceid-info', {
            _method: "reloadPage"
        })
        message.success("Thao tác thành công!");
    }

    const faceDataFilter = useMemo(() => {
        let face_datas = [...new Set(faceData)].sort((a, b) => b.timestamp - a.timestamp);

        if (selectFaceId) {
            let index = face_datas.findIndex((item) => item.id == selectFaceId.id);
            if (index > -1) {
                face_datas.splice(index, 1);
            }
            face_datas = [selectFaceId].concat(face_datas);
        }

        console.log("face_datas", face_datas);

        let face_data_key = {};

        face_datas = face_datas.filter((item) => {
            if (!face_data_key[item.id]) {
                face_data_key[item.id] = true;
                return true;
            } else {
                return false
            }
        })

        console.log("face_datas", face_data_key, face_datas);

        return face_datas;
    }, [faceData, selectFaceId])


    useEffect(() => {
        if (socket && isFaceView) {
            socket.emit("kbyt-faceid-info", {
                _method: "selectFaceId",
                data: selectFaceId
            })
        }
    }, [selectFaceId])

    // useEffect(() => {
    //     if(district || commune){
    //         sendInfoFaceIdApp();
    //     }
    // }, [ district, commune ])

    return <React.Fragment>
        {
            setting && setting.khaibao_yte ? <React.Fragment>
                {
                    isShowBanner && <div className="container">
                        <div className="row">
                            <img src={setting.banner} className="img-fluid img-thumbnail col-12" alt="Responsive image" />
                        </div>
                    </div>
                }
                {
                    valueSuccess ?
                        <div className="container body-content mt-5">
                            <div className="mb-3 row">
                                <Button onClick={() => {
                                    window.location.href = props.history.location.pathname;
                                }}><i className="fa fa-arrow-left mr-2"></i> Khai báo lại</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <a href={props.history.location.pathname + '?type=1'} style={{ fontSize: "15px" }}>
                                            <i className="far fa-dot-circle mr-2"></i>
                                            <span style={{ textDecoration: "none" }}>Khai báo cho người nhà.</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="row justify-content-center alert-success">
                                <span className="alert">
                                    <h4>Khai báo thành công</h4>
                                    <br></br>
                                    <hr></hr>
                                    Vui lòng lưu mã tiếp nhận bên dưới để sử dụng khi đo thân nhiệt và tiếp nhận tại quầy lễ tân.
                                    <br></br>
                                    <hr></hr>
                                    <h2>
                                        {valueSuccess}
                                    </h2>
                                </span>
                            </div>
                        </div>
                        : <div className={cx("body-content", !isFaceView ? "container mt-5" : "row pl-3 pr-3")}>
                            {
                                isFaceView && <>
                                    <div className="col-12">
                                        <div className="d-flex justify-content-end btn-full-screen pt-3 pb-3">
                                            <Button
                                                type="primary"
                                                size='small'
                                                className='d-flex align-items-center mb-1 mr-2'
                                                onClick={resetData}
                                            >
                                                Khai báo mới <i className='fa fa-reload ml-2'></i>
                                            </Button>
                                            {
                                                fullScreen ?
                                                    <Button
                                                        type="primary" danger size='small'
                                                        className='d-flex align-items-center mb-1'
                                                        onClick={hideFullScreen}
                                                    >
                                                        Thu hẹp <i className='fa fa-expand ml-2'></i>
                                                    </Button>
                                                    : <Button
                                                        type="primary" size='small'
                                                        className='d-flex align-items-center mb-1'
                                                        onClick={openFullScreen}
                                                    >
                                                        Mở rộng <i className='fa fa-expand ml-2'></i>
                                                    </Button>

                                            }
                                        </div>
                                    </div>
                                </>
                            }
                            <div className={cx(!isFaceView ? "col-12" : "col-md-9")}>
                                <Spin spinning={loadingInfo}>
                                    <div className="row border p-3 pt-5 pb-5 p-md-5" id="content_tokhai" >
                                        <div className="col-12">
                                            <h3 className="text-center"><b>Phiếu đăng ký khám</b></h3>
                                        </div>
                                        <Form
                                            className="col-12"
                                            ref={form_khaibao_khambenh}
                                            initialValues={initialValues.current}
                                            layout="vertical"
                                        >
                                            <Form.Item noStyle name="dia_chi_1">
                                                <Input type="hidden" />
                                            </Form.Item>
                                            <Form.Item noStyle name="parent_id">
                                                <Input type="hidden" />
                                            </Form.Item>
                                            <div className="row">
                                                <div className="col-md-6" id="form_so_dien_thoai">
                                                    <Form.Item label="Số điện thoại" name="so_dien_thoai"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "SDT không được để trống"
                                                            }
                                                        ]}

                                                    >
                                                        <Input placeholder="Nhập số điện thoại" size="large"
                                                            onBlur={(e) => {
                                                                if (searchType == "phone") {
                                                                    getInfoBooking({
                                                                        phone: e.target.value
                                                                    })
                                                                }
                                                            }}
                                                            onChange={() => setSearchType("phone")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Item label="Mã lịch hẹn khám chữa bệnh" name="booking_code" >
                                                        <Input placeholder="Nhập mã lịch hẹn để tìm kiếm.." size="large"
                                                            onBlur={(e) => {
                                                                if (searchType == "booking_code") {
                                                                    getInfoBooking({
                                                                        booking_code: e.target.value
                                                                    })
                                                                }
                                                            }}
                                                            onChange={() => setSearchType("booking_code")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="mt-4 mb-2">
                                                <h5>THÔNG TIN HÀNH CHÍNH</h5>
                                            </div>
                                            <div id="form_ho_ten" >
                                                {
                                                    infoCus && infoCus.data && <div className="text-right">
                                                        <a href="javascript:void(0)" onClick={() => {
                                                            if (infoCus.data.thong_tin) {
                                                                let data = {
                                                                    offset: infoCus.page + 1,
                                                                    created_time: infoCus.data.thong_tin.created_time
                                                                }
                                                                if (infoCus.search_type == "phone") {
                                                                    data['phone'] = infoCus.data.thong_tin.so_dien_thoai
                                                                } else if (infoCus.search_type == "fullname") {
                                                                    data['fullname'] = infoCus.data.thong_tin.ho_ten
                                                                }

                                                                getInfoBooking(data);
                                                            } else {
                                                                let data = {
                                                                    offset: infoCus.page + 1
                                                                }
                                                                if (infoCus.search_type == "phone") {
                                                                    data['phone'] = infoCus.data.phone
                                                                } else if (infoCus.search_type == "fullname") {
                                                                    data['fullname'] = infoCus.data.fullname
                                                                }

                                                                getInfoBooking(data)
                                                            }
                                                        }}>Không phải tôi</a>
                                                    </div>
                                                }
                                                <Form.Item label="Họ tên" name="ho_ten"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Họ tên không được để trống"
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder="Nhập theo CMTND/CCCD" size="large"
                                                        onBlur={(e) => {
                                                            if (searchType == "fullname") {
                                                                getInfoBooking({
                                                                    fullname: e.target.value
                                                                })
                                                            }
                                                        }}
                                                        onChange={() => setSearchType("fullname")}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div id="form_ngay_sinh">
                                                <Form.Item name="ngay_sinh"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ngày tháng năm sinh không được để trống"
                                                        }
                                                    ]}
                                                    label="Ngày tháng năm sinh"
                                                    className="birthday-hidden"
                                                >
                                                    <Input type="hidden" />
                                                </Form.Item>
                                                <Form.Item>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <Form.Item name="d_birthday">
                                                                <Select defaultValue="" onChange={setBirthDay}>
                                                                    <Select.Option value="">Chọn ngày</Select.Option>
                                                                    {
                                                                        list_day.map((item, i) => {
                                                                            return <Select.Option value={item}>{item}</Select.Option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <Form.Item name="m_birthday" >
                                                                <Select defaultValue="" onChange={setBirthDay}>
                                                                    <Select.Option value="">Chọn tháng</Select.Option>
                                                                    {
                                                                        list_month.map((item, i) => {
                                                                            return <Select.Option value={item}>{item}</Select.Option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <Form.Item name="y_birthday">
                                                                <Select defaultValue="" onChange={setBirthDay}>
                                                                    <Select.Option value="">Chọn năm</Select.Option>
                                                                    {
                                                                        list_year.map((item, i) => {
                                                                            return <Select.Option value={item}>{item}</Select.Option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <Form.Item label="Giới tính" name="gioi_tinh">
                                                <Radio.Group>
                                                    <Radio value={1}>Nam</Radio>
                                                    <Radio value={2}>Nữ</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                            <Form.Item label="Dân tộc" name="dan_toc">
                                                <Select
                                                    showSearch={!props.isAppNative}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }

                                                >
                                                    {
                                                        list_dantoc.map((item) => {
                                                            return <Select.Option value={item}>{item}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Quốc tịch" name="quoc_tich">
                                                <Select
                                                    showSearch={!props.isAppNative}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        list_quoctich.map((item) => {
                                                            return <Select.Option value={item}>{item}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Địa chỉ">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <Form.Item name="tinh">
                                                            <Select loading={loadingCity === "province"}
                                                                onChange={changeProvince}
                                                                showSearch={!props.isAppNative}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                placeholder="Chọn Tỉnh/ TP"
                                                            >
                                                                {
                                                                    province.map((item) => {
                                                                        return <Select.Option value={item.city_id}>{item.name}</Select.Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Item name="huyen">
                                                            <Select loading={loadingCity == "district"}
                                                                onChange={changeDistrict}
                                                                showSearch={!props.isAppNative}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                placeholder="Chọn Quận/ Huyện"
                                                            >
                                                                {
                                                                    district.map((item) => {
                                                                        return <Select.Option value={item.city_id}>{item.name}</Select.Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Item name="xa">
                                                            <Select loading={loadingCity == "commune"}
                                                                showSearch={!props.isAppNative}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                placeholder="Chọn Xã/ Phường"
                                                            >
                                                                {
                                                                    commune.map((item) => {
                                                                        return <Select.Option value={item.city_id}>{item.name}</Select.Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </Form.Item>
                                            <Form.Item label="Địa chỉ cụ thể" name="dia_chi">
                                                <Input placeholder="72 Trần Đăng Ninh" />
                                            </Form.Item>
                                            <div className="mt-4 mb-2">
                                                <h5>THÔNG TIN KHÁM BỆNH</h5>
                                            </div>
                                            <Form.Item label="Bác sỹ" name="bac_sy">
                                                <TreeSelect {...{
                                                    treeData: list_bs.dataMenu,
                                                    onChange: (e) => console.log(e),
                                                    // treeCheckable: true,
                                                    showCheckedStrategy: TreeSelect.SHOW_PARENT,
                                                    placeholder: 'Chọn Bác sỹ',
                                                    style: {
                                                        width: '100%',
                                                    },
                                                    showArrow: true,
                                                    treeDefaultExpandAll: true
                                                }} />
                                            </Form.Item>
                                            <Form.Item label="Dịch vụ" name="dich_vu">
                                                <TreeSelect {...{
                                                    treeData: list_dv.dataMenu,
                                                    onChange: (e) => console.log(e),
                                                    // treeCheckable: true,
                                                    showCheckedStrategy: TreeSelect.SHOW_PARENT,
                                                    placeholder: 'Chọn dịch vụ',
                                                    style: {
                                                        width: '100%',
                                                    },
                                                    showArrow: true,
                                                    treeDefaultExpandAll: true
                                                }} />
                                            </Form.Item>

                                            {
                                                setting.khaibao_yte.khaibao_dichte ?
                                                    <React.Fragment>
                                                        <div className="mt-4 mb-2">
                                                            <h5 className="text-uppercase">Khai báo dịch tễ</h5>
                                                        </div>
                                                        <Form.Item label="Các Triệu chứng">
                                                            <Table
                                                                columns={[
                                                                    {
                                                                        dataIndex: "title"
                                                                    },
                                                                    {
                                                                        className: "text-right",
                                                                        render: (item, value, index) => {
                                                                            return <Form.Item name={`dichte_${item.name}`}>
                                                                                {
                                                                                    item.type == "text" ?
                                                                                        <Input.TextArea size="large" placeholder={item.placeholder || ""} /> :
                                                                                        <Radio.Group className="text-right" defaultValue={false}>
                                                                                            <Radio value={true}>Có</Radio>
                                                                                            <Radio value={false}>Không</Radio>
                                                                                        </Radio.Group>
                                                                                }
                                                                            </Form.Item>
                                                                        }
                                                                    }
                                                                ]}
                                                                dataSource={list_trieuchung}
                                                                showHeader={false}
                                                                pagination={false}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label="Đi tới các vùng có dịch" className="mb-3">
                                                            <Form.Item name="dichte_vung_dich">
                                                                <Radio.Group onChange={(e) => {
                                                                    setVungDich(e.target.value);
                                                                }} defaultValue={false}>
                                                                    <Radio value={true}>Có</Radio>
                                                                    <Radio value={false}>Không</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {vungdich ? <Form.Item name="dichte_vung_dich_mota">
                                                                <Input.TextArea size="large" placeholder="Mô tả chi tiết.." />
                                                            </Form.Item> : null}
                                                        </Form.Item>

                                                        <Form.Item label="Tiếp xúc với người mắc Covid-19 trong vòng 2m">
                                                            <Form.Item name="dichte_tiep_xuc_covid">
                                                                <Radio.Group onChange={(e) => {
                                                                    setTiepXuc(e.target.value);
                                                                }} defaultValue={false}>
                                                                    <Radio value={true}>Có</Radio>
                                                                    <Radio value={false}>Không</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {tiepxuc ? <Form.Item name="dichte_tiep_xuc_covid_mota">
                                                                <Input.TextArea size="large" placeholder="Mô tả chi tiết.." />
                                                            </Form.Item> : null}
                                                        </Form.Item>

                                                        <Form.Item label="Tiêm Vaccine">
                                                            <Form.Item name="dichte_tiem_vaccince">
                                                                <Radio.Group onChange={(e) => {
                                                                    setTiemVaccine(e.target.value);
                                                                }} defaultValue={false}>
                                                                    <Radio value={true}>Có</Radio>
                                                                    <Radio value={false}>Không</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {tiemvaccine ? <React.Fragment>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <Form.Item name="dichte_tiem_vaccince_mui1" noStyle>
                                                                            <Input type="hidden" />
                                                                        </Form.Item>
                                                                        <DatePicker
                                                                            locale={locale}
                                                                            format="DD/MM/YYYY"
                                                                            allowClear={false}
                                                                            placeholder="Ngày tiêm mũi 1"
                                                                            style={{ width: "100%" }}
                                                                            onChange={(e) => {
                                                                                form_khaibao_khambenh.current.setFieldsValue({
                                                                                    dichte_tiem_vaccince_mui1: e.format("YYYY-MM-DD")
                                                                                })
                                                                            }}
                                                                            inputReadOnly={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Form.Item name="dichte_tiem_vaccince_mui2" noStyle>
                                                                            <Input type="hidden" />
                                                                        </Form.Item>
                                                                        <DatePicker
                                                                            locale={locale}
                                                                            format="DD/MM/YYYY"
                                                                            allowClear={false}
                                                                            placeholder="Ngày tiêm mũi 2"
                                                                            style={{ width: "100%" }}
                                                                            onChange={(e) => {
                                                                                form_khaibao_khambenh.current.setFieldsValue({
                                                                                    dichte_tiem_vaccince_mui2: e.format("YYYY-MM-DD")
                                                                                })
                                                                            }}
                                                                            inputReadOnly={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </React.Fragment> : null}
                                                        </Form.Item>

                                                    </React.Fragment> : null
                                            }

                                        </Form>
                                        <Form.Item className="col-12 mt-3">
                                            <Button disabled={loadingInfo || loadingForm} loading={loadingForm} size="large" id="submitform" className="w-100" type="primary" onClick={onSubmit}>Bấm vào đây để kết thúc khai báo</Button>
                                        </Form.Item>
                                    </div>
                                </Spin>
                            </div>
                            {
                                isFaceView && <>
                                    <div className="col-md-3">
                                        <div className="kbyt-neo">
                                            <div id="kbyt-face" className="border">
                                                <div className="kbyt-face-head">
                                                    <div className="loading">
                                                        <Spin size="small" spinning={loadingFaceId} />
                                                    </div>
                                                    <h3 className="kbyt-face-title">Nhận diện</h3>
                                                    <div className="reload-faceid">
                                                        {/* <Image
                                                            src={(selectFaceId && ConvertImgBase64(selectFaceId.images[0])) || "/user.jpg"}
                                                            preview={false}
                                                        /> */}
                                                        <Tooltip title="Reload data face checkin" >
                                                            <Button
                                                                type="text"
                                                                size='small'
                                                                className='d-flex align-items-center'
                                                                onClick={reloadPageAppFace}
                                                                danger
                                                            >
                                                                <i className="fa fa-sync mr-2"></i> Face
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <div className={cx("kbyt-face-content", loadingInfo && "disabled")}>
                                                    {
                                                        faceDataFilter.map((item, i) => {
                                                            return <div
                                                                className={cx("face-item", selectFaceId && selectFaceId.id == item.id && "active")}
                                                                key={i}
                                                                onClick={() => {
                                                                    setselectFaceId({ ...item });
                                                                    document.querySelector(".kbyt-face-content").scroll({
                                                                        top: 0,
                                                                        left: 0,
                                                                        behavior: "smooth"
                                                                    })
                                                                }}
                                                            >
                                                                <Image preview={false} src={ConvertImgBase64(item.images[0])} />
                                                                <div className="time">{moment(item.timestamp).format("HH:mm:ss DD/MM")}</div>
                                                                {
                                                                    item.face_id != -1 && <div className="status-checkin">Đã checkin</div>
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                }
            </React.Fragment> : loading ? <LoadingComponent /> : <div className="text-center p-5">
                <h5>Chi nhánh chưa được thiết lập, vui lòng thử lại!</h5>
            </div>
        }
    </React.Fragment>
}

export default withRouter(FormKhaiBaoYTe);