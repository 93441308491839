import React, { useEffect, useRef, useState } from "react";
import Hammer from "hammerjs";

import cx from "classnames";

const Swipe = (props) => {
    const { event, action, id, zIndex, visible } = props;

    const [ positionArrow, setPositionArrow ] = useState({
        x: window.innerWidth + 100,
        y: "-100px"
    });

    const swipeY = useRef(0);

    const item = useRef();

    useEffect(() => {
        if(item.current && visible !== false){
            var manager = new Hammer.Manager(item.current);
            var Swipe = new Hammer.Swipe();

            manager.add(Swipe);

            manager.on(event, function(e) {
                if(action){
                    document.querySelectorAll("textarea").forEach((item) => {
                        item.blur();
                    })
                    document.querySelectorAll("input").forEach((item) => {
                        item.blur();
                    })
                    action();
                }
            });

            var managerPan = new Hammer.Manager(item.current);
            var Pan = new Hammer.Pan();
            managerPan.add(Pan);

            managerPan.on("panstart", function(e){
                swipeY.current = e.center.y > 60 ? e.center.y - 50 : e.center.y;
            })

            managerPan.on(event == "swipeleft" ? "panleft" : "panright", function(e){
                if(event == "swipeleft"){
                    setPositionArrow({
                        y: swipeY.current,
                        x: window.innerWidth - e.center.x > 60 ? window.innerWidth - 60 : e.center.x
                    });
                } else{
                    setPositionArrow({
                        y: swipeY.current,
                        x: window.innerWidth + e.center.x > 60 ? 60 : window.innerWidth + e.center.x
                    });
                }
            })

            managerPan.on("panend", function(e){
                setPositionArrow({
                    ...positionArrow,
                    x: window.innerWidth + 100
                })
            })
        }
    }, []);

    return <React.Fragment>
        {
            visible === false ? null : <div ref={item} id={id} className="box-touch d-block" style={{
                zIndex: zIndex || 99
            }}></div>
        }
        {/* <i className={cx("far touch-arrow", event == "swipeleft" ? "fa-chevron-left" : "fa-chevron-left")} style={{top: positionArrow.y, left: positionArrow.x}}></i> */}
    </React.Fragment>
}

export default Swipe;