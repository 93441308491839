import React, { Component } from 'react';

import { Account, Contact } from '../../widgets';

export default class listPanelView extends Component{
  constructor(props){
    super(props);
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
    this.props = nextProps;
  }
  componentDidUpdate(prevProps, prevState){
      
  }
  render(){
    if(this.props.listData && this.props.listData.length > 0){
      return (
        <ul className="contact-box">
            {
              this.props.listData.map((item) => {
                return <Contact
                  Contact={item}
                  contactInfoSelected={this.props.contactInfoSelected}
                  key={item.contact_id}
                  mouseEnterDelayTooltip={this.props.mouseEnterDelayTooltip} 
                  mouseLeaveDelayTooltip={this.props.mouseLeaveDelayTooltip}
                />
              })
            }
        </ul>
      )
    }
    return  <div className="center-medium-text">Bạn chưa có cuộc hội thoại nào<br /></div>
  }
}
