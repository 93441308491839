import React, { useEffect, useState, useRef } from "react";

import { Form, Input, Button, Alert, DatePicker, Result, Table, Select } from "antd";
import moment from "moment";
import { formatPrice } from "../../../lib/helpers";

const Widget = (props) => {
    const list_type = {
        1: "Nhân viên",
        2: "Cộng tác viên",
        3: "Bác sỹ",
        4: "His",
        5: "Tự đến"
    }
    const expandedRowRender = (record, index, indent, expanded) => {
        return <Table
            columns={[
                {
                    dataIndex: "name",
                    render: (value, record) => {
                        return <React.Fragment>
                            {record.name} = <span className="text-danger">{formatPrice(record.value)} VND</span>
                        </React.Fragment>
                    }
                }
            ]}
            dataSource={record.data}
            pagination={false}
            showHeader={false}
        />;
    };

    return <React.Fragment>
        <div className="pl-4">
            <Table
                dataSource={props.data.map((item, i) => {
                    return {
                        key: i,
                        name: list_type[item._id.loai] || "KXD",
                        value: item.tong,
                        so_luong: item.so_luong,
                        data: [
                            {
                                key: `${i}1`,
                                name: "Dịch vụ",
                                value: item.dich_vu
                            },
                            {
                                key: `${i}2`,
                                name: "Thuốc",
                                value: item.thuoc
                            },
                            {
                                key: `${i}3`,
                                name: "Kính",
                                value: item.kinh
                            },
                            {
                                key: `${i}4`,
                                name: "Gói mổ",
                                value: item.goi_mo
                            }
                        ]
                    }
                })}
                columns={[
                    {
                        dataIndex: "name",
                        render: (value, record) => {
                            return <React.Fragment>
                                {record.name} = {record.so_luong} = <span className="text-danger">{formatPrice(record.value)} VND</span>
                            </React.Fragment>
                        }
                    }
                ]}
                showHeader={false}
                pagination={false}
                bordered={false}
                size={"small"}
                expandable={{ expandedRowRender, defaultExpandAllRows: false, showExpandColumn: false, defaultExpandedRowKeys: [0,1,2,3,4,5]}}
            />
        </div>
        {/* {
            props.data.map((item) => {
                return <React.Fragment>

                    <div className="pl-4 font-weight-bold">
                        <p>{list_type[item._id.loai] || "KXD"} = <span className="text-danger">{formatPrice(item.tong)} VND</span></p>
                        <div className="pl-5">
                            <p>Dịch vụ = <span className="text-danger">{formatPrice(item.dich_vu)} VND</span></p>
                            <p>Thuốc = <span className="text-danger">{formatPrice(item.thuoc)} VND</span></p>
                            <p className="mb-0">Kính = <span className="text-danger">{formatPrice(item.kinh)} VND</span></p>
                        </div>
                    </div>
                </React.Fragment>
            })
        } */}
    </React.Fragment>;
};

export default Widget;
