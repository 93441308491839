import React, { useEffect, useState, useRef } from "react";

import { Form, Input, Button, Alert, DatePicker, Result, Table, Select } from "antd";
import moment from "moment";

const Widget = (props) => {
    const level = {
        sale: "Nhân viên",
        collaborator: "Cộng tác viên",
        collaborator2: "Bác sỹ",
        manager: "Quản lý"
    }
    return <React.Fragment>
        <Table
                dataSource={props.data}
                columns={[
                    {
                        title: "Loại",
                        dataIndex: "sale_level",
                        render: (value) => {
                            return level[value] || value
                        },
                        width: 160,
                        onCell: (_, index) => {
                            // These two are merged into above cell
                            if (!props.data[index - 1] || props.data[index - 1].sale_level !== props.data[index].sale_level) {
                                return { rowSpan: props.data.filter((item) => item.sale_level == props.data[index].sale_level).length };
                            } else {
                                return { rowSpan: 0 };
                            }

                            return {};
                        }
                    },
                    {
                        title: "Nhân viên",
                        dataIndex: "sale",
                        width: 200,
                        render: (value) => value.fullname,
                        onCell: (_, index) => {
                            // These two are merged into above cell
                            if (!props.data[index - 1] || props.data[index - 1]._id.sale_id !== props.data[index]._id.sale_id) {
                                return { rowSpan: props.data.filter((item) => item._id.sale_id == props.data[index]._id.sale_id).length };
                            } else {
                                return { rowSpan: 0 };
                            }

                            return {};
                        }
                    },
                    {
                        title: "Dịch vụ",
                        dataIndex: "_id",
                        render: (value, record) => {
                            return <React.Fragment>
                                {value.service && value.service.name} = <span className="text-danger">{record.total}</span>
                            </React.Fragment>
                        }
                    }
                ]}
                pagination={false}
                scroll={{ x: true }}
            />
    </React.Fragment>;
};

export default Widget;
