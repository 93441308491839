import React from 'react';

import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

const TagComponent = (props) => {
    const { exportToCSV, loading, listData } = props;

    return <div className="table-responsive">
        <div className="text-right">
            <Button type="primary" onClick={() => {
                exportToCSV({
                    dom: document.querySelector('#statistic_tag')
                })
            }} className="mb-2" style={{ borderRadius: "5px" }}>
                <i className="far fa-download"></i>
            </Button>
        </div>

        <Table
            id="statistic_rating"
            bordered
            loading={loading}
            dataSource={listData}
            columns={[
                {
                    title: "Nhóm nhãn",
                    dataIndex: "tag",
                    render: (item) => {
                        return item[0]
                    }
                },
                {
                    title: "Nhãn",
                    dataIndex: "tag",
                    responsive: ["md"],
                    render: (item) => {
                        return item[1]
                    }
                },
                {
                    title: "Khách hàng",
                    render: (item) => {
                        return <a href={item.link} target="_blank">{item.user_id}</a>
                    }
                },
                {
                    title: "Ngày tương tác cuối",
                    dataIndex: "last_time",
                    render: (item) => {
                        return moment(item).format("HH:mm DD-MM-YYYY")
                    }
                },
                {
                    title: "Nhân viên",
                    dataIndex: "sale",
                    responsive: ["md"],
                    render: (item) => {
                        return item ? item.fullname : ""
                    }
                },
            ]}
            pagination={false}
            summary={pageData => {
                let total = 0;

                pageData.forEach(({ result_rating }) => {
                    total += 1;
                });

                return pageData.length > 0 && (
                    <Table.Summary.Row>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>{total}</b></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
        />
    </div>
}

export default TagComponent;