import React, { Component } from 'react';
import { Modal, Form, Input, Button, Select, Table, Spin} from 'antd';
import qs from 'qs';
import moment from 'moment';

export default class HistoryContactSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            loading: false,
            total: 0
        }
        this.page = 1;
        this.limit = 10;
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.visible && nextProps.visible != this.props.visible){
            this.getListData();
        }
    }

    componentDidMount = () => {

    }

    getListData = async (e) => {
        try {
            this.setState({ loading: true });
            let params = {
                limit: this.limit,
                page: this.page,
                contact_id: this.props.contactInfoSelected.contact_id
            }
            let response = await this.props.fetchData({
                url: `api/v1/contact/sale/list?${qs.stringify(params)}`
            })

            this.setState({
                listData: response.data,
                loading: false,
                total: response.total
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    render() {
        return <Modal
            title={'Lịch sử chăm sóc khách hàng'}
            visible={this.props.visible}
            onCancel={this.props.hide}
            className={"form-modal"}
            footer={null}
            width={600}
        >
            <Spin spinning={this.state.loading}>
                <Table 
                    columns={[
                        {
                            title: "Mã NV",
                            index: "code",
                            dataIndex: "sale",
                            render: (data) => data ? data.code : null
                        },
                        {
                            title: "Nhân viên",
                            index: "full_name",
                            dataIndex: "sale",
                            render: (data) => data ? data.fullname : null
                        },
                        {
                            title: "Trạng thái",
                            index: "status",
                            dataIndex: "status",
                            render: (data) => {
                                return data == 1 ? <span className="alert-success" style={{padding: "2px 5px"}}>Đang chăm sóc</span> : 
                                data == 2 ? <span className="alert-danger" style={{padding: "2px 5px"}}>Mất lượt</span> : 
                                data == 3 ? <span className="alert-warning" style={{padding: "2px 5px"}}>Đã chuyển NV chăm sóc</span> : 
                                data == 0 ? <span className="alert-warning" style={{padding: "2px 5px"}}>Chờ xác nhận</span> : 
                                data == 4 ? <span className="alert-danger" style={{padding: "2px 5px"}}>NV đã bị xóa</span> :
                                "kxd"
                            }
                        },
                        {
                            title: "Thời gian",
                            index: "updated_time",
                            dataIndex: "updated_time",
                            render: (data) => data ? moment(data).format('HH:mm DD-MM-YYYY') : ""
                        }
                    ]}
                    dataSource={this.state.listData}
                    pagination={{
                        pageSize: this.limit,
                        total: this.state.total,
                        onChange: (current) => {
                            this.page = current;
                            this.getListData();
                        }
                    }}
                />
            </Spin>
        </Modal>
    }
}
