import React, { Component } from "react";
import {
  Switch,
  message,
  Modal,
  Button,
  Form,
  Input,
  Checkbox,
  notification,
  InputNumber,
  Empty,
} from "antd";
import { fetchData } from "../../../lib/apis";
import { LoadSpinner } from "../../../widgets";

export default class AdminStatitiscalChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: []
    };

    this.formH = null;
  }
  componentWillMount() {
    this.getListData();
  }
  componentWillReceiveProps(nextProps) {}
  componentDidMount() {}
  getListData = async (options = {}) => {
    try {
      this.setState({ loading: true });
      let res = await fetchData({
        url: `api/v1/admin/channel/count`,
      });
      this.setState({
          loading: false,
          data: res.data
      })
    } catch (err) {
      message.warning({
        content: "Đã có lỗi xảy ra, vui lòng thử lại sau!",
        key: "error_api",
      });
    }
  };
  render() {
    return (
      <div
        className="content-m"
      >
        <div className="list-c list-m">
          <LoadSpinner show={this.state.loading} />
          {
            this.state.data.length > 0 ? 
            <div className="row list-statitiscal-channel">
              {
                this.state.data.map((item, i) => {
                  return <div key={i} className="card">
                    <div className="card-body">
                      <div className="text-value">{item.count}</div>
                      <div className="title">{item.type}</div>
                    </div>
                  </div>
                })
              }
            </div> : !this.state.loading ? <Empty /> : null 
          }
        </div>
      </div>
    );
  }
}
