import React, { useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet";

import { LoadingComponent } from '../../widgets';

import { Form, Input, Select, Checkbox, Radio, Button, Table, message, DatePicker, Alert, Modal } from "antd";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faAngry } from '@fortawesome/free-solid-svg-icons';

import moment from "moment";
import qs from "qs";

import Config from "../../lib/config";

const KhaiBaoYTe = (props) => {
    const [setting, setSetting] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [loadingForm, setLoadingForm] = useState(false);
    const [visibleSmsOtp, setVisibleSmsOtp] = useState(false);
    const [cusType, setCusType] = useState(1);

    const form_feedback = useRef();

    const params_search = new URLSearchParams(props.history.location.search);

    const [valueSuccess, setValueSuccess] = useState(params_search.get("success"));

    const company_id = props.match.params.company_id;

    const initialValues = useRef({
        cus_type: 1
    });

    const [timeOtp, setTimeOtp] = useState(0);

    const timeHandleOtp = useRef();
    const form_otp = useRef();

    const getSetting = async () => {
        try {
            setLoading(true);
            let response = await props.fetchData({
                url: `api/v1/public/company/setting?company_id=${props.match.params.company_id}&feedback=1&otp=1`
            })
            setSetting(response.data);

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const onSubmit = async () => {
        try {
            setLoadingForm(true);

            let values = null;

            try {
                values = await form_feedback.current.validateFields();
            } catch (err) {
                let field_name = err.errorFields[0]['name'][0];
                document.getElementById(`form_${field_name}`).scrollIntoView({
                    behavior: 'smooth',
                    block: "start"
                });
                throw ({ message: err.errorFields[0]['errors'][0] });
            }

            values['rating_text'] = [];

            Object.keys(values).forEach((key) => {
                if(key.indexOf("feedback_radio") > -1){
                    values['rating_text'].push(values[key]);
                    delete values[key];
                }
            })

            let response = await props.fetchData({
                url: `api/v1/company/feedback`,
                method: "post",
                data: {
                    company_id: company_id,
                    ...values
                }
            })

            setLoadingForm(false);

            window.location.href = props.history.location.pathname + '?success=1';

        } catch (err) {
            console.log(err);
            setLoadingForm(false);
            showMessage("error", err.message);
        }
    }

    useEffect(() => {
        getSetting();
    }, [])

    const showMessage = (type, text) => {
        message[type](text);
    }

    const showConfirmOtp = async () => {
        try {
            let values = null;
            try {
                values = await form_feedback.current.validateFields();
            } catch (err) {
                let field_name = err.errorFields[0]['name'][0];
                document.getElementById(`form_${field_name}`).scrollIntoView({
                    behavior: 'smooth',
                    block: "start"
                });
                throw ({ message: err.errorFields[0]['errors'][0] });
            }

            setLoadingForm(true);

            await sendOtp(values);

            setVisibleSmsOtp(true);
            setLoadingForm(false);
        } catch (err) {
            setLoadingForm(false);
            showMessage("error", err.message);
        }
    }

    const sendOtp = async (values) => {
        try{
            if(timeHandleOtp.current) clearInterval(timeHandleOtp.current);
            setTimeOtp(0);

            let response = await props.fetchData({
                url: `api/v1/sms/otp`,
                method: "post",
                data: {
                    company_id: company_id,
                    phone: values.phone
                }
            })

            let expired_time = 60;

            setTimeOtp(expired_time);

            timeHandleOtp.current = setInterval(() => {
                expired_time = expired_time - 1;
                setTimeOtp(expired_time);
                if(expired_time == 0) clearInterval(timeHandleOtp.current);
            }, 1000);

        } catch(err){
            throw(err);
        }
    }

    return <div id="app-container" style={{ background: "#fff", overflowY: "auto", paddingTop: `${props.statusBarHeight}px` }} className={"page-feedback"}>
        <Helmet>
            <title>Khảo sát chất lượng dịch vụ</title>
        </Helmet>
        <Modal
            centered
            title={'Xác nhận mã Otp'}
            visible={visibleSmsOtp}
            footer={null}
            onCancel={() => {
                form_feedback.current.resetFields();
                setVisibleSmsOtp(false);
            }}
        >
            <Form
                ref={form_otp}
                onFinish={(values) => {
                    form_feedback.current.setFieldsValue({
                        otp_code: values.otp_code
                    })
                    onSubmit();
                }}
                layout="vertical"
            >
                <p>Nhập mã Otp được gửi tới SDT của bạn.</p>
                <Form.Item name="otp_code"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống"
                        }
                    ]}
                >
                    <Input size="large" placeholder="Nhập mã otp.."/>
                </Form.Item>
                <div className="mb-3 d-flex justify-content-end">
                    {timeOtp > 0 && `Hạn: ${timeOtp} giây`}
                    {
                        !timeOtp && <a href="#" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            sendOtp({
                                phone: form_feedback.current.getFieldValue("phone")
                            })
                        }} className="d-flex align-items-center"><i className="fa fa-undo mr-2"></i> Gửi lại</a>
                    }
                </div>
                <Form.Item>
                    <Button htmlType="submit" type="primary" disabled={timeOtp <= 0}>Xác nhận</Button>
                </Form.Item>
            </Form>
        </Modal>
        {
            setting ? <React.Fragment>
                <div className="container">
                    <div className="row">
                        <img src={setting.banner} className="img-fluid img-thumbnail col-12" alt="Responsive image" />
                    </div>
                </div>
                {
                    valueSuccess ?
                        <div className="container body-content mt-5">
                            <div className="mb-3 row">
                                <Button onClick={() => {
                                    window.location.href = props.history.location.pathname;
                                }}><i className="fa fa-arrow-left mr-2"></i> Khảo sát</Button>
                            </div>
                            <div className="row justify-content-center alert-success">
                                <span className="alert">
                                    <h4>Khảo sát chất lượng thành công</h4>
                                    <br></br>
                                    <hr></hr>
                                    Cảm ơn bạn đã thực hiện khảo sát.
                                    <br></br>
                                    <hr></hr>
                                </span>
                            </div>
                        </div>
                        : <div className="container body-content mt-5">
                            <div className="row border p-3 pt-5 pb-5 p-md-5" >
                                <div className="col-12">
                                    <h3 className="text-center"><b>KHẢO SÁT CHẤT LƯỢNG DỊCH VỤ</b></h3>
                                </div>
                                <Form
                                    className="col-12"
                                    ref={form_feedback}
                                    initialValues={initialValues.current}
                                    layout="vertical"
                                >
                                    <Form.Item name="otp_code" noStyle>
                                        <Input type={"hidden"}/>
                                    </Form.Item>

                                    <div id="form_phone">
                                        <Form.Item label="Số điện thoại" name="phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "SDT không được để trống"
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Nhập số điện thoại" size="large" />
                                        </Form.Item>
                                    </div>
                                    <Form.Item label="Bạn là" name="cus_type">
                                        <Select onChange={(e) => setCusType(e)}>
                                            {
                                                Object.keys(Config.list_cus_type_feedback).map((key) => {
                                                    return <Select.Option value={parseInt(key)}>{Config.list_cus_type_feedback[key]}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Bạn có hài hòng với dịch vụ của chúng tôi?" name="contentment">
                                        <Radio.Group className="w-100 d-flex">
                                            <Radio style={{ width: "50%" }} value={1} className="d-flex justify-content-center"><FontAwesomeIcon icon={faSmile} /></Radio>
                                            <Radio style={{ width: "50%" }} value={2} className="d-flex justify-content-center"><FontAwesomeIcon icon={faAngry} /></Radio>
                                        </Radio.Group>

                                    </Form.Item>
                                    <Form.Item label="Xin vui lòng cho chúng tôi thấy được đánh giá của quý khách để chúng tôi hoàn thiện hơn">
                                        {
                                            setting.feedbacks.filter((item) => {
                                                // if(cusType != 2){
                                                //     return item.type != 2;
                                                // }
                                                return cusType === item.type
                                            }).map((item, i) => {
                                                let values = item.content.split("|");
                                                let text_1 = values[0] || "Không xác định";
                                                let text_2 = values[1] || "Không xác định";

                                                return <Form.Item name={`feedback_radio_${i}`}>
                                                    <Radio.Group className="w-100 d-flex">
                                                        <Radio style={{ width: "50%" }} value={text_1.trim()}>{text_1.trim()}</Radio>
                                                        <Radio style={{ width: "50%" }} value={text_2.trim()}>{text_2.trim()}</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            })
                                        }
                                    </Form.Item>
                                    <Form.Item label="Góp ý khác" name="feedback_other">
                                        <Input.TextArea size="large" style={{ minHeight: "100px" }} />
                                    </Form.Item>
                                </Form>
                                <Form.Item className="col-12 mt-3">
                                    <Button loading={loadingForm} size="large" id="submitform" className="w-100" type="primary" onClick={() => {
                                        if(setting.is_otp){
                                            showConfirmOtp()
                                        } else{
                                            onSubmit();
                                        }
                                    }}>Gửi khảo sát</Button>
                                </Form.Item>
                                {/* <div className="w-100 mt-2">
                                    <Alert type="success" message={<div>
                                        <h5>Xin hãy lưu số Hotline hỗ trợ bệnh nhân 24/7 của chúng tôi {setting.phone}</h5>
                                        <h5>Kính chúc Quý khách hàng luôn mạnh khỏe, không ốm!</h5>
                                    </div>}/>
                                </div>
                                <div className="w-100 mt-4">
                                    <Alert type="error" message={<div>
                                        <h5>Nếu bạn bức xúc và cần giải đáp ngay, xin vui lòng liên hệ Hotline {setting.phone} để giải đáp, góp ý, khiếu nại</h5>
                                    </div>}/>
                                </div> */}
                            </div>
                        </div>
                }
            </React.Fragment> : loading ? <LoadingComponent /> : <div className="text-center p-5">
                <h5>Chi nhánh chưa được thiết lập, vui lòng thử lại!</h5>
            </div>
        }
    </div>
}

export default KhaiBaoYTe;