import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Select, Tooltip, Row, Col, Result, Radio, Pagination, Spin, Calendar, Alert, DatePicker } from 'antd';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default class StaffWORK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: moment(new Date()),
            selectedValue: moment(new Date()),
            loading: false,
            groupChannel: [],
            workData: [],
            dataSearchStaff: [],
            loadingForm: false
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount() {
        this.getListData();
        this.handleSearchStaff("");
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async (option = {}) => {
        try {
            this.setState({
                loading: true
            })
            let params = {
                full_date: this.state.selectedValue.format('YYYY-MM-DD')
            }
            let res = await fetchData({
                url: `api/v1/staff/work?${qs.stringify(params)}`
            });
            this.setState({
                loading: false,
                groupChannel: res.groups,
                workData: res.works
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lịch làm việc",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    onSelect = value => {
        this.setState({
            value,
            selectedValue: value,
        }, () => {
            this.getListData();
        });
    };

    onPanelChange = value => {
        console.log(value);
        this.setState({ value });
    };

    saveWork = async (data) => {
        try {
            this.setState({
                loadingForm: true
            })
            let res = await fetchData({
                url: `api/v1/staff/work`,
                method: 'post',
                data: data
            });
            this.getListData();
            this.props.notification({
                title: "Lịch làm việc",
                content: "Cập nhập thành công",
                type: "success"
            })
            this.setState({
                loadingForm: false
            })
        } catch (err) {
            this.setState({
                loadingForm: false
            })
            this.props.notification({
                title: "Lịch làm việc",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }
    handleSearchStaff = (e) => {
        try {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                let response = await fetchData({
                    url: `api/v1/staff/list?limit=10&fullname=${e}&role=sale`
                })
                this.setState({
                    dataSearchStaff: response.data
                })
            }, 300);
        } catch (err) {
            this.props.notification({
                title: "Tìm nhân viên",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    render() {
        if (this.state.loadingData) return null;

        return (
            <div className="list-connect content-m">
                <Modal
                    title={`Phân quyền chat: ${this.state.visibleEditWork ? this.state.visibleEditWork.group_name : ""}`}
                    visible={this.state.visibleEditWork}
                    footer={null}
                    onCancel={() => this.setState({ visibleEditWork: null })}
                >
                    <div style={{ marginBottom: "10px" }}>
                        <div style={{ marginBottom: "5px" }}><b>Ngày:</b> {this.state.selectedValue.format('DD-MM-YYYY')}</div>
                        <div><b>Giờ làm việc:</b> {this.state.visibleEditWork && this.state.visibleEditWork.time_in_works == 1 ? "Hành chính" : "Ngoài giờ"}</div>
                    </div>
                    {/* <Form
                        onFinish={(e) => {
                            this.saveWork({
                                data: [{
                                    ...this.state.visibleEditWork,
                                    sale: this.state.visibleEditWork.sale.map(({ _id }) => _id),
                                    type: e.type || []
                                }]
                            });
                        }}
                        ref={evt => this.formWork = evt}
                    >
                        <div className="d-flex">
                            <Form.Item name="type" style={{width: "100%"}}>
                                <Select mode="multiple" placeholder="Thêm loại">
                                    <Select.Option value="callcenter">Callcenter</Select.Option>
                                </Select>
                            </Form.Item>
                            <Button type="primary" htmlType="submit" loading={this.state.loadingForm}>Lưu</Button>
                        </div>
                    </Form> */}
                    <Form
                        onFinish={(e) => {
                            let find = this.state.visibleEditWork.sale.find((item) => item._id == e.user_id);
                            let user = this.state.dataSearchStaff.find((user) => user._id == e.user_id);
                            if (!find && user) {
                                this.state.visibleEditWork.sale.push({
                                    _id: user._id,
                                    fullname: user.fullname
                                })
                                this.saveWork({
                                    data: [{
                                        ...this.state.visibleEditWork,
                                        sale: this.state.visibleEditWork.sale.map(({ _id }) => _id)
                                    }]
                                });
                            }
                        }}
                    >
                        <div className="d-flex">
                            <Form.Item name="user_id" style={{ width: "100%" }}>
                                <Select
                                    showSearch
                                    placeholder={"Thêm nhân viên"}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={this.handleSearchStaff}
                                    notFoundContent={null}
                                >
                                    {
                                        this.state.dataSearchStaff.map((item) => {
                                            return <Select.Option value={item._id} key={item._id}>{item.fullname}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Button type="primary" htmlType="submit">Thêm mới</Button>
                        </div>
                    </Form>
                    {
                        this.state.visibleEditWork ?
                            <div className="table-responsive table-fixed" style={{ overflow: "auto", maxHeight: "250px" }}>
                                <table className="table table-vcenter">
                                    <thead>
                                        <tr>
                                            <th>Tên</th>
                                            <th className="text-right">Thao tác</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.visibleEditWork ? this.state.visibleEditWork.sale.map((item, i) => {
                                                return <tr key={item._id}>
                                                    <td>{item.fullname}</td>
                                                    <td className="text-right">
                                                        <Button size="small" type="danger" onClick={() => {
                                                            this.state.visibleEditWork.sale.splice(i, 1);
                                                            this.saveWork({
                                                                data: [{
                                                                    ...this.state.visibleEditWork,
                                                                    sale: this.state.visibleEditWork.sale.map(({ _id }) => _id)
                                                                }]
                                                            });
                                                        }}><i class="far fa-trash"></i></Button>
                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan="12" className="text-center"> Chưa có dữ liệu</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div> : []
                    }
                </Modal>
                <Modal
                    title={`Copy lịch làm việc: ${this.state.selectedValue.format('DD-MM-YYYY')}`}
                    visible={this.state.visibleCopyDateWork}
                    footer={null}
                    onCancel={() => this.setState({ visibleCopyDateWork: null })}
                >
                    <Form
                        onFinish={(e) => {
                            if (e.to_date) {
                                this.saveWork({
                                    copy_date: this.state.selectedValue.format('YYYY-MM-DD'),
                                    to_date: e.to_date
                                })
                            }
                        }}
                        ref={(evt) => this.formCopyDate = evt}
                    >
                        <Form.Item name="to_date" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <div className="form-group">
                            <label className="form-label">To: </label>
                            <DatePicker style={{ width: "100%" }} onChange={(e) => this.formCopyDate.setFieldsValue({ to_date: e ? e.format('YYYY-MM-DD') : "" })} format="DD-MM-YYYY" />
                        </div>
                        <div className="form-group">
                            <Button type="primary" htmlType="submit">Copy ngay</Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title={`Copy lịch làm việc: ${this.state.selectedValue.format('MM-YYYY')}`}
                    visible={this.state.visibleCopyMonthWork}
                    footer={null}
                    onCancel={() => this.setState({ visibleCopyMonthWork: null })}
                >
                    <Form
                        onFinish={(e) => {
                            if (e.to_month) {
                                this.saveWork({
                                    copy_month: this.state.selectedValue.format('YYYY-MM'),
                                    to_month: e.to_month
                                })
                            }
                        }}
                        ref={(evt) => this.formCopyMonth = evt}
                    >
                        <Form.Item name="to_month" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <div className="form-group">
                            <label className="form-label">To: </label>
                            <DatePicker style={{ width: "100%" }} onChange={(e) => this.formCopyMonth.setFieldsValue({ to_month: e ? e.format('YYYY-MM') : "" })} format="MM-YYYY" picker="month" />
                        </div>
                        <div className="form-group">
                            <Button type="primary" htmlType="submit">Copy ngay</Button>
                        </div>
                    </Form>
                </Modal>
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Lịch làm việc</h3>

                    </div>
                    <div>
                        <div>
                            <div className="d-flex align-center">
                                <span className="alert alert-primary mr-2 mb-0" style={{ padding: "0.3rem 1.25rem" }}>Tháng: {this.state.selectedValue.format('MM-YYYY')}</span>
                                <Tooltip title={'Copy lịch tháng'}>
                                    <a className="btn btn-primary text-white" onClick={() => this.setState({ visibleCopyMonthWork: true })}><i className="far fa-copy"></i></a>
                                </Tooltip>
                            </div>
                        </div>
                        <Calendar value={this.state.value} onSelect={this.onSelect} onPanelChange={this.onPanelChange} fullscreen={false} />
                    </div>
                    <div className="d-flex align-center" style={{ marginBottom: "15px" }}>
                        <span className="alert alert-primary mr-2 mb-0" style={{ padding: "0.3rem 1.25rem" }}>Lịch làm việc Ngày: {this.state.selectedValue.format('DD-MM-YYYY')}</span>
                        <Tooltip title={'Copy ngày'}>
                            <a className="btn btn-primary text-white" onClick={() => this.setState({ visibleCopyDateWork: true })}><i className="far fa-copy"></i></a>
                        </Tooltip>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-bordered table-vcenter">
                                <thead>
                                    <tr>
                                        <th className="text-center">Nhóm</th>
                                        <th className="text-center">Ca</th>
                                        <th>Nhân viên</th>
                                        <th className="text-right">Chức năng</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.groupChannel.map((item) => {
                                            let in_works = this.state.workData.find((work) => work.time_in_works == 1 && item._id == work.channel_group);
                                            let overtime = this.state.workData.find((work) => work.time_in_works != 1 && item._id == work.channel_group);
                                            let html = [
                                                <tr>
                                                    <td className="text-center" rowSpan={2}>
                                                        <b>{item.name}</b>
                                                    </td>
                                                    <td className="text-center">Hành chính</td>
                                                    <td style={{ maxWidth: "200px" }}>
                                                        {in_works ? in_works.sale.map(({ fullname }) => fullname).join(', ') : ''}
                                                    </td>
                                                    <td className="text-right">
                                                        <Tooltip title={'Chỉnh sửa'}>
                                                            <Button type="primary" size="small" onClick={() => this.setState({
                                                                visibleEditWork: in_works ? { ...in_works, group_name: item.name } : {
                                                                    sale: [],
                                                                    time_in_works: 1,
                                                                    channel_group: item._id,
                                                                    full_date: this.state.selectedValue.format('YYYY-MM-DD'),
                                                                    group_name: item.name
                                                                }
                                                            }, () => {
                                                                setTimeout(() => {
                                                                    if(this.formWork){
                                                                        this.formWork.setFieldsValue({
                                                                            type: in_works && in_works.type
                                                                        })
                                                                    }
                                                                })
                                                            })}><i class="far fa-edit"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>,
                                                <tr>
                                                    <td className="text-center">Ngoài giờ</td>
                                                    <td style={{ maxWidth: "200px" }}>{overtime ? overtime.sale.map(({ fullname }) => fullname).join(', ') : ''}</td>
                                                    <td className="text-right">
                                                        <Tooltip title={'Chỉnh sửa'}>
                                                            <Button type="primary" size="small" onClick={() => this.setState({
                                                                visibleEditWork: overtime ? { ...overtime, group_name: item.name } : {
                                                                    sale: [],
                                                                    time_in_works: 0,
                                                                    channel_group: item._id,
                                                                    full_date: this.state.selectedValue.format('YYYY-MM-DD'),
                                                                    group_name: item.name
                                                                }
                                                            }, () => {
                                                                setTimeout(() => {
                                                                    if(this.formWork){
                                                                        this.formWork.setFieldsValue({
                                                                            type: overtime && overtime.type
                                                                        })
                                                                    }
                                                                })
                                                            })}><i class="far fa-edit"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            ]
                                            return html;
                                        })
                                    }
                                </tbody>
                            </table>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
