import React, { Component, useCallback } from 'react';
import LogoView from '../../views/logo-view';

export default class LoadingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    return (
        <div id="app-container">
        <div className="loading-m">
          <div id="loading-wrapper">
            <div id="loading-text">LOADING</div>
            <div id="loading-content"></div>
          </div>
        </div>
      </div>
    )
  }
}
