import React, { useEffect, useRef, useState } from "react";

import { Switch, message, Modal, Button, Form, Input, InputNumber, Tooltip, Select, Tag, Tabs, Upload, Table, Popover } from 'antd';

import moment from "moment";

import qs from "qs";

import Config from "../../../lib/config";

const FeedbackSetting = (props) => {

    const [data, setData] = useState({
        total: 0,
        listData: []
    });
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 20
    });
    const [visibleForm, setVisibleForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [dataSelected, setDataSelected] = useState([]);
    const [dataOption, setDataOption] = useState([]);

    const formFilter = useRef();
    const timeout = useRef();
    const formUpdate = useRef();

    useEffect(() => {
        getListData();
    }, [pagination]);

    useEffect(() => {
        if (visibleForm) getListOption();
    }, [visibleForm])

    const getListData = async () => {
        try {
            setLoading(true);

            let params = {
                page: pagination.page,
                limit: pagination.limit
            }

            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() };

            let response = await props.fetchData({
                url: `api/v1/feedback/list?${qs.stringify(params)}`
            })

            setData({
                total: response.total,
                listData: response.data
            })

            setLoading(false);
        } catch (err) {
            setLoading(false);
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    const getListOption = async () => {
        try {
            let response = await props.fetchData({
                url: `api/v1/feedback/option/list`
            })

            setDataOption(response.data);

        } catch (err) {
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    const saveOption = async (values) => {
        try {
            message.loading({
                content: "Đang cập nhập dữ liệu",
                key: "update"
            });

            let response = await props.fetchData({
                url: values['_id'] ? `api/v1/feedback/setting/${values['_id']}` : `api/v1/feedback/setting`,
                method: values['_id'] ? "put" : "post",
                data: values
            })

            getListOption();

            formUpdate.current.resetFields();

            message.success({ content: 'Cập nhập dữ liệu thành công', key: "update", duration: 2 });
        } catch (err) {
            message.error({ content: 'Cập nhập dữ liệu thất bại', key: "update", duration: 2 });
        }
    }

    const deleteOption = async (id) => {
        try {
            message.loading({
                content: "Đang xóa dữ liệu",
                key: "delete"
            });

            let response = await props.fetchData({
                url: `api/v1/feedback/setting/${id}`,
                method: "delete"
            })

            getListOption();

            message.success({ content: 'Xóa dữ liệu thành công', key: "delete", duration: 2 });
        } catch (err) {
            message.error({ content: 'Xóa dữ liệu thất bại', key: "delete", duration: 2 });
        }
    }

    return <div className="list-connect content-m d-flex">
        <div className="list-c">
            <Modal
                title={'Quản lý nội dung'}
                visible={visibleForm}
                footer={null}
                onCancel={() => setVisibleForm(false)}
                centered
                width={800}
            >
                <Form
                    onFinish={saveOption}
                    className="mb-3"
                    ref={formUpdate}
                >
                    <div className="d-flex">
                        <Form.Item name="content" className="flex-fill">
                            <Input placeholder="Nhập nội dung.." />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">Thêm mới</Button>
                        </Form.Item>
                    </div>
                    <div>VD: Khang trang sạch đẹp | Bẩn thỉu, hôi hám</div>
                </Form>
                <Table
                    dataSource={dataOption}
                    columns={[
                        {
                            title: "Nội dung",
                            dataIndex: "content"
                        },
                        {
                            title: "Hiển thị",
                            render: (item) => {
                                return <Switch defaultChecked={item.is_active} size="small" onChange={(e) => {
                                    saveOption({
                                        _id: item._id,
                                        is_active: e ? 1 : 0
                                    })
                                }} />
                            }
                        },
                        {
                            title: "Loại",
                            render: (item) => {
                                return <Select defaultValue={item.type} onChange={(e) => {
                                    saveOption({
                                        _id: item._id,
                                        type: e
                                    })
                                }} style={{ width: "100%" }}>
                                    {
                                        Object.keys(Config.list_cus_type_feedback).map((key) => {
                                            return <Select.Option value={parseInt(key)}>{Config.list_cus_type_feedback[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            }
                        },
                        {
                            title: "Hành động",
                            className: "text-right",
                            render: (item) => {
                                return <React.Fragment>
                                    <Tooltip title="Xóa">
                                        <Button disabled={item.status_test_covid >= 1} size="small" type="danger" onClick={() => {
                                            deleteOption(item._id)
                                        }}><i className="fa fa-trash"></i></Button>
                                    </Tooltip>
                                </React.Fragment>
                            }
                        }
                    ]}
                    pagination={false}
                />
            </Modal>
            <div style={{ marginBottom: "15px" }} className="table-header">
                <h3>Góp ý, khiếu nại: {data.total}</h3>
                <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                    <Button type="primary" onClick={() => setVisibleForm(true)}>Thiết lập nội dung góp ý</Button>
                </div>
                <Form
                    initialValues={{
                        status: ""
                    }}
                    onValuesChange={(e) => {
                        if (timeout.current) clearTimeout(timeout.current);
                        let delay = 0;

                        if (e.phone) delay = 300;

                        timeout.current = setTimeout(() => {
                            getListData();
                        }, delay);
                    }}
                    ref={formFilter}
                >
                    <div className="row">
                        <div className="col-md-2">
                            <Form.Item name="phone">
                                <Input placeholder="Tìm số điện thoại.." />
                            </Form.Item>
                        </div>
                        <div className="col-md-2">
                            <Form.Item name="cus_type">
                                <Select placeholder="Chọn loại khách" allowClear>
                                    {
                                        Object.keys(Config.list_cus_type_feedback).map((key) => {
                                            return <Select.Option value={parseInt(key)}>{Config.list_cus_type_feedback[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-auto">
                            <Button type="danger" onClick={() => {
                                formFilter.current.resetFields();
                                getListData();
                            }}>Xóa lọc</Button>
                        </div>
                        <div className="col-auto">
                            <Tooltip title={'Làm mới'}>
                                <Button type="primary" onClick={getListData}><i className="far fa-sync"></i></Button>
                            </Tooltip>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="table-responsive">
                <Table
                    dataSource={data.listData}
                    columns={[
                        {
                            title: "#",
                            index: "#",
                            responsive: ["md"],
                            render: (item, value, index) => {
                                return (pagination.page * pagination.limit - pagination.limit) + index + 1
                            }
                        },
                        {
                            title: "SDT",
                            dataIndex: "phone"
                        },
                        {
                            title: "Loại khách",
                            dataIndex: "cus_type",
                            render: (item) => {
                                return Config.list_cus_type_feedback[item];
                            }
                        },
                        {
                            title: "Kết quả",
                            dataIndex: "contentment",
                            render: (item) => {
                                return item == 1 ? "Hài lòng" : item == 2 ? "Không hài lòng" : ""
                            }
                        },
                        {
                            title: "Feedback",
                            dataIndex: "rating_text",
                            render: (item) => {
                                return item.join("\n")
                            }
                        },
                        {
                            title: "Ngày thêm",
                            dataIndex: "created_time",
                            className: "text-right",
                            render: (item) => {
                                return moment(item).format("HH:mm:ss DD-MM-YYYY")
                            }
                        }
                    ]}
                    pagination={{
                        total: data.total,
                        pageSize: pagination.limit,
                        onChange: (current, size) => {
                            pagination.page = current;
                            pagination.limit = size;

                            setPagination(pagination);
                        },
                        current: pagination.page,
                        showSizeChanger: false
                    }}
                    rowKey="_id"
                    rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: dataSelected,
                        onChange: (values) => {
                            setDataSelected(values);
                        }
                    }}
                    loading={loading}
                />
            </div>
        </div>
    </div>
}

export default FeedbackSetting;
