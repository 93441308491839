import React, { useEffect, useRef, useState } from "react";

import { Modal, Button, Form, Input, Table, Alert, Tooltip, message } from "antd";
import moment from "moment";
import qs from "qs";

const FormAutoSms = (props) => {
    const { visible, hide } = props;
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 5
    });
    const [ data, setData ] = useState({
        total: 0,
        listData: []
    });

    useEffect(() => {
        if(visible) getListData();
    }, [pagination, visible])

    const getListData = async (values) => {
        try {
            setLoading(true);
            
            let params = {
                limit: pagination.limit,
                page: pagination.page
            }

            let res = await props.fetchData({
                url: `api/v1/auto_sms/list?${qs.stringify(params)}`
            });

            setLoading(false);

            setData({
                total: res.total,
                listData: res.data
            });
        } catch (err) {
            setLoading(false);
        }
    }

    const getDetail = async (id) => {
        try {
            message.loading({ content: "Loading...", key: "select_detail", duration: 0 });

            let res = await props.fetchData({
                url: `api/v1/auto_sms/${id}`
            });

            message.success({ content: "Thao tác thành công", key: "select_detail"});

            if(res.data){
                props.setListPhone(res.data.list_phone);
            }

            hide();

        } catch (err) {
            message.error({ content: err.message, key: "select_detail"});
        }
    }

    return <Modal
        title={'Lịch sử đã gửi'}
        visible={visible}
        footer={null}
        onCancel={hide}
        width={600}
        centered
    >
        <Table 
            columns={[
                {
                    title: "Mã chiến dịch",
                    dataIndex: "campaign_code"
                },
                {
                    title: "Số lượng",
                    dataIndex: "num_target"
                },
                {
                    title: "Mô tả",
                    dataIndex: "note",
                    render: (item) => {
                        return <Input.TextArea value={item}/>
                    }
                },
                {
                    title: "Hành động",
                    className: "text-right",
                    render: (item) => {
                        return <div className="d-flex justify-content-end">
                            <Tooltip title="Chọn">
                                <Button className="mb-2 ml-2" size="small" type="primary" onClick={() => {
                                    getDetail(item._id)
                                }}><i className="fa fa-check"></i></Button>
                            </Tooltip>
                        </div>
                    }
                }
            ]}
            dataSource={data.listData}
            pagination={{
                total: data.total,
                pageSize: pagination.limit,
                onChange: (current, size) => {
                    pagination.page = current;
                    pagination.limit = size;

                    setPagination({...pagination});
                },
                current: pagination.page,
                showSizeChanger: false
            }}
            rowKey="_id"
            loading={loading}
        />
        <div className="d-flex justify-content-end mt-4">
            <Button htmlType="button" style={{ margin: '0 8px' }} onClick={hide}>
                Đóng
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
                Tiếp tục
            </Button>
        </div>
    </Modal>
}

export default FormAutoSms;