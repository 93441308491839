import React, { useEffect, useRef, useState } from "react";

import { Switch, message, Modal, Button, Form, Input, InputNumber, Tooltip, Select, Tag, Tabs, Upload, Table, Checkbox, Alert } from 'antd';

import moment from "moment";

import qs from "qs";

import { WidgetSelectSpinText } from '../../../widgets';

const SmsOtp = (props) => {

    const [data, setData] = useState({
        total: 0,
        listData: [],
        setting: null
    });
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 20
    });
    const [visibleForm, setVisibleForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [dataSelected, setDataSelected] = useState([]);
    const [error, setError] = useState(null);

    const formFilter = useRef();
    const timeout = useRef();
    const formUpdate = useRef();

    useEffect(() => {
        getListData();
    }, [pagination]);

    useEffect(() => {
        if(data.setting){
            setTimeout(() => {
                if(formUpdate.current) formUpdate.current.setFieldsValue(data.setting)
            })
        }
    }, [visibleForm]);

    const getListData = async () => {
        try {
            setLoading(true);

            let params = {
                page: pagination.page,
                limit: pagination.limit
            }

            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() };

            let response = await props.fetchData({
                url: `api/v1/sms_brandname/otp?${qs.stringify(params)}`
            })

            setData({
                total: response.total,
                listData: response.data,
                setting: response.setting
            })

            setLoading(false);
        } catch (err) {
            setLoading(false);
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    const saveSetting = async (values) => {
        try {
            setLoadingForm(true);

            let response = await props.fetchData({
                url: values['_id'] ? `api/v1/auto_target/${values['_id']}` : `api/v1/auto_target/create`,
                method: values['_id'] ? "put" : "post",
                data: values
            })

            setLoadingForm(false);

            message.success({ content: 'Cập nhập dữ liệu thành công', key: "update", duration: 2 });
        } catch (err) {
            setLoadingForm(false);
            message.error({ content: 'Cập nhập dữ liệu thất bại', key: "update", duration: 2 });
        }
    }

    return <div className="list-connect content-m d-flex">
        <div className="list-c">
            <Modal
                title={'Thiết lập nội dung'}
                visible={visibleForm}
                footer={null}
                onCancel={() => setVisibleForm(false)}
                centered
                width={600}
            >
                <Form
                    name="basic"
                    ref={formUpdate}
                    initialValues={{
                        type: 'sms_otp',
                        content: "",
                        name: "Sms Otp"
                    }}
                    onFinish={saveSetting}
                    layout="vertical"
                >
                    {
                        error ? <Alert message={error} type="error" /> : null
                    }
                    <Form.Item name="_id" noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="name" noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="type" noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item label="Trạng thái" name="active">
                        <Select placeholder="Chọn trạng thái">
                            <Select.Option value={1}>Kích hoạt</Select.Option>
                            <Select.Option value={2}>Bỏ kích hoạt</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="content"
                        label="Nội dung"
                        rules={[{ required: true, message: 'Nội dung không được để trống!' }]}
                    >
                        <Input.TextArea style={{ minHeight: "120px" }} />
                    </Form.Item>
                    <WidgetSelectSpinText
                        data={[
                            {
                                value: "#{COMPANY_NAME}",
                                text: "Tên chi nhánh"
                            },
                            {
                                value: "#{COMPANY_ID}",
                                text: "Mã chi nhánh"
                            },
                            {
                                value: "#{OTP_CODE}",
                                text: "Mã Otp"
                            },
                            {
                                value: "#{Spin_1|Spin_2}",
                                text: "Chọn từ khóa ngẫu nhiên"
                            }
                        ]}
                        setValues={(value) => {
                            formUpdate.current.setFieldsValue({ content: formUpdate.current.getFieldValue('content') + value })
                        }}
                        numShow={4}
                    />
                    <Form.Item name="content_type" valuePropName="checked">
                        <Checkbox value={1}>Cho phép gửi tiếng Việt có dấu (sms brandname)</Checkbox>
                    </Form.Item>

                    <div className="d-flex justify-content-end">
                        <Button htmlType="button" style={{ margin: '0 8px' }} onClick={() => setVisibleForm(false)}>
                            Đóng
                        </Button>
                        <Button htmlType="submit" type="primary" loading={loadingForm} >
                            Tiếp tục
                        </Button>
                    </div>
                </Form>
            </Modal>
            <div style={{ marginBottom: "15px" }} className="table-header">
                <h3>Sms otp: {data.total}</h3>
                <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                    <Button type="primary" onClick={() => setVisibleForm(true)}>Thiết lập Sms</Button>
                </div>
                <Form
                    initialValues={{
                        status: ""
                    }}
                    onValuesChange={(e) => {
                        if (timeout.current) clearTimeout(timeout.current);
                        let delay = 0;

                        if (e.phone) delay = 300;

                        timeout.current = setTimeout(() => {
                            getListData();
                        }, delay);
                    }}
                    ref={formFilter}
                >
                    <div className="row">
                        <div className="col-md-2">
                            <Form.Item name="phone">
                                <Input placeholder="Tìm số điện thoại.." />
                            </Form.Item>
                        </div>
                        <div className="col-auto">
                            <Button type="danger" htmlType="reset">Xóa lọc</Button>
                        </div>
                        <div className="col-auto">
                            <Tooltip title={'Làm mới'}>
                                <Button type="primary" onClick={getListData}><i className="far fa-sync"></i></Button>
                            </Tooltip>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="table-responsive">
                <Table
                    dataSource={data.listData}
                    columns={[
                        {
                            title: "#",
                            index: "#",
                            responsive: ["md"],
                            render: (item, value, index) => {
                                return (pagination.page * pagination.limit - pagination.limit) + index + 1
                            }
                        },
                        {
                            title: "SDT",
                            dataIndex: "phone"
                        },
                        {
                            title: "Otp",
                            dataIndex: "otp_code",
                        },
                        {
                            title: "Trạng thái",
                            render: (item) => {
                                return item.status == 2 ? "Đã xác nhận" : item.expired_time < Date.now() ? "Hết hạn" : "Đang chờ"
                            }
                        },
                        {
                            title: "Ngày thêm",
                            dataIndex: "created_time",
                            className: "text-right",
                            render: (item) => {
                                return moment(item).format("HH:mm:ss DD-MM-YYYY")
                            }
                        }
                    ]}
                    pagination={{
                        total: data.total,
                        pageSize: pagination.limit,
                        onChange: (current, size) => {
                            pagination.page = current;
                            pagination.limit = size;

                            setPagination(pagination);
                        },
                        current: pagination.page,
                        showSizeChanger: false
                    }}
                    rowKey="_id"
                    rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: dataSelected,
                        onChange: (values) => {
                            setDataSelected(values);
                        }
                    }}
                    loading={loading}
                />
            </div>
        </div>
    </div>
}

export default SmsOtp;
