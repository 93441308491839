import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, Radio, Steps, Spin, Alert, Pagination, Tooltip, Select, Tag, Tabs, Upload, Table } from 'antd';
import { fetchData } from '../../../lib/apis';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import qs from 'qs';
import moment from 'moment';
import { SketchPicker } from 'react-color';
import { AccountMetaData } from '../../../config/settings';

import ServiceComponent from "./component/service";
import { TagsMessageFb } from '../../../lib/config';

export default class SettingCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            groupTag: [],
            total: 0,
            tagColorEdit: "#000",
            timeWork: null,
            listCompanyCode: [],
            activeTab: ""
        }
        this.limit = 20;
        this.page = 1;
        this.day_timwork = [2, 3, 4, 5, 6, 7, 8];
    }
    componentWillMount() {
        this.getListCompanyCode();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async (options = {}) => {
        try {
            this.setState({ loading: true });
            let { type } = options;
            if (type == 'reset') this.page = 1;
            let params = {
                limit: this.limit,
                page: this.page
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/company/tag/list?${qs.stringify(params)}`
            });

            this.setState({
                listData: res.data,
                loading: false,
                groupTag: res.tag_parent,
                total: res.total
            }, () => {
                if (document.querySelector('[name="checkall"]')) this.props.handleChecked(null, document.querySelector('[name="checkall"]'));
            })
        } catch (err) {
            this.setState({ loading: false });
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    getListDataService = async (options = {}) => {
        try {
            this.setState({ loading: true });
            let params = {
                limit: this.limit,
                page: this.page,
                type: "LIST_DV"
            }
            if (this.formFilterService) params = { ...params, ...this.formFilterService.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/company/service/list?${qs.stringify(params)}`
            });

            this.setState({
                listDataService: res.data,
                loading: false,
                totalService: res.total,
                groupService: res.parent
            })
        } catch (err) {
            this.setState({ loading: false });
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    getTimeWork = async () => {
        try {
            this.setState({ loading: true });

            let res = await fetchData({
                url: `api/v1/company/setting/timework`
            });

            let timework = res.data ? res.data.timework : null;

            this.setState({
                loading: false,
                timeWork: timework
            }, () => {
                timework = timework ? timework : {};

                if (this.formTimeWork) {
                    Object.keys(timework).forEach((key) => {
                        this.formTimeWork.setFieldsValue({
                            [`time_in_work_${key}`]: timework[key]['time_in_work'],
                            [`time_out_work_${key}`]: timework[key]['time_out_work']
                        })
                    })
                }
            })

        } catch (err) {
            this.setState({ loading: false });
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    deleteMany = (options = {}) => {
        let { ids } = options;
        if (!ids || ids.length <= 0) {
            this.props.notification({
                title: "Xóa nhãn",
                content: "Chọn ít nhất một đối tượng",
                type: "error"
            })
            return
        }
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await fetchData({
                            url: `api/v1/company/tag/`,
                            method: 'delete',
                            data: { tag_ids: ids }
                        })
                        document.querySelector('[name="checkall"]').click();
                        _this.props.notification({
                            title: "Xóa nhãn",
                            content: "Thao tác thành công",
                            type: "success"
                        })
                        _this.getListData();
                        return resolve(true);
                    } catch (err) {
                        _this.props.notification({
                            title: "Xóa nhãn",
                            content: err.message ? err.message : 'Đã có lỗi xảy ra!',
                            type: "error"
                        })
                        return reject(false);
                    }
                })
            },
            onCancel() { },
        });
    }

    componentWillUnmount = () => {

    }

    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    updateTag = async (values) => {
        try {
            this.setState({ loadingEdit: true });
            let url = `api/v1/company/tag`;
            let method = 'post';
            if (values['tag_id']) {
                url = `api/v1/company/tag/${values['tag_id']}`;
                method = 'put';
            }
            await fetchData({
                url: url,
                method: method,
                data: values
            })
            this.setState({
                loadingEdit: false
            })
            this.getListData();
            this.props.notification({
                title: "Cập nhập nhãn",
                content: 'Thao tác thành công!'
            })
        } catch (err) {
            this.setState({ loadingEdit: false });
            this.props.notification({
                title: "Cập nhập nhãn",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }
    createGroupChannel = async (values) => {
        try {
            this.props.message({
                type: 'loading',
                duration: 20,
                content: 'Đang xử lý..',
                key: 'create_group_channel'
            })
            let response = await fetchData({
                url: `api/v1/group/channel`,
                method: 'post',
                data: values
            })
            if (response.data) {
                this.setState({
                    channelGroup: [response.data, ...this.state.channelGroup]
                })
            }
            this.props.message({
                type: 'success',
                duration: 2,
                content: 'Thao tác thành công',
                key: 'create_group_channel'
            })
        } catch (err) {
            this.props.message({
                type: 'error',
                duration: 2,
                content: err.message || 'Thao tác thất bại',
                key: 'create_group_channel'
            })
        }
    }
    deleteGroupChannel = async (id) => {
        try {
            this.props.message({
                type: 'loading',
                duration: 20,
                content: 'Đang xử lý..',
                key: 'edit_group_channel'
            })
            let response = await fetchData({
                url: `api/v1/group/channel/${id}`,
                method: 'delete'
            })

            let channelGroup = this.state.channelGroup;
            let findIndex = channelGroup.findIndex((item) => item._id == id);
            if (findIndex > -1) {
                channelGroup.splice(findIndex, 1);
                this.setState({ channelGroup })
            }

            this.getListData();

            this.props.message({
                type: 'success',
                duration: 2,
                content: 'Thao tác thành công',
                key: 'edit_group_channel'
            })
        } catch (err) {
            this.props.message({
                type: 'error',
                duration: 2,
                content: err.message || 'Thao tác thất bại',
                key: 'edit_group_channel'
            })
        }
    }
    showEditGroupChannel = (id) => {
        try {
            let find = this.state.channelGroup.findIndex((item) => item._id == id);
            if (find > -1) {
                this.state.channelGroup[find]['edit'] = true;
                this.setState({
                    reload: !this.state.reload
                })
            }
        } catch (err) {

        }
    }
    saveGroupTag = async (e, tag_id) => {
        try {
            let tag_name = e.target.closest('tr').querySelector('[name="tag_name"]').value;
            if (!tag_name) throw ({ message: 'Tên không hợp lệ!' })
            this.props.message({
                type: 'loading',
                duration: 20,
                content: 'Đang xử lý..',
                key: 'edit_group_channel'
            })
            let response = await fetchData({
                url: `api/v1/company/tag/${tag_id}`,
                method: 'put',
                data: {
                    tag_name: tag_name
                }
            })
            if (response.data) {
                let groupTag = this.state.groupTag;
                let findIndex = groupTag.findIndex((item) => item.tag_id == tag_id);
                if (findIndex > -1) {
                    groupTag[findIndex]['edit'] = false;
                    groupTag[findIndex]['tag_name'] = tag_name;
                    this.setState({ groupTag })
                }
            }

            this.getListData();

            this.props.message({
                type: 'success',
                duration: 2,
                content: 'Thao tác thành công',
                key: 'edit_group_channel'
            })
        } catch (err) {
            this.props.message({
                type: 'error',
                duration: 2,
                content: err.message || 'Thao tác thất bại',
                key: 'edit_group_channel'
            })
        }
    }
    showEditGroupTag = (id) => {
        let findIndex = this.state.groupTag.findIndex((item) => item._id == id);
        if (findIndex > -1) {
            this.state.groupTag[findIndex]['edit'] = true;
            this.setState({
                reload: !this.state.reload
            })
        }
    }
    updateCompany = async (values) => {
        try {
            if (values['company_address_code']) {
                values['company_address'] = {
                    code: values['company_address_code'],
                    name: values['company_name']
                }
            }
            if (values['picture'] && values['picture']['file'] && values['picture']['file']['status'] == "done") {
                values['picture'] = values['picture']['file']['response']['attachment']['url'];
            } else {
                delete values['picture'];
            }
            if (values['hotline_other']) {
                values['hotline_other'] = values['hotline_other'].split('|').map((item) => item.replace(/\s/gi, ''))
            }
            this.setState({ loading: true });
            await fetchData({
                url: `api/v1/company/${values['_id']}`,
                method: 'put',
                data: values
            })
            this.setState({
                loading: false
            })
            this.props.notification({
                title: "Cập nhập thiết lập chi nhánh",
                content: 'Thao tác thành công!'
            })
            this.props.handleCompanySelected({
                ...this.props.companySelected,
                ...values
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Cập nhập thiết lập chi nhánh",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }
    saveTimeWork = async (values) => {
        try {
            this.setState({ loading: true });

            let timework = {};

            this.day_timwork.map((day) => {
                timework[day] = {
                    time_in_work: values[`time_in_work_${day}`],
                    time_out_work: values[`time_out_work_${day}`]
                }
            })

            await fetchData({
                url: `api/v1/company/setting/timework`,
                method: 'post',
                data: {
                    timework: timework
                }
            })

            this.setState({
                loading: false
            })

            this.props.notification({
                title: "Cập nhập thời gian làm việc",
                content: 'Thao tác thành công!'
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Cập nhập thời gian làm việc",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }
    getListCompanyCode = async () => {
        try {
            this.setState({ loadingCompanyAddressCode: true });

            let res = await fetchData({
                url: `api/v1/his/branch`
            });

            this.setState({
                listCompanyCode: res.data,
                loadingCompanyAddressCode: false
            })
        } catch (err) {
            this.setState({ loadingCompanyAddressCode: false });
        }
    }

    saveService = async (values) => {
        try {
            this.setState({ loadingEdit: true });
            let url = `api/v1/company/service`;
            let method = 'post';
            if (values['_id']) {
                url = `api/v1/company/service/${values['_id']}`;
                method = 'put';
            }
            values['type'] = 'LIST_DV';

            await fetchData({
                url: url,
                method: method,
                data: values
            })
            this.setState({
                loadingEdit: false,
                visibleFormService: false
            })
            this.getListDataService();
            this.props.notification({
                title: "Cập nhập dịch vụ",
                content: 'Thao tác thành công!'
            })
            this.formService.resetFields()
        } catch (err) {
            this.setState({ loadingEdit: false });
            this.props.notification({
                title: "Cập nhập dịch vụ",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }

    deleteServiceMany = (options = {}) => {
        let { ids } = options;
        if (!ids || ids.length <= 0) {
            this.props.notification({
                title: "Xóa dịch vụ",
                content: "Chọn ít nhất một đối tượng",
                type: "error"
            })
            return
        }
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await fetchData({
                            url: `api/v1/company/service/`,
                            method: 'delete',
                            data: { ids: ids }
                        })
                        _this.props.notification({
                            title: "Xóa dịch vụ",
                            content: "Thao tác thành công",
                            type: "success"
                        })
                        _this.getListDataService();
                        return resolve(true);
                    } catch (err) {
                        _this.props.notification({
                            title: "Xóa dịch vụ",
                            content: err.message ? err.message : 'Đã có lỗi xảy ra!',
                            type: "error"
                        })
                        return reject(false);
                    }
                })
            },
            onCancel() { },
        });
    }

    render() {
        if (!this.props.companySelected) return null;
        return (
            <div className="list-connect content-m d-flex">
                <Modal
                    visible={this.state.visibleSketchPicker}
                    onCancel={() => this.setState({ visibleSketchPicker: false })}
                    closable={false}
                    footer={[
                        <Button key="back" onClick={() => this.setState({ visibleSketchPicker: false })}>
                            OK
                        </Button>
                    ]}
                >
                    <SketchPicker
                        width={"100%"}
                        color={this.state.tagColorEdit || "#000"}
                        onChange={(color) => {
                            this.setState({
                                tagColorEdit: color.hex
                            }, () => {
                                this.formEdit.setFieldsValue({
                                    "tag_color": color.hex
                                })
                            })
                        }}
                    />
                </Modal>
                <Modal
                    title={'Cập nhập nhãn'}
                    visible={this.state.visibleFormEdit}
                    onCancel={() => this.setState({ visibleFormEdit: null })}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formEdit = evt}
                        onFinish={this.updateTag}
                        layout="vertical"
                    >
                        <Form.Item noStyle name="tag_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="tag_name" label="Tên"
                            rules={[{ required: true, message: 'Tên không được để trống' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="tag_color" noStyle>
                            <Input type="hidden" value={this.state.tagColorEdit} />
                        </Form.Item>
                        <Form.Item label={"Màu"}>
                            {
                                this.state.tagColorEdit ?
                                    <div className="d-flex">
                                        <span style={{ marginRight: "15px" }}>{this.state.tagColorEdit}</span>
                                        <Tag className="ant-tag-h" style={{ cursor: "pointer", borderRadius: "5px!important", boxShadow: "0 3px 6px rgba(0,0,0,0.14)", minWidth: "80px" }} color={this.state.tagColorEdit} onClick={() => this.setState({ visibleSketchPicker: true })}></Tag>
                                    </div>
                                    : null
                            }
                        </Form.Item>
                        <Form.Item name="parent_id" label="Nhóm"
                        // rules={[{ required: true, message: 'Nhóm không được để trống' }]}
                        >
                            <Select
                                placeholder="Chọn nhóm"
                            >
                                <Select.Option value={0}>Chọn nhóm</Select.Option>
                                {
                                    this.state.groupTag.map((item, i) => {
                                        return <Select.Option key={i} value={item.tag_id}>{item.tag_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <div style={{ textAlign: "right" }} className="mt-4">
                            <Button onClick={() => this.setState({ visibleFormEdit: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingEdit}>
                                Cập nhập
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title={'Quản lý nhóm nhãn'}
                    visible={this.state.visibleGroupTag}
                    footer={null}
                    onCancel={() => this.setState({ visibleGroupTag: null })}
                >
                    <Form
                        onFinish={this.updateTag}
                    >
                        <div className="d-flex">
                            <Form.Item name="tag_name" style={{ width: "100%" }}>
                                <Input placeholder="Nhập tên nhóm" />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">Thêm mới</Button>
                        </div>
                    </Form>
                    <div className="table-responsive table-fixed" style={{ overflow: "auto", maxHeight: "250px" }}>
                        <table className="table table-vcenter">
                            <thead>
                                <tr>
                                    <th>Tên nhóm</th>
                                    <th className="text-right">Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.groupTag.length > 0 ? this.state.groupTag.map((item) => {
                                        return <tr key={item._id}>
                                            <td>
                                                {
                                                    item.edit ?
                                                        <Input defaultValue={item.tag_name} name="tag_name" />
                                                        : item.tag_name
                                                }
                                            </td>
                                            <td className="text-right">
                                                {
                                                    !item.edit ? <Button size="small" type="primary" className="mr-2" onClick={() => this.showEditGroupTag(item._id)}><i class="far fa-edit"></i></Button> :
                                                        <Button size="small" className="mr-2" type="primary" onClick={(e) => this.saveGroupTag(e, item.tag_id)}><i class="far fa-check"></i></Button>
                                                }
                                                <Button size="small" type="danger" onClick={() => this.deleteMany({
                                                    ids: [item.tag_id]
                                                })}><i class="far fa-trash"></i></Button>
                                            </td>
                                        </tr>
                                    }) : <tr><td colSpan="12" className="text-center"> Chưa có dữ liệu</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal>

                <Modal
                    title={'Cập nhập dịch vụ'}
                    visible={this.state.visibleFormService}
                    onCancel={() => this.setState({ visibleFormService: null }, () => this.formService.resetFields())}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formService = evt}
                        onFinish={this.saveService}
                        layout="vertical"
                    >
                        <Form.Item noStyle name="_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="name" label="Tên"
                            rules={[{ required: true, message: 'Tên không được để trống' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="parent_id" label="Nhóm"
                        >
                            <Select
                                placeholder="Chọn nhóm"
                                allowClear={true}
                            >
                                <Select.Option value={0}>Chọn nhóm</Select.Option>
                                {
                                    this.state.groupService ? this.state.groupService.map((item, i) => {
                                        return <Select.Option key={i} value={item.service_id}>{item.name}</Select.Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="code_his" label="Mã his"
                        // rules={[{ required: true, message: 'Tên không được để trống' }]}
                        >
                            <Input />
                        </Form.Item>

                        <div style={{ textAlign: "right" }} className="mt-4">
                            <Button onClick={() => this.setState({ visibleFormService: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingEdit}>
                                Cập nhập
                            </Button>
                        </div>
                    </Form>
                </Modal>

                <div className="list-c">
                    <Tabs type="card" onChange={(e) => {
                        this.page = 1;
                        if (e == "time_work") {
                            this.getTimeWork();
                        } else if (e == 'manager_tag') {
                            this.getListData();
                        } else if (e == "manager_service") {
                            this.getListDataService();
                        }
                        this.setState({ activeTab: e })
                    }}>
                        <Tabs.TabPane tab="Thông tin" key="info_company">
                            <div style={{ padding: "20px", background: "#fafafa" }}>
                                <Form
                                    initialValues={{
                                        company_name: this.props.companySelected.company_name,
                                        _id: this.props.companySelected._id,
                                        email: this.props.companySelected.email,
                                        phone: this.props.companySelected.phone,
                                        address: this.props.companySelected.address,
                                        company_address_code: this.props.companySelected.company_address ? this.props.companySelected.company_address.code : "",
                                        hotline: this.props.companySelected.hotline,
                                        hotline_other: this.props.companySelected.hotline_other ? this.props.companySelected.hotline_other.join(' | ') : '',
                                        slug_booking: this.props.companySelected.slug_booking || "",
                                        booking_type: this.props.companySelected.booking_type || 0,
                                        api_his: this.props.companySelected.api_his,
                                        sid_his: this.props.companySelected.sid_his,
                                        is_show_booking: this.props.companySelected.is_show_booking,
                                        thoi_gian_kham_benh: this.props.companySelected.thoi_gian_kham_benh,
                                        telegram_config: this.props.companySelected.telegram_config,
                                        message_tag_default: this.props.companySelected.message_tag_default
                                    }}
                                    onFinish={(values) => {
                                        if (values['banner'] && values['banner']['file'] && values['banner']['file']['status'] == "done") {
                                            values['banner'] = values['banner']['file']['response']['attachment']['url'];
                                        }
                                        this.updateCompany(values);
                                    }}
                                    layout="vertical"
                                >
                                    <Form.Item label="Logo">
                                        <div className="mb-3">
                                            <div>
                                                <img src={this.props.companySelected.picture} className="company-picture-preview"
                                                    onError={(e) => e.target.src = require('../../../img/no_images.png')}
                                                />
                                            </div>
                                        </div>
                                        <Form.Item name="picture">
                                            <Upload
                                                action={`${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/uploads`}
                                                withCredentials={true}
                                                headers={{
                                                    Authorization: AccountMetaData.getToken()
                                                }}
                                                // showUploadList={false}
                                                maxCount={1}
                                                accept={'image/*'}
                                                beforeUpload={(e) => {
                                                    document.querySelector('.company-picture-preview').setAttribute("src", URL.createObjectURL(e));
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Form.Item>
                                    <p style={{ marginTop: "10px" }}><i>Logo (mã màu #506dad, chiều cao 40px)</i></p>
                                    <Form.Item label="Banner">
                                        <div className="mb-3">
                                            <div>
                                                <img src={this.props.companySelected.banner || require('../../../img/no_images.png')} id="img-company-banner" className="company-picture-preview"
                                                    onError={(e) => e.target.src = require('../../../img/no_images.png')}
                                                />
                                            </div>
                                        </div>
                                        <Form.Item name="banner">
                                            <Upload
                                                action={`${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/uploads`}
                                                withCredentials={true}
                                                headers={{
                                                    Authorization: AccountMetaData.getToken()
                                                }}
                                                // showUploadList={false}
                                                maxCount={1}
                                                accept={'image/*'}
                                                beforeUpload={(e) => {
                                                    document.querySelector('#img-company-banner').setAttribute("src", URL.createObjectURL(e));
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item name="_id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="company_name" label="Tên chi nhánh"
                                                rules={[{ required: true, message: 'Tên không được để trống' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item name="email" label="Email">
                                                <Input />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="phone" label="Số điện thoại">
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item name="address" label="Địa chỉ">
                                                <Input />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="hotline_other" label="Hotline kinh doanh">
                                                <Input placeholder="hotline 1 | hotline 2.." />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item name="hotline" label="Hotline ưu tiên gọi ra">
                                                <Input />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="company_address_code" label="Mã Chi nhánh">
                                                {/* <Select loading={this.state.loadingCompanyAddressCode}>
                                                    {
                                                        this.state.listCompanyCode.map((item, i) => {
                                                            return <Select.Option value={item.MaCoSo} key={i}>{item.TenCoSo}</Select.Option>
                                                        })
                                                    }
                                                </Select> */}
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item label="Link lịch hẹn web">
                                                <Input value={`${window.location.origin}/lichhen?ref=${this.props.companySelected._id_code}`} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="booking_type" label="Thiết lập loại lịch hẹn">
                                                <Select>
                                                    <Select.Option value={0}>Cho phép đặt lịch trùng</Select.Option>
                                                    <Select.Option value={1}>Không cho phép đặt lịch trùng</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        {
                                            this.props.companySelected.hotline ?
                                                <div className="col-md-6">
                                                    <Form.Item label="Callcenter callback">
                                                        <Input value={`${window.location.origin}/callcenter/callback?type=1&code=${this.props.companySelected._id_code}`} />
                                                    </Form.Item>
                                                </div> : null
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="api_his" label="Api his" extra={`Cấu trúc: http://abc.vn|username|password`}>
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item label="Key đồng bộ crm -> His">
                                                <Input value={this.props.companySelected._id_code} readOnly/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="thoi_gian_kham_benh" label="Khung giờ khám bệnh">
                                                <Input placeholder="vd: 08:00-12:00 | 13:00-17:00" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item label="Link góp ý">
                                                <Input value={`${window.location.origin}/${this.props.companySelected.company_id}/gop-y`} />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item name="telegram_config" label="Telegram Config">
                                                <Input placeholder="Cấu trúc: bot_token@chatid" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item name="message_tag_default" label="Message tag fb"
                                                tooltip={"Dùng để gửi tin nhắn cho khách hàng fb sau 24h"}
                                            >
                                                <Select
                                                    placeholder="Chọn tag"
                                                    optionFilterProp="children"
                                                    className="text-left"
                                                    allowClear
                                                >
                                                    {
                                                        TagsMessageFb.map((item) => {
                                                            return <Select.Option value={item}>{item}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item name="is_show_booking" label="Cho phép đặt lịch hẹn ở chi nhánh khác" >
                                                <Radio.Group>
                                                    <Radio value={0}>Không</Radio>
                                                    <Radio value={1}>Có</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div> */}
                                    <Form.Item style={{ marginTop: "20px" }}>
                                        <Button htmlType="submit" type="primary" loading={this.state.loading}>Cập nhập</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Khai báo khám chữa bệnh" key="khaibao_yte">
                            <div style={{ padding: "20px", background: "#fafafa" }}>
                                {/* <div className="mb-3 d-flex align-items-center">
                                    <Button className="mr-2"
                                        onClick={() => {
                                            this.props.history.push(`/khaibaoyte/bacsy`)
                                        }}
                                    >Quản lý Bác Sỹ</Button>
                                    <Button 
                                        onClick={() => {
                                            this.props.history.push(`/khaibaoyte/testcovid`)
                                        }}
                                    >Quản lý Test Covid</Button>
                                </div> */}
                                <Form
                                    initialValues={this.props.companySelected.khaibao_yte ? {
                                        ...this.props.companySelected.khaibao_yte
                                    } : {}}
                                    onFinish={(values) => {
                                        try {
                                            this.updateCompany({
                                                _id: this.props.companySelected._id,
                                                khaibao_yte: values
                                            })

                                        } catch (err) {

                                        }
                                    }}
                                    layout="vertical"
                                >
                                    <a href={`${window.location.origin}/${this.props.companySelected.company_id}/khai-bao-yte`} target="_blank">{`${window.location.origin}/${this.props.companySelected.company_id}/khai-bao-yte`}</a>

                                    <div className="mt-5"></div>
                                    <Form.Item name="khaibao_dichte" valuePropName="checked" label="Khai báo dịch tễ">
                                        <Checkbox className="ml-2">Hiển thị</Checkbox>
                                    </Form.Item>
                                    <Form.Item style={{ marginTop: "20px" }}>
                                        <Button htmlType="submit" type="primary" loading={this.state.loading}>Cập nhập</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Quản lý Bác Sỹ" key="doctor">
                            {
                                this.state.activeTab == "doctor" ? <ServiceComponent {...this.props} type="LIST_BS" /> : null
                            }
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Quản lý test covid" key="testcovid">
                            {
                                this.state.activeTab == "testcovid" ? <ServiceComponent {...this.props} type="LIST_TEST_COVID" /> : null
                            }
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Quản lý nhãn" key="manager_tag">
                            <div style={{ marginBottom: "15px" }} className="table-header">
                                <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                                    <Tooltip title={'Làm mới'}>
                                        <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                                    </Tooltip>
                                    <Tooltip title={'Xóa nhiều'}>
                                        <a className="btn btn-danger text-white mr-2" onClick={() => {
                                            let ids = []
                                            document.querySelectorAll("[name='check_item']:checked").forEach((item) => {
                                                ids.push(item.value);
                                            })
                                            this.deleteMany({
                                                ids: ids
                                            })
                                        }}><i className="far fa-trash"></i></a>
                                    </Tooltip>
                                    <Tooltip title={'Quản lý nhóm'}>
                                        <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleGroupTag: true })}><i className="far fa-tags"></i></a>
                                    </Tooltip>
                                    <a className="btn btn-primary text-white mr-2" onClick={() => {
                                        this.setState({
                                            visibleFormEdit: true
                                        }, async () => {
                                            if (this.formEdit) this.formEdit.setFieldsValue({
                                                tag_id: "",
                                                parent_id: 0,
                                                tag_name: "",
                                                tag_color: "#2196f3"
                                            })
                                        })
                                    }}>Thêm nhãn</a>
                                </div>
                                <Form
                                    ref={(evt) => this.formFilter = evt}
                                    initialValues={{
                                        parent_id: "",
                                    }}
                                    onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                                >
                                    <div className="row justify-content-between">
                                        <div className="col-8">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Item name="parent_id">
                                                        <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', value: e })}>
                                                            <Select.Option value={""}>Chọn nhóm</Select.Option>
                                                            {
                                                                this.state.groupTag.map((item) => {
                                                                    return <Select.Option key={item.tag_id} value={item.tag_id}>{item.tag_name}</Select.Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <Pagination
                                                total={this.state.total}
                                                showSizeChanger
                                                pageSize={this.limit}
                                                pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                                current={this.page}
                                                onShowSizeChange={(current, size) => {
                                                    this.limit = size;
                                                    this.getListData();
                                                }}
                                                onChange={(current) => {
                                                    this.page = current;
                                                    this.getListData();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div className="table-responsive">
                                <Spin spinning={this.state.loading}>
                                    <table className="table table-striped table-vcenter">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <label className="css-control css-control-primary css-checkbox p-0">
                                                        <input name="checkall" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked} />
                                                        <span className="css-control-indicator"></span>
                                                    </label>
                                                </th>
                                                <th>Stt</th>
                                                <th>Tên</th>
                                                <th>Màu</th>
                                                <th>Nhóm</th>
                                                <th className="text-right">Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listData.length > 0 ?
                                                    this.state.listData.map((item, i) => {
                                                        let stt = this.limit * (this.page - 1) + (i + 1);
                                                        return <tr key={item._id + '_' + Date.now()}>
                                                            <td>
                                                                <label className="css-control css-control-primary css-checkbox p-0">
                                                                    <input name="check_item" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked} value={item.tag_id} />
                                                                    <span className="css-control-indicator"></span>
                                                                </label>
                                                            </td>
                                                            <td>
                                                                {stt}
                                                            </td>
                                                            <td>{item.tag_name}</td>
                                                            <td>
                                                                <Tag color={item.tag_color}>{item.tag_color}</Tag>
                                                            </td>
                                                            <td>
                                                                {item.parent ? item.parent.tag_name : ""}
                                                            </td>
                                                            <td className="text-right">
                                                                <Tooltip title="Xóa">
                                                                    <Button size="small" type="danger" className="mr-2" onClick={() => this.deleteMany({ ids: [item.tag_id] })}><i className="far fa-trash-alt"></i></Button>
                                                                </Tooltip>
                                                                <Tooltip title="Chỉnh sửa">
                                                                    <Button size="small" type="primary" onClick={() => {
                                                                        this.setState({
                                                                            visibleFormEdit: true,
                                                                            tagColorEdit: item.tag_color || "#2196f3"
                                                                        }, async () => {
                                                                            if (!this.formEdit) {
                                                                                await this.props.sleep(100)
                                                                            }
                                                                            this.formEdit.setFieldsValue({
                                                                                tag_id: item.tag_id,
                                                                                parent_id: item.parent_id,
                                                                                tag_name: item.tag_name,
                                                                                tag_color: item.tag_color
                                                                            })
                                                                        })
                                                                    }}><i className="far fa-edit"></i></Button>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    }) : <tr>
                                                        <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </Spin>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Quản lý dịch vụ" key="manager_service">
                            <div style={{ marginBottom: "15px" }} className="table-header">
                                <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                                    <Tooltip title={'Làm mới'}>
                                        <a className="btn btn-primary text-white mr-2" onClick={this.getListDataService}><i className="far fa-sync"></i></a>
                                    </Tooltip>
                                    <Tooltip title={'Xóa nhiều'}>
                                        <a className="btn btn-danger text-white mr-2" onClick={() => {
                                            this.deleteServiceMany({
                                                ids: this.state.dataSelected
                                            })
                                        }}><i className="far fa-trash"></i></a>
                                    </Tooltip>
                                    <a className="btn btn-primary text-white mr-2" onClick={() => {
                                        this.setState({
                                            visibleFormService: true
                                        })
                                    }}>Thêm mới</a>
                                </div>
                                <Form
                                    ref={(evt) => this.formFilterService = evt}
                                    initialValues={{
                                        parent_id: "",
                                    }}
                                >
                                    <div className="row justify-content-between">
                                        <div className="col-8">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Item name="parent_id">
                                                        <Select style={{ width: "100%" }} onChange={this.getListDataService}>
                                                            <Select.Option value="">Chọn nhóm</Select.Option>
                                                            {
                                                                this.state.groupService ? this.state.groupService.map((item) => {
                                                                    return <Select.Option key={item.service_id} value={item.service_id}>{item.name}</Select.Option>
                                                                }) : null
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    dataSource={this.state.listDataService}
                                    columns={[
                                        {
                                            title: "#",
                                            index: "#",
                                            render: (item, value, index) => {
                                                return (this.page * this.limit - this.limit) + index + 1
                                            }
                                        },
                                        {
                                            title: "Tên",
                                            dataIndex: "name"
                                        },
                                        {
                                            title: "Mã his",
                                            dataIndex: "code_his"
                                        },
                                        {
                                            title: "Nhóm",
                                            render: (item) => {
                                                return item.parent ? item.parent.name : ""
                                            }
                                        },
                                        {
                                            title: "Ngày thêm",
                                            render: (item) => {
                                                return moment(item.created_time).format('DD-MM-YYYY HH:mm')
                                            }
                                        },
                                        {
                                            title: "Hành động",
                                            className: "text-right",
                                            render: (item) => {
                                                return <div>
                                                    <Tooltip title="Xóa">
                                                        <Button size="small" type="danger" className="mr-2" onClick={() => {
                                                            this.deleteServiceMany({ ids: [item._id] })
                                                        }}><i className="far fa-trash"></i></Button>
                                                    </Tooltip>
                                                    <Tooltip title="Chỉnh sửa">
                                                        <Button size="small" type="primary" onClick={() => {
                                                            this.setState({
                                                                visibleFormService: true
                                                            }, () => {
                                                                setTimeout(() => {
                                                                    if (this.formService) this.formService.setFieldsValue(item);
                                                                })
                                                            })
                                                        }}><i className="far fa-edit"></i></Button>
                                                    </Tooltip>
                                                </div>
                                            }
                                        }
                                    ]}
                                    pagination={{
                                        total: this.state.totalService,
                                        pageSize: this.limit,
                                        onChange: (current) => {
                                            this.page = current;
                                            this.getListDataService();
                                        },
                                        current: this.page,
                                        showSizeChanger: false
                                    }}
                                    rowKey="_id"
                                    rowSelection={{
                                        type: "checkbox",
                                        selectedRowKeys: this.state.dataSelected,
                                        onChange: (values) => {
                                            this.setState({
                                                dataSelected: values
                                            })
                                        }
                                    }}
                                    loading={this.state.loading}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Thời gian làm việc" key="time_work">
                            <Form
                                onFinish={this.saveTimeWork}
                                ref={(evt) => this.formTimeWork = evt}
                            >
                                <div className="mb-1">
                                    Vd: 00:00-07:00 | 13:15-18:00 | 19:00-00:00 (dấu "|" là ngăn cách giữa các khoảng thời gian)
                                </div>
                                <div className="text-right mb-2">
                                    <Button htmlType="submit" type="primary" disabled={this.state.loading}>Lưu cập nhập</Button>
                                </div>
                                <div className="table-responsive">
                                    <Spin spinning={this.state.loading}>
                                        <table className="table table-bordered table-vcenter">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Ngày</th>
                                                    <th className="text-center">Ca</th>
                                                    <th className="">Thời gian</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.day_timwork.map((item) => {
                                                        let html = [
                                                            <tr key={'1_' + item}>
                                                                <td className="text-center" rowSpan={2}>
                                                                    {item <= 7 ? `Thứ ${item}` : 'CN'}
                                                                </td>
                                                                <td className="text-center">Hành chính</td>
                                                                <td style={{ maxWidth: "100px" }}>
                                                                    <Form.Item name={`time_in_work_${item}`}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                </td>
                                                            </tr>,
                                                            <tr key={'2_' + item}>
                                                                <td className="text-center">Ngoài giờ</td>
                                                                <td style={{ maxWidth: "100px" }}>
                                                                    <Form.Item name={`time_out_work_${item}`}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                </td>
                                                            </tr>
                                                        ]
                                                        return html;
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Spin>
                                </div>
                            </Form>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Quản lý bộ phận" key="LIST_BOPHAN">
                            {
                                this.state.activeTab == "LIST_BOPHAN" ? <ServiceComponent {...this.props} type="LIST_BOPHAN" /> : null
                            }
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Quản lý chức vụ" key="LIST_CHUCVU">
                            {
                                this.state.activeTab == "LIST_CHUCVU" ? <ServiceComponent {...this.props} type="LIST_CHUCVU" /> : null
                            }
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane tab="Tab 2" key="2">
                        Content of Tab Pane 2
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Tab 3" key="3">
                        Content of Tab Pane 3
                        </Tabs.TabPane> */}
                    </Tabs>
                </div>
            </div>
        )
    }
}
