import React, { Component } from 'react';
import { Button, Input, Form, Modal, Switch, Select, Pagination, Tooltip, Alert, Upload, Table, InputNumber, Tag } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import qs from 'qs';

import { formatPrice } from '../../../../lib/helpers';

export default class ListDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loading: false,
            dataSelected: [],
            listDataGroup: []
        }
        this.limit = 20;
        this.page = 1;
        this.type_his = props.type || "default";
        this.title = props.title || "Quản lý";
    }
    componentWillMount = () => {
        this.getListData();
        this.getListGroup();
    }
    componentDidMount = () => {

    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit,
                type: this.type_his
            }

            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await this.props.fetchData({
                url: `api/v1/company/service/list?${qs.stringify(params)}`
            });

            this.setState({ listData: res.data, loading: false, total: res.total });

        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    getListGroup = async () => {
        try {
            let params = {
                page: 1,
                limit: 20,
                type: this.type_his,
                parent_id: 0
            }

            let res = await this.props.fetchData({
                url: `api/v1/company/service/list?${qs.stringify(params)}`
            });

            this.setState({ listDataGroup: res.data, loadingGroup: false });

        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    remove = async (ids = []) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ({ message: "ChỌn ít nhất 1 dữ liệu" });

                        let res = await _this.props.fetchData({
                            url: `api/v1/company/service/delete`,
                            method: 'delete',
                            data: {
                                ids: ids
                            }
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Xóa',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Xóa',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }

    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    save = async (values) => {
        try {
            this.setState({ loadingForm: true });

            values['type'] = this.type_his;

            let url = `api/v1/company/service`;
            let method = 'post';

            if (values['_id']) {
                url = `api/v1/company/service/${values['_id']}`;
                method = 'put';
            }

            values['option'] = {};

            Object.keys(values).forEach((key) => {
                if(key.indexOf('option_') > -1) values['option'][key.replace('option_', '')] = values[key];
            })

            await this.props.fetchData({
                url: url,
                data: values,
                method: method
            });

            this.getListData();

            this.setState({ loadingForm: false, visibleForm: false });

            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập dữ liệu',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Cập nhập dữ liệu',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                        title={'Cập nhập dữ liệu'}
                        visible={this.state.visibleForm}
                        footer={null}
                        onCancel={() => this.setState({ visibleForm: false }, () => {
                            this.formH.resetFields();
                        })}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            initialValues={{

                            }}
                            onFinish={this.save}
                            layout="vertical"
                        >
                            {
                                this.state.error ? <Alert message={this.state.error} type="error" /> : null
                            }
                            <Form.Item name="_id" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="name" label="Tên"
                                rules={[{ required: true, message: 'Không được để trống' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="code_his" label="Mã His"
                                // rules={[{ required: true, message: 'Không được để trống' }]}
                            >
                                <Input />
                            </Form.Item>

                            {
                                this.type_his == "LIST_TEST_COVID" ? 
                                <Form.Item name="value" label="Đơn giá" >
                                    <InputNumber style={{width: "100%"}}/>
                                </Form.Item> : null
                            }

                            {
                                ["LIST_TEST_COVID", "LIST_BOPHAN", "LIST_CHUCVU"].indexOf(this.type_his) == -1 && <Form.Item name="parent_id" label="Nhóm"
                                // rules={[{ required: true, message: 'Không được để trống' }]}
                                >
                                    <Select>
                                        <Select.Option value={0}>Chọn nhóm</Select.Option>
                                        {
                                            this.state.listDataGroup.map((item) => {
                                                return <Select.Option value={item.service_id}>{item.name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            }

                            {
                                this.type_his == "LIST_BS" ?
                                <React.Fragment>
                                    <Form.Item name="option_time_check" label="Thời gian khám bệnh/ 1 bệnh nhân (Phút)"
                                    >
                                        <Select>
                                            {
                                                [3,5,10,15,20,30,60].map((item) => {
                                                    return <Select.Option value={item}>{item}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </React.Fragment> : null
                            }

                            <div className="d-flex justify-content-end">
                                <Button htmlType="button" style={{ margin: '0 8px' }} onClick={() => this.setState({ visibleForm: false })}>
                                    Đóng
                                </Button>
                                <Button htmlType="submit" type="primary" loading={this.state.loadingForm} disabled={this.state.loadingForm}>
                                    Tiếp tục
                                </Button>
                            </div>
                        </Form>
                    </Modal>

                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Xóa'}>
                                <a className="btn btn-danger text-white mr-2" onClick={() => this.remove(this.state.dataSelected)}><i className="far fa-trash"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm dữ liệu'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true }, () => {
                                    if (this.formH) this.formH.resetFields();
                                })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <Table
                            dataSource={this.state.listData}
                            columns={[
                                {
                                    title: "#",
                                    index: "#",
                                    render: (item, value, index) => {
                                        return (this.page * this.limit - this.limit) + index + 1
                                    }
                                },
                                {
                                    title: "Tên",
                                    dataIndex: "name"
                                },
                                {
                                    title: "Mã His",
                                    dataIndex: "code_his",
                                },
                                {
                                    title: "Nhóm",
                                    dataIndex: "parent",
                                    render: (item) => item ? item.name : "",
                                    className: ["LIST_TEST_COVID", "LIST_BOPHAN", "LIST_CHUCVU"].indexOf(this.type_his) == -1 ? "" : "d-none"
                                },
                                {
                                    title: "Đơn giá",
                                    dataIndex: "value",
                                    className: this.type_his != "LIST_TEST_COVID" ? "d-none" : "",
                                    render: (item) => {
                                        return formatPrice(item)
                                    }
                                },
                                {
                                    title: "Ngày tạo",
                                    dataIndex: "created_time",
                                    render: (item) => moment(item).format("HH:MM DD-MM-YYYY")
                                },
                                {
                                    title: "Hành động",
                                    className: "text-right",
                                    render: (item) => {
                                        return <div>
                                            <Tooltip title="Xóa">
                                                <Button size="small" type="danger" className="mr-2" onClick={() => {
                                                    this.remove([item._id])
                                                }}><i className="far fa-trash"></i></Button>
                                            </Tooltip>
                                            <Tooltip title="chi tiết">
                                                <Button size="small" type="primary" onClick={() => {
                                                    this.setState({
                                                        visibleForm: true
                                                    }, () => {
                                                        setTimeout(() => {
                                                            try {
                                                                this.formH.setFieldsValue({
                                                                    ...item
                                                                });
                                                                if(item.option){
                                                                    Object.keys(item.option).forEach((key) => {
                                                                        this.formH.setFieldsValue({
                                                                            ['option_'+key] : item.option[key]
                                                                        });
                                                                    })
                                                                }
                                                            } catch (err) {
                                                                console.log(err);
                                                            }
                                                        }, 0)
                                                    })
                                                }}><i className="far fa-edit"></i></Button>
                                            </Tooltip>
                                        </div>
                                    }
                                }
                            ]}
                            dataSource={this.state.listData}
                            rowKey="_id"
                            loading={this.state.loading}
                            pagination={{
                                total: this.state.total,
                                pageSize: this.limit,
                                onChange: (current) => {
                                    this.page = current;
                                    this.getListData();
                                },
                                current: this.page,
                                showSizeChanger: false
                            }}
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: this.state.dataSelected,
                                onChange: (values) => {
                                    this.setState({
                                        dataSelected: values
                                    })
                                }
                            }}
                        />
                    </div>
            </React.Fragment>
        )
    }
}
