import React, { Component } from 'react';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Table, Upload, Pagination, Tooltip, Alert } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';


export default class AutoCallView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loading: false,
            fileList: []
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
        this.searchListAutoCall();
    }
    componentDidMount = () => {

    }

    create = async (values) => {
        try {
            this.setState({ loadingForm: true, error: null });
            if (this.state.fileList) {
                values['play_files'] = [];
                this.state.fileList.forEach((item) => {
                    if (item.status == "done") {
                        values['play_files'].push(item.response.attachment.url);
                    }
                })
            }
            delete values['play_file'];

            if(values['time_run']){
                values['start_time'] = values['time_run'][0].unix() * 1000;
                values['stop_time'] = values['time_run'][1].unix() * 1000;
            }
            values['customer_phone'] = values.customer_phone.split("\n");
            // values['ext_seq'] = values.ext_seq.replace(/\s/gi, "").split(";");

            await fetchData({
                url: `api/v1/callcenter/autocall`,
                data: values,
                method: 'post'
            });

            this.setState({ loadingForm: false, visibleForm: false, fileList: [] });
            this.formH.resetFields();
            this.getListData();
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Thêm bài đăng',
                type: 'success'
            })

        } catch (err) {
            let message = "Đã có lỗi xảy ra!";
            if (typeof err == "object") {
                message = err.message;

                if (err.errorFields && err.errorFields.length > 0) {
                    message = err.errorFields[0]['errors'][0]
                }
            }
            this.setState({ loadingForm: false, error: message });
        }
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/callcenter/autocall?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    update = async (id, data) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn cập nhập',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/broadcast/${id}`,
                            method: 'put',
                            data: data
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Cập nhập',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Cập nhập',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleStatus = async (id, data) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn cập nhập',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/post/auto/${id}`,
                            method: 'put',
                            data: data
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Cập nhập đăng bài',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Cập nhập đăng bài',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    searchListAutoCall = async (keyword) => {
        try {
            this.setState({ loadingSearchAutoCall: true });
            let params = {
                limit: 10,
                name: keyword || ""
            }

            let res = await fetchData({
                url: `api/v1/callcenter/autocall?${qs.stringify(params)}`
            });

            this.setState({ listDataSearch: res.data, loadingSearchAutoCall: false, error: null });
        } catch (err) {
            this.setState({ loadingSearchAutoCall: false, error: err.message || 'Đã có lỗi xảy ra' });
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <Modal
                        title={'Chiến dịch'}
                        visible={this.state.visibleForm}
                        className={"form-modal"}
                        footer={null}
                        onCancel={() => this.setState({ visibleForm: false, error: null, fileList: []}, () => this.formH.resetFields())}
                        width={600}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            onFinish={this.create}
                            initialValues={{
                                content: "",
                                servicenum: this.props.companySelected.hotline
                            }}
                            layout="vertical"
                        >
                            {
                                this.state.error ? <Alert message={this.state.error} type="error" /> : null
                            }

                            <Form.Item name="campid_load" label="Chọn dữ liệu chiến dịch có sẵn">
                                <Select 
                                    placeholder="Tìm tên chiến dịch"
                                    showArrow 
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={false}
                                    onSearch={(e) => {
                                        if (this.timeout_fetchcamp) clearTimeout(this.timeout_fetchcamp);
                                        this.timeout_fetchcamp = setTimeout(() => {
                                            this.searchListAutoCall(e)
                                        }, 300);
                                    }}
                                    allowClear
                                    onChange={(e) => {
                                        let camp = this.state.listDataSearch.find((item) => item._id == e);
                                        if(camp){
                                            this.setState({
                                                fileList: camp.play_files.map((file, i) => {
                                                    return {
                                                        uid: i,
                                                        name: file.split('/').pop(),
                                                        status: 'done',
                                                        response: {
                                                            attachment: {
                                                                url: file
                                                            }
                                                        }
                                                      }
                                                })
                                            }, () => {
                                                this.formH.setFieldsValue({
                                                    name: camp.name,
                                                    servicenum: camp.servicenum
                                                })
                                            })
                                        }
                                    }}
                                >
                                    {
                                        this.state.listDataSearch ? 
                                        this.state.listDataSearch.map((item, i) => {
                                            return <Select.Option value={item._id} key={i}>{item.name}</Select.Option>
                                        }) : null
                                    }
                                </Select>
                            </Form.Item>

                            <div className="block-header-default p-3 border rounded">
                                <Form.Item
                                    name="servicenum"
                                    rules={[{ required: true, message: 'Hotline không được để trống' }]}
                                    label="Hotline"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Tên không được để trống' }]}
                                    label="Tên chiến dịch"
                                >
                                    <Input placeholder="Nhập tên chiến dịch" />
                                </Form.Item>

                                <Form.Item
                                    label="Lời chào"
                                >
                                    <Upload
                                        action={`${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/uploads`}
                                        withCredentials={true}
                                        ref={(evt) => this.uploadFile = evt}
                                        onPreview={(file) => {
                                            let url = file.url;
                                            if (file.response && file.response.attachment) url = file.response.attachment.url;
                                            if (url) window.open(url)
                                        }}
                                        accept={"audio/*"}
                                        fileList={[...this.state.fileList]}
                                        onChange={({ fileList, file }) => {
                                            this.setState({ fileList })
                                        }}
                                        maxCount={3}
                                        multiple
                                    >
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </Form.Item>
                                
                                <Form.Item
                                    name="customer_phone"
                                    rules={[{ required: true, message: 'Không được để trống' }]}
                                    label="Danh sách khách hàng"
                                >
                                    <Input.TextArea style={{ minHeight: "160px" }} />
                                </Form.Item>

                                {/* <Form.Item
                                    name="ext_seq"
                                    rules={[{ required: true, message: 'Không được để trống' }]}
                                    label="Số máy trả lời"
                                >
                                    <Input />
                                </Form.Item> */}

                                <Form.Item
                                    name="time_run"
                                    rules={[{ required: true, message: 'Không được để trống' }]}
                                    label="Thời gian thực hiện"
                                >
                                    <DatePicker.RangePicker disabledDate={(current) => current && current < moment().add(-1, "day").endOf('day')}
                                        showTime={{ format: 'HH:mm' }}
                                        placeholder={["Bắt đầu", "Kết thúc"]}
                                        style={{borderRadius: "0px !important", width: "100%"}}
                                    />
                                </Form.Item>
                            </div>

                            <div style={{ marginTop: "10px", fontWeight: "600" }} className="note">
                                <i>Lưu ý: </i>
                                <p><i>- Danh sách khách hàng: mỗi dòng 1 khách hàng.</i></p>
                                {/* <p><i>- Số máy trả lời VD: 501;502;503.</i></p> */}
                                <p><i>- File lời chào: tối đa 3 file, hệ thống tự gán tự động theo thứ tự.</i></p>
                            </div>
                            <div className="list-button">
                                <Button disabled={this.state.loadingForm} htmlType="submit" type="primary" loading={this.state.loadingForm}>
                                    Tiếp tục
                                </Button>
                            </div>
                        </Form>
                    </Modal>

                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý chiến dịch: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm chiến dịch'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                status: "",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row">
                                <div className="col-md-2">
                                    <Form.Item name="name">
                                        <Input placeholder="Nhập tên chiến dịch" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2">
                                    <Form.Item name="campid">
                                        <Input placeholder="Id chiến dịch" />
                                    </Form.Item>
                                </div>
                                {/* <div className="col-md-2">
                                            <Form.Item name="status">
                                                <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', input: e })}>
                                                    <Select.Option value="">Trạng thái</Select.Option>
                                                    <Select.Option value={0}>Đang xử lý</Select.Option>
                                                    <Select.Option value={1}>Thành công</Select.Option>
                                                    <Select.Option value={2}>Thất bại</Select.Option>
                                                    <Select.Option value={3}>Đang tạm dừng</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div> */}
                                <div className="col-md-1">
                                    <Button type="primary" onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData();
                                    }}>Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Table
                            dataSource={this.state.listData}
                            columns={[
                                {
                                    title: "#",
                                    index: "#",
                                    render: (item, value, index) => {
                                        return (this.page * this.limit - this.limit) + index + 1
                                    }
                                },
                                {
                                    title: "Tên",
                                    dataIndex: "name"
                                },
                                {
                                    title: "Id",
                                    dataIndex: "campid"
                                },
                                {
                                    title: "Hotline",
                                    dataIndex: "servicenum"
                                },
                                {
                                    title: "Play files",
                                    dataIndex: "play_files",
                                    render: (files) => {
                                        return files ? files.map((item, i) => {
                                            return <span><a href={item} target="_blank">File {i+1}</a> {i < files.length - 1 ? ', ' : ''}</span>
                                        }) : null
                                    }
                                },
                                // {
                                //     title: "Máy lẻ",
                                //     dataIndex: "ext_seq",
                                //     render: (value) => value.join(', ')
                                // },
                                {
                                    title: "Thời gian chạy",
                                    render: (item) => {
                                        return <div>{moment(item.start_time).format('HH:mm DD/MM/YYYY')} - {moment(item.stop_time).format('HH:mm DD/MM/YYYY')}</div>
                                    }
                                },
                                {
                                    title: "Hành động",
                                    className: "text-right",
                                    render: (item) => {
                                        return <div>
                                            <Tooltip title="Xóa">
                                                <Button size="small" type="danger" className="mr-2" onClick={() => {
                                                }}><i className="far fa-trash"></i></Button>
                                            </Tooltip>
                                        </div>
                                    }
                                }
                            ]}
                            dataSource={this.state.listData}
                            pagination={{
                                total: this.state.total,
                                pageSize: this.limit,
                                onChange: (current) => {
                                    this.page = current;
                                    this.getListData();
                                },
                                current: this.page,
                                showSizeChanger: false
                            }}
                            rowKey="_id"
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: this.state.dataSelected,
                                onChange: (values) => {
                                    this.setState({
                                        dataSelected: values
                                    })
                                }
                            }}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
