import React, { Component } from 'react';
import { Button, Input, Form, Modal, Switch, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip, Alert, Upload, Checkbox } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import { AccountMetaData } from '../../../config/settings';

const { Option } = Select;

export default class AutoReplyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listCate: [],
            listData: [],
            total: 0,
            loading: false,
            fileList: []
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
        this.getListCate();
    }
    componentDidMount = () => {

    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/product/list?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    remove = async (id) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/product/${id}`,
                            method: 'delete'
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Xóa',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Xóa',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    save = async (values) => {
        try {
            this.setState({ loadingForm: true});
            if (values['attachments']) {
                let attachments = [];
                if (values['attachments']['fileList']) {
                    values['attachments']['fileList'].forEach((item) => {
                        if (item.status == "done") {
                            attachments.push(item.response ? {
                                type: item.response.attachment.type,
                                url: item.response.attachment.url,
                                name: item.response.attachment.name
                            } : { type: item.type, url: item.url, name: item.name })
                        }
                    })
                } else {
                    values['attachments'].forEach((item) => {
                        attachments.push({ type: item.type, url: item.url, name: item.name });
                    })
                }
                values['attachments'] = attachments;
            }
            let url = `api/v1/product`;
            let method = 'post';

            if (values['_id']) {
                url = `api/v1/product/${values['_id']}`;
                method = 'put';
            }

            await fetchData({
                url: url,
                data: values,
                method: method
            });
            this.getListData();
            this.setState({ loadingForm: false, visibleForm: values['_id'] ? this.state.visibleForm : false });

            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập kịch bản',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Cập nhập kịch bản',
                type: 'error'
            })
        }
    }

    getListCate = async (keyword = '') => {
        try {
            let res = await fetchData({
                url: `api/v1/product/cate/list?name=${keyword}`
            });
            this.setState({ listCate: res.data });
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <Modal
                        title={'Cập nhập sản phẩm'}
                        visible={this.state.visibleForm}
                        footer={null}
                        onCancel={() => this.setState({ visibleForm: false })}
                        width={600}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            initialValues={{
                                content: '',
                                is_direct: true
                            }}
                            onFinish={this.save}
                            layout="vertical"
                        >
                            {
                                this.state.error ? <Alert message={this.state.error} type="error" /> : null
                            }
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Item name="_id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item
                                        name="category_id"
                                        label="Chọn danh mục"
                                        rules={[{ required: true, message: 'Không được để trống' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Chọn danh mục"
                                            optionFilterProp="children"
                                            filterOption={false}
                                            onSearch={(e) => {
                                                if (this.timeout_fetchcate) clearTimeout(this.timeout_fetchcate);
                                                this.timeout_fetchcate = setTimeout(() => {
                                                    this.getListCate(e)
                                                }, 300);
                                            }}
                                        >
                                            {this.state.listCate.map((item, i) => {
                                                return <Option key={i} value={item._id}>{item.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="title"
                                        label="Tiêu đề"
                                        rules={[{ required: true, message: 'Tiêu đề không được để trống' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="description"
                                        label="Mô tả"
                                        rules={[{ required: true, message: 'Mô tả không được để trống!' }]}
                                    >
                                        <Input.TextArea style={{ minHeight: "120px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Tệp đính kèm"
                                        name="attachments"
                                    >
                                        <Upload
                                            action={`${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/uploads`}
                                            listType="picture-card"
                                            fileList={[...this.state.fileList]}
                                            className="upload-list-inline"
                                            withCredentials={true}
                                            headers={{
                                                Authorization: AccountMetaData.getToken()
                                            }}
                                            ref={(evt) => this.uploadFile = evt}
                                            onRemove={(e) => {
                                                let index = this.state.fileList.findIndex((item) => item.uid == e.uid);
                                                if (index > -1) {
                                                    let fileList = this.state.fileList;
                                                    fileList.splice(index, 1);
                                                    this.setState({ fileList: fileList })
                                                }
                                            }}
                                            onChange={({ fileList }) => this.setState({ fileList })}
                                            onPreview={(file) => {
                                                let url = file.url;
                                                if (file.response && file.response.attachment) url = file.response.attachment.url;
                                                if (url) window.open(url)
                                            }}
                                        >
                                            {/* <Button icon={<UploadOutlined />}>Upload</Button> */}
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>

                                        </Upload>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <Button htmlType="button" style={{ margin: '0 8px' }} onClick={() => this.setState({ visibleForm: false })}>
                                    Đóng
                                    </Button>
                                <Button htmlType="submit" type="primary" loading={this.state.loadingForm} disabled={this.state.loadingForm}>
                                    Tiếp tục
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý sản phẩm: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm sản phẩm'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true, fileList: [] }, () => {
                                    if (this.formH) this.formH.resetFields();
                                })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                            <Tooltip title={'Danh mục'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => {
                                    this.props.history.push('/product/cate');
                                }}><i className="far fa-folder"></i></a>
                            </Tooltip>
                            <a className="btn btn-primary text-white mr-2" onClick={() => {
                                    this.formFilter.resetFields();
                                }}>Xóa lọc</a>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                category_id: "",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="title">
                                                <Input placeholder="Tiêu đề" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Item name="category_id">
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={false}
                                                    onSearch={(e) => {
                                                        if (this.timeout_fetchcate) clearTimeout(this.timeout_fetchcate);
                                                        this.timeout_fetchcate = setTimeout(() => {
                                                            this.getListCate(e)
                                                        }, 300);
                                                    }}
                                                    onChange={(e) => this.handleFilterData({ type: 'select', input: e })}
                                                    style={{ width: "100%" }}
                                                >
                                                    <Select.Option value="">Danh mục</Select.Option>
                                                    {this.state.listCate.map((item, i) => {
                                                        return <Option key={i} value={item._id}>{item.name}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Stt</th>
                                        <th>Tiêu đề</th>
                                        <th>Mô tả</th>
                                        <th>Danh mục</th>
                                        <th>Ngày thêm</th>
                                        <th className="text-right">Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id}>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>{item.title}</td>
                                                    <td>
                                                        <textarea disabled className="form-control" value={item.description}></textarea>
                                                    </td>
                                                    <td>{item.category ? item.category.name : ""}</td>
                                                    <td>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</td>
                                                    <td className="text-right">
                                                        <Tooltip title="Chi tiết">
                                                            <Button size="small" type="primary" className="mr-2" onClick={() => {
                                                                this.setState({
                                                                    visibleForm: true
                                                                }, () => {
                                                                    setTimeout(() => {
                                                                        try {
                                                                            this.formH.setFieldsValue(item);
                                                                            let fileList = item.attachments.map((item, i) => {
                                                                                return {
                                                                                    uid: `${i}`,
                                                                                    status: 'done',
                                                                                    url: item.url,
                                                                                    type: item.type,
                                                                                    name: item.name
                                                                                }
                                                                            })
                                                                            this.setState({ fileList: fileList })
                                                                        } catch (err) {
                                                                            console.log(err);
                                                                        }
                                                                    }, 0)
                                                                })
                                                            }}><i className="far fa-eye"></i></Button>
                                                        </Tooltip>
                                                        <Tooltip title="Xóa">
                                                            <Button size="small" type="danger" onClick={() => this.remove(item._id)}><i className="far fa-trash"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
