import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {fetchData} from '../lib/apis';
import {Button, Modal} from 'antd';

export default class PopupQuickReply extends Component{
  constructor(props){
    super(props);
    this.state = {
        short_cut: '',
        message: '',
        err: ''
    }
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
      if(nextProps.editQuickReply){
        this.setState({
            short_cut: nextProps.editQuickReply.short_cut,
            message: nextProps.editQuickReply.message,
            quick_reply_id: nextProps.editQuickReply.quick_reply_id
        })
      } else{
        this.setState({
            short_cut: '',
            message: '',
            quick_reply_id: '',
            err: ''
        })
      }
  }
  componentDidMount(){
    
  }
  addDefault = (mes) => {
      this.setState({
          message: this.state.message + ' ' + mes
      })
  }
  saveQuickReply = async () => {
    if(!this.state.short_cut || !this.state.message){
        this.setState({
            err: 'Vui lòng điền đầy đủ thông tin'
        })
        return false;
    }
    let url = 'api/v2/quick_reply/add';
    let method = 'post';
    if(this.state.quick_reply_id){
        url = `api/v2/quick_reply/update/${this.state.quick_reply_id}`;
        method = 'put';
    }
    try{
        let settingSystem = this.props.settingSystem ? {...this.props.settingSystem} : {};
        let response = await fetchData({
            url: url,
            method: method,
            data: {
                short_cut: this.state.short_cut,
                message: this.state.message,
                quick_reply_id: this.state.quick_reply_id
            }
        });
        let replies = settingSystem.quick_reply;
        let add_bol = false;
        replies.forEach((item, i) => {
          if(item.quick_reply_id == this.state.quick_reply_id){
            add_bol = i;
          }
        })
        if(typeof add_bol == 'number'){
          replies[add_bol] = response.data;
        } else{
          replies.unshift(response.data);
        }
        settingSystem['quick_reply'] = replies;
        if(this.props.handleSettingSystem){
            this.props.handleSettingSystem(settingSystem);
            this.props.hide();
        }
    } catch (err){
        this.setState({
            err: err.message ? err.message : 'Đã có lỗi xảy ra, vui lòng thử lại sau!'
        })
    }
  }
  render(){
    return (
        <Modal 
            title={'Quản lý câu trả lời nhanh'}
            visible={this.props.show}
            footer={[
                <Button key="back" onClick={() => this.props.hide()}>
                Đóng
                </Button>,
                <Button key="submit" type="primary" onClick={this.saveQuickReply}>
                Hoàn tất
                </Button>,
            ]}
            onCancel={() => this.props.hide()}
            className="modal-h-antd modal-content-md "
        >
            <div className="modal-form">
                {
                    this.state.err ?
                        <div className="form-group error">{this.state.err}</div> : null
                }
                <div className="form-group">
                    <label>Ký tự tắt</label>
                    <div className="form-group-c">
                        <span className="icon" style={{ height: "30px" }}>/</span>
                        <input onChange={(e) => this.setState({ short_cut: e.target.value })} value={this.state.short_cut} />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-group-c">
                        <textarea onChange={(e) => this.setState({ message: e.target.value })} value={this.state.message} />
                    </div>
                </div>
                <div className="form-group">
                    <div style={{ width: '33%' }}>
                        <div className="btn-add-reply-defaul" onClick={() => this.addDefault('#{full_name}')}>{'#{full_name}'}</div>
                    </div>
                    <div>Chèn tên đầy đủ của khách hàng</div>
                </div>
                <div className="form-group">
                    <div style={{ width: '33%' }}>
                        <div className="btn-add-reply-defaul" onClick={() => this.addDefault('#{username}')}>{'#{username}'}</div>
                    </div>
                    <div>Chèn tên đăng nhập của khách hàng</div>
                </div>
                <div className="form-group">
                    <div style={{ width: '33%' }}>
                        <div className="btn-add-reply-defaul" onClick={() => this.addDefault('#{SPIN_1|SPIN_2}')}>{'#{SPIN_1|SPIN_2}'}</div>
                    </div>
                    <div>Lựa chọn ngẫu nhiên giữa các từ</div>
                </div>
            </div>
        </Modal>
    )
    return(
        <div className="modal modal-h">
            <div className="modal-fix" onClick={() => this.props.hide()}></div>
            <div className="modal-content modal-content-md">
                <div className="modal-header">
                    <div>
                        <i className="material-icons" style={{marginRight: '10px'}}>edit</i>
                        Thêm câu trả lời nhanh
                    </div>
                    <div style={{cursor: 'pointer'}}><i className="material-icons" onClick={() => this.props.hide()}>close</i></div>
                </div>
                <div className="modal-body modal-form">
                    {
                        this.state.err ? 
                        <div className="form-group error">{this.state.err}</div> : null
                    }
                    <div className="form-group">
                        <label>Ký tự tắt</label>
                        <div className="form-group-c">
                            <span className="icon" style={{height: "30px"}}>/</span>
                            <input onChange={(e) => this.setState({short_cut: e.target.value})} value={this.state.short_cut}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group-c">
                             <textarea onChange={(e) => this.setState({message: e.target.value})} value={this.state.message}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div style={{width: '33%'}}>
                            <div className="btn-add-reply-defaul" onClick={() => this.addDefault('#{full_name}')}>{'#{full_name}'}</div>
                        </div>
                        <div>Chèn tên đầy đủ của khách hàng</div>
                    </div>
                    <div className="form-group">
                        <div style={{width: '33%'}}>
                            <div className="btn-add-reply-defaul"  onClick={() => this.addDefault('#{username}')}>{'#{username}'}</div>
                        </div>
                        <div>Chèn tên đăng nhập của khách hàng</div>
                    </div>
                    <div className="form-group">
                        <div style={{width: '33%'}}>
                            <div className="btn-add-reply-defaul"  onClick={() => this.addDefault('#{SPIN_1|SPIN_2}')}>{'#{SPIN_1|SPIN_2}'}</div>
                        </div>
                        <div>Lựa chọn ngẫu nhiên giữa các từ</div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn-h" onClick={() => this.props.hide()}>Bỏ qua</button>
                    <button className="btn-h blue" onClick={() => this.saveQuickReply()}>Tiếp tục</button>
                </div>
            </div>
        </div>
    )
  }
}
