import React, { Component } from 'react';
import { Dropdown, Menu } from 'antd';
import { AccountMetaData, setCookie, dimens } from '../config/settings';
import { Modal, Button, Tooltip, message, Pagination } from 'antd';
import config from '../lib/config';
import { Link } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            user_ids_filter: []
        }
        this.user_ids_filter_v2 = [];
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {
         document.querySelector('.btn-bar-home').addEventListener("click", function(){
             if(document.querySelector('.sidebar').classList.contains("show")){
                document.querySelector('.sidebar').classList.remove('show');
             } else{
                document.querySelector('.sidebar').classList.add('show');
             }
         })
         document.querySelector('.btn-bar-chat').addEventListener("click", function(){
            if(document.querySelector('#sidebar-filter').classList.contains("show")){
               document.querySelector('#sidebar-filter').classList.remove('show');
            } else{
               document.querySelector('#sidebar-filter').classList.add('show');
            }
        })
    }
    showModal = () => {
        this.setState({
            visible: true
        });
    };
    cancelFilter = () => {
        let user_ids_filter = this.state.user_ids_filter;
        this.user_ids_filter_v2 = [];
        this.setState({
            visible: false,
            user_ids_filter: []
        }, () => {
            if (this.props.handleFilterOwner && user_ids_filter.length > 0) this.props.handleFilterOwner([]);
        });
    };
    filterOwner = () => {
        let user_ids_filter = [...this.state.user_ids_filter];
        this.user_ids_filter_v2 = user_ids_filter;
        this.setState({
            confirmLoading: true
        }, () => {
            if (this.props.handleFilterOwner) this.props.handleFilterOwner(user_ids_filter);
            this.setState({
                confirmLoading: false,
                visible: false
            })
        })
    }
    selectOwner = (e, user_id) => {
        e.preventDefault();
        let user_ids_filter = this.state.user_ids_filter;
        if (user_ids_filter.indexOf(user_id) == -1) {
            user_ids_filter.push(user_id);
        } else {
            user_ids_filter.splice(user_ids_filter.indexOf(user_id), 1);
        }
        this.setState({
            user_ids_filter: user_ids_filter
        })
    }
    selectCompany = (company) => {
        setCookie(dimens.name_company, JSON.stringify({
            company_id: company.company_id
        }), 7);
        window.location.href = '/';
    }
    render() {
        let menu = (
            <Menu>
                <Menu.Item>
                    <a href="/" rel="noopener noreferrer">
                        Quản lý kênh
                </a>
                </Menu.Item>
                <Menu.Item>
                    <a href="/me" rel="noopener noreferrer" target="_blank">
                        Cá nhân
                </a>
                </Menu.Item>
                <Menu.Item>
                    <a href="#" rel="noopener noreferrer" onClick={(e) => {
                        e.preventDefault();
                        AccountMetaData.logOut();
                    }}>
                        Đăng xuất
                </a>
                </Menu.Item>
            </Menu>
        );
        let avatar_header = '';
        let listOwner = this.props.listOwner;
        let listOwnersV2 = [];
        if (listOwner && listOwner.data.length > 1) {
            let list_owner = [];
            for (let i = 0; i < listOwner.data.length; i++) {
                let item = listOwner.data[i];
                let title = item['username'] || item['full_name'] || item['user_id'];
                if (i <= 1) {
                    if (item['avatar']) {
                        list_owner.push(<div key={'owner_' + i} className="header-avatar">
                            <img className="avatar"
                                src={item['avatar']}
                                onError={(e) => {
                                    e.target.src = require("../img/user.jpg")
                                }} />
                        </div>)
                    } else {
                        list_owner.push(<div key={'owner_' + i} className="header-avatar">
                            <img className="avatar"
                                src={require("../img/user.jpg")} />
                        </div>)
                    }

                }
                let source = config.type_account_social[item['acc_type']];
                if (source) {
                    listOwnersV2.push(
                        <div className="item" key={'owner-filter-' + i}>
                            <Tooltip placement="top" title={title} zIndex={2000}>
                                <div className={this.state.user_ids_filter.indexOf(item['user_id']) != -1 ? 'item-img active' : 'item-img'} onClick={(e) => this.selectOwner(e, item['user_id'])}>
                                    <img src={item['avatar']}
                                        onError={(e) => {
                                            e.target.src = require('../img/no_images.png')
                                        }}
                                    />
                                    <div className="info-source">
                                        {
                                            source['icon'] ?
                                                <img src={source['icon']} /> :
                                                <span>{source['name'] ? source['name'] : 'Chưa xác định'}</span>
                                        }
                                    </div>
                                    {
                                        this.state.user_ids_filter.indexOf(item['user_id']) != -1 ?
                                            <div className="icon-check"><i className="material-icons">check</i></div> : null
                                    }
                                </div>
                            </Tooltip>
                        </div>
                    )
                }
            }
            avatar_header = <div className="list-owners" onClick={this.showModal}>
                {list_owner}
            </div>;
        } else {
            // avatar_header = <div className="header-avatar">
            //     <img className="avatar" src={this.props.UserLogin.avatar ? this.props.UserLogin.avatar : require('../img/user.jpg')} />
            // </div>
        }
        let companyHtml = null;
        if (this.props.Company) {
            let listHtmlCompany = [];
            if (this.props.ListCompany) {
                this.props.ListCompany.forEach((company, i) => {
                    listHtmlCompany.push(<Menu.Item key={i}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.selectCompany(company);
                        }}>
                            <div className="avatar-c">
                                {
                                    company.avatar ?
                                        <img className="avatar" alt="avatar"
                                            src={company.avatar}
                                            onLoad={(e) => {
                                                e.target.className = "avatar-ready avatar";
                                            }}
                                            onError={(e) => {
                                                e.target.src = "/user.jpg"
                                            }}
                                        />
                                        : <div className={`lettertile dark-color5`}>
                                            <div>{company.company_name.slice(0, 1)}</div>
                                        </div>
                                }
                            </div>
                            {company.company_name}
                        </a>
                    </Menu.Item>);
                })
                // if(listHtmlCompany.length >= 5){
                //     listHtmlCompany.push(<Menu.Item className="text-center pl-0">
                //         <Link to="/company/list" >
                //         Xem thêm
                //     </Link></Menu.Item>)
                // }
            }
            let menuCompany = <Menu className="header-list-company">
                {
                    this.props.UserLogin.is_root_company ? 
                    <Menu.Item>
                        <Link to="/company/create" >
                            <span className="icon"><i className="fal fa-plus-circle"></i></span>
                        Tạo chi nhánh mới
                    </Link>
                    </Menu.Item> : null
                }
                <Menu.Item style={{ marginBottom: "10px", borderBottom: "1px solid #eef2f5" }}>
                    <Link to="/company/join" >
                        <span className="icon"><i className="fal fa-exchange-alt"></i></span>
                    Chuyển chi nhánh
                </Link>
                </Menu.Item>
                {listHtmlCompany}
            </Menu>;
            companyHtml = <Dropdown overlay={menuCompany} placement="bottomCenter" trigger={['click']}>
                <div className="company-select">
                    <i className="fal fa-store-alt" style={{ margin: "0px 6px 0px 0px" }}></i>
                    {this.props.Company.company_name}
                    <i className="fas fa-caret-down"></i>
                </div>
            </Dropdown>;
        }
        return (
            <div className="header"
                style={{
                    paddingTop: `${this.props.statusBarHeight}px`
                }}
            >
                <Modal
                    title={'Quản lý nguồn'}
                    visible={this.state.visible}
                    onOk={this.filterOwner}
                    onCancel={() => this.setState({ visible: false, user_ids_filter: [...this.user_ids_filter_v2] })}
                    className={"modal-page-chat"}
                    zIndex={1062}
                    footer={[
                        <Button key="back" onClick={this.cancelFilter}>
                            Bỏ lọc
                    </Button>,
                        <Button key="submit" type="primary" loading={this.state.confirmLoading} onClick={this.filterOwner}>
                            Lọc
                    </Button>,
                    ]}
                >
                    <div className="list-owners">
                        {listOwnersV2}
                    </div>
                    {
                        this.props.listOwner && this.props.getListOwnerEnable ?
                            <div style={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                                <Pagination
                                    total={this.props.listOwner.total}
                                    pageSize={this.props.listOwner.limit}
                                    current={this.props.listOwner.page}
                                    onChange={(current) => {
                                        this.props.getListOwnerEnable({ page: current });
                                    }}
                                />
                            </div> : null
                    }
                </Modal>
                <div className="logo" onClick={() => window.location.reload()}>
                    <img src={this.props.companySelected ? this.props.companySelected.picture : require('../img/logo96.png')} 
                        onError={(e) => e.target.src = require('../img/logo96.png')}
                    />
                    {/* <span>Vienmat</span> */}
                </div>
                <div className="header-root">
                    <div className="header-container header-top">
                        <div className="header-container-left">
                            <div className="btn-bar btn-bar-chat">
                                <svg focusable="false" viewBox="0 0 24 24" role="presentation" fill="#000">
                                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="header-container-right">
                            {companyHtml}
                            {avatar_header}
                            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                                <div className="header-acc-name shadow">
                                    <div className="header-avatar">
                                        <img className="avatar" src={this.props.UserLogin.avatar ? this.props.UserLogin.avatar : require('../img/user.jpg')} />
                                    </div>
                                    {this.props.UserLogin.fullname}
                                </div>
                            </Dropdown>
                            {/* <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                            <div className="btn-bar">
                                <svg focusable="false" viewBox="0 0 24 24" role="presentation" fill="#000">
                                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                                </svg>
                            </div>
                        </Dropdown> */}
                            <div className="btn-bar btn-bar-home">
                                <svg focusable="false" viewBox="0 0 24 24" role="presentation" fill="#000">
                                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
