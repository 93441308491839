import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Form, Input, Tooltip, Select, message } from "antd";

import qs from "qs";

import moment from "moment";
import cx from "classnames";

import WidgetForm from "./component/form";

const listStatus = {
    "-1": "Tạm dừng",
    "1": "Đang xử lý",
    "2": "Thành công"
}

const listType = {
    1: "Sms Marketing",
    2: "Sms Remarketing"
}

const AutoSms = (props) => {
    const [data, setData] = useState({
        total: 0,
        listData: []
    });
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10
    });
    const [visibleForm, setVisibleForm] = useState(false);
    const [listBrandName, setListBrandName] = useState([]);

    const formFilter = useRef();

    useEffect(() => {
        getListData();
    }, [pagination])

    // request get mẫu
    const getListData = async () => {
        try {
            setLoading(true);

            let params = {
                limit: pagination.limit,
                page: pagination.page
            }

            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() }

            let res = await props.fetchData({
                url: `api/v1/auto_sms/list?${qs.stringify(params)}`
            });

            setLoading(false);

            setData({
                total: res.total,
                listData: res.data
            });

        } catch (err) {
            setLoading(false);
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    const update = async (values) => {
        try {
            message.loading({ content: 'Đang cập nhập..', key: values._id, duration: 0 });

            let res = await props.fetchData({
                url: `api/v1/auto_sms/${values['_id']}`,
                method: 'put',
                data: values
            });
            getListData();

            message.success({ content: "Thao tác thành công", key: values._id })
        } catch (err) {
            message.error({ content: err.message || "Thao tác thất bại", key: values._id })
        }
    }

    const getListBrandName = async () => {
        try {
            let params = {
                status: 1
            }

            let res = await props.fetchData({
                url: `api/v1/sms_brandname?${qs.stringify(params)}`
            });

            setListBrandName(res.data);

        } catch (err) {
        }
    }

    useEffect(() => {
        getListBrandName();
    }, [])

    return <div className="list-connect content-m">
        <div className="list-c">
            <React.Fragment>

                <WidgetForm
                    visible={visibleForm}
                    hide={() => setVisibleForm(false)}
                    listType={listType}
                    fetchData={props.fetchData}
                    listBrandName={listBrandName}
                    onFinish={() => {
                        setVisibleForm(false);
                        getListData();
                        props.notification({
                            content: "Thao tác thành công",
                            title: 'Thêm kịch bản',
                            type: 'success'
                        })
                    }}
                />

                <div>
                    <h3>Auto Sms: {data.total}</h3>
                </div>

                <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                    <Tooltip title={'Làm mới'}>
                        <a className="btn btn-primary text-white mr-2" onClick={() => getListData()}><i className="far fa-sync"></i></a>
                    </Tooltip>
                    <Tooltip title={'Thêm kịch bản'}>
                        <a className="btn btn-primary text-white mr-2" onClick={() => setVisibleForm(true)}><i className="far fa-plus"></i></a>
                    </Tooltip>
                </div>
                <Form
                    initialValues={{

                    }}
                    ref={formFilter}
                    onValuesChange={() => {
                        getListData();
                    }}
                >
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Item name="keyword">
                                <Input placeholder="Nhập tên chiến dịch.." />
                            </Form.Item>
                        </div>
                        <div className="col-md-2">
                            <Form.Item name="status">
                                <Select placeholder="Trạng thái">
                                    {
                                        Object.keys(listStatus).map((key) => {
                                            return <Select.Option value={key}>{listStatus[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-2">
                            <Form.Item name="type">
                                <Select placeholder="Loại">
                                    {
                                        Object.keys(listType).map((key) => {
                                            return <Select.Option value={key}>{listType[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-auto">
                            <Button type="primary" onClick={() => {
                                formFilter.current.resetFields();
                                getListData();
                            }}>Xóa lọc</Button>
                        </div>
                    </div>
                </Form>
                <Table
                    columns={[
                        {
                            title: "#",
                            render: (value, item, index) => {
                                return (pagination.page * pagination.limit - pagination.limit) + index + 1
                            }
                        },
                        {
                            title: "Tên",
                            dataIndex: "name"
                        },
                        {
                            title: "Loại",
                            dataIndex: "type",
                            render: (item) => {
                                return listType[item]
                            }
                        },
                        {
                            title: "Nội dung",
                            dataIndex: "content",
                            render: (item) => {
                                return <Input.TextArea value={item} />
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: (item) => {
                                return <span className={item == 2 ? "text-success" : item == 3 ? "text-danger" : ""}>{listStatus[item]}</span>
                            }
                        },
                        {
                            title: "Mục tiêu",
                            dataIndex: "num_target"
                        },
                        {
                            title: "Kết quả",
                            render: (item) => {
                                return <div>
                                    <span className="text-success">{item.num_success}</span>/<span>{item.num_failed}</span>
                                </div>
                            }
                        },
                        {
                            title: "Người tạo",
                            dataIndex: "from",
                            render: (item) => item && item.fullname
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_time",
                            render: (item) => moment(item).format("HH:mm DD-MM-YYYY")
                        },
                        {
                            title: "Ngày gửi",
                            dataIndex: "time_start",
                            render: (item) => item && moment(item).format("HH:mm DD-MM-YYYY")
                        },
                        {
                            title: "Hành động",
                            className: "text-right",
                            render: (item) => {
                                return <div className="d-flex justify-content-end">
                                    <Tooltip title={item.status == -1 ? "Chạy" : "Tạm dừng"}>
                                        <Button className="mb-2 d-flex align-items-center justify-content-center" size="small" type="primary" disabled={item.status > 1} onClick={() => {
                                            update({
                                                _id: item._id,
                                                status: item.status == -1 ? 1 : -1
                                            })
                                        }} >
                                            <i className={cx(`fa`, item.status == -1 ? 'fa-play' : 'fa-pause')}></i>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Tiến trình gửi tin">
                                        <Button className="mb-2 ml-2" size="small" type="primary" onClick={() => {
                                            props.history.push(`/tools/auto_sms/${item._id}`)
                                        }}><i className="fa fa-eye"></i></Button>
                                    </Tooltip>
                                </div>
                            }
                        }
                    ]}
                    dataSource={data.listData}
                    pagination={{
                        total: data.total,
                        pageSize: pagination.limit,
                        onChange: (current, size) => {
                            pagination.page = current;
                            pagination.limit = size;

                            setPagination({ ...pagination });
                        },
                        current: pagination.page,
                        showSizeChanger: false
                    }}
                    rowKey="_id"
                    loading={loading}
                />
            </React.Fragment>
        </div>
    </div>
}

export default AutoSms;