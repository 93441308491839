import React, { Component } from 'react';
import { fetchData } from '../../lib/apis';

import ListPanels from './list-panel-view';
import { LoadSpinner, Filter } from '../../widgets';

export default class sidePanelView extends Component{
  constructor(props){
    super(props);
    this.state = {
        
    };
  }
  componentWillMount(){
  }
  componentWillReceiveProps(nextProps){

  }
  componentDidMount(){

  }
  handleScroll(e) {
    try{
      document.querySelectorAll("input").forEach((item) => {
        item.blur();
      })
  
      let scrollValues = {
        scroll: e.target.scrollHeight - e.target.clientHeight,
        scrollHeight: e.target.scrollHeight,
        clientHeight: e.target.clientHeight,
        scrollTop: e.target.scrollTop,
        statusBarHeight: this.props.statusBarHeight,
        scrollTopAdd: 160
      }
      
      // console.log(`Scroll get contact`, scrollValues)
      
      if(scrollValues.scroll <= (scrollValues.scrollTop + scrollValues.statusBarHeight + scrollValues.scrollTopAdd) && this.props.nextPageContact){
        this.props.nextPageContact();
      }
    } catch(err){
      console.log(`Scroll error: `, err);
    }
  }

  componentWillUnmount = () => {
    this.setState({
      listData: []
    })
  }

  render(){
    return(
        <div id="sidepanel" className={this.props.hideSelf ? 'nodisplay' : null}>

          <Filter
            tagsFilter={this.props.tagsFilter}
            settingSystem={this.props.settingSystem}
            handleFilterTag={this.props.handleFilterTag}
            
            search_keyword={this.props.search_keyword}
            handleSearchKeyword={this.props.handleSearchKeyword}
            handleFiltersKeyword={this.handleFiltersKeyword}
            deviceType={this.props.deviceType}
          />
          <LoadSpinner show={this.props.loading} />
          
          <div className="scrollable-panel" onScroll={this.handleScroll.bind(this)}>
            <ListPanels
              listData={this.props.listContacts}
              contactInfoSelected={this.props.contactInfoSelected}
              channelSelected={this.props.channelSelected}
              settingSystem={this.props.settingSystem}
              mouseEnterDelayTooltip={this.props.mouseEnterDelayTooltip}
              mouseLeaveDelayTooltip={this.props.mouseLeaveDelayTooltip}
            />
          </div>
        </div> 
    )
  }
}
