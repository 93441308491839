import React, { Component } from 'react';
import { requestApi } from '../lib/apis';
import { Tooltip, Popover } from 'antd';

import cx from "classnames";

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.handleFilterTag = this.handleFilterTag.bind(this);
    this.state = {
      tagsOption: this.props.tagsOption ? this.props.tagsOption : [],
      tagsFilter: this.props.tagsFilter ? this.props.tagsFilter : [],
      read_state: '',
      username: '',
      textActionSelect: 'Chọn thao tác',
      showListSelectAction: false
    }
    this.mouseEnterDelay = 0.02;
    this.mouseLeaveDelay = 0.03;
    this.focusSearchContact = false;
  }
  componentWillMount() {
    if (this.props.deviceType == "iOS") {
      window.addEventListener("scroll", (e) => this.onScroll(e, this));
    }
  }
  onScroll = (e, _this) => {
    if (document.querySelector("#input-search-contact") && _this.focusSearchContact == true) {
      document.querySelector("#input-search-contact").blur();
      _this.focusSearchContact = false;
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      tagsOption: nextProps.tagsOption ? nextProps.tagsOption : [],
      tagsFilter: nextProps.tagsFilter ? nextProps.tagsFilter : [],
      showListSelectAction: false
    })
  }
  handleFilterTag(e, tag_id) {
    e.preventDefault();
    if (this.props.handleFilterTag) {
      this.props.handleFilterTag({
        tag_id: tag_id
      });
    }
  }
  componentWillUnmount = () => {
    this.setState({
      showListSelectAction: false
    })
  }
  render() {
    if (this.props.typeView == 'account') {
      return null;
    }
    let tag_favourites = [];
    if (this.props.settingSystem) {
      tag_favourites = this.props.settingSystem.tags.filter((item) => item.is_favourite)
    }
    return (
      <div className="tool-filter-m">
        <div className="tool-top" style={{ display: 'none' }}>
          <div className="div_checkbox_cover" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.handleReadSelectAll();
          }}>
            <label>
              <input type="checkbox" readOnly
                checked={this.props.selectReadSelectAll ? true : false}
              />
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 3.75L5.625 10.625L2.5 7.5" stroke="#0084FF" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </label>
          </div>

          {/* <select className="select-tool" onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.actionSelectAll(e.target.value)
              }}>
                  <option value="0">Chọn thao tác</option>
                  <option value="3">Đánh dấu đã đọc</option>
                </select> */}
          <div className="select-tool">
            <div className="fixed-pos"></div>
            <div className="text select-too-text" onClick={(e) => {
              e.preventDefault();
              if (this.selectToolRef && this.selectToolRef.classList.contains('show')) {
                this.setState({
                  showListSelectAction: false
                })
              } else if (this.state.showListSelectAction && !this.selectToolRef.classList.contains('show')) {
                this.setState({
                  showListSelectAction: false
                }, () => {
                  this.setState({
                    showListSelectAction: true
                  })
                })
              } else {
                this.setState({
                  showListSelectAction: !this.state.showListSelectAction
                })
              }
            }}>
              {this.state.textActionSelect}
              <div className="arrow-down arrow"></div>
            </div>
            <div ref={(evt) => this.selectToolRef = evt} className={this.state.showListSelectAction ? "select-tool-box show" : "select-tool-box hide"}>
              <ul>
                <li onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    textActionSelect: 'Chọn thao tác',
                    showListSelectAction: false
                  })
                  this.props.actionSelectAll(0);
                }}>Chọn thao tác</li>
                <li onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    textActionSelect: 'Đánh dấu đã đọc',
                    showListSelectAction: false
                  })
                  this.props.actionSelectAll(1);
                }}>Đánh dấu đã đọc</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tool-search align-items-center">
          <div className="tool-filter-text">
            <input id="input-search-contact" placeholder="Tìm kiếm" onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (!e.shiftKey) {
                  this.props.handleSearchKeyword(e.target.value, true, false);
                }
              }
            }}
              onChange={(e) => {
                if (e.target.value.length == 0) {
                  this.props.handleSearchKeyword(e.target.value)
                } else {
                  this.props.handleSearchKeyword(e.target.value, false);
                }
              }}
              value={this.props.search_keyword}
              onFocus={() => {
                this.focusSearchContact = true;
              }}
              onBlur={() => {
                // this.focusSearchContact = false;
              }}
            />
          </div>
          {
            tag_favourites.length > 0 ? 
            <Tooltip title="Lọc theo nhãn">
              <Popover placement="bottom" content={<div>
                <div className="tool-filter-tag">{
                  tag_favourites.map((item, i) => {
                    return <Tooltip key={item.tag_id} placement="top" title={item.tag_name} mouseEnterDelay={this.mouseEnterDelay} mouseLeaveDelay={this.mouseLeaveDelay}>
                      <a href="#"
                        className="item-filter-tag"
                        style={{ background: item.tag_color }}
                        onClick={(e) => this.handleFilterTag(e, item.tag_id)}
                      >
                        {
                          this.state.tagsFilter.indexOf(item.tag_id) != -1 ?
                            <i className="material-icons">done</i> : null
                        }
                      </a>
                    </Tooltip>
                  })
                }
                </div>
              </div>} trigger="click" overlayClassName="popover-filter-tag">
                <div className={cx("btn-filter-tag", this.state.tagsFilter.length > 0 && "active")}>
                    <i className={cx("fa-tags", this.state.tagsFilter.length > 0 ? "fa" : "far")}></i>
                </div>
              </Popover>
            </Tooltip>
            : null
          }
        </div>
      </div>
    )
  }
}
