import React, { Component } from 'react';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Upload, Menu, Pagination, Tooltip, Alert } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import DetailRun from './list-run';
import qs from 'qs';
import { WidgetSelectSpinText } from '../../../widgets';

const { Step } = Steps;
const { Option } = Select;

const steps = [
    {
        title: 'Mục tiêu'
    },
    {
        title: 'Nội dung',
    },
    {
        title: 'Hoàn tất',
    },
];

export default class Broadcast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentForm: 0,
            listPages: [],
            customer: 0,
            listData: [],
            total: 0,
            loading: false,
            dataSendPage: [],
            dataDetail: {},
            valuesFilter: {},
            listTagParent: [],
            ListTag: [],
            endOfMonth: moment().endOf('month').format("DD"),
            fileList: [],
            typeScript: 1
        }
        this.limit = 20;
        this.page = 1;
        this.listType = {
            1: "Remarketing",
            2: "Gửi hàng ngày",
            3: "Marketing đuổi"
        }
    }
    componentWillMount = () => {
        this.getListData();
        this.getListPages();
        this.getListTags();
    }
    componentDidMount = () => {

    }
    nextForm = async () => {
        const values = await this.formH.validateFields();
        if (this.state.currentForm < steps.length - 1) {
            const currentForm = this.state.currentForm + 1;
            this.setState({ currentForm });
        } else {
            this.create(values)
        }
    }

    create = async () => {
        try {
            const values = await this.formH.validateFields();

            this.setState({ loadingForm: true, error: null });

            let data = {
                name: values['name'],
                content: values['content'],
                type: values['type'],
                channels: values['channels'],
                contact: {},
                tag: values['tag']
            }
            if (values['range_time_contact'] && values['range_time_contact'][0]) {
                data['contact']['min_time'] = values['range_time_contact'][0].startOf("day").unix() * 1000
            };
            if (values['range_time_contact'] && values['range_time_contact'][1]) data['contact']['max_time'] = values['range_time_contact'][1].endOf("day").unix() * 1000;
            if (values['contact_tag_children'] && values['contact_tag_children'].length > 0) {
                data['contact']['tags'] = values['contact_tag_children'];
            }
            if (values['contact_tag_parent']) {
                data['contact']['tag_parent'] = [values['contact_tag_parent']];
            }

            if(values['last_hour_contact'] || values['last_day_contact']){
                data['contact']['last_time_contact'] = values['last_hour_contact'] || 0;
                if(values['last_day_contact']){
                    data['contact']['last_time_contact'] += values['last_day_contact'] * 24;
                }
            }
            if(values['last_hour_contact']) data['contact']['last_hour_contact'] = values['last_hour_contact'];
            if(values['last_day_contact']) data['contact']['last_day_contact'] = values['last_day_contact'];

            if (values['type'] == 1) {
                if (values['time_start']) {
                    data['time_start'] = values['time_start'].unix() * 1000;
                } else {
                    data['time_start'] = Date.now();
                }
            } else {
                if (!values['start_month'] && !values['start_day'] && !values['start_hours']) {
                    throw ({ message: 'Chọn ít nhất 1 option thời gian' })
                }
                if (values['start_month']) data['start_month'] = values['start_month'];
                if (values['start_day']) data['start_day'] = values['start_day'];
                if (values['start_hours']) data['start_hours'] = values['start_hours'];
                data['time_start'] = Date.now();
            }

            if (values['attachments']) {
                let attachments = [];
                if (values['attachments']['fileList']) {
                    values['attachments']['fileList'].forEach((item) => {
                        if (item.status == "done") {
                            attachments.push(item.response ? {
                                type: item.response.attachment.type,
                                url: item.response.attachment.url
                            } : { type: item.type, url: item.url })
                        }
                    })
                } else {
                    values['attachments'].forEach((item) => {
                        attachments.push({ type: item.type, url: item.url });
                    })
                }
                data['attachments'] = attachments;
            }

            await fetchData({
                url: `api/v1/broadcast/create`,
                data: data,
                method: 'post'
            });
            this.getListData();
            this.resetField();
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Thêm chiến dịch',
                type: 'success'
            })

        } catch (err) {
            let message = "Đã có lỗi xảy ra!";
            if (typeof err == "object") {
                message = err.message;

                if (err.errorFields && err.errorFields.length > 0) {
                    message = err.errorFields[0]['errors'][0]
                }
            }
            this.setState({ loadingForm: false, error: message });
        }
    }

    prevForm = () => {
        const currentForm = this.state.currentForm - 1;
        this.setState({ currentForm });
    }
    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/broadcast/list?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    getListPages = async (keyword = '') => {
        try {
            let res = await fetchData({
                url: `api/v1/channel/list?acc_type=5&limit=10&keyword=${keyword}`
            });
            this.setState({ listPages: res.data });
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Tìm fanpage',
                type: 'error'
            })
        }
    }
    getListTags = async (params = {}) => {
        try {
            this.setState({
                ListTag: []
            })
            params = {
                ...params,
                limit: 20
            }
            if (this.formH) {
                params['parent_id'] = this.formH.getFieldValue("contact_tag_parent");
                this.formH.setFieldsValue({
                    "contact_tag_children": []
                })
            }
            console.log(params);
            let res = await fetchData({
                url: `api/v1/company/tag/list?${qs.stringify(params)}`
            });
            this.setState({ listTagParent: res.tag_parent, ListTag: res.data });

        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Get danh sách tag',
                type: 'error'
            })
        }
    }
    handlePage = async () => {
        try {
            let page_id = this.formH.getFieldValue('page_id');
            let last_time = this.formH.getFieldValue('time_action').unix() * 1000;
            let type_lasttime = this.formH.getFieldValue('type_time_action');
            if (!page_id) return;
            let res = await fetchData({
                url: `api/v1/broadcast?page_id=${page_id}&last_time=${last_time}&type_lasttime=${type_lasttime}`
            });
            this.setState({ customer: res.total, dataSendPage: res.data });
        } catch (err) {

        }
    }
    resetField = () => {
        this.setState({
            currentForm: 0,
            customer: 0,
            loadingForm: false,
            visibleForm: false,
            typeScript: 1,
            detailForm: false,
            fileList: []
        }, () => {
            this.formH.resetFields();
            this.getListPages();
        });
    }
    detailRun = async (id) => {
        try {
            let res = await fetchData({
                url: `api/v1/broadcast/run/${id}`
            });
            this.setState({ visibleDetail: true, dataDetail: { ...res, id: id } });
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Tiến trình gửi',
                type: 'error'
            })
        }
    }
    remove = async (id) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/broadcast/${id}`,
                            method: 'delete'
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Xóa',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Xóa',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    update = async (id, data) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn cập nhập',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/broadcast/${id}`,
                            method: 'put',
                            data: data
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Cập nhập',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Cập nhập',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleStatus = async (id, data) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn cập nhập',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/broadcast/status/${id}`,
                            method: 'post',
                            data: data
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Cập nhập chiến dịch',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Cập nhập chiến dịch',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    render() {
        const { currentForm } = this.state;

        var option_time = [];
        new Array(24).fill().forEach((acc, index) => {
            option_time.push(<Option key={moment({ hour: index }).format('H:mm')}>{moment({ hour: index }).format('H:mm')}</Option>);
            option_time.push(<Option key={moment({ hour: index, minute: 15 }).format('H:mm')}>{moment({ hour: index, minute: 15 }).format('H:mm')}</Option>);
            option_time.push(<Option key={moment({ hour: index, minute: 30 }).format('H:mm')}>{moment({ hour: index, minute: 30 }).format('H:mm')}</Option>);
            option_time.push(<Option key={moment({ hour: index, minute: 45 }).format('H:mm')}>{moment({ hour: index, minute: 45 }).format('H:mm')}</Option>);
        })
        var days_broadcast = [];
        for (let i = 1; i <= this.state.endOfMonth; i++) {
            days_broadcast.push(i)
        }

        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <DetailRun getData={this.detailRun} visible={this.state.visibleDetail} data={this.state.dataDetail} hide={() => this.setState({ visibleDetail: false })} />
                    <Modal
                        title='Điều khoản của facebook'
                        visible={this.state.visiblePolicyTagFb}
                        onCancel={() => this.setState({ visiblePolicyTagFb: false })}
                        onOk={() => this.setState({ visiblePolicyTagFb: false })}
                        centered
                    >
                        <div>
                            <h4>1. Post-Purchase Update</h4>
                            <div>
                                <p style={{ marginBottom: "0px" }}>Tin nhắn cập nhật cho khách hàng về giao dịch mua hàng gần đây. Ví dụ:</p>
                                <p style={{ marginBottom: "0px" }}>- Hóa đơn hoặc biên lai</p>
                                <p style={{ marginBottom: "0px" }}>- Tình trạng đơn hàng, vận chuyển</p>
                                <p>- Thay đổi liên quan đến đơn hàng mà người dùng đã đặt</p>
                            </div>
                            <h4>2. Confirmed Event Update</h4>
                            <div>
                                <p style={{ marginBottom: "0px" }}>Gửi lời nhắc hoặc cập nhật của người dùng về sự kiện mà họ đã đăng ký. Ví dụ:</p>
                                <p style={{ marginBottom: "0px" }}>- Nhắc nhở về một sự kiện hoặc cuộc hẹn</p>
                                <p style={{ marginBottom: "0px" }}>- Xác nhận đặt phòng hoặc tham dự</p>
                                <p>- Cập nhật lịch trình di chuyển</p>
                            </div>
                            <h4>3. Account Update</h4>
                            <div>
                                <p style={{ marginBottom: "0px" }}>Thông báo cho người dùng về sự thay đổi không lặp lại đối với ứng dụng hoặc tài khoản của họ. Ví dụ:</p>
                                <p style={{ marginBottom: "0px" }}>- Thay đổi trạng thái công việc</p>
                                <p style={{ marginBottom: "0px" }}>- Thay đổi tài khoản thanh toán</p>
                                <p>- Cảnh báo gian lận</p>
                            </div>
                            <div>
                                <strong>Xem tài liệu facebook chính thức <a target="_blank" href="https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags/#supported_tags"><i>tại đây.</i></a></strong>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        title={'Thêm chiến dịch'}
                        visible={this.state.visibleForm}
                        className={"form-modal"}
                        footer={[]}
                        onCancel={() => this.resetField()}
                        width={900}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            className="form-broadcast"
                            initialValues={{
                                name: 'Nhắn tin ngày ' + moment().format('DD/MM'),
                                num_send_time: 6,
                                tag: 'CONFIRMED_EVENT_UPDATE',
                                content: '',
                                type: 1,
                                contact_tag_parent: "",
                                start_day: "",
                                start_month: "",
                                start_hours: "",
                                last_time_contact: 1
                            }}
                            layout="vertical"
                        >
                            {
                                this.state.error ? <Alert message={this.state.error} type="error" /> : null
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Item
                                        name="name"
                                        rules={[{ required: true, message: 'Tên không được để trống' }]}
                                        label="Tên chiến dịch"
                                    >
                                        <Input placeholder="Tên chiến dịch" />
                                    </Form.Item>
                                    <Form.Item
                                        name="type"
                                        label="Loại chiến dịch"
                                    >
                                        <Select
                                            onChange={(e) => {
                                                this.setState({
                                                    typeScript: e
                                                })
                                            }}
                                        >
                                            {
                                                Object.keys(this.listType).map((key) => {
                                                    return <Option value={parseInt(key)} >{this.listType[key]}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="channels"
                                        label="Chọn fanpage"
                                        rules={[{ required: true, message: 'Chọn ít nhất 1 fanpage' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Chọn fanpage"
                                            optionFilterProp="children"
                                            filterOption={false}
                                            onSearch={(e) => {
                                                if (this.timeout_fetchpages) clearTimeout(this.timeout_fetchpages);
                                                this.timeout_fetchpages = setTimeout(() => {
                                                    this.getListPages(e)
                                                }, 300);
                                            }}
                                            // notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                            allowClear
                                            mode="multiple"
                                        >
                                            {this.state.listPages.map((item, i) => {
                                                return <Option key={i} value={item.user_id}>{item.username || item.full_name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    {
                                        this.state.typeScript == 3 ?
                                        <Form.Item label="Thời gian tương tác cuối">
                                            <div className="d-flex align-items-center">
                                                <Form.Item name="last_day_contact" noStyle>
                                                    <InputNumber min={1}/>
                                                </Form.Item>
                                                <div className="ml-2 mr-2">Ngày</div>
                                                <Form.Item name="last_hour_contact" noStyle>
                                                    <InputNumber min={1} max={23}/>
                                                </Form.Item>
                                                <div className="ml-2">Giờ trước</div>
                                            </div>
                                        </Form.Item> :
                                        <Form.Item label="Thời gian tương tác" name="range_time_contact">
                                            <DatePicker.RangePicker disabledDate={(current) => current && current > moment().endOf('day')}
                                                allowEmpty={[true, true]}
                                            />
                                        </Form.Item>
                                    }
                                    
                                    <Form.Item
                                        label="Chọn nhãn"
                                    >
                                        <div className="row">
                                            <div className="col-6">
                                                <Form.Item name="contact_tag_parent">
                                                    <Select onChange={(e) => this.getListTags()}>
                                                        <Select.Option value="">Chọn loại nhãn</Select.Option>
                                                        {this.state.listTagParent.map((tag, i) => {
                                                            return <Select.Option value={tag.tag_id} key={i}>{tag.tag_name}</Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    name="contact_tag_children"
                                                >
                                                    <Select
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={false}
                                                        onSearch={(e) => {
                                                            if (this.timeout_get_tag) clearTimeout(this.timeout_get_tag);
                                                            this.timeout_get_tag = setTimeout(() => {
                                                                this.getListTags({ tag_name: e })
                                                            }, 300);
                                                        }}
                                                        allowClear
                                                        mode="multiple"
                                                        maxTagCount={1}
                                                    >
                                                        {this.state.ListTag.map((tag, i) => {
                                                            return <Select.Option value={tag.tag_id} key={i}>{tag.tag_name}</Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label="Tệp đính kèm"
                                        name="attachments"
                                    >
                                        <Upload
                                            action={`${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/uploads`}
                                            listType="picture-card"
                                            fileList={[...this.state.fileList]}
                                            className="upload-list-inline"
                                            withCredentials={true}
                                            ref={(evt) => this.uploadFile = evt}
                                            onRemove={(e) => {
                                                let index = this.state.fileList.findIndex((item) => item.uid == e.uid);
                                                if (index > -1) {
                                                    let fileList = this.state.fileList;
                                                    fileList.splice(index, 1);
                                                    this.setState({ fileList: fileList })
                                                }
                                            }}
                                            onChange={({ fileList }) => {
                                                this.setState({ fileList })
                                            }}
                                            onPreview={(file) => {
                                                let url = file.url;
                                                if (file.response && file.response.attachment) url = file.response.attachment.url;
                                                if (url) window.open(url)
                                            }}
                                            maxCount={1}
                                        >
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>

                                        </Upload>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item
                                        name="content"
                                        label="Nội dung"
                                        rules={[{ required: true, message: 'Nội dung không được để trống!' }]}
                                    >
                                        <Input.TextArea style={{ minHeight: "120px" }} />
                                    </Form.Item>
                                    <WidgetSelectSpinText
                                        data={[
                                            {
                                                value: "#{FULL_NAME}",
                                                text: "Tên khách hàng"
                                            },
                                            {
                                                value: "#{COMPANY_NAME}",
                                                text: "Tên chi nhánh"
                                            },
                                            {
                                                value: "#{FULL_NAME_MEMBER}",
                                                text: "Tên nhân viên"
                                            },
                                            {
                                                value: "#{BOOKING_CODE}",
                                                text: "Mã lịch hẹn gần nhất"
                                            },
                                            {
                                                value: "#{TAG_NAME}",
                                                text: "Nhãn"
                                            },
                                            {
                                                value: "#{PARENT_TAG_NAME}",
                                                text: "Nhóm Nhãn"
                                            },
                                            {
                                                value: "#{COMPANY_ID}",
                                                text: "Mã chi nhánh"
                                            },
                                            {
                                                value: "#{DATE}",
                                                text: "Ngày tháng năm"
                                            },
                                            {
                                                value: "#{Spin_1|Spin_2}",
                                                text: "Chọn từ khóa ngẫu nhiên"
                                            }
                                        ]}
                                        visible={this.state.visibleSpinText}
                                        handleVisible={() => this.setState({ visibleSpinText: !this.state.visibleSpinText })}
                                        setValues={(value) => {
                                            this.formH.setFieldsValue({ content: this.formH.getFieldValue('content') + value })
                                        }}
                                        numShow={2}
                                    />
                                    <Form.Item
                                        name="tag"
                                        label={<span className="d-flex align-items-center"><InfoCircleFilled style={{ color: "#1a90ff", marginRight: "5px" }} onClick={() => this.setState({ visiblePolicyTagFb: true })} /> Chọn tag</span>}
                                    >
                                        <Select
                                            placeholder="Chọn tag"
                                            optionFilterProp="children"
                                            className="text-left"
                                        >
                                            <Option value="CONFIRMED_EVENT_UPDATE">CONFIRMED_EVENT_UPDATE</Option>
                                            <Option value="POST_PURCHASE_UPDATE">POST_PURCHASE_UPDATE</Option>
                                            <Option value="ACCOUNT_UPDATE">ACCOUNT_UPDATE</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="num_send_time"
                                        label="Số tin nhắn tối đa / giây"
                                    >
                                        <InputNumber min={1} max={50} style={{ width: "100%" }} />
                                    </Form.Item>
                                    {
                                        this.state.typeScript == 1 ?
                                        <div>
                                            <Form.Item
                                                label="Thời gian chạy"
                                                name="time_start"
                                            >
                                                <DatePicker format="YYYY-MM-DD HH:mm" disabledDate={(current) => current && current < moment().startOf('day')} showTime />
                                            </Form.Item>
                                            <div>
                                                <p style={{ fontSize: "13px" }}><i>Nếu để trống chiến dịch sẽ thực hiện ngay lập tức.</i></p>
                                            </div>
                                        </div>
                                        : <Form.Item
                                            label="Thời gian chạy"
                                        >
                                            <div className="row">
                                                <div className="col-4">
                                                    <Form.Item name="start_month">
                                                        <Select
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    endOfMonth: e ? moment().set("month", e - 1).endOf("month").format("DD") : moment().endOf("month").format("DD")
                                                                }, () => {
                                                                    this.formH.setFieldsValue({ start_day: "" })
                                                                })
                                                            }}
                                                        >
                                                            <Option value="">Chọn tháng</Option>
                                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
                                                                return <Option key={item} value={item}>Tháng {item}</Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-4">
                                                    <Form.Item name="start_day">
                                                        <Select
                                                        >
                                                            <Option value="">Chọn ngày</Option>
                                                            {days_broadcast.map((item) => {
                                                                return <Option key={item} value={item}>{item}</Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-4">
                                                    <Form.Item name="start_hours">
                                                        <Select
                                                        >
                                                            <Option value="">Giờ gửi</Option>
                                                            {option_time}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    }
                                    {
                                        this.state.typeScript == 3 ? 
                                        <div className="font-weight-bold">
                                            Để chạy liên tục, chọn 0h00 <br></br>
                                            Nếu chọn khung giờ khác, thì đúng khung giờ đó BOT sẽ quét những khách đáp ứng điều kiện để gửi tin.
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                            {/* <Steps current={currentForm}>
                                {steps.map(item => (
                                    <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                            <div className="steps-content text-left">
                                
                                <div className={`${currentForm == 0 ? '' : 'd-none'}`}>
                                    
                                </div>
                                <div className={`${currentForm != 1 ? 'd-none' : ''}`}>
                                    
                                </div>
                                <div className={`${currentForm != 2 ? 'd-none' : ''}`}>
                                    
                                </div>
                            </div> */}
                            <div style={{ marginTop: "10px" }} className="note">
                                <i>Lưu ý: </i>
                                <p><i>- Bạn có thể theo dõi, hủy tiến trình gửi tại danh sách chiến dịch.</i></p>
                                <p><i>- Đọc kỹ <a href="#" onClick={() => this.setState({ visiblePolicyTagFb: true })}>điều khoản</a> của facebook áp dụng cho khách hàng tương tác > 24h.</i></p>
                            </div>
                            {/* <div className="list-button">
                                {currentForm > 0 && (
                                    <Button style={{ margin: '0 8px' }} onClick={this.prevForm}>
                                        Quay lại
                                    </Button>
                                )}
                                {currentForm <= steps.length - 1 && (
                                    <Button type="primary" onClick={this.nextForm} loading={this.state.loadingForm} disabled={this.state.loadingForm}>
                                        Tiếp tục
                                    </Button>
                                )}
                            </div> */}
                            {
                                !this.state.detailForm ?
                                <div className="list-button">
                                    <Button type="primary" onClick={this.create} loading={this.state.loadingForm} disabled={this.state.loadingForm}>
                                        Tiếp tục
                                    </Button>
                                </div> : null
                            }
                        </Form>
                    </Modal>
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý chiến dịch: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm chiến dịch'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                status: "",
                                type: ""
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="keyword">
                                                <Input placeholder="Nhập tên chiến dịch" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Item name="status">
                                                <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', input: e })}>
                                                    <Select.Option value="">Trạng thái</Select.Option>
                                                    <Select.Option value={0}>Đang xử lý</Select.Option>
                                                    <Select.Option value={1}>Thành công</Select.Option>
                                                    <Select.Option value={2}>Thất bại</Select.Option>
                                                    <Select.Option value={3}>Đang tạm dừng</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Item name="type">
                                                <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', input: e })}>
                                                    <Select.Option value="">Loại</Select.Option>
                                                    {
                                                        Object.keys(this.listType).map((key) => {
                                                            return <Select.Option value={parseInt(key)}>{this.listType[key]}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Stt</th>
                                        <th>Tên</th>
                                        <th>Loại</th>
                                        <th>Trạng thái</th>
                                        <th>Thành công</th>
                                        <th>Lỗi</th>
                                        <th>Ngày tạo</th>
                                        <th>Last time</th>
                                        <th className="text-right">Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id}>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        {this.listType[item.type]}
                                                    </td>
                                                    <td>
                                                        {item.status == 0 ? <span>Đang xử lý</span> : item.status == 1 ?
                                                            <span className="text-success">Thành công</span> :
                                                            item.status == 3 ? <span className="text-primary">Đang tạm dừng</span> :
                                                                <span className="text-danger">Thất bại</span>}
                                                    </td>
                                                    <td>{item.num_success}</td>
                                                    <td>{item.num_failed}</td>
                                                    <td>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</td>
                                                    <td>{item.last_time_get ? moment(item.last_time_get).format('HH:mm DD-MM-YYYY') : null}</td>
                                                    <td className="text-right">
                                                        {
                                                            item.status == 0 ?
                                                                <Tooltip title="Dừng">
                                                                    <Button size="small" type="danger" className="mr-2" onClick={() => {
                                                                        this.handleStatus(item._id, {
                                                                            status: 3
                                                                        })
                                                                    }}><i className="far fa-pause"></i></Button>
                                                                </Tooltip> :
                                                                <Tooltip title="Chạy">
                                                                    <Button size="small" type="danger" className="mr-2" disabled={item.status == 1}
                                                                        onClick={() => {
                                                                            this.handleStatus(item._id, {
                                                                                status: 0
                                                                            })
                                                                        }}
                                                                    ><i className="far fa-play"></i></Button>
                                                                </Tooltip>
                                                        }
                                                        <Tooltip title="Tiến trình chạy">
                                                            <Button size="small" type="primary" className="mr-2" onClick={() => {
                                                                this.props.history.push('/broadcast/' + item._id);
                                                            }}><i className="far fa-file"></i></Button>
                                                        </Tooltip>
                                                        <Tooltip title="Chi tiết">
                                                            <Button size="small" type="primary" className="mr-2" onClick={() => {
                                                                this.setState({
                                                                    visibleForm: true,
                                                                    detailForm: true,
                                                                    typeScript: item.type,
                                                                    fileList: item.attachments || []
                                                                }, () => {
                                                                    setTimeout(() => {
                                                                        let obj = JSON.parse(JSON.stringify(item));
                                                                        obj = {
                                                                            ...obj,
                                                                            time_start: item.time_start ? moment(item.time_start) : "",
                                                                            range_time_contact: [
                                                                                item.contact && item.contact.min_time ? moment(item.contact.min_time): null,
                                                                                item.contact && item.contact.max_time ? moment(item.contact.max_time): null
                                                                            ],
                                                                            last_day_contact: item.contact ? item.contact.last_day_contact : "",
                                                                            last_hour_contact: item.contact ? item.contact.last_hour_contact : ""
                                                                        };
                                                                        this.formH.setFieldsValue(obj);
                                                                        if(item.contact && item.contact['tag_parent']){
                                                                            this.formH.setFieldsValue({
                                                                                contact_tag_parent: item.contact['tag_parent']
                                                                            })
                                                                        }
                                                                        if(item.contact && item.contact['tags']){
                                                                            this.getListTags({ tag_ids: item.contact['tags'].join(',') })
                                                                            this.formH.setFieldsValue({
                                                                                contact_tag_children: item.contact['tags']
                                                                            })
                                                                        }
                                                                    }, 100)
                                                                })
                                                            }}><i className="far fa-eye"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
