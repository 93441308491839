import React, { Component } from 'react';
import { message, Form, Input, Dropdown, Button, Select, InputNumber, Tooltip, notification } from 'antd';
import { change_alias } from '../lib/helpers';
import NumberFormat from 'react-number-format';
import {fetchData} from '../lib/apis';

const { Option } = Select;

notification.config({
    placement: 'topRight',
    bottom: 50,
    duration: 3,
    top: 100
});

export default class FormCreateOrder extends Component {
    constructor(props) {
        super(props);
        this.contact = this.props.contactInfoSelected;
        let custormer_name = this.props.contactInfoSelected ? this.props.contactInfoSelected.full_name || this.props.contactInfoSelected.username : '';
        this.state = {
            reload: false,
            list_products: [],
            total_price: 0,
            custormer_name: custormer_name
        }
    }
    componentWillMount() {

    }
    componentDidMount() {

    }
    componentWillUnmount() {

    }
    componentDidUpdate(prevProps, prevState) {
        // console.log(prevProps, prevState);
    }
    componentWillReceiveProps(nextProps) {
        // console.log(nextProps);
        if(nextProps.contactInfoSelected && this.contact && nextProps.contactInfoSelected.contact_id != this.contact.contact_id){
            this.contact = nextProps.contactInfoSelected;
            this.setState({
                custormer_name: this.contact.full_name || this.contact.username,
                list_products: [],
                total_price: 0
            })
            if(this.formH){
                this.formH.resetFields();
            }
        }
    }
    handleSubmit = async (values) => {
        this.setState({btnLoading: true});
        try {
            let list_products = [];
            for(let i = 0; i < this.state.list_products.length; i++) {
                list_products.push({
                    name: values[`product_name[${i}]`],
                    price: parseInt(values[`product_price[${i}]`]),
                    total_price: parseInt(values[`product_total_price[${i}]`].replace(/\D/g, '')),
                    count: values[`product_count[${i}]`]
                })
            }
            values['list_products'] = list_products;
            values['source'] = {
                user_id: this.contact.user_id,
                contact_id: this.contact.contact_id,
                channel_id: this.contact.user_owner.user_id,
                channel_type: this.contact.user_owner.acc_type
            };
            values['total_price'] = parseInt(values['total_price'].replace(/\D/g, ''));
            let response = await fetchData({
                url: 'api/v2/orders/create',
                method: 'post',
                data: values
            })
            message.success({content: 'Tạo đơn hàng thành công', key: 'orders'});
            this.setState({btnLoading: false});
        } catch(err) {
            this.setState({btnLoading: false});
            message.error({content: err.message || 'Đã có lỗi xảy ra', key: 'orders'});
        }
    }
    onFinishFailed = () => {

    }
    handleChange = (value) => {
        console.log(`selected ${value}`);
    }
    editProduct = async (index) => {
        // let list_products = this.state.list_products;
        // if (item) {
        //     list_products[index] = item;
        // }
        // let total_price = 0;
        // list_products.forEach((item, i) => {
        //     total_price += parseInt(item['total_price']);
        // })
        // if (this.formH) {
        //     let shipping_fee = this.formH.getFieldValue('shipping_fee');
        //     let discount = this.formH.getFieldValue('discount');
        //     shipping_fee = shipping_fee ? parseInt(shipping_fee) : 0;
        //     discount = discount ? parseInt(discount) : 0;
        //     total_price = total_price + shipping_fee - discount;
        // }
        // this.setState({ list_products: list_products, total_price: total_price });
        let product_name = this.formH.getFieldValue(`product_name[${index}]`);
        let product_price = this.formH.getFieldValue(`product_price[${index}]`);
        product_price = product_price ? parseInt(product_price.toString().replace(/\D/g, '')) : 0;
        let product_count = this.formH.getFieldValue(`product_count[${index}]`);
        product_count = product_count ? parseInt(product_count) : 0;

        let data = {};
        data[`product_count[${index}]`] = product_count;
        data[`product_total_price[${index}]`] = new Intl.NumberFormat('vi-VN').format(product_count * product_price).replace(/\./g, ',')+'đ';
        data[`product_price[${index}]`] = new Intl.NumberFormat('vi-VN').format(product_price).replace(/\./g, ',');
        this.formH.setFieldsValue(data);

        this.setTotalPriceOrder();
    }
    setTotalPriceOrder = () => {
        let shipping_fee = this.formH.getFieldValue('shipping_fee');
        let discount = this.formH.getFieldValue('discount');
        shipping_fee = shipping_fee ? parseInt(shipping_fee) : 0;
        discount = discount ? parseInt(discount) : 0;

        let list_products = this.state.list_products;
        let total_price_product = 0;
        list_products.forEach((item, i) => {
            let price = this.formH.getFieldValue(`product_total_price[${i}]`).toString().replace(/\D/g, '');
            total_price_product += parseInt(price);
        })
        this.formH.setFieldsValue({
            'total_price' : new Intl.NumberFormat('vi-VN').format(total_price_product + shipping_fee - discount).replace(/\./g, ',')+'đ'
        });
    }
    
    render() {
        let Provinces = this.props.Provinces ? this.props.Provinces : [];
        let Districts = this.props.Districts ? this.props.Districts : [];
        let Communes = this.props.Communes ? this.props.Communes : [];
        // console.log(this.props.contactInfoSelected);
        return (
            <div id="form-create-order">
                <Form
                    name="basic"
                    onFinish={this.handleSubmit}
                    onFinishFailed={this.onFinishFailed}
                    ref={(evt) => this.formH = evt}
                    fields={
                        [
                            {
                                name: "full_name",
                                value: this.state.custormer_name
                            }
                        ]
                    }
                    initialValues={{
                        shop_type: 1,
                        discount: 0,
                        shipping_fee: 0,
                        total_price: '0đ'
                    }}
                    
                >
                    <div className="profile-custormer">
                        <Form.Item
                            name="full_name"
                            rules={[{ required: true, message: 'Tên khách hàng không được để trống' }]}
                            style={{ width: "50%" }}
                            onFocus={(e) => {
                                e.target.setAttribute('autocomplete', 'registration-select')
                            }}
                        >
                            <Input placeholder="Họ tên" size={'small'}
                                onChange={(e) => {
                                    this.setState({
                                        custormer_name: e.target.value
                                    })
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            style={{ width: "50%" }}
                        >
                            <Input placeholder="Số điện thoại" size={'small'}
                                onFocus={(e) => {
                                    e.target.setAttribute('autocomplete', 'registration-select')
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="address"
                            style={{ width: "100%" }}
                            onFocus={(e) => {
                                e.target.setAttribute('autocomplete', 'registration-select')
                            }}
                        >
                            <Input placeholder="Địa chỉ" size={'small'} />
                        </Form.Item>
                        <Form.Item
                            name="province_id"
                            style={{ width: "100%" }}
                            onFocus={(e) => {
                                e.target.setAttribute('autocomplete', 'registration-select')
                            }}
                            rules={[{ required: true, message: 'Tỉnh/TP không được để trống' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Chọn tỉnh thành"
                                optionFilterProp="children"
                                size={'small'}
                                onChange={(e) => {
                                    this.formH.resetFields(['district_id', 'commune_id'])
                                    if (this.props.getCity) this.props.getCity('districts', e);
                                }}
                                onFocus={() => {

                                }}
                                onBlur={() => {

                                }}
                                onSearch={() => {

                                }}
                                filterOption={(input, option) => {
                                    let value = option.name_en;
                                    input = change_alias(input.toLowerCase());
                                    return value.indexOf(input) >= 0
                                }
                                }
                            >
                                {
                                    Provinces.map((item, i) => {
                                        return <Option value={item.id} key={item.id} name_en={item.name_en}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="district_id"
                            style={{ width: "50%" }}
                            onFocus={(e) => {
                                e.target.setAttribute('autocomplete', 'registration-select')
                            }}
                        >
                            <Select
                                showSearch
                                placeholder="Quận huyện"
                                size={'small'}
                                onChange={(e) => {
                                    this.formH.resetFields(['commune_id'])
                                    if (this.props.getCity) this.props.getCity('communes', e);
                                }}
                                filterOption={(input, option) => {
                                    let value = option.name_en;
                                    input = change_alias(input.toLowerCase());
                                    return value.indexOf(input) >= 0
                                }
                                }
                            >
                                {
                                    Districts.map((item, i) => {
                                        return <Option value={item.id} key={item.id} name_en={item.name_en}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="commune_id"
                            style={{ width: "50%" }}
                            onFocus={(e) => {
                                e.target.setAttribute('autocomplete', 'registration-select')
                            }}
                        >
                            <Select
                                showSearch
                                placeholder="Phường xã"
                                size={'small'}
                                filterOption={(input, option) => {
                                    let value = option.name_en;
                                    input = change_alias(input.toLowerCase());
                                    return value.indexOf(input) >= 0
                                }
                                }
                            >
                                {
                                    Communes.map((item, i) => {
                                        return <Option value={item.id} key={item.id} name_en={item.name_en}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="product-order">
                        <div className="head">
                            <div className="title">Thông tin đặt hàng</div>
                            <Form.Item
                                name="shop_type"
                            >
                                <Select
                                    size={'small'}
                                >
                                    <Option value={1}>Kho mặc định</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <table className="list-product">
                            <thead>
                                <tr>
                                    <th style={{ width: "35%" }}>Tên SP</th>
                                    <th>SL</th>
                                    <th>Đơn giá</th>
                                    <th>Tổng</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.list_products.map((item, i) => {
                                        return <tr key={i}>
                                            <td>
                                            <Form.Item
                                                    style={{ width: "100%" }}
                                                    name={`product_name[${i}]`}
                                                    onFocus={(e) => {
                                                        e.target.setAttribute('autocomplete', 'registration-select')
                                                    }}
                                                    rules={[{ required: true, message: ' ' }]}
                                                    onChange={(e) => {

                                                    }}
                                                >
                                                    <Input placeholder="Tên sản phẩm *"
                                                        size={'small'}
                                                        className={'product_name'}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item
                                                    style={{ width: "100%" }}
                                                    name={`product_count[${i}]`}
                                                >
                                                    <InputNumber min={1} size={'small'}
                                                        onChange={(e) => {
                                                            this.editProduct(i);
                                                        }}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item
                                                    style={{ width: "100%", textOverflow: "ellipsis" }}
                                                    name={`product_price[${i}]`}
                                                >
                                                    <Input size={'small'}
                                                        onChange={(e) => {
                                                            this.editProduct(i);
                                                        }}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item
                                                    style={{ width: "100%", textOverflow: "ellipsis" }}
                                                    name={`product_total_price[${i}]`}
                                                >
                                                    <Input size={'small'} disabled/>
                                                </Form.Item>
                                                <i className="fas fa-trash btn-remove-product" onClick={(e) => {
                                                    let list_products = this.state.list_products;
                                                    list_products.splice(i, 1);
                                                    this.setState({
                                                        list_products: list_products
                                                    })
                                                }}></i>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <div style={{ textAlign: "right" }}>
                            <Button style={{ width: "40%" }}
                                type={'default'} size={'small'}
                                onClick={() => {
                                    let list_products = this.state.list_products;
                                    list_products.push(0);
                                    this.setState({
                                        list_products: list_products
                                    })
                                }}
                            >Thêm sp</Button>
                        </div>
                    </div>
                    <div className="order-bottom">
                        <div className="order-note">
                            <Form.Item
                                style={{ width: "100%" }}
                                name="note"
                            >
                                <Input.TextArea placeholder="ghi chú"></Input.TextArea>
                            </Form.Item>
                            {/* <Form.Item
                            style={{width: "50%"}}
                        >
                            <Input.TextArea placeholder="ghi chú"></Input.TextArea>
                        </Form.Item> */}
                        </div>
                        <div className="price">
                            <Form.Item
                                label="Phí vận chuyển"
                                name="shipping_fee"
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: "100%" }}
                                    onBlur={(e) => {
                                        this.editProduct();
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Giảm giá"
                                name="discount"
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: "100%" }}
                                    onBlur={(e) => {
                                        this.editProduct();
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Tổng"
                                name="total_price"
                            >
                                <Input style={{width: "100%"}} disabled/>
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button type="primary" loading={this.state.btnLoading} htmlType="submit">
                                Hoàn tất
                        </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        )
    }
}
