import React, { useEffect, useRef, useState } from "react";

import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import { LoadingComponent, WidgetSwipe } from '../../widgets';

import { Form, Input, Select, Checkbox, Radio, Button, Table, message, DatePicker, TreeSelect, Spin } from "antd";

import { list_quoctich, list_day, list_month, list_year, list_trieuchung, list_dantoc } from "./data";

import moment from "moment";
import qs from "qs";

import locale from 'antd/es/date-picker/locale/vi_VN';

import WidgetFormKBYT from "./form";

const KhaiBaoYTe = (props) => {
    return <div id="app-container" style={{ background: "#fff", overflowY: "auto", paddingTop: `${props.statusBarHeight}px` }} >
        <Helmet>
            <title>Khai báo khám chữa bệnh</title>
        </Helmet>
        <WidgetFormKBYT isShowBanner={true}/>
    </div>
}

export default KhaiBaoYTe;