import React, { Component } from 'react';
import { Modal, Progress } from 'antd';

export default class BroadcastRun extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentWillUpdate = (nextProps, nextState) => {
        if(nextProps.visible != this.props.visible){
            if(nextProps.visible){
                if(!this.timeout){
                    this.timeout = setInterval(() => {
                        this.props.getData(this.props.data.id)
                    }, 2000);
                }
            } else{
                if(this.timeout) {
                    clearInterval(this.timeout);
                    this.timeout = null;
                }
            }
        } else {
            if(nextProps.data.total == nextProps.data.total_done && this.timeout){
                clearInterval(this.timeout);
            }
        }
    }
    render(){
        var { total, total_fail, total_done } = this.props.data;
        var total_send = total_fail + total_done;
        var percent = Math.floor((total_send/total) * 100);
        return <Modal
            title={'Tiến trình gửi'}
            visible={this.props.visible}
            className={"form-modal"}
            footer={[]}
            onCancel={() => this.props.hide()}
        >
            <div className="detail-broadcast">
                <div>
                    <Progress percent={percent} status={percent == 100 ? "" : "active"} />
                </div>
                <div className="detail-run">
                    <span>Đã gửi: {total_send}/{total}</span>
                    <span>Thành công: <span className="text-success">{total_done}</span> </span>
                    <span>Lỗi: <span className="text-danger">{total_fail}</span></span>
                </div>
            </div>
        </Modal>
    }
}