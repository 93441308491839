import React, { Component } from 'react';
import {requestApi} from '../lib/apis';
import { SketchPicker } from 'react-color';

export default class OptionItemTag extends Component{
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
        edit: this.props.edit ? this.props.edit : false,
        tag_name: this.props.tag && this.props.tag.tag_name ? this.props.tag.tag_name : 'Nhãn',
        tag_color: this.props.tag && this.props.tag.tag_color ? this.props.tag.tag_color : '#2196f3',
        tag_id: this.props.tag && this.props.tag.tag_id ? this.props.tag.tag_id : 'new_'+new Date().getTime(),
        show: true
    }
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
    if(nextProps.tag){
        this.setState({
            tag_name: nextProps.tag.tag_name,
            tag_color: nextProps.tag.tag_color,
            tag_id: nextProps.tag.tag_id,
        })
    }
    if(nextProps.handleTag && nextProps.handleTag.tag_id == this.state.tag_id){
        this.setState({
            tag_color : nextProps.handleTag.tag_color
        })
    }
  }
  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  handleEditTag = (e) => {
    e.preventDefault();
    this.setState({
        edit: !this.state.edit
    })
  }
  saveTag = (e) => {
    e.preventDefault();
    if(this.props.saveTag){
        this.props.saveTag({
            tag_id: this.state.tag_id,
            tag_name: this.state.tag_name,
            tag_color: this.state.tag_color,
        })
    
        this.setState({
            edit: false
        })
    }
  }
  removeTag = (e) => {
    e.preventDefault();
    if(this.props.removeTag){
        this.props.removeTag(this.state.tag_id);
    }
  }
  handleTagColor = () => {
    this.setState({
        edit: true
    }, () => {
        this.props.handleColorTag(this.state.tag_id, this.state.tag_color);
    })
  }
  cancelEdit = (e) => {
    e.preventDefault();
    if(this.state.tag_id.toString().indexOf('new') == -1){
        this.setState({
            edit: false,
            tag_name: this.props.tag.tag_name ? this.props.tag.tag_name : 'Nhãn',
            tag_color: this.props.tag.tag_color ? this.props.tag.tag_color : '#2196f3',
            tag_id: this.props.tag.tag_id,
        }, () => {
            this.props.handleColorTag(this.state.tag_id, this.state.tag_color, false);
        })
    } else{
        this.setState({
            show: false
        }, () => {
            this.props.setNewTag();
        })
    }
  }
  render(){
    return this.state.show ?
    <tr>
                <td>
                    {
                        this.state.edit ?
                        <input value={this.state.tag_name} 
                        onChange={(e) => this.setState({tag_name: e.target.value})}/>
                        : <span className="text">{this.state.tag_name}</span>
                    } 
                </td>
                <td>
                    <span style={{background: this.state.tag_color, opacity: 1}} className="item-tag-h"
                        onClick={() => this.handleTagColor()}
                    >
                        {this.state.tag_color}
                    </span>
                </td>
                <td className="action">
                    {
                        this.state.edit ? 
                        <a href="#" onClick={(e) => this.saveTag(e)}>
                            lưu
                        </a> : 
                        <a href="#" onClick={(e) => this.handleEditTag(e)}>
                            sửa
                        </a>
                    }
                    {
                        this.state.edit ? 
                        <a href="#" onClick={(e) => this.cancelEdit(e)}>
                            hủy
                        </a> : 
                        <a href="#" onClick={(e) => this.removeTag(e)}>
                            xóa
                        </a>
                    }
                </td>
        </tr> : ''
  }
}
