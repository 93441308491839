import React, { Component } from 'react';
import { fetchData } from '../lib/apis';
import { Tooltip, Button, Modal, Input, Spin } from 'antd';

import WidgetFormEditQuickReply from "./chat/formEditQuickReply";

export default class FormReplyFast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: ""
    }
    this.typeReplySample = props.typeReplySample || "default";
  }
  componentWillMount() {

  }
  componentWillReceiveProps(nextProps) {

  }
  remove = async (_id) => {
    try {
      this.setState({ loading: true });
      await this.props.fetchData({
        url: `api/v1/quick_reply/${_id}`,
        method: 'delete'
      })
      let index = this.props.settingSystem[this.typeReplySample].findIndex((item) => item._id == _id);
      if (index > -1) {
        this.props.settingSystem[this.typeReplySample].splice(index, 1);
        this.props.handleSettingSystem(this.props.settingSystem);
      }
      this.props.notification({
        title: "Xóa trả lời nhanh",
        content: "Thao tác thành công",
        type: "success"
      })
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      this.props.notification({
        title: "Xóa trả lời nhanh",
        content: err.message || "Thao tác thất bại",
        type: "error"
      })
    }
  }
  render() {
    let { settingSystem } = this.props;

    let quickReply = settingSystem ? settingSystem[this.typeReplySample] : [];

    return (
      <React.Fragment>
        <WidgetFormEditQuickReply {...this.props}
          visible={this.state.showEditQuickReply}
          hide={() => this.setState({ showEditQuickReply: false })}
          onFinish={(values) => {
            let index = this.props.settingSystem[this.typeReplySample].findIndex((item) => item._id == values['_id']);

            if (index > -1) {
              this.props.settingSystem[this.typeReplySample][index] = values;
            } else {
              this.props.settingSystem[this.typeReplySample].splice(0, 0, values);
            }

            this.props.handleSettingSystem(this.props.settingSystem)

            this.setState({
              showEditQuickReply: false
            })
          }}
        />
        <Modal
          visible={this.props.visible}
          title="Tin trả lời lưu sẵn"
          onCancel={() => this.props.hide()}
          footer={null}
          width={600}
          centered
        >
          <div id="form-reply-fast">
            <div className="d-flex align-items-center justify-content-between title">
              <div>
                <Input placeholder="Nhập ký tự tắt.." onChange={(e) => this.setState({ filter: e.target.value })} />
              </div>
              <Button type="primary" size="small" onClick={() => this.setState({ showEditQuickReply: true })}>
                Thêm mới
              </Button>
            </div>
            <div className="list-reply">
              <Spin spinning={this.state.loading}>
                <ul ref={(evt) => this.myref = evt}>
                  {
                    quickReply.length > 0 ? quickReply.map((item, i) => {
                      let response = this.state.filter ? true : false;
                      if (this.state.filter) {
                        let match = item.short_cut.match(new RegExp(this.state.filter, 'gi'));
                        if (match) response = false;
                        match = item.message.match(new RegExp(this.state.filter, 'gi'));
                        if (match) response = false;
                      }
                      if (response) return null
                      return <li
                        key={i}
                        className={i == this.props.activeQuickReply ? 'active' : ''}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.props.selectReply({ content: item.message });
                        }}
                      >
                        <div>
                          <div><b>{item.short_cut}</b></div>
                          {item.message}
                        </div>
                        <div className="tool">
                          <a href="#" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({ showEditQuickReply: item })
                          }}>
                            <i className="far fa-edit"></i>
                          </a>
                          <a href="#" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.remove(item._id);
                          }}>
                            <i className="far fa-trash"></i>
                          </a>
                        </div>
                      </li>
                    }) : <li className="text-center" style={{ pointerEvents: "none" }}>
                      Không có dữ liệu
                    </li>
                  }
                </ul>
              </Spin>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
