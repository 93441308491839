import React, { Component, useCallback } from 'react';
import {
  API_KEY, APP_NAME, DEFAULT_P2P_ACCESS_MODE, LOGGING_ENABLED, MEDIA_BREAKPOINT,
  READ_DELAY, RECEIVED_DELAY, PROTOCOL_VERSION, DEFAULT_HOST, NEW_GRP_ACCESS_MODE
} from '../../config.js';

import { requestApi, fetchData } from '../../lib/apis';
import { Helmet } from 'react-helmet'

import SidePanelView from './side-panel-view-chat';
import MessageView from './message';
import InfoContactView from './info-contact-view';
import {
  Header, NotifyAlert, SideBarFilter, ConfirmWidget, WidgetChatFormEditInfo, WidgetChatModalDetailUser, WidgetChatModalChangeSales,
  WidgetModalHistoryContactSale, WidgetConvertUid, WidgetModalCallCustomer, WidgetFotterApp, WidgetSwipe
} from '../../widgets';
import WidgetModalSearchMedical from "./widget/poup-search-medical";

import { message } from 'antd';

import qs from 'qs';

import cx from "classnames";

export default class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reload: false,
      title: 'Tổng đài chat',
      list_tags: [],
      listOwner: null,
      tagsFilter: [],
      userOwnerFilter: [],
      listContacts: [],
      listMessage: [],
      contactIdSelected: props.match && props.match.params.contact_id ? props.match.params.contact_id : null,
      listNoteContact: [],
      showFormContactNote: false,
      list_message: [],
      visibleSearchMedical: false
    };

    this.handleResize = this.handleResize.bind(this);
    this.handleGetMessage = this.handleGetMessage.bind(this);
    this.handleGetNewMessage = this.handleGetNewMessage.bind(this);
    this.handleContactInfoSelected = this.handleContactInfoSelected.bind(this);
    this.countNewContact = this.countNewContact.bind(this);
    this.showNotiFy = this.showNotiFy.bind(this);
    this.changeRouter = this.changeRouter.bind(this);
    this.handleFilterOwner = this.handleFilterOwner.bind(this);
    this.handleFilterSideBar = this.handleFilterSideBar.bind(this);
    this.handleFilterTag = this.handleFilterTag.bind(this);
    this.saveNoteContact = this.saveNoteContact.bind(this);
    this.deleteNoteContact = this.deleteNoteContact.bind(this);

    this.mouseEnterDelayTooltip = 0.02;
    this.mouseLeaveDelayTooltip = 0.03;
    this.page_contact = 1;
    this.page_message = 1;
    this.limit_message = 16;
    this.nextPageContact = true;

    this.timeoutreload = null;

  }
  async componentWillMount() {
    try {
      this.getListOwnerEnable({ page: 1, limit: 20 });
      this.getSettings();
      if (this.state.contactIdSelected) this.get_detail_contact = true;
      this.getListContact();
      this.listenerPostMessage();
    } catch (err) {

    }
    // try {
    //   await instagram.init();
    //   window.instagram = instagram;
    // } catch (err) {

    // }
  }
  async componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.timeoutreload = setInterval(() => {
      this.setState({
        reload: !this.state.reload
      })
    }, 30 * 1000);
    this.props.postMessageApp({
      type: "updateBadge",
      badgeNumber: "0"
    })

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (this.timeoutreload) clearInterval(this.timeoutreload);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.contact_id && this.state.contactIdSelected != nextProps.match.params.contact_id) {
      this.setState({
        contactIdSelected: nextProps.match.params.contact_id,
        showFormContactNote: false,
        loadingBtnFormNote: false,
        contactInfoSelected: null
      }, () => {
        this.getListMessage({ type: 'reset' });
      })
    } else if (!nextProps.match.params.contact_id && this.state.contactIdSelected) {
      this.setState({
        contactIdSelected: null,
        // contactInfoSelected: null
      })
    }
    if (nextProps.stateBackground == "active" && this.props.stateBackground != nextProps.stateBackground) {
      this.nextPageContact = false;
      this.page_contact = 1;
      this.getListContact();
    }
  }
  handleResize() {
    var mobile = document.documentElement.clientWidth <= MEDIA_BREAKPOINT;
    this.setState({
      viewportWidth: document.documentElement.clientWidth,
      viewportHeight: document.documentElement.clientHeight
    });
    if (this.state.displayMobile != mobile) {
      this.setState({
        displayMobile: mobile,
        hideSelf: mobile && this.state.contactIdSelected ? true : false
      });
    } else {
      this.setState({
        hideSelf: mobile && this.state.contactIdSelected ? true : false
      });
    }
  }

  handleGetMessage() {
    this.setState({
      getMessage: false
    })
  }
  handleGetNewMessage(bolea = false) {
    this.setState({
      getNewMessage: bolea
    })
  }
  getContactId(contact_id) {
    if (!contact_id || contact_id == this.state.contactIdSelected) return;
    let findIndex = this.state.listContacts.findIndex((item) => item.contact_id == contact_id);
    let contactInfoSelected = null;

    if (findIndex > -1) {
      this.state.listContacts[findIndex]['read_state'] = 3;
      contactInfoSelected = this.state.listContacts[findIndex];
    }
    this.setState({
      contactIdSelected: contact_id,
      contactInfoSelected: contactInfoSelected
    })
  }
  getTags = () => {
    fetchData({
      url: 'api/v1/tag/list'
    }).then((res) => {
      this.setState({
        tagsOption: res.code == 200 ? res.data : [],
        getSuccessTag: true
      })
    }).catch((err) => {
      console.log(err);
    })
  }
  getSettings = () => {
    fetchData({
      url: 'api/v1/settings/list'
    }).then((res) => {
      if (res.code == 200 && res.settings) {
        this.setState({
          settingSystem: res.settings,
          tagsOption: res.settings.tags,
          successSettingSystem: true,
          tagSelect: res.settings.tags.length > 0 ? res.settings.tags[0]['tag_id'] : null,
          treeDataService: this.parseMenu(res.settings.list_services || []),
          treeDataDoctor: this.parseMenu(res.settings.list_doctor || [])
        })
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  parseMenu = (list) => {
    var map = {}, node, i;
    var roots = []

    for (i = 0; i < list.length; i++) {
      map[list[i].service_id] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i++) {
      node = list[i];
      node['title'] = node.name;
      node['key'] = node.service_id;
      node['value'] = node.service_id;

      if (node.parent_id !== 0) {
        try {
          list[map[node.parent_id]].children.push(node);
        } catch (err) {
          // console.log(node);
        }
      } else {
        node['checkable'] = false;
        node['selectable'] = false;
        roots.push(node);
      }
    }
    return roots;
  }

  handleContactInfoSelected = (contact) => {
    let listContacts = this.state.listContacts;
    for (let i = 0; i < listContacts.length; i++) {
      if (contact['contact_id'] == listContacts[i]['contact_id']) {
        listContacts[i] = contact;
        break;
      }
    }
    this.setState({
      contactInfoSelected: contact,
      listContacts: listContacts
    })
  }
  countNewContact = (new_mes) => {
    let title_new = 'Tin nhắn mới';
    let title_main = 'Tổng đài chat';

    let title = new_mes ? `Tin nhắn mới` : 'Tổng đài chat';
    let list_mes_not_read = this.state.listContacts.filter((item) => item.read_state == 1);
    let totalNoti = list_mes_not_read.length > 10 ? '+10' : list_mes_not_read.length;

    this.setState({
      title: title
    }, () => {
      try {
        this.props.handleTotalNoti(totalNoti);

        // this.props.postMessageApp({
        //   type: "updateBadge",
        //   badgeNumber: totalNoti
        // })

      } catch (err) {

      }
      document.title = `${title} ${totalNoti ? `(${totalNoti})` : ''}`;
      if (this.timeinterval_handle_title) clearInterval(this.timeinterval_handle_title);
      if (new_mes) {
        let count = 1;
        this.timeinterval_handle_title = setInterval(() => {
          this.setState({
            title: this.state.title == title_new ? title_main : title_new
          }, () => {
            document.title = `${this.state.title} ${totalNoti ? `(${totalNoti})` : ''}`;
          })
          count++;
        }, 2000);
      }
    })
  }
  handleClickMain = (e) => {
    if (!e.target.matches('.select-tool-text') && !e.target.matches('.arrow-down arrow')) {
      var myDropdown = document.getElementsByClassName("select-tool-box")[0];
      if (myDropdown && myDropdown.classList.contains('show')) {
        myDropdown.classList.remove('show');
      }
    }
  }
  showNotiFy = (mes, options) => {
    this.setState({
      showNotiFy: true,
      mesNotify: mes,
      options: options
    }, () => {
      // console.log(this.state.showNotiFy);
    })
  }
  changeRouter = (link = '/') => {
    this.props.history.push({
      pathname: link
    });
  }

  getListOwnerEnable = async (option) => {
    try {
      this.limit_channel = this.limit_channel ? this.limit_channel : 20;
      this.page_channel = this.page_channel ? this.page_channel : 1;
      let { page, limit } = option;
      if (page) this.page_channel = page;
      if (limit) this.limit_channel = limit;

      let owners = await fetchData({ url: `api/v1/chat/channel/list?page=${this.page_channel}&limit=${this.limit_channel}` });
      if (!this.state.listOwner) {
        this.setState({
          listOwner: owners
        })
      } else {
        let listOwner = this.state.listOwner;
        listOwner.data = listOwner.data.concat(owners.data);
        this.setState({
          listOwner: owners
        })
      }
    } catch (err) {
      console.log(err);
    }
  }
  getListContact = async () => {
    try {
      this.setState({
        showLoadingContact: true
      })
      let params = {
        limit: 20,
        page: this.page_contact,
        tags: this.state.tagsFilter.join(','),
        user_owner_ids: this.state.userOwnerFilter.join(','),
        search: this.state.search_keyword
      }

      let response = await fetchData({
        url: `api/v1/contact/list?${qs.stringify(params)}${this.state.data_filter ? `&${this.state.data_filter}` : ''}`
      })
      this.nextPageContact = true;
      let listContacts = JSON.parse(JSON.stringify(this.state.listContacts));

      response.data.forEach((item) => {
        let find = this.state.listContacts.find((contact) => contact._id == item._id);
        if (!find) listContacts.push(item);
      })
      let nextPageContact = response.data.length < 20 ? false : true;

      let list_mes_not_read = this.state.list_mes_not_read || [];
      let totalNoti = this.props.totalNoti;

      if (this.page_contact == 1) {
        list_mes_not_read = listContacts.filter((item) => item.read_state == 1);
        totalNoti = list_mes_not_read.length > 10 ? '+10' : list_mes_not_read.length;
      }
      listContacts.sort((a, b) => b.last_time - a.last_time)
      this.setState({
        listContacts: listContacts,
        nextPageContact: nextPageContact,
        showLoadingContact: false,
        list_mes_not_read: list_mes_not_read
      }, () => {
        this.props.handleTotalNoti(totalNoti);
        if (this.get_detail_contact) {
          this.get_detail_contact = false;
          this.getListMessage();
        }
      })
    } catch (err) {
      this.setState({ showLoadingContact: false })
      console.log(err);
      message.warning({ content: err.message || 'Đã có lỗi xảy ra!', duration: 3 });
    }
  };

  loadContact = async () => {
    if (!this.nextPageContact) return;
    this.nextPageContact = false;
    this.page_contact++;
    this.getListContact();
  }
  handleFilterSideBar = (filters) => {
    this.setState({
      data_filter: filters,
      nextPageContact: true,
      listContacts: []
    }, () => {
      this.nextPageContact = false;
      this.page_contact = 1;
      this.getListContact();
    })
  }
  handleSearchKeyword = (keyword = '', search = true, handle = true) => {
    if (search) {
      if (handle && keyword == this.state.search_keyword) keyword = "";
      this.setState({
        search_keyword: keyword,
        nextPageContact: true,
        listContacts: []
      }, () => {
        this.nextPageContact = false;
        this.page_contact = 1;
        this.getListContact();
      })
    } else {
      this.setState({
        search_keyword: keyword
      })
    }
  }
  handleFilterTag = (data = {}) => {
    let tagsFilter = this.state.tagsFilter;
    if (data['tag_id'] && tagsFilter.indexOf(data['tag_id']) == -1) {
      tagsFilter.push(data['tag_id']);
    } else {
      tagsFilter.splice(tagsFilter.indexOf(data['tag_id']), 1);
    }
    this.setState({
      tagsFilter: tagsFilter,
      nextPageContact: true,
      listContacts: []
    }, () => {

      this.nextPageContact = false;
      this.page_contact = 1;
      this.getListContact();
    })
  }
  handleFilterOwner = (user_ids) => {
    this.setState({
      userOwnerFilter: user_ids,
      nextPageContact: true,
      listContacts: []
    }, () => {
      clearTimeout(this.realtimeContact);
      this.realtimeContact = null;

      this.nextPageContact = false;
      this.page_contact = 1;
      this.getListContact();
    })
  }
  componentWillUnmount = () => {
    clearTimeout(this.realtimeContact);
    this.realtimeContact = null;
  }
  getNoteContact = async (contact_id) => {
    try {
      let listData = await fetchData({
        url: `api/v1/contact/note/list?contact_id=${contact_id}`
      });
      let data = listData.data;
      data.sort((a, b) => { return b.time_update - a.time_update });
      this.setState({
        listNoteContact: data
      })
    } catch (err) {
      console.log(err);
    }
  }
  saveNoteContact = async (data) => {
    try {
      message.loading({ content: 'Đang xử lý', key: 'save_note' });
      let results = await fetchData({
        url: `api/v1/contact/note`,
        method: 'post',
        data: data
      });
      let listData = this.state.listNoteContact;
      listData.unshift(results.data);
      this.setState({
        listNoteContact: listData
      }, () => {
        message.success({ content: 'Thao tác thành công', key: 'save_note' });
      })
    } catch (err) {
      console.log(err);
      message.error({ content: err.message ? err.message : 'Đã có lỗi xảy ra', key: 'save_note' });
    }
  }
  deleteNoteContact = async (note_id) => {
    try {
      message.loading({ content: 'Đang xử lý', key: 'save_note' });
      let results = await fetchData({
        url: `api/v1/contact/note/${note_id}`,
        method: 'delete'
      });
      let listData = this.state.listNoteContact;
      for (let i = 0; i < listData.length; i++) {
        if (listData[i]['note_contact_id'] == note_id) {
          listData[i] = results['data'];
        }
      }
      this.setState({
        listNoteContact: listData
      }, () => {
        message.success({ content: 'Thao tác thành công', key: 'save_note' });
      })
    } catch (err) {
      console.log(err);
      message.error({ content: err.message ? err.message : 'Đã có lỗi xảy ra', key: 'save_note' });
    }
  }
  getListMessage = async (option = {}) => {
    try {
      let { type, scrollHeight } = option;
      if (type == 'reset') {
        this.page_message = 1;
      }

      let list_message = this.page_message > 1 ? this.state.listMessage : [];
      this.setState({ loadingMessageView: true, list_message: list_message });

      let params = {
        contact_id: this.state.contactIdSelected,
        page: this.page_message,
        limit: this.limit_message
      }

      let res = await fetchData({
        url: `api/v1/chat/list?${qs.stringify(params)}`
      });

      if (!res.contact) {
        this.props.changeRouter('/chat');
        return false;
      }
      res.messages.data.forEach((item) => {
        list_message.unshift(item);
      })
      let list_contact = this.state.listContacts;
      if (this.page_message == 1) {
        let find = list_contact.findIndex((item) => item._id == res.contact._id);
        if (find > -1) {
          list_contact[find]['read_state'] = 3;
          this.countNewContact();
        }
      }
      let contactInfoSelected = this.state.contactInfoSelected;

      if (contactInfoSelected) {
        contactInfoSelected = {
          ...contactInfoSelected,
          ...res.contact
        }
      } else {
        contactInfoSelected = res.contact;
      }

      this.setState({
        loadingMessageView: false,
        listMessage: list_message,
        contactInfoSelected: contactInfoSelected
      }, () => {
        if (this.timeout_message) clearTimeout(this.timeout_message);
        this.timeout_message = setTimeout(() => {
          if (this.page_message == 1) {
            this.scrollToBottom();
            this.getBooking(res.contact);
            this.getContactNote(res.contact);

            let selected_reply = document.querySelectorAll('.selected-reply');
            if (selected_reply.length > 0) {
              selected_reply[selected_reply.length - 1].click();
            }
          }
          if (res.messages.data.length >= this.limit_message) {
            this.getDataMessageNavigator = true;
          }
        }, 200)
        if (this.page_message > 1) {
          if (this.refMessage) {
            let scrollTop = scrollHeight ? this.refMessage.scrollHeight - scrollHeight : 500;
            this.refMessage.scrollTop = scrollTop;
          }
        }
      })

    } catch (err) {
      this.setState({ loadingMessageView: false });
      this.props.notification({
        title: "Chi tiết hội thoại",
        content: err.message + ', chuyển hướng sau 3s..' || "Đã có lỗi xảy ra, chuyển hướng sau 3s..",
        type: "error"
      })
      setTimeout(() => {
        window.location.href = "/chat";
      }, 3000)
    }
  }
  scrollToBottom = () => {
    if (this.refMessage) {
      this.refMessage.classList = "scroll-smooth";
      const scrollHeight = this.refMessage.scrollHeight;
      const height = this.refMessage.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.refMessage.scrollTop = scrollHeight;
      this.refMessage.classList = ""
    }
  }
  handleScrollBoxMessage = (e) => {
    if (e.target.scrollTop == 0 && this.getDataMessageNavigator) {
      this.getDataMessageNavigator = false;
      this.page_message += 1;
      this.getListMessage({ scrollHeight: e.target.scrollHeight });
    }
  }
  listenerPostMessage = () => {
    try {
      let _this = this;
      let socket = window.socket || document.socket || this.props.socket;

      socket.on('message', async function (data) {
        try {

          let { verb, type, contact_id, from, user_id, add_new_sale } = data;
          let list_contact = _this.state.listContacts;
          let contact_selected = _this.state.contactInfoSelected;

          

          let noti_browser = null;

          if (type == "remove_contact") {
            while (true) {
              let find = list_contact.findIndex((item) => item.user_id == user_id);
              if (find > -1) {
                _this.state.listContacts.splice(find, 1);
              } else {
                break;
              }
            }
            if (contact_selected && contact_selected.user_id == user_id) contact_selected = null;

          } else if (type == "handle_sale" && contact_selected && contact_selected.user_id == user_id) {
            _this.getListMessage({ type: 'reset' });
          } else {
            let query = {
              contact_id: contact_id
            };
            if (add_new_sale) {
              query = { user_id: user_id }
            }

            query = {
              ...query,
              tags: _this.state.tagsFilter.join(','),
              user_owner_ids: _this.state.userOwnerFilter.join(','),
              search: _this.state.search_keyword
            }

            let response = await fetchData({
              url: `api/v1/contact/list?${qs.stringify(query)}${_this.state.data_filter ? `&${_this.state.data_filter}` : ''}`
            })
            if (response.data.length == 0) return;

            let contact = response.data[0];
            response.data.forEach((contact) => {
              let find = list_contact.findIndex((item) => item._id == contact._id);
              if (find == -1) {
                list_contact.push(contact);
              } else {
                list_contact[find] = contact;
              }
            })

            list_contact.sort((a, b) => b.last_time - a.last_time);

            let find_message = null;

            if ((type == 'direct' || type == 'www') && (verb == 'add' || verb == 'reaction')) find_message = { item_id: data.item_id };
            if (type == 'direct' && verb == 'read') find_message = { watermark: data.read.watermark };
            if (type == 'comment' && verb == 'add') find_message = { comment_id: data.comment_id };
            if (type == 'callcenter') find_message = { item_id: data.item_id };

            if (find_message) {
              find_message['contact_id'] = contact.contact_id;
              let response = await fetchData({
                url: `api/v1/chat/list?${qs.stringify(find_message)}`
              })
              if (contact_selected && contact_selected.contact_id == contact.contact_id && verb == 'read') {
                _this.state.list_message.forEach((item, i) => {
                  _this.state.list_message[i]['user_read'] = null;
                })
              }
              if (response.messages.data.length > 0) {
                let message = response.messages.data[0];
                if (contact_selected && contact_selected.contact_id == contact.contact_id) {
                  contact_selected = response.contact ? { ...contact_selected, ...response.contact } : contact_selected;
                  if (type == 'comment' && message.parent_id) {
                    let find = _this.state.list_message.findIndex((item) => item.comment_id == message.parent_id);
                    if (find > -1) {
                      let find_reply = _this.state.list_message[find]['reply'].findIndex((item) => item.comment_id == message.comment_id);
                      if (find_reply > -1) {
                        _this.state.list_message[find]['reply'][find_reply] = message;
                      } else {
                        _this.state.list_message[find]['reply'].push(message);
                      }
                    }
                  } else {
                    let find = _this.state.list_message.findIndex((item) => item._id == message._id);
                    if (find > -1) {
                      _this.state.list_message[find] = message;
                    } else {
                      _this.state.list_message.unshift(message);
                    }
                  }
                  _this.state.list_message = _this.state.list_message.sort((a, b) => a.timestamp - b.timestamp);
                }
                if ((type == "direct" || type == "comment") && message && message.user_id != contact.channel.user_id && (verb == 'add' || verb == 'reaction')) {
                  noti_browser = `${contact.thread_title || "Người dùng"}: ${message.attachments && message.attachments.length > 0 ? 'Gửi file đính kèm' : message.content}`
                } else if (type == "www") {
                  noti_browser = `${contact.thread_title || "Người dùng"}: ${message.content}`
                } else if (type == "change_sale_1") {
                  console.log('hello');
                }
              }
            }
          }

          _this.setState({
            contactInfoSelected: contact_selected,
            reload: !_this.state.reload
          }, () => {
            if (noti_browser) {
              _this.countNewContact(true);
              // _this.props.notifyBrowser({
              //   body: noti_browser,
              //   link: `/chat/${contact_id}`,
              //   title: 'Thông báo mới từ Tổng đài chat'
              // })
            }
          })
        } catch (err) {
          console.log(err);
        }
      })
    } catch (err) {
      console.log(err);
    }
  }
  handleUpdateTag = async (option) => {
    try {
      //tags
      let { tag, contact } = option;
      contact = { ...contact };

      let data = {
        tags: contact.tags || []
      };
      let is_new = false;

      let find_contact = this.state.listContacts.findIndex((item) => item.contact_id == contact.contact_id);
      if (data['tags'].findIndex((item) => item.tag_id == tag.tag_id) > -1) {
        data['tags'].splice(data['tags'].findIndex((item) => item.tag_id == tag.tag_id), 1);
      } else {
        data['tags'].push(tag);
        is_new = true;
      }

      let res = await fetchData({
        url: `api/v1/contact/${contact.user_id}/tag`,
        method: 'put',
        data: {
          tags_old: this.state.listContacts[find_contact]['tags'].map((item) => item.tag_id),
          tags: data['tags'].map((item) => item.tag_id),
          contact_id: contact.contact_id,
          tag: tag.tag_id,
          is_new
        }
      });

      this.state.listContacts.map((item) => {
        if (item.user_id == contact.user_id) {
          item.tags = data['tags'];
        }
        return item;
      })

      contact.tags = data['tags'];

      this.setState({
        reload: !this.state.reload,
        contactInfoSelected: contact
      })

    } catch (err) {
      this.props.notification({
        title: "Cập nhập nhãn cho contact",
        content: err.message || "Thao tác không thành công"
      })
    }
  }
  handleFavouriteTag = async (option) => {
    try {
      let { tag, is_favourite } = option;

      let res = await fetchData({
        url: `api/v1/chat/tag_favourite/${tag.tag_id}`,
        method: 'put',
        data: {
          is_favourite: is_favourite
        }
      });

      let find = this.state.settingSystem.tags.findIndex((item) => item.tag_id == tag.tag_id);
      if (find) this.state.settingSystem.tags[find]['is_favourite'] = is_favourite;

      this.setState({
        reload: !this.state.reload
      })

    } catch (err) {
      this.props.notification({
        title: "Cập nhập nhãn yêu thích",
        content: err.message || "Thao tác không thành công",
        type: "error"
      })
    }
  }
  handleSelectTagChat = (tag_id) => {
    try {
      this.state.settingSystem.tags = this.state.settingSystem.tags.map((item) => {
        if (item.tag_id == tag_id) {
          item.is_select = 1;
        } else {
          item.is_select = 0;
        }
        return item;
      })
      this.setState({
        reload: !this.state.reload
      })
    } catch (err) {

    }
  }

  getBooking = async (contact) => {
    try {
      if (!this.state.contactInfoSelected) throw ('not contact info');
      let res = await fetchData({
        url: `api/v1/chat/booking/count?contact_id=${contact.contact_id}`,
        method: 'get'
      });
      this.state.contactInfoSelected['booking'] = {
        total: res.total,
        data: res.data
      };
      this.setState({
        reload: !this.state.reload
      })
    } catch (err) {
      console.log(err);
    }
  }

  getContactNote = async (contact) => {
    try {
      let res = await fetchData({
        url: `api/v1/contact/${contact.contact_id}/note?limit=5`,
        method: 'get'
      });

      this.setState({
        listNoteContact: res.data
      })

    } catch (err) {
      console.log(err);
    }
  }

  saveContactNote = async (values) => {
    try {
      this.setState({
        loadingBtnFormNote: true
      })

      let res = await fetchData({
        url: `api/v1/contact/${values['contact_id']}/note`,
        method: 'post',
        data: values
      });
      this.getContactNote(this.state.contactInfoSelected);

      this.setState({
        loadingBtnFormNote: false,
        showFormContactNote: false
      })

    } catch (err) {
      this.setState({
        loadingBtnFormNote: false
      })
      this.props.notification({
        title: "Tạo ghi chú cho khách hàng",
        content: err.message || "Thao tác không thành công"
      })
    }
  }

  removeContactNote = async (id) => {
    try {
      let res = await fetchData({
        url: `api/v1/contact/note/${id}`,
        method: 'delete'
      });
      this.getContactNote(this.state.contactInfoSelected);

    } catch (err) {

    }
  }

  handleSalesContact = async (values) => {
    try {
      this.setState({ loadingChangeSalesContact: true });
      let res = await fetchData({
        url: `api/v1/contact/sale/change`,
        method: 'post',
        data: {
          user_id: this.state.contactInfoSelected.user_id,
          ...values
        }
      });
      this.setState({ loadingChangeSalesContact: false, visibleChangeSales: false });
      this.props.notification({
        title: "Chuyển nhân viên chăm sóc",
        content: "Thao tác thành công",
        type: "success"
      })
    } catch (err) {
      this.setState({ loadingChangeSalesContact: false });
      this.props.notification({
        title: "Chuyển nhân viên chăm sóc",
        content: err.message || "Thao tác thất bại",
        type: "error"
      })
    }
  }

  hiddenComment = async (values) => {
    try {
      let { is_hidden, _id, comment_id } = values;
      let index = this.state.listMessage.findIndex((item) => item._id == _id);
      let message_data = null;
      if (index > -1) {
        message_data = this.state.listMessage[index];
        this.state.listMessage[index]['is_hidden'] = is_hidden;
      } else {
        return;
      }
      message.loading({ content: 'Đang xử lý..', key: 'hidden_comment', duration: 10 });
      try {
        let res = await fetchData({
          url: `api/v1/chat/comment/hidden/${comment_id}`,
          method: 'post',
          data: {
            ...values
          }
        });
        this.props.notification({
          title: "Ẩn bình luận",
          content: "Thao tác thành công",
          type: "success"
        })
      } catch (err) {
        this.state.listMessage[index]['is_hidden'] = message_data.is_hidden;
        throw (err);
      }
      message.success({ content: 'Thành công', key: 'hidden_comment', duration: 2 });

      this.setState({
        reload: !this.state.reload
      })
    } catch (err) {
      message.warning({ content: err.message || "Thao tác thất bại", key: 'hidden_comment', duration: 3 });
    }
  }

  getHisBooking = async (phone) => {
    this.setState({ visibleSearchMedical: true });
    return;
    try {
      if (!phone) throw ({ message: "Vui lòng thêm sdt cho khách hàng để tiếp tục!" })
      let res = await fetchData({
        url: `api/v1/his/booking/list?phone=${phone}`,
        method: 'get',
      });
      if (res.data.data && res.data.data.length > 0) {
        window.open(res.data.data[0]['Link']);
      } else {
        this.props.notification({
          title: "Kiểm tra bệnh án",
          content: "Khách hàng chưa có lịch sử khám!",
          type: "success"
        })
      }
    } catch (err) {
      this.props.notification({
        title: "Kiểm tra bệnh án",
        content: err.message || "Thao tác thất bại",
        type: "error"
      })
    }
  }

  confirmSales = async (contact) => {
    try {
      this.setState({ loadingConfirmSales: true });

      let contactInfoSelected = this.state.contactInfoSelected;
      let id = contactInfoSelected ? contactInfoSelected._id : contact._id;

      let findIndex = this.state.listContacts.findIndex((item) => item._id == id);
      contact = this.state.listContacts[findIndex];
      let res = await fetchData({
        url: `api/v1/contact/sale/confirm`,
        method: 'post',
        data: {
          user_id: contact.user_id
        }
      });

      this.state.listContacts.forEach((item, i) => {
        if (item.user_id == contact.user_id) {
          item['sale']['status'] = 1;
        }
      })
      if (contactInfoSelected) {
        contactInfoSelected['sale']['status'] = 1;
      }

      this.setState({
        loadingConfirmSales: false,
        contactInfoSelected: contactInfoSelected,
        listContacts: this.state.listContacts
      }, () => {
        this.getListMessage({ type: 'reset' });
      })
      this.props.notification({
        title: "Nhận chăm sóc",
        content: "Thao tác thành công",
        type: "success"
      })
    } catch (err) {
      this.setState({ loadingConfirmSales: false });
      this.props.notification({
        title: "Nhận chăm sóc",
        content: err.message || "Thao tác thất bại",
        type: "error"
      })
    }
  }

  render() {
    let props_m = {
      ...this.props,
      mouseEnterDelayTooltip: this.mouseEnterDelayTooltip,
      mouseLeaveDelayTooltip: this.mouseLeaveDelayTooltip,
      tagSelect: this.state.tagSelect,
      handleTagSelect: (tag_id) => this.setState({ tagSelect: tag_id }),
      handleEditInfoContact: (info) => this.setState({ editInfoContact: info }),
      updateContactSelected: (contact) => this.setState({ contactInfoSelected: contact }),
      handleFavouriteTag: this.handleFavouriteTag,
      handleSelectTagChat: this.handleSelectTagChat,
      handleFormContactNote: () => this.setState({ showFormContactNote: !this.state.showFormContactNote }),
      showFormContactNote: this.state.showFormContactNote,
      saveContactNote: this.saveContactNote,
      removeContactNote: this.removeContactNote,
      handleModalChangeSales: () => this.setState({ visibleChangeSales: !this.state.visibleChangeSales }),
      getHisBooking: this.getHisBooking,
      handleHistoryContactSale: () => this.setState({ visibleHistoryContactSale: !this.state.visibleHistoryContactSale }),
      countNewContact: this.countNewContact,
      isSalesContact: !this.state.contactInfoSelected || !this.state.contactInfoSelected.sale || this.state.contactInfoSelected.sale.status == 1 ? true : false,
      loadingConfirmSales: this.state.loadingConfirmSales,
      confirmSales: this.confirmSales,
      handleCallCustomer: (values) => this.setState({ visibleCallCustomer: !this.state.visibleCallCustomer, dataCallCustomer: values })
    }
    return (
      <div id="app-container" onClick={(e) => this.handleClickMain(e)} className={`${this.props.typePage} app-chat`}>

        <Helmet>
          <title>
            {this.state.title}  {this.props.totalNoti ? `(${this.props.totalNoti})` : ""}
          </title>
        </Helmet>

        {/* <WidgetFotterApp visible={this.props.isAppNative ? true : false} numMessage={this.props.totalNoti} /> */}

        {/* <div className="top-bar-app" style={{ paddingTop: `${this.props.statusBarHeight}px` }}></div> */}
        <Header
          {...this.props}
          listOwner={this.state.listOwner}
          handleFilter={(filters) => this.setState({ dataFilter: filters })}
          dataFilter={this.state.dataFilter}
          handleFilterOwner={this.handleFilterOwner}
          // Company={this.props.companySelected}
          // ListCompany={this.props.companys}
          getListOwnerEnable={this.getListOwnerEnable}
        />
        <div className="app-content">
          {/* <div id = "pot">
          <span>Bạn đang Offline</span>
          <img src = "https://i.stack.imgur.com/qgNyF.png?s=328&g=1" width = "46px" height ="46px" />
        </div> */}
          <NotifyAlert options={this.state.options} show={this.state.showNotiFy} content={this.state.mesNotify} hide={() => this.setState({ showNotiFy: false })} />
          <ConfirmWidget
            visible={this.state.showConfirm}
            onReject={() => this.setState({ valConfirm: false, showConfirm: false })}
            onConfirm={() => this.setState({ valConfirm: true, showConfirm: false })}
          />
          <WidgetChatFormEditInfo
            {...props_m}
            visible={this.state.editInfoContact}
            hide={() => this.setState({ editInfoContact: null })}
            item={this.state.editInfoContact}
          />
          <SideBarFilter
            {...props_m}
            handleFilter={this.handleFilterSideBar}
            dataFilter={this.state.dataFilter}
            userOwnerFilter={this.state.userOwnerFilter}
            contactInfoSelected={this.state.contactInfoSelected}
            treeDataService={this.state.treeDataService || []}
            treeDataDoctor={this.state.treeDataDoctor || []}
          />
          <SidePanelView
            {...props_m}
            tagsFilter={this.state.tagsFilter}
            handleFilterTag={this.handleFilterTag}
            search_keyword={this.state.search_keyword}
            handleSearchKeyword={this.handleSearchKeyword}
            listContacts={this.state.listContacts}
            loading={this.state.showLoadingContact}
            nextPageContact={this.loadContact}
            contactInfoSelected={this.state.contactInfoSelected}
            settingSystem={this.state.settingSystem}
          />

          <WidgetChatModalDetailUser visible={this.state.detailUser} hide={() => this.setState({ detailUser: null })}
            title={this.state.detailUser ? this.state.detailUser.title : ""}
            user={this.state.detailUser}
          />

          <WidgetChatModalChangeSales {...props_m} visible={this.state.visibleChangeSales} hide={() => this.setState({ visibleChangeSales: false })}
            save={this.handleSalesContact} loading={this.state.loadingChangeSalesContact}
          />

          <WidgetModalHistoryContactSale
            notification={this.props.notification}
            visible={this.state.visibleHistoryContactSale}
            hide={() => this.setState({ visibleHistoryContactSale: false })}
            fetchData={this.props.fetchData}
            contactInfoSelected={this.state.contactInfoSelected}
          />

          <WidgetModalCallCustomer
            {...props_m}
            visible={this.state.visibleCallCustomer}
            hide={() => this.setState({ visibleCallCustomer: false })}
            item={this.state.dataCallCustomer}
          />

          <WidgetModalSearchMedical 
            visible={this.state.visibleSearchMedical}
            hide={() => this.setState({
                visibleSearchMedical: false
            })}
            contact_id={this.state.contactIdSelected}
          />

          {
            this.state.contactIdSelected || this.props.isAppNative ?
              <div id="message-view" className={cx(this.state.contactIdSelected && "active", `${this.state.contactInfoSelected && this.state.contactInfoSelected.sale && this.state.contactInfoSelected.sale.status == 3 ? 'view-message-disable' : ''}`)}
                style={{
                  paddingTop: `${this.props.statusBarHeight}px`
                }}
              >
                <MessageView
                  {...props_m}
                  key={this.state.contactIdSelected}
                  contactInfoSelected={this.state.contactInfoSelected}
                  channelSelected={this.state.contactInfoSelected ? this.state.contactInfoSelected.channel : null}
                  listMessage={this.state.listMessage}
                  settingSystem={this.state.settingSystem}
                  handleSettingSystem={(handleSetting) => this.setState({
                    settingSystem: handleSetting
                  })}
                  showNotiFy={this.showNotiFy}
                  displayMobile={this.state.displayMobile}
                  mouseEnterDelayTooltip={this.mouseEnterDelayTooltip}
                  mouseLeaveDelayTooltip={this.mouseLeaveDelayTooltip}
                  setRefMessage={(evt) => this.refMessage = evt}
                  handleScrollBoxMessage={this.handleScrollBoxMessage}
                  loading={this.state.loadingMessageView}
                  search_keyword={this.state.search_keyword}
                  handleSearchKeyword={this.handleSearchKeyword}
                  reload={() => this.setState({ reload: !this.state.reload })}
                  handleUpdateTag={this.handleUpdateTag}
                  scrollToBottom={this.scrollToBottom}
                  handleDetailUser={(user) => this.setState({ detailUser: user })}
                  hiddenComment={this.hiddenComment}
                  getListMessage={this.getListMessage}
                />

                <InfoContactView
                  {...props_m}
                  contactInfoSelected={this.state.contactInfoSelected}
                  settingSystem={this.state.settingSystem}
                  handleUpdateTag={this.handleUpdateTag}
                  listNoteContact={this.state.listNoteContact}
                  loadingBtnFormNote={this.state.loadingBtnFormNote}
                />

                {
                  this.props.history.location.hash != "#info" && <WidgetSwipe id="box-touch-right" event="swipeleft" action={() => {
                    if (!this.props.history.location.hash) this.props.history.push(this.props.history.location.pathname + '#info');
                  }} visible={this.props.deviceType == "iOS" && this.props.isAppNative} />
                }

              </div> : <React.Fragment>
                <div id="dummy-view" className="logo-view">
                  <div>
                    <a>
                      <img id="logo" alt="logo" src={require('../../img/background.png')} />
                    </a>
                  </div>
                </div>
              </React.Fragment>
          }
          {
            !this.state.contactIdSelected ?
              <WidgetSwipe id="box-touch-right" event="swipeleft" action={() => {
                document.querySelector('#sidebar-filter').classList.add("show");
              }} visible={this.props.deviceType == "iOS" && this.props.isAppNative} /> : null
          }
        </div>
      </div>
    )
  }
}
