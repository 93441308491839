import React, { Component } from 'react';
import { Modal, Form, Input, Button, Select, Alert} from 'antd';


export default class ModalCallCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            called: null
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.visible && nextProps.visible != this.props.visible){
            setTimeout(() => {
                if(this.form) {
                    if(this.props.item){
                        this.setState({ called: this.props.item.cus_phone, error: null})
                        this.form.setFieldsValue({ called: this.props.item.cus_phone, contact_id: this.props.item.contact_id });
                    }
                }
            })
        }
    }

    create = async (values) => {
        try{
            this.setState({ loading: true});

            await this.props.fetchData({
                url: `api/v1/callcenter/call`,
                method: 'post',
                data: values
            })
            this.setState({ loading: false, loading_ext: false, loading_mobile: false }, () => {
                this.props.hide();
                this.props.notification({
                    title: "Thực hiện cuộc gọi",
                    content: "Đang kết nối cuộc gọi với khách hàng, vui lòng chờ chút!",
                    type: "success"
                })
            });
        } catch(err){
            this.setState({ error: err.message || "Đã có lỗi xảy ra ", loading: false, loading_ext: false, loading_mobile: false })
        }
    }

    render() {
        return <Modal
            title={"Thực hiện cuộc gọi"}
            visible={this.props.visible}
            onCancel={this.props.hide}
            footer={null}
        >
            <Form 
                ref={(evt) => this.form = evt}
                onFinish={this.create}
                layout="vertical"
            >
                {
                    this.state.error ? <Alert type="error" description={this.state.error} /> : null
                }
                <Form.Item name="contact_id" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                {
                    this.state.called ? 
                    <Form.Item name="called" label="Số điện thoại khách hàng">
                        <Input onChange={(e) => {
                            this.form.setFieldsValue({ called: this.state.called })
                        }}/>
                    </Form.Item> : null
                }
                <Form.Item name="caller" label="Số điện thoại chăm sóc" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="type_caller" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <div className="d-flex justify-content-between" style={{marginTop: "20px"}}>
                    <Button type="primary" disabled={this.state.loading_ext} loading={this.state.loading_ext == 1} size="large" onClick={() => {
                        this.setState({
                            loading_ext: 1
                        }, () => {
                            this.form.setFieldsValue({ type_caller: 1 });
                            this.form.submit();
                        })
                    }}><i className="far fa-phone mr-2"></i> Kết nối máy lẻ 1</Button>
                    <Button type="primary" disabled={this.state.loading_ext} loading={this.state.loading_ext == 2} size="large" onClick={() => {
                        this.setState({
                            loading_ext: 2
                        }, () => {
                            this.form.setFieldsValue({ type_caller: 2 });
                            this.form.submit();
                        })
                    }}><i className="far fa-phone mr-2"></i> Kết nối máy lẻ 2</Button>
                    {/* <Button type="primary" disabled={this.state.loading} loading={this.state.loading_mobile} size="large" onClick={() => {
                        this.setState({
                            loading: true,
                            loading_mobile: true
                        }, () => {
                            this.form.setFieldsValue({ type_caller: 2 });
                            this.form.submit();
                        })
                    }}><i className="far fa-mobile mr-2"></i> Kết nối di động</Button> */}
                    {/* <Button className="mr-2" onClick={() => this.props.hide() } size="large">Đóng</Button> */}
                </div>
            </Form>
        </Modal>
    }
}
