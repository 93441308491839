import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Image, Modal, notification, Table, Upload } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { apis, fetchData } from '../../../lib/apis';

const FaceDataTestView = (props) => {
    const [ visible, setvisible ] = useState({
        visible: false,
        loading: false,
        success: 0,
        failed: 0
    });
    const [ visibleCheck, setvisibleCheck ] = useState({
        visible: false,
        loading: false
    });
    const [fileList, setFileList] = useState([]);

    const formRef = useRef();
    const formCheckRef = useRef();

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }

    const [ logs, setLogs ] = useState([]);

    const InsertFace = async (values) => {
        try{
            visible.success = 0;
            visible.failed = 0;
            setvisible({...visible, loading: true});
            if(!values.images || !values.images.fileList.length) throw({ message: "Vui lòng chọn ít nhất 1 ảnh!"});
            let fileList = [...values.images.fileList];

            while(fileList.length){
                let data = fileList.splice(0, 10);
                let promise = [];

                data.forEach((item) => {
                    promise.push(new Promise(async (resolve, reject) => {
                        try{
                            let response = await fetchData({
                                url: apis.app.face_id.insert,
                                method: "post",
                                data: {
                                    images: [item.response.replace(/data:image\/(.*);base64,/gi, '')]
                                }
                            });
                            visible.success++;
                        } catch(err){
                            visible.failed++;
                        }
                        resolve(true);
                    }))
                })
                await Promise.all(promise);

                setvisible(visible);
            }

            notification.success({
                message: "Thông báo",
                description: "Thêm face thành công"
            })
        } catch(err){
            notification.error({
                message: "Thông báo",
                description: err.message
            })
        } finally{
            setvisible({...visible, loading: false });
        }
    }
    const CheckFace = async (values) => {
        try{
            setvisibleCheck({...visibleCheck, loading: true });
            if(!values.images || !values.images.fileList.length) throw({ message: "Vui lòng chọn ít nhất 1 ảnh!"});
            let fileList = [...values.images.fileList];

            let data = [];

            for(let i = 0; i < fileList.length; i++){
                let item = fileList[i];
                let face_id = -1;
                try{
                    let response = await fetchData({
                        url: apis.app.face_id.check,
                        method: "post",
                        data: {
                            images: [item.response.replace(/data:image\/(.*);base64,/gi, '')]
                        }
                    });
                    face_id = parseInt(response.data);
                } catch(err){
                    console.log(err);
                }
                data.push({
                    stt: i + 1,
                    image: item.response,
                    face_id
                });

                setLogs([...data]);
            }
            notification.success({
                message: "Thông báo",
                description: "Kiểm tra face thành công"
            })
        } catch(err){
            notification.error({
                message: "Thông báo",
                description: err.message
            })
        } finally{
            setvisibleCheck({...visibleCheck, loading: false });
        }
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const handlePreview = async (file) => {
        return
    };

    const resetFormCheck = () => {
        setFileList([]);
        if(formCheckRef.current) formCheckRef.current.resetFields();
    }
    const resetForm= () => {
        setFileList([]);
        if(formRef.current) formRef.current.resetFields();
    }

    useEffect(() => {
        if(!visible.visible){
            resetForm();
        }
    }, [visible ])

    useEffect(() => {
        if(!visibleCheck.visible){
            resetFormCheck();
        }
    }, [ visibleCheck ])

    const faceExist = useMemo(() => {
        return {
            face_id: logs.filter((item) => item.face_id > -1).length,
            guest_id: logs.filter((item) => item.face_id === -1).length
        }
    }, [ logs ])

    return (
        <div className="list-connect content-m">
            <Modal
                title="Thêm mới khuôn mặt"
                visible={visible.visible}
                onCancel={() => setvisible({ ...visible, visible: false })}
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={InsertFace}
                    ref={formRef}
                    onReset={resetFormCheck}
                >
                    <Form.Item label="Chọn ảnh" name={"images"}>
                        <Upload.Dragger
                            multiple
                            customRequest={async (option) => {
                                option.onSuccess(await getBase64(option.file));
                            }}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            accept="image/*"
                            showUploadList={false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
                            <p className="ant-upload-hint">Bạn đang chọn {fileList.length} file</p>
                        </Upload.Dragger>
                    </Form.Item>
                    <div>
                        <p className='mb-2'>Thành công: {visible.success}</p>
                        <p className='mb-2 text-danger'>Thất bại: {visible.failed}</p>
                    </div>
                    <div className='text-right'>
                        <Button
                            htmlType="reset"
                        >
                            Làm mới
                        </Button>
                        <Button
                            htmlType='submit'
                            type="primary"
                            className="ml-2"
                            loading={visible.loading}
                        >
                            Thêm ngay
                        </Button>
                    </div>
                </Form>
            </Modal>
            <Modal
                title="Kiểm tra khuôn mặt"
                visible={visibleCheck.visible}
                onCancel={() => setvisibleCheck({ ...visibleCheck, visible: false })}
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={CheckFace}
                    ref={formCheckRef}
                    onReset={resetForm}
                >
                    <Form.Item label="Chọn ảnh" name={"images"}>
                        <Upload.Dragger
                            multiple
                            customRequest={async (option) => {
                                option.onSuccess(await getBase64(option.file));
                            }}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            accept="image/*"
                            showUploadList={false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
                            <p className="ant-upload-hint">Bạn đang chọn {fileList.length} file</p>
                        </Upload.Dragger>
                    </Form.Item>
                    <div className='text-right'>
                        <Button
                            htmlType="reset"
                            disabled={visibleCheck.loading}
                        >
                            Làm mới
                        </Button>
                        <Button
                            htmlType='submit'
                            type="primary"
                            className="ml-2"
                            loading={visibleCheck.loading}
                        >
                            Kiểm tra ngay
                        </Button>
                    </div>
                </Form>
            </Modal>
            <div className="list-c">
                <div className='mb-3'>
                    <Button
                        onClick={InsertFace}
                        type="primary"
                        onClick={() => setvisible({ ...visible, visible: true })}
                    >Thêm face</Button>
                    <Button
                        onClick={() => setvisibleCheck({ ...visibleCheck, visible: true })}
                        type="primary"
                        className='ml-3'
                    >Kiểm tra</Button>
                    <Button
                        className='ml-3'
                        danger
                        onClick={() => setLogs([])}
                    >
                        Xóa log
                    </Button>
                </div>
                <div className='mb-3'>
                    <p>Người quen: {faceExist.face_id}</p>
                    <p>Người lạ: {faceExist.guest_id}</p>
                </div>
                <Table 
                    dataSource={logs}
                    columns={[
                        {
                            title: "#",
                            dataIndex: "stt"
                        },
                        {
                            title: "Face",
                            dataIndex: "image",
                            render: (value) => {
                                return <Image 
                                    src={value} 
                                    preview={false} 
                                    width={100}
                                />
                            }
                        },
                        {
                            title: "Face Id",
                            dataIndex: "face_id"
                        }
                    ]}
                    scroll={{
                        x: true
                    }}
                    pagination={{
                        size: "small"
                    }}
                />
            </div>
        </div>
    )
}

export default FaceDataTestView;
