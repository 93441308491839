import React, { useState, useEffect } from "react";

import { Tooltip, Popover, Modal } from 'antd';
// import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";

import { Picker, EmojiData } from "emoji-mart";

const ContentPicker = (props) => {
    return <div>
        {/* <Picker
            onEmojiClick={props.onEmojiClick}
            disableAutoFocus={true}
            skinTone={SKIN_TONE_MEDIUM_DARK}
            native
        /> */}
    </div>
}

const Emoij = (props) => {
    const [visible, setVisible] = useState(null);

    return <Tooltip placement="top" title={'Đăng nhãn dán'} >
        {/* <Popover
            content={<ContentPicker {...props}/>}
            trigger={"click"}
            placement="top"
            onVisibleChange={props.onVisibleChange}
            visible={props.visible}
            style={{ padding: "0px" }}
        >
            <a href="#"
                style={{ color: 'rgb(0, 102, 255)', display: 'flex', marginRight: '4px' }}
                className="btn-manage-tool"
            >
                <i className="far fa-smile"></i>
            </a>
        </Popover> */}
        <Modal visible={visible}
            centered
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
        >
            <Picker style={{width: "100%"}} emoji="" title="" native={true} onSelect={(e) => {
                props.onEmojiClick(e, {
                    emoji: e.native
                })
            }} />
        </Modal>
        <a href="#"
            style={{ color: 'rgb(0, 102, 255)', display: 'flex', marginRight: '4px' }}
            className="btn-manage-tool"
            onClick={(e) => {
                e.preventDefault();
                console.log(e.pageX)
                setVisible(true)
            }}
        >
            <i className="far fa-smile"></i>
        </a>
    </Tooltip>
}

export default Emoij;

