import React, { Component } from 'react';

export default class SelectSpinText extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    render() {
        let { data, visible, numShow} = this.props;
        return (
            <div>
                <table className="border-0">
                    <tbody>
                        {
                            data.slice(0, this.props.visible ? data.length : numShow || 2).map((item, i) => {
                                return <tr key={i}>
                                    <td className="pt-1 pb-2">
                                        <a className="btn btn-default border" style={{ textTransform: "uppercase", fontSize: "13px" }}
                                            onClick={() => this.props.setValues(item.value)}
                                        >{item.value}</a>
                                    </td>
                                    <td >{item.text}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                {
                    data.length > numShow ? 
                    <div>
                        {
                            this.props.visible ?
                                <div style={{ marginBottom: "10px" }}>
                                    <a href="#" style={{ textDecoration: "revert" }} onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleVisible()
                                    }}>Thu gọn</a>
                                </div> :
                                <div style={{ marginBottom: "10px" }}>
                                    <a href="#" style={{ textDecoration: "revert" }} onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleVisible()
                                    }}>Hiển thị thêm</a>
                                </div>
                        }
                    </div> : null
                }
            </div>
        )
    }
}
