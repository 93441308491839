import React, { useRef, useState } from 'react';

import { Switch, message, Modal, Button, Form, Input, Tooltip, DatePicker, Table, Alert } from 'antd';
import { fetchData } from '../../../lib/apis';
import moment from 'moment';

import locale from 'antd/es/date-picker/locale/vi_VN';
import { useEffect } from 'react';

import WidgetFormSmsBrandName from "../../chat/message/elements/sms-brandname";

const CareHisView = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: []
    })
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 20
    })
    const [ loading, setloading ] = useState(false);
    const [ visibleSms, setvisibleSms ] = useState({
        visible: false,
        contact: null
    })

    const formFilter = useRef();
    const searchFilter = useRef();

    const getListData = async () => {
        try{
            setloading(true);

            let params = {
                ...pagination
            }

            if(formFilter.current){
                let values = formFilter.current.getFieldsValue();
                Object.keys(values).map((key) => {
                    if(values[key]){
                        params[key] = values[key]
                    }
                })
            }

            let response = await fetchData({
                url: `api/v1/khambenh/cham-soc`,
                params
            });

            setData({
                total: response.total,
                data: response.data
            })

        } catch(err){
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    useEffect(() => {
        getListData();
    }, [ pagination ])

    return (
        <div className="list-connect content-m">
            <WidgetFormSmsBrandName 
                {...visibleSms}
                hide={() => setvisibleSms({ visible: false })}
                type={"khambenh"}
                hideContentExample={true}
            />
            <div className="list-c">
                <div style={{ marginBottom: "15px" }} className="table-header">
                    <h3>Chăm sóc HIS: {data.total}</h3>
                    <Form
                        ref={formFilter}
                        initialValues={{
                            fullname: "",
                            status: "",
                            booking_code: "",
                            start_time: moment().format("YYYY-MM-DD"),
                            end_time: moment().format("YYYY-MM-DD")
                        }}
                        onValuesChange={(e) => {
                            let timeout = 0;
                            if (e.fullname || e.booking_code) {
                                timeout = 300;
                            }
                            if (searchFilter.current) clearTimeout(searchFilter.current)
                            searchFilter.current = setTimeout(() => {
                                getListData()
                            }, timeout)
                        }}
                    >
                        <div className="row">
                            <div className="col-md-2">
                                <Form.Item name="fullname">
                                    <Input placeholder="Họ và tên" />
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item name="booking_code">
                                    <Input placeholder="Mã" />
                                </Form.Item>
                            </div>
                            <Form.Item name="start_time" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="end_time" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item className="col-md-3">
                                <DatePicker.RangePicker
                                    locale={locale}
                                    format={'DD-MM-YYYY'}
                                    ranges={{
                                        'Hôm nay': [moment().startOf("day"), moment().endOf("day")],
                                        'Hôm qua': [moment().startOf("day").add(-1, "day"), moment().endOf("day").add(-1, "day")],
                                        'Tuần này': [moment().startOf("week"), moment().endOf("week")],
                                        'Tuần trước': [moment().add(-1, "week").startOf("week"), moment().add(-1, "week").endOf("week")],
                                        'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                        'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                    }}
                                    allowClear={false}
                                    style={{ width: "100%" }}
                                    onOpenChange={(e) => {
                                        if(!e) getListData()
                                    }}
                                    onChange={(values) => {
                                        formFilter.current.setFieldsValue({
                                            start_time: values[0] ? values[0].format("YYYY-MM-DD") : "",
                                            end_time: values[1] ? values[1].format("YYYY-MM-DD") : ""
                                        })
                                    }}
                                    defaultValue={[moment(), moment()]}
                                />
                            </Form.Item>
                            <div className="col-auto">
                                <Button type="primary" onClick={() => {
                                    formFilter.current.resetFields();
                                    getListData();
                                }}>Xóa lọc</Button>
                            </div>
                            <div className="col-auto" >
                                <Tooltip title={'Làm mới'}>
                                    <a className="btn btn-primary text-white mr-2" onClick={getListData}><i className="far fa-sync"></i></a>
                                </Tooltip>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="table-responsive">
                    <Table
                        dataSource={data.data}
                        columns={[
                            {
                                title: "#",
                                index: "#",
                                render: (item, value, index) => {
                                    return (pagination.page * pagination.limit - pagination.limit) + index + 1
                                }
                            },
                            {
                                title: "Mã lịch hẹn",
                                dataIndex: "booking_code"
                            },
                            {
                                title: "Bệnh nhân",
                                render: (item) => {
                                    return <React.Fragment>
                                        <p className='mb-1'>Họ tên: {item.ho_ten}</p>
                                        <p className='mb-1'>SDT: {item.so_dien_thoai}</p>
                                    </React.Fragment>
                                }
                            },
                            {
                                title: "Thời gian",
                                render: (item) => moment(item.created_time).format('HH:mm DD-MM-YYYY')
                            },
                            {
                                title: "Hành động",
                                className: "text-right",
                                render: (item) => {
                                    return <div>
                                        <Tooltip title="Sms">
                                            <Button size="small" type="primary" onClick={() => {
                                                setvisibleSms({
                                                    visible: true,
                                                    contact: {
                                                        contact_id: item._id
                                                    }
                                                })
                                            }}>
                                                <i className="far fa-envelope"></i>
                                            </Button>
                                        </Tooltip>
                                    </div>
                                }
                            }
                        ]}
                        rowKey="_id"
                        loading={loading}
                        pagination={{
                            total: data.total,
                            pageSize: pagination.limit,
                            onChange: (current, pageSize) => {
                                setpagination({
                                    page: current,
                                    limit: pageSize
                                })
                            },
                            current: pagination.page,
                            showSizeChanger: false
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default CareHisView;
