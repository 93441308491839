import React, { Component } from 'react';
import { message, Form, Input, TimePicker, Button, Select, InputNumber, Tooltip, notification, DatePicker, Spin, Alert, TreeSelect } from 'antd';

import moment from 'moment';

import { fetchData } from '../../lib/apis';

import qs from "qs";

import FormBooking from "./formBooking";

export default class FormCreateOrderHis extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentWillMount() {

    }
    componentDidMount() {
        
    }
    componentWillUnmount() {

    }
    componentDidUpdate(prevProps, prevState) {

    }
    componentWillReceiveProps(nextProps) {

    }

    handleSubmit = (values) => {
        values['contact_id'] = this.props.contactInfoSelected.contact_id;

        if (this.props.createBooking) this.props.createBooking(values);
    }

    render() {

        return (
            <div id="form-create-order">
                {
                    this.props.alertBooking ?
                        <Alert message={this.props.alertBooking.text} type={`${this.props.alertBooking.error ? 'error' : 'success'}`} />
                        : null
                }
                <Spin spinning={this.props.loading}>
                    <FormBooking 
                        onFinish={this.handleSubmit}
                        initialValues={{
                            fullname: this.props.contactInfoSelected.from.full_name_other || this.props.contactInfoSelected.from.full_name,
                            phone: this.props.contactInfoSelected.phone_list.length > 0 ? this.props.contactInfoSelected.phone_list[this.props.contactInfoSelected.phone_list.length - 1] : this.props.contactInfoSelected.from.phone,
                            address_code: this.props.companySelected.company_address ? this.props.companySelected.company_address.code : "",
                            address_name: this.props.companySelected.company_address ? this.props.companySelected.company_address.name : "",
                            date_booking: moment().add("day", 1),
                            // time_booking: moment("08:00", "HH:mm")
                        }}
                        loading={this.state.btnLoading} 
                        disabled={this.props.UserLogin.role_action.indexOf('create_booking') > -1 || this.props.UserLogin.role_action.indexOf('full_role') > -1 ? false : true}
                        hoursBooking={this.props.companySelected.thoi_gian_kham_benh}
                        companyId={this.props.companySelected.company_id}
                        // isPostFaceData={true}
                    />
                </Spin>
            </div>
        )
    }
}
