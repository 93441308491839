import React, { useState, useRef, useEffect } from "react";

import { Button, Input, Form, Modal, Select, Pagination, Tooltip, Alert, Upload, Table, InputNumber, Tag, Tabs } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';

import { formatPrice } from '../../../lib/helpers';
import cx from "classnames";

const Setting = (props) => {
    const [type, handleType] = useState("viettel");
    const [loading, handleLoading] = useState(false);
    const [loadingCheck, handleLoadingCheck] = useState(false);

    const { listBrandName } = props;

    const [dataSmsLogin, handleData] = useState(null);

    const form = useRef();

    const login = async () => {
        try {
            let values = await form.current.getFieldsValue();
            values['type'] = type;

            handleLoading(true);

            let url = `api/v1/sms_brandname/login`;
            let method = 'post';

            await props.fetchData({
                url: url,
                data: values,
                method: method
            });

            props.getListData();

            handleLoading(false);

            props.notification({
                content: 'Thao tác thành công',
                title: 'Login brandname',
                type: 'success'
            })
        } catch (err) {
            handleLoading(false);

            props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'login brandname',
                type: 'error'
            })
        }
    }

    const logout = async () => {
        try {
            handleLoading(true);

            await props.fetchData({
                url: `api/v1/sms_brandname/logout`,
                data: {
                    type: type
                },
                method: 'post'
            });

            props.getListData();

            handleLoading(false);

        } catch (err) {
            handleLoading(false);

            props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'logout brandname',
                type: 'error'
            })
        }
    }

    const checkBalance = async () => {
        try {
            handleLoadingCheck(true);

            await props.fetchData({
                url: `api/v1/sms_brandname/balance`,
                data: {
                    type: type
                },
                method: 'post'
            });

            props.getListData();

            handleLoadingCheck(false);

        } catch (err) {
            handleLoadingCheck(false);

            props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Kiểm tra tài khoản',
                type: 'error'
            })
        }
    }

    useEffect(() => {
        let data = listBrandName.find((item) => item.type == type);
        handleData(data);

        if (form.current) {
            if (!data) {
                form.current.setFieldsValue({
                    user: "",
                    password: "",
                    brandname: "",
                    cpcode: ""
                });
            } else {
                form.current.setFieldsValue(data);
            }
        }

    }, [listBrandName, type])

    return <React.Fragment>
        <div className="">
            <Tabs type="card" onChange={(e) => {
                handleType(e);
            }}>
                <Tabs.TabPane tab="Nhà cung cấp viettel" key="viettel">

                </Tabs.TabPane>
                <Tabs.TabPane tab="CMC Telecom" key="cmc_telecom"></Tabs.TabPane>
                <Tabs.TabPane tab="Vinaphone" key="vinaphone">

                </Tabs.TabPane>
                <Tabs.TabPane tab="Mobiphone" key="mobiphone">

                </Tabs.TabPane>
            </Tabs>
            <Form
                ref={form}
                initialValues={{
                    user: "",
                    password: ""
                }}
                layout="vertical"
            >
                {
                    dataSmsLogin && dataSmsLogin.status == 1 ?
                        <React.Fragment>
                            <div className="text-left mt-3 p-md-3">
                                <p className="d-flex align-items-center">
                                    <strong className="mr-2">Tài khoản:</strong> {dataSmsLogin.user}
                                    <Button loading={loading} onClick={logout} className="ml-5" size="small">Đăng xuất</Button>
                                </p>
                                <p>
                                    <strong className="mr-2">Brandname:</strong> {dataSmsLogin.brandname}
                                </p>
                                <p>
                                    <strong className="mr-2">Số dư:</strong> <span style={{ color: "red" }}>{formatPrice(dataSmsLogin.balance)}</span>
                                    <a className={cx('ml-3 d-inline-block', loadingCheck ? 'anticon-spin' : '')} onClick={checkBalance}><i className="fa fa-sync"></i></a>
                                </p>
                            </div>
                        </React.Fragment>
                        : <React.Fragment>
                            <div className="m-auto" style={{ width: "fit-content" }}>
                                <Form.Item name="user" label="Tên đăng nhập"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Không được để trống"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item name="password" label="Mật khẩu"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Không được để trống"
                                        }
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                {
                                    ["viettel"].indexOf(type) != -1 && <React.Fragment>
                                        <Form.Item name="cpcode" label="Cpcode (mã khách hàng)"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Không được để trống"
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </React.Fragment>
                                }
                                <Form.Item name="brandname" label="Brandname"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Không được để trống"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <div className="d-flex mt-5 justify-content-end">
                                    <Button htmlType="reset" className="mr-3">Hủy bỏ</Button>
                                    <Button htmlType="button" loading={loading} onClick={login} type="primary">Đăng nhập</Button>
                                </div>
                            </div>
                        </React.Fragment>
                }
            </Form>
        </div>
    </React.Fragment>
}

export default Setting;