import React, { useState } from "react";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons"
import ImgCrop from 'antd-img-crop';

const WidgetUploadImage = (props) => {
    const [fileList, setFileList] = useState([]);

    const handleChange = ({ fileList: newFileList }) => {
        console.log(newFileList);
        setFileList(newFileList);
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const handlePreview = async (file) => {
        return
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        window.open(file.url || file.preview)
    };

    return <>
        <ImgCrop rotate>
            <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
                customRequest={async (options) => {
                    options.onSuccess(await getBase64(options.file));
                }}
                onPreview={handlePreview}
            >
                {fileList.length >= 8 ? null : uploadButton}
            </Upload>
        </ImgCrop>
    </>
}

export default WidgetUploadImage;