import React, { Component } from 'react';
import { Tooltip, Button, Input, message, Select, Tag, Modal, Form, Checkbox, DatePicker } from 'antd';
import { fetchData } from '../lib/apis';
import config from '../lib/config';
import moment from 'moment';

import locale from "moment/locale/vi";

export default class InfoContactView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reload: false,
            list_tags: [],
            hiddenComment: null,
            showEditNote: false,
            noteContact: '',
            confirmLoading: false,
            listNote: [],
            time_support: true
        }
        this.contact_id = '';
    }
    componentWillMount() {

    }
    componentDidMount() {

    }
    componentWillUnmount() {

    }
    componentDidUpdate(prevProps, prevState) {

    }
    componentWillReceiveProps(nextProps) {

    }

    handleTag = (e) => {

    }
    settingView = (e, view) => {
        e.preventDefault();
        if (this.props.handleView) {
            this.props.handleView(view);
        }
    }
    hiddenComment = async (e) => {
        e.preventDefault();
        try {
            let data = await fetchData({
                url: `api/v2/owner/update/${this.props.contactInfoSelected.user_owner.user_id}`,
                method: 'post',
                data: {
                    hidden_cmt: this.state.hiddenComment ? 0 : 1
                }
            })
            this.setState({
                hiddenComment: !this.state.hiddenComment
            }, () => {
                message.success({
                    content: 'Thao tác thành công!',
                    key: 'success'
                })
            })
        } catch (err) {
            message.error({
                content: err.message ? err.message : 'Đã có lỗi xảy ra!',
                key: 'error'
            })
        }
    }
    saveInfoContact = async () => {
        try {
            this.setState({
                confirmLoading: true
            })
            setTimeout(async () => {
                let contactInfoSelected = this.props.contactInfoSelected;
                let data = await fetchData({
                    url: `api/v2/${contactInfoSelected.user_owner.user_id}/contact/update/${contactInfoSelected.contact_id}`,
                    method: 'put',
                    data: {
                        note: this.state.noteContact
                    }
                })
                this.setState({
                    showEditNote: false,
                    confirmLoading: false
                })
            }, 300)
        } catch (err) {
            this.setState({
                confirmLoading: false
            })
            let content = err.message ? err.message : 'Đã có lỗi xảy ra';
            message.warning({ content: content, key: 'error_api' });
        }
    }
    saveNote = async (content) => {
        if (this.props.saveNoteContact) {
            this.props.saveNoteContact({
                content: content,
                time_update: new Date().getTime(),
                user_vnp_name: this.props.UserLogin.name,
                user_vnp_id: this.props.UserLogin.user_id,
                contact_id: this.props.contactInfoSelected.contact_id
            });
            if (this.listNoteRef) {
                this.listNoteRef.scrollTo(0, 0);
            }
        }
    }
    deleteNote = (note_id) => {
        if (this.props.deleteNoteContact) this.props.deleteNoteContact(note_id);
    }
    render() {
        if (!this.props.contactInfoSelected) {
            return null;
        }
        let tag_parents = [];
        let tag_select = null;

        if (this.props.settingSystem) {
            tag_parents = this.props.settingSystem.tags.filter((item) => !item.parent_id);
            tag_parents = tag_parents.map((item) => {
                item['tag_childs'] = this.props.settingSystem.tags.filter((tag) => tag.parent_id == item.tag_id);
                return item;
            })
            tag_select = tag_parents.length > 0 ? tag_parents.find((find) => find.is_select) || tag_parents[0] : null;
        }
        return (
            <div id="info-view-content" className="scrollable-panel">

                <div>
                    <div className="panel-form-row">
                        <div className="panel-form-column" style={{ marginBottom: "5px" }}>
                            <label className="label-title">Nguồn</label>
                            <div className="source-owner">
                                <div id="self-avatar">
                                    <img
                                        src={this.props.contactInfoSelected.channel.avatar || "undefined"}
                                        className="avatar"
                                        onError={(e) => {
                                            e.target.src = "/user.jpg"
                                        }}
                                    />
                                    <div className="source-icon">
                                        {
                                            !config.type_account_social[this.props.contactInfoSelected.channel.acc_type] ? null : config.type_account_social[this.props.contactInfoSelected.channel.acc_type]['icon'] ?
                                                <img src={config.type_account_social[this.props.contactInfoSelected.channel.acc_type]['icon']} /> : null
                                                // <span>{config.type_account_social[this.props.contactInfoSelected.channel.acc_type]['name']}</span>
                                        }
                                    </div>
                                </div>
                                <span>{this.props.contactInfoSelected.type == "callcenter" ? "Callcenter" : this.props.contactInfoSelected.type == "www" ? "Web" : this.props.contactInfoSelected.channel.full_name}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-top"></div>
                <div className="panel-form-row about-contact">
                    <div className="panel-form-column">
                        <div className="d-flex justify-content-between">
                            <label className="label-title">Thông tin</label>
                            {
                                this.props.isSalesContact ?
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleEditInfoContact(this.props.contactInfoSelected);
                                }}>Chỉnh sửa</a> : null
                            }
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <i className="far fa-user"></i><span>{this.props.contactInfoSelected.from.full_name_other || this.props.contactInfoSelected.thread_title}</span>
                                </li>
                                <li>
                                    <i className="far fa-envelope"></i><span>{this.props.contactInfoSelected.from.email || "+ Email"}</span>
                                </li>
                                <li>
                                        <i className="far fa-phone"></i><span>{this.props.contactInfoSelected.from.phone || this.props.contactInfoSelected.from.phone2 || "+ Số điện thoại"}</span>
                                    </li> 
                                <li>
                                    <i className="far fa-home"></i><span>{this.props.contactInfoSelected.from.address || "+ Địa chỉ"}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="border-top"></div>
                <div className="panel-form-row">
                    <div className="panel-form-column">
                        <label className="label-title">Số điện thoại</label>
                        <div>
                            <div className="list-phone">
                                {
                                    this.props.contactInfoSelected.phone_list.length > 0 ?
                                        this.props.contactInfoSelected.phone_list.map((phone, i) => {
                                            return <div key={i} className="item-phone" onClick={() => {
                                                if(this.props.handleCallCustomer){
                                                    this.props.handleCallCustomer({
                                                        cus_phone: phone,
                                                        contact_id: this.props.contactInfoSelected.contact_id
                                                    })
                                                }
                                            }}><i className="material-icons">contact_phone</i> {phone}</div>
                                        }) : <span style={{ color: "#8d949e", fontSize: "13px" }}>Số điện thoại trong hội thoại.</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-top"></div>
                <div className="panel-form-row">
                    <div className="panel-form-column">
                        <div className="d-flex justify-content-between">
                            <label className="label-title">Lịch hẹn</label>
                            {
                                this.props.isSalesContact ?
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    if (this.props.showFormBooking) this.props.showFormBooking();
                                }}>Thêm mới</a> : null
                            }
                        </div>
                        <div style={{maxHeight: "250px", overflow: "auto"}}>
                            {this.props.contactInfoSelected.booking && this.props.contactInfoSelected.booking.data.length > 0 ?
                                this.props.contactInfoSelected.booking.data.map((item, i) => {
                                    return <div key={item._id} className="block-header-default p-2 mb-2">
                                        <p style={{ fontSize: "13px", marginBottom: "5px" }}>Code: {item['booking_code']} ({item['address_code']})</p>
                                        <p style={{ fontSize: "13px", marginBottom: "5px" }}>DV: {item['dich_vu'] && item['dich_vu']['name']}</p>
                                        <p style={{ fontSize: "13px", marginBottom: "5px" }}>TG: {item['booking_time']} ({item.status == 2 ? <span className="text-success">Thành công</span> : item.status == 0 && item.booking_timestamp >= Date.now() ? <span className="text-primary">Đang chờ</span> : <span className="text-danger">Thất bại</span>})</p>
                                    </div>
                                })
                                : <span style={{ color: "#8d949e", fontSize: "13px" }}>Khách hàng chưa có lịch hẹn mới nào.</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="border-top"></div>
                <div className="panel-form-row">
                    <div className="panel-form-column">
                        <div className="d-flex justify-content-between">
                            <label className="label-title">Lịch chăm sóc</label>
                            {
                                !this.props.showFormContactNote && this.props.isSalesContact ?
                                    <a href="#" className="" onClick={(e) => {
                                        e.preventDefault();
                                        if (this.props.handleFormContactNote) this.props.handleFormContactNote();
                                    }}>Thêm mới</a> : null
                            }
                        </div>
                        <div className="note">
                            {
                                this.props.showFormContactNote ?
                                    <Form
                                        onFinish={(values) => {
                                            if (this.props.saveContactNote) this.props.saveContactNote(values);
                                        }}
                                        initialValues={{
                                            contact_id: this.props.contactInfoSelected.contact_id,
                                            notify_time: moment().add(1, "day").startOf("day").unix() * 1000,
                                            note_type: this.state.time_support ? 1 : 0
                                        }}
                                        style={{ marginBottom: "10px" }}
                                        ref={(evt) => this.formContactNote = evt }
                                    >
                                        <Form.Item name="contact_id" noStyle>
                                            <Input type="hidden" />
                                        </Form.Item>
                                        <Form.Item
                                            name="content"
                                            rules={[{ required: true, message: 'Nội dung không được để trống' }]}
                                        >
                                            <Input.TextArea placeholder="Nhập nội dung cần chăm sóc.."></Input.TextArea>
                                        </Form.Item>
                                        <div className='d-flex justify-content-between'>
                                            <Form.Item noStyle name="note_type">
                                                <Input type={"hidden"} />
                                            </Form.Item>
                                            <Form.Item noStyle name="notify_time">
                                                <Input type={"hidden"} />
                                            </Form.Item>
                                            <Form.Item valuePropName="checked" >
                                                <Checkbox checked={this.state.time_support} onChange={(e) => this.setState({ time_support: e.target.checked }, () => {
                                                    this.formContactNote.setFieldsValue({
                                                        note_type: e.target.checked ? 1 : 0
                                                    })
                                                })}>Nhắc nhở</Checkbox>
                                            </Form.Item>
                                            {
                                                this.state.time_support && <Form.Item >
                                                    <DatePicker 
                                                        format={"HH DD/MM/YYYY"}
                                                        size="small"
                                                        showTime
                                                        inputReadOnly
                                                        defaultValue={moment().add(1, "day").startOf("day")}
                                                        disabledDate={(current) => current && current < moment().startOf("day")}
                                                        onChange={(value) => {
                                                            this.formContactNote.setFieldsValue({
                                                                notify_time: value && value.startOf("hour").unix() * 1000
                                                            })
                                                        }}
                                                    />
                                                </Form.Item>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <Button htmlType="button" type="default" onClick={this.props.handleFormContactNote} disabled={this.props.loadingBtnFormNote}>Hủy</Button>
                                            <Button htmlType="submit" type="primary" loading={this.props.loadingBtnFormNote}>Lưu</Button>
                                        </div>
                                    </Form> : null
                            }
                            {
                                this.props.listNoteContact.length == 0 && !this.props.showFormContactNote ?
                                    <span style={{ color: "#8d949e", fontSize: "13px" }}>Theo dõi các tương tác quan trọng của khách hàng. Chỉ những người quản lý Trang mới nhìn thấy hoạt động.</span> : null
                            }
                            {
                                this.props.showFormContactNote ? <div className="border-top" style={{ marginBottom: "10px" }}></div> : null
                            }
                            <div className="list-note" ref={(evt) => this.listNoteRef = evt}>
                                {
                                    this.props.listNoteContact.map((item, i) => {
                                        return <div className="item-note" key={'note_' + i}>
                                            <div className="avatar">
                                                <img src={require('../img/user.jpg')} />
                                            </div>
                                            <div className="content-note">
                                                <div className="head">
                                                    <h3 className="user-name">{item.from.fullname}</h3>
                                                    <span>
                                                        {
                                                            item.status == 1 && this.props.isSalesContact ?
                                                                <svg onClick={() => {
                                                                    if (this.props.removeContactNote) this.props.removeContactNote(item._id);

                                                                }} fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 40 40" className="delete-note-btn">
                                                                    <g>
                                                                        <path d="m28.6 25q0-0.5-0.4-1l-4-4 4-4q0.4-0.5 0.4-1 0-0.6-0.4-1.1l-2-2q-0.4-0.4-1-0.4-0.6 0-1 0.4l-4.1 4.1-4-4.1q-0.4-0.4-1-0.4-0.6 0-1 0.4l-2 2q-0.5 0.5-0.5 1.1 0 0.5 0.5 1l4 4-4 4q-0.5 0.5-0.5 1 0 0.7 0.5 1.1l2 2q0.4 0.4 1 0.4 0.6 0 1-0.4l4-4.1 4.1 4.1q0.4 0.4 1 0.4 0.6 0 1-0.4l2-2q0.4-0.4 0.4-1z m8.7-5q0 4.7-2.3 8.6t-6.3 6.2-8.6 2.3-8.6-2.3-6.2-6.2-2.3-8.6 2.3-8.6 6.2-6.2 8.6-2.3 8.6 2.3 6.3 6.2 2.3 8.6z"></path>
                                                                    </g>
                                                                </svg> : null
                                                        }
                                                        {(Date.now() - item.updated_time) >= (60 * 1000 * 60 * 24) ? moment(item.updated_time).format('HH') + 'h ' + moment(item.updated_time).format('DD-MM-YYYY') : moment(item.updated_time).format('HH:mm')}
                                                    </span>
                                                </div>
                                                <p>
                                                    {/* {item.status == 1 ? item.content :
                                                        <div style={{ color: 'rgb(136, 136, 136)', fontSize: '10px' }}>Ghi chú đã được xóa gần đây bởi </div>
                                                    } */}
                                                    {item.content}
                                                </p>
                                                {
                                                    item.note_type == 1 && <div className='notify-time'>
                                                        <p className='text-right'>
                                                            {moment(item.notify_time).format("HH:mm DD/MM/YYYY")} <i className='fa fa-clock ml-2 text-danger'></i>
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-top mt-0"></div>
                <div className="panel-form-row">
                    <div className="panel-form-column">
                        <label className="label-title">Nhãn</label>
                        <div className="list-tags">
                            <div>
                                {
                                    this.props.contactInfoSelected.tags.map((item) => {
                                        return <Tag key={item.tag_id} color={`${item.tag_color}`} closable={this.props.isSalesContact ? true : false} onClose={(e) => {
                                            if (this.props.handleUpdateTag) {
                                                this.props.handleUpdateTag({
                                                    tag: item,
                                                    contact: this.props.contactInfoSelected
                                                })
                                            } else {
                                                return false;
                                            }
                                        }}>{item.tag_name}</Tag>
                                    })
                                }
                            </div>
                            {this.props.contactInfoSelected.tags.length > 0 ? <div className="border-top" style={{ padding: "5px 0px", marginTop: "10px" }}>Thêm nhãn</div> : null}
                            {
                                tag_parents.length > 0 ?
                                    <div>
                                        <Select defaultValue={tag_select ? tag_select['tag_id'] : ''} style={{ width: "50%", borderRadius: "5px" }} size="small"
                                            onChange={(e) => this.props.handleSelectTagChat(e)}
                                        >
                                            {
                                                tag_parents.map((item) => {
                                                    return <Select.Option value={item.tag_id} key={item.tag_id}>{item.tag_name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                        <table style={{ padding: "10px 0px", marginTop: "10px" }} className="table-bordered table-vcenter text-center table-tags">
                                            <tbody className={`${!this.props.isSalesContact ? 'disable-sale' : ''}`}>
                                                <tr>
                                                    <td className="text-left">Tên</td>
                                                    <td>Màu</td>
                                                    <td>Hành động</td>
                                                </tr>
                                                {
                                                    tag_select ? tag_select['tag_childs'].map((item) => {
                                                        return <tr>
                                                            <td className="text-left" style={{ color: "#6d6d6d", userSelect: "none" }}>
                                                                <span onClick={(e) => {
                                                                    if (this.props.handleUpdateTag) {
                                                                        this.props.handleUpdateTag({
                                                                            tag: item,
                                                                            contact: this.props.contactInfoSelected
                                                                        })
                                                                    }
                                                                }} style={{cursor: "pointer"}}>
                                                                    {item.tag_name}
                                                                </span>
                                                            </td>
                                                            <td style={{ padding: "5px" }}>
                                                                <Tag key={item.tag_id} color={`${item.tag_color}`} style={{ marginBottom: "0px" }}></Tag>
                                                            </td>
                                                            <td>
                                                                <i style={{ cursor: "pointer" }}
                                                                    className={this.props.contactInfoSelected.tags.find((tag) => tag.tag_id == item.tag_id) ? `fa fa-tags` : `fal fa-tags`}
                                                                    onClick={(e) => {
                                                                        if (this.props.handleUpdateTag) {
                                                                            this.props.handleUpdateTag({
                                                                                tag: item,
                                                                                contact: this.props.contactInfoSelected
                                                                            })
                                                                        }
                                                                    }}></i>
                                                                <Tooltip title="Nhãn yêu thích">
                                                                    <i style={{ cursor: "pointer" }} className={item.is_favourite ? `fa fa-heart ml-2 text-danger` : `fal fa-heart ml-2`}
                                                                        onClick={(e) => {
                                                                            if (this.props.handleFavouriteTag) {
                                                                                this.props.handleFavouriteTag({
                                                                                    tag: item,
                                                                                    is_favourite: item.is_favourite ? 0 : 1
                                                                                })
                                                                            }
                                                                        }}
                                                                    ></i>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    }) : null
                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                    : <span style={{ color: "#8d949e", fontSize: "13px" }}>Chi nhánh bạn chưa có nhãn nào, vui lòng liên hệ quản lý chi nhánh để thêm nhãn mới.</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
