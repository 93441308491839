import React, { Component } from 'react';
import { Modal, Form, Input, Button, Select } from 'antd';

export default class ModalChangeSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staff: []
        }
    }

    componentDidMount = () => {
        this.handleSearchStaff("");
    }

    save = async (values) => {
        try {
            console.log(values);
        } catch (err) {

        }
    }

    handleSearchStaff = (e) => {
        try {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                let response = await this.props.fetchData({
                    url: `api/v1/chat/staff/list?limit=10&fullname=${e}`
                })
                this.setState({
                    staff: response.data
                })
            }, 300);
        } catch (err) {
            this.props.notification({
                title: "Tìm nhân viên",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    render() {
        return <Modal
            title={'Chuyển nhân viên chăm sóc'}
            visible={this.props.visible}
            onCancel={this.props.hide}
            className={"form-modal"}
            footer={null}
        >
            <Form
                name="basic"
                ref={(evt) => this.form = evt}
                onFinish={this.props.save}
            >
                <Form.Item name="to_id" style={{ width: "100%" }}>
                    <Select
                        showSearch
                        placeholder={"Tên nhân viên"}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.handleSearchStaff}
                        notFoundContent={null}
                    >
                        {
                            this.state.staff.map((item) => {
                                return <Select.Option value={item._id} key={item._id}>
                                    <div className="demo-option-label-item">
                                        <span role="img">
                                            <img className="img-avatar" src={item.avatar}
                                                onError={(e) => e.target.src = "/user.jpg"}
                                                style={{ width: "25px", height: "25px", marginRight: "15px" }}
                                            />
                                        </span>
                                        {item.fullname} - ({item.code})
                                    </div>
                                </Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <div style={{marginBottom: "10px"}}>
                    <i>Lưu ý: Khi chuyển khách hàng cho nhân viên khác chăm sóc, bạn không sẽ không nhận thông báo, quyền chat với khách hàng từ thời điểm đó.</i>
                </div>
                <div style={{ textAlign: "right" }}>
                    <Button onClick={this.props.hide} style={{ marginRight: "10px" }}>
                        Đóng
                </Button>
                    <Button htmlType="submit" type="primary" loading={this.props.loading}>
                        Tiếp tục
                </Button>
                </div>
            </Form>
        </Modal>
    }
}
