import React from 'react';
import {Spin} from 'antd'

const Loading = (props) => {
    return props.show && <div className='load-spin-popup'>
        <div><Spin spinning={props.show}/></div>
    </div>;
}

export default Loading;
