import React, { useState, useEffect } from "react";

import { Tooltip, Popover } from 'antd';
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";

export default function button(props){
    return <Tooltip placement="top" title={'Nhãn dán'}>
        <Popover
            content={
                <Picker
                    onEmojiClick={props.onEmojiClick}
                    disableAutoFocus={true}
                    skinTone={SKIN_TONE_MEDIUM_DARK}
                    native
                />
            }
            trigger={"click"}
            placement="top"
            onVisibleChange={props.onVisibleChange}
            visible={props.visible}
            style={{ padding: "0px" }}
        >
            <a href="#"
            style={{fontSize: "18px", color: "#e91e63"}}
            >
                <i className="far fa-smile"></i>
            </a>
        </Popover>
    </Tooltip>
}

