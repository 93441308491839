import React, { Component } from 'react';

export default class Confirm extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return this.props.visible ? 
        <div className="alert-container">
          <div className="alert-out" onClick={(e) => {
          e.preventDefault();
          this.props.onReject()
        }}></div>
                <div className="alert">
                    <div className="title">Delete</div>
                    <div className="content">Are you sure you want to delete this conversation?</div>
                    <div className="dialog-buttons">
                        <button className="btn-h outline" onClick={() => this.props.onReject()}>Cancel</button>
                        <button className="btn-h blue" onClick={() => this.props.onConfirm()}>
                          OK
                          {
                            this.props.showLoading ?
                            <div className="loader-spinner"></div>
                            : null
                          }
                        </button>
                    </div>
                </div>
        </div>
    : ''
  }
}
