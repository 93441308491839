import React, { Component } from 'react';
import { Modal, Form, Input, Button } from 'antd';

export default class ConfirmSales extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        if(!this.props.visible) return null;

        return <div className="confirm-sales">
            <Button type="primary" loading={this.props.loading} onClick={() => this.props.confirmSales(this.props.contactInfoSelected)}><i className="far fa-user"></i> Nhận chăm sóc</Button>
        </div>
    }
}
