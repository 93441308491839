"use strict";

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import io from 'socket.io-client';

import { AccountMetaData, getCookie, setCookie, dimens } from '../../config/settings';

import MainAdminView from '../admin';
import { LoadingComponent, WidgetPhoneCall, WidgetFotterApp, WidgetModalContactNote, WidgetModelErrorFanpage } from '../../widgets';
import ChatView from '../chat/index';
import PolicyView from '../policy';
import extConnect from '../../lib/conector';
import FaceIdInfoView from '../face-id/info';

export default class MainlayoutView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserLogin: null,
      companys: null,
      companySelected: null,
      title: 'Tổng đài chat',
      loadingMain: false,
      // totalNoti: 0,
      visibleModalContactNote: {
        visible: false
      },
      socketConnect: false
    };

    if (window.Notification && window.Notification.permission !== 'granted') {
      window.Notification.requestPermission();
    }

  }

  componentDidMount = () => {
    if (this.props.isAppNative && this.props.location.pathname == '/') {
      this.props.history.push('/chat');
    }
  }

  componentWillMount = async () => {
    if (!AccountMetaData.checkIssetLogin()) {
      AccountMetaData.logIn(window.location.href);
    } else {
      setTimeout(() => {
        this.getInfo();
      }, 100)
    }

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FB_ID,
        cookie: true,
        xfbml: true,
        version: 'v5.0'
      });
    }.bind(this);

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    try {
      await extConnect.init({
        ver_ext: '0.0.3'
      });
    } catch (err) {
      console.log(err);
    }
  }

  getInfo = async () => {
    try {

      let _this = this;

      let response = await this.props.fetchData({
        url: `api/v1/user/detail`,
      });

      if (response.company.length == 0) {
        setCookie(dimens.name_company, '', 0);
        _this.props.history.push({
          pathname: '/company/join'
        })
        _this.setState({ loadingMain: true, UserLogin: response.user, listDataMenu: [] })
        return;
      }
      let companySelected = response.company_selected || response.company[0];

      setCookie(dimens.name_company, JSON.stringify({
        company_id: companySelected.company_id
      }), 7);

      this.socket = io(process.env.REACT_APP_URL_SOCKET, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 2000,
        withCredentials: true,
        // cookie: false
        // reconnectionAttempts: Infinity,
        // forceBase64: true,
        auth: {
          df_t: getCookie(dimens.name_auth),
          c_t: getCookie(dimens.name_company)
        }
      });
      _this.socket.on('connect', function () {
        _this.socket.emit('join-chat');
        _this.setState({
          socketConnect: true
        })
      })
      _this.socket.on('disconnect', function () {
        _this.setState({
          socketConnect: false
        })
      })
      _this.socket.on('notification', function (message) {
        // console.log('noti', message);
        _this.props.notifyBrowser({
          body: message.text,
          link: message.link || `/`,
          title: message.title || 'Thông báo mới từ Tổng đài chat',
          audio: true
        })
      })

      _this.socket.on('callcenter', async function (data) {
        try {
          let { method } = data;
          
          if (method == "FB_PostEvent") {
            if (data.eventstatus == "RING") {
              if (_this.state.visiblePhoneCall) {
                _this.handlePhoneCall(false)
              }
              setTimeout(() => {
                _this.handlePhoneCall(true, {
                  title: data.appId ? "Đã kết nối cuộc gọi tới khách hàng" : "Cuộc gọi đến",
                  phone: data.phone
                })
              })
            } else {
              _this.handlePhoneCall(false)
            }
          }
        } catch (err) {
          console.log(err);
        }
      })

      _this.socket.on('contact_note', function (message) {
        // console.log('noti', message);
        _this.handleModalContactNote(true, message)
      })

      if (this.timeping) clearInterval(this.timeping);
      this.timeping = setInterval(() => {
        if (_this.socket && _this.socket.connected) {
          _this.socket.emit('ping')
        }
      }, 5000);
      this.props.postMessageApp({
        type: 'updateDeviceId',
        url: `${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/user/device?auth_token=${dimens['df_t']}`,
        method: 'post',
        user_id: response.user._id,
        data: {
          company_id: companySelected.company_id
        }
      })

      if (this.props.handleCompany) this.props.handleCompany(companySelected);

      let get_menu = await this.props.fetchData({
        url: 'api/v1/menu/list',
      });

      // if (this.props.isAppNative && this.props.location.pathname == '/') {
      //   this.props.history.push('/chat');
      // }

      while (true) {
        await _this.props.sleep(50);
        if (_this.socket.connected) {
          document.socket = _this.socket;
          window.socket = _this.socket;

          _this.setState({
            UserLogin: response.user,
            companys: response.company,
            companySelected: companySelected,
            loadingMain: true,
            listDataMenu: get_menu.data
          });
          break;
        }
      }
    } catch (err) {
      console.log(err);
      if (typeof err == 'object' && err.code == 401) {
        this.props.message({ content: 'Bạn không có quyền truy cập! Chuyển hướng sau 3s..', key: 'error', type: 'warning', duration: 3 });
        setTimeout(() => {
          AccountMetaData.logOut(window.location.href);
        }, 3000)
      } else {
        this.props.message({ content: 'Xin lỗi hệ thống tạm thời bị gián đoạn vui lòng thử lại sau!', key: 'message', type: 'warning', duration: 180 });
        setTimeout(() => {
          AccountMetaData.logOut();
        }, 3000)
      }
    }
  }
  getUserInfo = async () => {
    try {
      let response = await this.props.fetchData({
        url: 'api/v1/user/detail',
      });
      if (response.user) {
        this.setState({
          UserLogin: response.user
        })
      }
    } catch (err) {
      console.log(err);
    }
  }
  handleLoading = (bol) => {
    this.setState({
      loadingMain: bol
    })
  }

  handleChecked = (e, slt) => {
    try {
      let table = e ? e.target.closest('table') : slt.closest('table');
      let name = e ? e.target.name : null;
      let items = table.querySelectorAll('[name="check_item"]:not(:disabled)');

      let num_checked = 0;
      items.forEach((item) => {
        if (item.checked) num_checked++;
      })
      if (name == 'checkall') {
        if (items.length == num_checked) {
          items.forEach((item) => {
            item.checked = false;
          })
          num_checked = 0;
        } else {
          items.forEach((item) => {
            item.checked = true;
          })
          num_checked = items.length;
        }

      }
      if (items.length == num_checked && num_checked > 0) {
        table.querySelector('[name="checkall"]').checked = true;
      } else {
        table.querySelector('[name="checkall"]').checked = false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleOffline = async (value, to_id) => {
    try {
      let data = {};
      if (value == 1) data['offline'] = true;
      if (value == 0) data['online'] = true;
      if(to_id) data['to_id'] = to_id;
      
      let response = await this.props.fetchData({
        url: 'api/v1/chat/status',
        method: 'post',
        data: data
      });
      this.props.notification({
        title: "Thay đổi trạng thái",
        content: "Thao tác thành công",
        type: "success"
      })
      this.state.UserLogin['is_offline'] = value;
      this.setState({
        UserLogin: this.state.UserLogin
      })
    } catch (err) {
      this.props.notification({
        title: "Kích hoạt trạng thái nhắn tin",
        content: err.message || "Thao tác không thành công",
        type: "error"
      })
    }
  }

  selectCompany = (company, url) => {
    setCookie(dimens.name_company, JSON.stringify({
      company_id: company.company_id
    }), 7);
    window.location.href = url || '/';
  }

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  handlePhoneCall = (visible, data) => {
    this.setState({ visiblePhoneCall: visible, phoneCall: data })
  }

  handleModalContactNote = (visible, item) => {
    this.setState({ visibleModalContactNote: { visible: visible, item: item }})
  }

  render() {
    if (!this.state.loadingMain) return <LoadingComponent />;

    var props_m = {
      companys: this.state.companys,
      companySelected: this.state.companySelected,
      AccountMetaData: AccountMetaData,
      setCookie: setCookie,
      UserLogin: this.state.UserLogin,
      ...this.props,
      handleChecked: this.handleChecked,
      handleOffline: this.handleOffline,
      handleCompanySelected: (company) => this.setState({ companySelected: company }),
      getUserInfo: this.getUserInfo,
      selectCompany: this.selectCompany,
      handlePhoneCall: this.handlePhoneCall,
      visiblePhoneCall: this.state.visiblePhoneCall,
      socket: this.socket,
      listDataMenu: this.state.listDataMenu,
      dimens: dimens,
      socketConnect: this.state.socketConnect,
      // totalNoti: this.state.totalNoti,
      // handleTotalNoti: (value) => this.setState({ totalNoti: value })
    }

    return (

      <div style={{ width: "100%", height: "100%" }} >
        <Helmet>
          <title>
            {this.state.title}
          </title>
        </Helmet>
        
        <WidgetModelErrorFanpage 
          company={this.state.companySelected}
        />

        <WidgetPhoneCall visible={this.state.visiblePhoneCall} item={this.state.phoneCall} />

        <WidgetModalContactNote {...this.state.visibleModalContactNote} hide={() => this.handleModalContactNote(false)}/>

        <Switch>
          <Route
            exact
            path='/chat'
            render={props => <ChatView {...props_m} {...props} typePage={'list-contact'} />}
          />
          <Route
            exact
            path='/chat/:contact_id?'
            render={props => <ChatView {...props_m} {...props} typePage={'detail-contact'} />}
          />

          <Route
            exact
            path='/policy'
            render={props => <PolicyView />}
          />

          <Route
            exact
            path='/home'
            render={props => <MainAdminView {...props_m} {...props} />}
          />

          <Route
            exact
            path='/app/faceid-info/:company_id?'
            render={props => <FaceIdInfoView {...props_m} {...props} />}
          />

          <Route
            path=''
            render={props => <MainAdminView {...props_m} {...props} />}
          />
        </Switch>
      </div>
    )
  }
}
