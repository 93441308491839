import { Form, DatePicker, Input, Select, TimePicker } from "antd";
import React, { useEffect, useRef, useState } from "react";

import moment from "moment";

const FormTimeBooking  =(props) => {
    const { hoursBooking, minuteStep} = props;
    const [ date, setDate ] = useState();
    const [ time, setTime ] = useState();
    const [ listTime, setListTime] = useState();

    const parseListTimeRanger = (time, minute_step = 5) => {
        try {
            if (!time || !minute_step) return [];

            let time_rangers = time.replace(/\s/gi, '').split('|');

            let result = [];

            time_rangers.forEach((item) => {
                try {
                    for (let i = moment(item.split('-')[0], "HH:mm"); i < moment(item.split('-')[1], "HH:mm"); i = i.add("minute", minute_step)) {
                        result.push(i.format("HH:mm"));
                    }
                } catch (err) {

                }
            })

            return result.sort((a, b) => a - b);
        } catch (err) {
            return [];
        }
    }

    useEffect(() => {
        setListTime(parseListTimeRanger(hoursBooking, minuteStep));
    }, [])

    useEffect(() => {
        try{
            if(time || date){
                let fulldate = moment();
                if(date){
                    fulldate.set("year", date.format("YYYY"))
                    fulldate.set("month", date.format("MM") - 1)
                    fulldate.set("date", date.format("DD"))
                }
                if(time){
                    fulldate.set("hour", time.format("HH"))
                    fulldate.set("minute", time.format("mm"))
                }
                
                if(props.form){
                    props.form.setFieldsValue({
                        booking_time: fulldate.format("YYYY-MM-DD HH:mm"),
                        booking_timestamp: fulldate.unix() * 1000
                    })
                }
            }
        } catch(err){
            console.log(err)
        }
    }, [time, date])

    return <Form.Item label="Lịch hẹn"
        >
            <Form.Item noStyle name="booking_time" >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item noStyle name="booking_timestamp" >
                <Input type={"hidden"} />
            </Form.Item>
            <div className="row" style={{ marginRight: "-5px", marginLeft: "-5px" }}>
                <div className="col-md-7" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                    <Form.Item name="date_booking" className="p-0" >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" size="large"
                            disabledDate={(current) => current && current < moment().add("day", -1).endOf('day')}
                            inputReadOnly={true}
                            onChange={(value) => setDate(value)}
                        />
                    </Form.Item>
                </div>
                <div className="col-md-5" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                    <Form.Item name="time_booking" className="p-0">
                        <TimePicker style={{ width: "100%" }} format="HH:mm" size="large"
                            hideDisabledOptions={true}
                            disabledHours={() => {
                                let list_hours = [];
                                let list_time = parseListTimeRanger(hoursBooking, minuteStep);

                                for (let i = 0; i < list_time.length; i++) {
                                    list_hours.push(parseInt(moment(list_time[i], "HH:mm").format("HH")));
                                }

                                let disable_hours = [];

                                if(list_hours.length > 0){
                                    for (let i = 0; i < 24; i++) {
                                        if (list_hours.indexOf(i) == -1) {
                                            disable_hours.push(i);
                                        }
                                    }    
                                }
                                return disable_hours
                            }}
                            disabledMinutes={(hour) => {
                                let list_disabled = [];

                                let list_time = parseListTimeRanger(hoursBooking, minuteStep);

                                if(hour > -1 && list_time.length > 0){
                                    let list_minute = list_time.filter((item) => moment(item, "HH:mm").get("hour") == hour).map((item) => moment(item, "HH:mm").get("minute"));

                                    for(let i = 0; i < 60; i++){
                                        if(list_minute.indexOf(i) == -1){
                                            list_disabled.push(i);
                                        }
                                    }
                                }

                                return list_disabled
                            }}
                            inputReadOnly={true}
                            onChange={(value) => setTime(value)}
                            showNow={false}
                        />
                    </Form.Item>
                </div>
            </div>
        </Form.Item>
}

export default FormTimeBooking;