import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import { apis, fetchData } from '../../../lib/apis';

import {
    ItemTag, PopupManageTags, WidgetFormEditQuickReply, FormReplyFast, SourceContact, FormNewDirect, LoadSpinner, MessageViewItem, FormReplyProduct,
    WidgetConfirmSales,
    WidgetPolicyTagFb
} from '../../../widgets';

import { send_mes } from '../../../lib/service';
import config, { TagsMessageFb } from '../../../lib/config';
import { Tooltip, Spin, Modal, Button, Input, Form, message, Popover, Carousel, Select } from 'antd';
import qs from 'qs';
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";

import ButtonShowEmoji from './elements/btn-emoji';

import WidgetListTag from "./elements/list-tag";

import WidgetFormSmsBrandName from "./elements/sms-brandname";

import cx from "classnames";
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';

export default class MessageView extends Component {
    constructor(props) {
        super(props);
        this.contactId = this.props.contactIdSelected;
        this.handleCommentReply = this.handleCommentReply.bind(this);
        this.handleShowFormDirectNew = this.handleShowFormDirectNew.bind(this);
        this.selectReply = this.selectReply.bind(this);

        this.page = 1;
        this.limit = 18;
        this.getDataNavigator = true;
        this.scrollBt = false;

        this.state = {
            listHtmlMessage: [],
            listMessage: [],
            array_id: [],
            showLoading: false,
            contact: null,
            reload: false,
            message: '',
            send_media: false,
            comment_reply: null,
            selectedAccInfo: {},
            visibleFormNewDirect: false,
            toUserMes: null,
            linkInsta: '',
            activeQuickReply: 0,
            previewImage: null,
            lastTimeSendDirect: 0,
            lastTimeSendCmt: 0,
            lastTimeSendDirectNew: 0,
            showViewImage: false,
            itemReadUser: null
        }
        this.myRef = React.createRef();
        this.textAreaRef = React.createRef();
        this.refInputLinkInsta = null;

        this.type_send = 'text';

        this.innerHeight = window.innerHeight;
        this.focusSendMessage = false;
    }
    componentWillMount() {
        if (this.props.deviceType == "iOS") {
            let _this = this;
            window.onscroll = function (e) {
                console.log(e);
                if (document.querySelector("#topic-caption-panel")) {
                    if (_this.focusSendMessage == false) {
                        document.querySelector("#topic-caption-panel").style.transition = "unset";
                        document.querySelector("#sendMessage").blur();
                        document.querySelector("#topic-caption-panel").style.top = '0px';
                        // document.querySelector("#topic-caption-panel").style.paddingTop = _this.props.statusBarHeight+'px';
                        document.querySelector('#messages-panel').style.paddingTop = '15px';
                    } else {
                        document.querySelector("#topic-caption-panel").style.transition = "all 0.15s ease-in-out";
                        document.querySelector("#topic-caption-panel").style.top = window.pageYOffset + 'px';
                        // document.querySelector("#topic-caption-panel").style.paddingTop = _this.props.statusBarHeight+'px';
                        document.querySelector('#messages-panel').style.paddingTop = window.pageYOffset + 'px';
                    }
                }
                if (_this.focusSendMessage == true) {
                    _this.focusSendMessage = false;
                }
            }
        }
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState) {

    }
    componentWillReceiveProps(nextProps) {

    }
    handleSend(e, tag) {
        e.preventDefault();
        if (this.props.contactInfoSelected.type == "comment" && !this.state.comment_reply) {
            this.props.showNotiFy('Không có bình luận để trả lời !');
            return;
        };

        const message = this.state.message ? this.state.message.trim() : "";
        var attachments = this.state.attachmentPreview || [];

        if (attachments.length > 0) {
            for (let i = 0; i < attachments.length; i++) {
                if (attachments[i]['code'] != 200) return;
            }
        }
        this.setState({
            message: '',
            attachmentPreview: null
        }, () => {
            if (message) {
                this.sendMessage({
                    content: message,
                    item_type: 'text',
                    message_tag: tag
                });
            }
            setTimeout(() => {
                attachments.forEach((attachment) => {
                    this.sendMessage({
                        attachment: attachment,
                        item_type: 'attachment',
                        message_tag: tag
                    });
                })
            }, 300)
        })
    }

    handleSendTag(e) {
        e.preventDefault();
        let _this = this;
        let tag = "HUMAN_AGENT";
        Modal.confirm({
            title: "Gửi tin nhắn đính kèm nhãn",
            width: 600,
            content: <>
                <Form
                    layout="vertical"
                    initialValues={{
                        tag: tag
                    }}
                >
                    <Form.Item
                        name="tag"
                        label={<span className="d-flex align-items-center"><InfoCircleFilled style={{ color: "#1a90ff", marginRight: "5px" }} onClick={() => _this.setState({ visiblePolicyTagFb: true })} /> Chọn tag</span>}
                    >
                        <Select
                            placeholder="Chọn tag"
                            optionFilterProp="children"
                            className="text-left"
                            onChange={(e) => {
                                tag = e
                            }}
                        >
                            {
                                TagsMessageFb.map((item) => {
                                    return <Select.Option value={item}>{item}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </>,
            okText: 'Gửi tin nhắn ngay',
            cancelText: 'Hủy',
            onOk: () => {
                _this.handleSend(e, tag)
            },
            onCancel: () => {

            }
        })
    }

    async sendMessage(data_send) {
        let id = data_send.id || Date.now();
        data_send.id = id;
        try {
            let { item_type, content, attachment, replay } = data_send;
            let comment_reply = this.state.comment_reply;

            data_send['contact_id'] = this.props.contactInfoSelected.contact_id;

            let url = this.props.contactInfoSelected.type == 'direct' ? 'api/v1/chat/fanpagefb/send' : 'api/v1/chat/fanpagefb/comment';

            if (!replay) {
                let data_pending = {
                    _id: id,
                    contact_id: this.props.contactInfoSelected.contact_id,
                    user_id: this.props.channelSelected.user_id,
                    timestamp: Date.now() + 5000,
                    status_send: 1,
                    content: content,
                    item_type: item_type,
                    attachments: attachment ? [
                        {
                            type: attachment.type,
                            payload: { url: attachment.url }
                        }
                    ] : [],
                    data_send: data_send
                }

                if (this.props.contactInfoSelected.type == 'direct') {
                    this.props.listMessage.push(data_pending);
                    data_send['to_user_id'] = this.props.contactInfoSelected.user_id;
                    this.props.scrollToBottom();
                } else {
                    let find = this.props.listMessage.findIndex((item) => item.comment_id == (comment_reply.parent_id || comment_reply.comment_id));
                    if (find == -1) throw ({ message: 'Comment không hợp lệ' });

                    data_send['comment_id'] = comment_reply.comment_id;

                    this.props.listMessage[find]['reply'].push(data_pending);

                }
                this.props.reload();
            } else if (data_send.send_24h) {
                message.loading({
                    content: "Đang gửi tin nhắn sau 24h, bạn vui lòng chờ!",
                    duration: 0,
                    key: "send_24h"
                })
            }
            let response = null;

            try {
                response = await fetchData({
                    url: url,
                    method: 'post',
                    data: data_send
                })
            } catch (err) {
                data_send.replay = false;
                throw (err);
            } finally {
                message.destroy("send_24h");
            }

            if (data_send.send_24h) {
                let find = this.props.listMessage.findIndex((item) => item._id == id);
                if (find > -1) {
                    this.props.listMessage[find] = response.payload;
                }
                message.success({
                    content: "Gửi tin nhắn sau 24h thành công",
                    duration: 5
                })
            } else {
                if (this.props.contactInfoSelected.type == 'direct') {
                    let find = this.props.listMessage.findIndex((item) => item._id == id);
                    if (find > -1) {
                        this.props.listMessage[find] = response.payload;
                    }
                } else {
                    let find = this.props.listMessage.findIndex((item) => item.comment_id == response.payload.parent_id);
                    if (find > -1) {
                        let find_reply = this.props.listMessage[find]['reply'].findIndex((item) => item._id == id);
                        if (find_reply > - 1) {
                            this.props.listMessage[find]['reply'].splice(find_reply, 1);
                        }
                        let find_comment = this.props.listMessage[find]['reply'].findIndex((item) => item.comment_id == response.payload.comment_id);
                        if (find_comment == -1) {
                            this.props.listMessage[find]['reply'].push(response.payload);
                        }
                    }
                }
            }
            this.props.reload();
        } catch (err) {
            let error = typeof err == "object" ? err.message : 'Không thể gửi tin nhắn vui lòng thử lại!';
            if (error.indexOf('#100') > -1 && !(typeof err == "object" && err.errors?.error_subcode == 2018276) && !data_send.replay) {
                await this.props.sleep(1000);
                data_send.replay = true;
                this.sendMessage(data_send);
            } else if (typeof err == "object" && err.errors && err.errors.error_subcode == 2018278 && !data_send.replay && !data_send.send_24h) {
                await this.props.sleep(1000);
                this.sendMessage({
                    ...data_send,
                    replay: true,
                    send_24h: true
                });
            } else {
                if (this.props.contactInfoSelected.type == 'direct') {
                    let find = this.props.listMessage.findIndex((item) => item._id == id);
                    if (find > -1) {
                        this.props.listMessage[find]['status_send'] = 3;
                        this.props.listMessage[find]['error_send'] = error;
                    }
                } else {
                    let find = this.props.listMessage.findIndex((item) => item.reply.find((reply) => reply._id === id));
                    if (find > - 1) {
                        let find_reply = this.props.listMessage[find]['reply'].findIndex((item) => item._id == id);
                        if (find_reply > -1) {
                            this.props.listMessage[find]['reply'][find_reply]['status_send'] = 3;;
                            this.props.listMessage[find]['reply'][find_reply]['error_send'] = error;
                        }
                    }
                }
                this.props.reload();
                this.props.notification({
                    title: "Gửi tin nhắn",
                    content: error,
                    type: "error",
                    duration: 5
                })
            }
        }
    }
    sendDirect = async (data) => {
        try {
            this.setState({ loadingDirectNew: true });
            let response = await fetchData({
                url: `api/v1/chat/fanpagefb/send`,
                method: 'post',
                data: data
            })
            this.setState({
                visibleFormNewDirect: false,
                loadingDirectNew: false
            })
            this.props.notification({
                title: "Gửi tin nhắn",
                content: "Thao tác thành công",
                type: "success"
            })
        } catch (err) {
            this.setState({ loadingDirectNew: false });
            this.props.notification({
                title: "Gửi tin nhắn",
                content: err.message || "Không thể gửi tin nhắn vui lòng thử lại!",
                type: "error"
            })
        }
    }
    handleCommentReply = (comment_reply) => {
        this.setState({
            comment_reply: comment_reply,
            message: comment_reply.from.full_name + " "
        }, () => {
            if (!this.props.isMobile) this.textAreaRef.focus();
        })
    }
    handleShowFormDirectNew = async (bol = false, user = null) => {
        if (!bol) {
            this.setState({
                visibleFormNewDirect: false,
                toUserMes: null
            });
            return;
        }
        try {
            let res = await fetchData({
                url: 'api/v1/chat/direct/history/check',
                method: 'post',
                data: {
                    user_id: this.props.channelSelected.user_id,
                    to_user_id: user.user_id || user.userid
                }
            })
            let type_send = 'direct_new';
            if (res.data.contact) {
                type_send = 'direct';
            } else {
                let time_comp = new Date().getTime() - this.state.lastTimeSendDirectNew;
                let max_time = config.block_send.block_insta_direct_new - Math.floor(time_comp / 1000);
                if (!this.state.countDownDirectNew || this.state.countDownDirectNew <= 0 && max_time > 0) {
                    this.countDownIntervalDirectNew = setInterval(() => {
                        if (max_time >= 0) {
                            this.setState({
                                countDownDirectNew: max_time--
                            })
                        } else {
                            clearInterval(this.countDownIntervalDirectNew);
                        }
                    }, 1000);
                }
            }
            this.setState({
                visibleFormNewDirect: bol,
                toUserMes: {
                    comment: user,
                    type_send: type_send,
                    history: res.data.history ? res.data.history : [],
                    contact: res.data.contact
                }
            }, () => {
                setTimeout(() => {
                    let slt_history = document.querySelector('.history-direct');
                    if (slt_history) {
                        slt_history.scrollTop = slt_history.scrollHeight;
                    }
                }, 100);
            });
        } catch (err) {
            this.props.showNotiFy('Đã có lỗi xảy ra !');
        }
    }
    handleKeyUp = (e) => {
        if (this.state.showReplyProduct) {
            let quickReply = this.state.quickReply;
            let activeQuickReply = this.state.activeQuickReply;
            if (e.key == 'ArrowUp') {
                activeQuickReply -= 1;
                activeQuickReply = activeQuickReply >= 0 ? activeQuickReply : quickReply.length - 1;
                this.setState({
                    activeQuickReply: activeQuickReply
                })
            } else if (e.key == 'ArrowDown') {
                activeQuickReply += 1;
                activeQuickReply = activeQuickReply > quickReply.length - 1 ? 0 : activeQuickReply;
                this.setState({
                    activeQuickReply: activeQuickReply
                })
            } else if (e.key == 'Enter') {
                let message = quickReply[activeQuickReply] ? quickReply[activeQuickReply]['message'].toString() : '';
                this.setState({
                    message: this.decodeMess(message),
                    showReplyProduct: false
                });
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        } else {
            if (e.key === 'Enter') {
                if (!e.shiftKey && !this.props.isMobile) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleSend(e);
                }
            }
        }
    }
    decodeMess = (message = '') => {
        try {
            let obj_user = this.props.contactInfoSelected.from;
            let matching = message.match(/#{(.*?)?}/gm);
            if (matching) {
                matching.forEach((match) => {
                    let arr_match = match.split('|');
                    let match_u = arr_match[Math.floor(Math.random() * arr_match.length)].trim('').replace(/[\#|\{|\}]/g, '');
                    let mes_data = obj_user[match_u] ? obj_user[match_u] : match_u;
                    message = message.replace(match, mes_data);
                })
            }
            return message;
        } catch (err) {
            return "";
        }
    }
    changeMessage = (e) => {
        if (this.state.message[0] == '/') {
            if (!this.props.settingSystem.quick_reply || this.props.settingSystem.quick_reply.length === 0) {
                this.setState({
                    showFormQuickRepy: true
                })
                return false;
            }
            let filter = this.state.message.length > 2 ? this.state.message.slice(1) : '';
            let quick_reply = [];
            this.props.settingSystem.quick_reply.forEach((item, i) => {
                if (item.short_cut.indexOf(filter) != -1) {
                    quick_reply.push(item);
                }
            })
            this.setState({
                quickReply: quick_reply,
                showReplyFast: true
            })
        } else {
            this.setState({
                showReplyFast: false
            })
        }
    }
    selectReply = (options = {}) => {
        let { content, attachments } = options;
        if (attachments) {
            attachments = attachments.map((attachment) => { attachment.code = 200; return attachment }) || [];
        }
        let matching = content.match(/#{(.*?)?}/gm);
        if (matching) {
            matching.forEach((match) => {
                content = this.decodeMess(content, match);
            })
        }
        this.setState({
            showReplyProduct: false,
            activeQuickReply: 0,
            message: content,
            attachmentPreview: attachments,
            showReplyFast: false
        }, () => {
            if (!this.props.isMobile) this.textAreaRef.focus();
        })
    }
    previewImage = (file) => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onload = function (e) {
                return resolve(e.target.result);
            }
            reader.readAsDataURL(file);
        })
    }
    uploadImage = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _this = this;
        if (e.target.files.length > 0) {
            var file = e.target.files[0];
            e.target.value = "";
            let url = URL.createObjectURL(file);
            let attachmentPreview = {
                type: file.type,
                url: url,
                name: file.name
            }

            _this.setState({
                attachmentPreview: [attachmentPreview]
            }, async () => {
                // console.log(attachmentPreview);
                let formData = new FormData();
                formData.append('attachment', file);
                console.log('start---------');
                try {
                    let res_upload = await fetchData({
                        url: `api/v1/chat/fanpagefb/${_this.props.channelSelected.user_id}/upload_attactment`,
                        data: formData,
                        method: 'post',
                        type_post: 'upload_image'
                    });
                    attachmentPreview['type'] = res_upload.attachment.type;
                    attachmentPreview['code'] = 200;
                    attachmentPreview['url'] = `${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/${res_upload.attachment.url}`;

                    _this.setState({
                        attachmentPreview: [attachmentPreview]
                    }, () => {
                        URL.revokeObjectURL(file);
                        if (!this.props.isMobile) this.textAreaRef.focus();
                    })
                } catch (err) {
                    message.warning({ content: 'Upload không thành công!', key: 'error_api' });
                }
            })
        }

    }
    showViewImage = (link = '') => {
        this.setState({
            linkViewImage: link,
            showViewImage: true
        })
    }
    replaySendMessage = (data) => {
        try {
            let { id, data_send, type } = data;
            let replay = false;
            if (this.props.contactInfoSelected.type == 'direct') {
                let find = this.props.listMessage.findIndex((item) => item._id == id);
                if (find > -1) {
                    this.props.listMessage[find]['status_send'] = 1;
                    replay = true;
                }
            } else {
                let find = this.props.listMessage.findIndex((item) => item.reply.find((reply) => reply._id === id));
                if (find > - 1) {
                    let find_reply = this.props.listMessage[find]['reply'].findIndex((item) => item._id == id);
                    if (find_reply > -1) {
                        this.props.listMessage[find]['reply'][find_reply]['status_send'] = 1;
                        replay = true;
                    }
                }
            }
            data_send.replay = replay;
            if (replay) {
                this.setState({
                    reload: !this.state.reload
                }, () => {
                    this.sendMessage(data_send);
                })
            }
        } catch (err) {

        }
    }
    render() {
        let link = '';
        let inputUploadAttachment = '';
        let topTitle = '';
        let btnUpload = <Tooltip placement="top" title={'Tệp đính kèm'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
            <label
                style={{ color: 'rgb(0, 102, 255)', display: 'flex', marginRight: '5px' }}
                className="btn-manage-tool"
                htmlFor="inputUploadImage"
            >
                <i className="far fa-paperclip"></i>
            </label>
        </Tooltip>;
        let link_picture = '/user.jpg';

        if (this.props.contactInfoSelected) {
            if (this.props.channelSelected.acc_type == 1) link = `https://www.instagram.com/${this.props.contactInfoSelected.username}`;
            if (this.props.channelSelected.acc_type == 5) {
                // if (this.props.contactInfoSelected.type == 'comment' && this.props.listMessage.length > 0) {
                //     link = `https://facebook.com/${this.props.listMessage[this.props.listMessage.length - 1]['comment_id']}`
                // }
                // if (this.props.contactInfoSelected.type == 'direct') {
                //     link = this.props.contactInfoSelected.from.user_id2 ? `https://facebook.com/${this.props.contactInfoSelected.from.user_id2}` : "#"
                // }
                link = this.props.contactInfoSelected.from.user_id2 ? `https://facebook.com/${this.props.contactInfoSelected.from.user_id2}` : "#"
            }
            if (this.props.channelSelected.acc_type == 1) {
                topTitle = <a target="_blank" href={`https://www.instagram.com/${this.props.contactInfoSelected.username}`} id="topic-title" className="panel-title">{this.props.contactInfoSelected.username}</a>
            } else {
                topTitle = <a href="#" onClick={(e) => e.preventDefault()} id="topic-title" className="panel-title">{this.props.contactInfoSelected.thread_title} <span style={{ color: "#666666", fontWeight: "500" }}>{this.props.channelSelected.alias ? `( ${this.props.channelSelected.alias} )` : ''}</span></a>
            }

            let type_send = this.props.contactInfoSelected.type;
            if (this.props.channelSelected.acc_type == 5) {
                inputUploadAttachment = type_send == 'direct' ? <input type="file" style={{ display: 'none' }} id="inputUploadImage"
                    onChange={this.uploadImage}
                /> : <input type="file" accept="image/jpg, image/jpeg, image/png" style={{ display: 'none' }} id="inputUploadImage"
                    onChange={this.uploadImage}
                />
            } else if (this.props.channelSelected.acc_type == 1 && type_send == 'direct') {
                inputUploadAttachment = <input type="file" accept="image/jpg, image/jpeg, image/png" style={{ display: 'none' }} id="inputUploadImage"
                    onChange={this.uploadImage}
                />;
            } else {
                btnUpload = '';
            }
            link_picture = this.props.contactInfoSelected.picture;
        }
        var avatar = <img className="avatar"
            src={link_picture}
            onLoad={(e) => {
                e.target.className = "avatar avatar-ready";
            }}
            onError={(e) => {
                e.target.src = "/user.jpg"
            }}
        />

        let htmlPreviewAttachment = [];
        if (this.state.attachmentPreview) {
            let attachments = this.state.attachmentPreview;
            attachments.forEach((attachment, i) => {
                if (attachment.type.indexOf('image') > -1) {
                    htmlPreviewAttachment.push(<div className={attachment.code == 200 ? "preview-image image-upload success" : "preview-image image-upload"}>
                        <img src={attachment.url} className="" onClick={() => {
                            window.open(attachment.url);
                        }} />
                        <i className="material-icons close" onClick={(e) => {
                            e.preventDefault();
                            this.state.attachmentPreview.splice(i, 1);
                            this.setState({ reload: !this.state.reload });
                        }}>close</i>
                    </div>)
                } else {
                    htmlPreviewAttachment.push(<div className={attachment.code == 200 ? "preview-image image-upload success" : "preview-image image-upload"}>
                        <div className="file" onClick={() => {
                            window.open(attachment.url);
                        }}>
                            <i className="fal fa-file-alt"></i>
                            <div className="file-name">{attachment.type || attachment.name}</div>
                        </div>
                        <i className="material-icons close" onClick={(e) => {
                            e.preventDefault();
                            this.state.attachmentPreview.splice(i, 1);
                            this.setState({ reload: !this.state.reload });
                        }}>close</i>
                    </div>)
                }
            })
        }
        let tag_favourites = [];
        if (this.props.settingSystem) {
            tag_favourites = this.props.settingSystem.tags.filter((item) => item.is_favourite);
        }

        // console.log(this.props.contactInfoSelected, this.props.settingSystem);
        return (
            <div id="topic-view" >
                <WidgetPolicyTagFb 
                    visible={this.state.visiblePolicyTagFb}
                    hide={() => this.setState({ visiblePolicyTagFb: false })}
                />
                <FormNewDirect visible={this.state.visibleFormNewDirect} contactInfoSelected={this.props.contactInfoSelected}
                    hide={() => this.setState({ visibleFormNewDirect: false })}
                    loadingDirectNew={this.state.loadingDirectNew}
                    sendDirect={this.sendDirect}
                    toUserMes={this.state.toUserMes}
                    channelSelected={this.props.channelSelected}
                    handleDetailUser={this.props.handleDetailUser}
                />
                <FormReplyProduct {...this.props} visible={this.state.showReplyProduct}
                    hide={() => this.setState({ showReplyProduct: false })} selectReply={this.selectReply}
                />

                <Modal
                    title={''}
                    visible={this.state.showViewImage}
                    onCancel={() => this.setState({ showViewImage: false })}
                    className={"modal-image"}
                    zIndex={1062}
                    footer={[
                        <Button key="back" onClick={() => this.setState({ showViewImage: false })}>
                            Đóng
                        </Button>
                    ]}
                >
                    <div className="box-image">
                        <img src={this.state.linkViewImage} onError={(e) => {
                            e.target.src = require('../../../img/no_images.png')
                        }} />
                    </div>
                </Modal>

                <Modal
                    title={'Chia sẻ bài viết'}
                    visible={this.state.formPostLink}
                    footer={[
                        <Button key="back" onClick={this.cancelModalShareLink}>
                            Đóng
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.addLink}>
                            Thêm mới
                        </Button>,
                    ]}
                    onCancel={this.cancelModalShareLink}
                    onOk={this.addLink}
                    className="modal-h-antd"
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formShareLink = evt}
                        onFinish={this.addLink}
                    >
                        <Form.Item name="link" rules={[{ required: true, message: 'Liên kết không được để trống' }]}>
                            <Input placeholder={'Nhập liên kết'}
                                ref={(evt) => this.refInputLinkInsta = evt}
                            />
                        </Form.Item>
                    </Form>
                </Modal>

                {/* form send sms brandname */}

                {
                    this.props.contactInfoSelected && <WidgetFormSmsBrandName
                        {...this.props}
                        visible={this.state.visibleSms}
                        hide={() => this.setState({ visibleSms: false })}
                        contact={this.props.contactInfoSelected}
                    />
                }

                <LoadSpinner show={this.props.loading} />

                {
                    this.props.contactInfoSelected ?
                        <div id="topic-caption-panel" className="caption-panel" style={this.props.statusBarHeight ? { paddingTop: `${this.props.statusBarHeight}px` } : {}}>
                            <Link to="/chat" id="hide-message-view" className="btn-arrow-back"><i className="fal fa-arrow-left"></i></Link>
                            {
                                <a href="#" className="avatar-box">
                                    {avatar}
                                    {/* <span className="online"></span> */}
                                </a>
                            }
                            <div id="topic-title-group" onClick={(e) => {
                                e.preventDefault();
                                if (this.props.contactInfoSelected.type == "callcenter") {
                                    this.props.handleCallCustomer({
                                        cus_phone: this.props.contactInfoSelected.user_id,
                                        contact_id: this.props.contactInfoSelected.contact_id
                                    });
                                }
                            }}>
                                {topTitle}
                                <div id="topic-last-seen"></div>
                            </div>
                            <div id="topic-users"> </div>
                            <div className="d-flex align-items-center">
                                <ul id="chat-menu-bar-tool" className={cx('chat-menu-bar', this.props.isMobile ? 'd-none' : '')}>
                                    {
                                        (this.props.UserLogin.role_action.indexOf('full_role') > -1 || this.props.UserLogin.role_action.indexOf('history_contact_sale') > -1) ?
                                            <li>
                                                <Tooltip placement="bottom" title={'Lịch sử chăm sóc khách hàng'}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.handleHistoryContactSale();
                                                    }} >
                                                        <i className="far fa-history"></i>
                                                    </a>
                                                </Tooltip>
                                            </li> : null
                                    }
                                    {
                                        this.props.isSalesContact ?
                                            <li>
                                                <Tooltip placement="bottom" title={'Kiểm tra bệnh án'}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        let phone = this.props.contactInfoSelected.from.phone;
                                                        if (!phone) phone = this.props.contactInfoSelected.phone_list[Math.floor(Math.random() * this.props.contactInfoSelected.phone_list.length)];
                                                        this.props.getHisBooking(phone);
                                                    }} >
                                                        <i className="fa fa-id-badge"></i>
                                                    </a>
                                                </Tooltip>
                                            </li> : null
                                    }
                                    {
                                        this.props.contactInfoSelected.sale && this.props.contactInfoSelected.sale.status == 1 || (this.props.UserLogin.role_company.indexOf('sale') == -1 && this.props.contactInfoSelected.sales && this.props.contactInfoSelected.sales.length == 0) ?
                                            <li>
                                                <Tooltip placement="bottom" title={'Chuyển nhân viên chăm sóc'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!(this.props.contactInfoSelected && this.props.contactInfoSelected.sale && this.props.contactInfoSelected.sale.status == 3)) {
                                                            this.props.handleModalChangeSales();
                                                        }
                                                    }} >
                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 16.555 16.555" height="0.7em" width="0.7em">
                                                            <g>
                                                                <g>
                                                                    <path d="M6.409,7.421c0.034,0.207,0.114,0.337,0.193,0.42C6.789,9.084,7.83,10.24,8.781,10.24    c1.11,0,2.12-1.25,2.314-2.397c0.08-0.082,0.16-0.213,0.195-0.422c0.063-0.233,0.146-0.639,0.002-0.828    c-0.007-0.01-0.017-0.019-0.022-0.028c0.133-0.489,0.303-1.501-0.303-2.19C10.912,4.306,10.572,3.9,9.846,3.686L9.5,3.566    C8.928,3.39,8.568,3.35,8.553,3.349c-0.026-0.002-0.053,0-0.078,0.006C8.454,3.361,8.387,3.379,8.334,3.372    c-0.137-0.02-0.342,0.05-0.378,0.064C7.909,3.455,6.802,3.898,6.466,4.93C6.435,5.013,6.3,5.452,6.479,6.527    c-0.027,0.018-0.05,0.041-0.071,0.067C6.264,6.783,6.345,7.188,6.409,7.421z" />
                                                                    <path d="M12.33,10.637c0,0-0.045-0.015-0.114-0.037c-0.478-0.224-0.929-0.367-0.929-0.367    c-0.098-0.033-0.182-0.067-0.26-0.103c-0.322-0.159-0.592-0.34-0.648-0.51c0,0,0.188,1.81-1.395,1.851l-0.219-0.01    c-1.433-0.127-1.528-1.845-1.528-1.845c-0.15,0.471-1.952,1.019-1.952,1.019c-0.536,0.204-0.762,0.514-0.762,0.514    c-0.792,1.176-0.885,3.789-0.885,3.789c0.01,0.598,0.268,0.66,0.268,0.66c1.821,0.812,4.679,0.955,4.679,0.955    c0.154,0.004,0.298-0.004,0.441-0.013l0.003,0.015c0,0,2.858-0.145,4.679-0.957c0,0,0.258-0.063,0.269-0.658    c0,0-0.094-2.614-0.886-3.789C13.092,11.148,12.865,10.841,12.33,10.637z" />
                                                                    <path d="M10.316,9.698c0.021-0.026,0.041-0.052,0.063-0.078c-0.004-0.017-0.005-0.026-0.005-0.026    C10.356,9.632,10.336,9.661,10.316,9.698z" />
                                                                    <path d="M7.246,9.614L7.241,9.595L7.237,9.616c0.001-0.004,0.004-0.009,0.006-0.011    C7.243,9.605,7.244,9.607,7.246,9.614z" />
                                                                    <path d="M8.732,0C4.436,0,0.94,3.496,0.94,7.792c0,0.849,0.148,1.692,0.417,2.492l-0.702-0.157    c-0.277-0.065-0.55,0.111-0.612,0.388c-0.062,0.275,0.111,0.549,0.388,0.611l2.332,0.523c0.038,0.008,0.075,0.012,0.112,0.012    c0.234,0,0.446-0.162,0.5-0.399l0.499-2.221c0.062-0.276-0.112-0.55-0.387-0.612C3.208,8.368,2.937,8.54,2.875,8.816L2.706,9.571    C2.537,8.998,2.441,8.404,2.441,7.792c0-3.47,2.823-6.292,6.292-6.292s6.292,2.823,6.292,6.292c0,1.047-0.262,2.082-0.758,2.992    l1.318,0.717c0.614-1.131,0.939-2.412,0.939-3.709C16.524,3.496,13.028,0,8.732,0z" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </a>
                                                </Tooltip>
                                            </li> : null
                                    }
                                    {
                                        this.props.contactInfoSelected.type == 'comment' ?
                                            <li>
                                                <Tooltip placement="bottom" title={'Tất cả hội thoại của bài viết này'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.handleSearchKeyword(`post:${this.props.contactInfoSelected.media_id}`);
                                                    }} className={this.props.search_keyword === `post:${this.props.contactInfoSelected.media_id}` ? 'active' : ''}>
                                                        <svg fill="currentColor" enableBackground="new 0 0 496 496" height="0.7em" viewBox="0 0 496 496" width="0.7em" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="m344 59.496h-296c-26.47 0-48 21.53-48 48v172c0 26.47 21.53 48 48 48h43.08l.92 43.34c.281 13.002 15.209 20.285 25.63 12.44 78.785-59.398 67.621-50.982 73.99-55.78h152.38c26.47 0 48-21.53 48-48v-172c0-26.47-21.53-48-48-48zm-93 191h-110c-8.84 0-16-7.16-16-16s7.16-16 16-16h110c8.84 0 16 7.16 16 16s-7.16 16-16 16zm29-70h-168c-8.84 0-16-7.16-16-16s7.16-16 16-16h168c8.84 0 16 7.16 16 16s-7.16 16-16 16zm216 27v132c0 26.51-21.49 48-48 48h-43.15l-.85 33.41c-.227 8.69-7.363 15.59-16 15.59-6.322 0-1.703 1.809-82.86-49h-70.14c-12.236 0-23.414-4.602-31.897-12.165-10.987-9.796-4.097-27.974 10.623-27.974h145.57c31.041 0 56.204-25.163 56.204-56.204v-115.657c0-8.837 7.163-16 16-16h16.5c26.51 0 48 21.49 48 48z"></path>
                                                        </svg>
                                                    </a>
                                                </Tooltip>
                                            </li> : null
                                    }
                                    <li>
                                        <Tooltip placement="bottom" title={'Tất cả hội thoại của người dùng này'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.props.handleSearchKeyword(`uid:${this.props.contactInfoSelected.user_id || this.props.contactInfoSelected.userid}`);
                                            }} className={this.props.search_keyword === `uid:${this.props.contactInfoSelected.user_id || this.props.contactInfoSelected.userid}` ? 'active' : ''}>
                                                <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 40 40" className="customer-conversations" style={{ verticalAlign: "middle", color: "#fff" }}>
                                                    <g>
                                                        <path d="m19.8 22.5c2.3 0 4.2-1.6 4.8-3.7h8.9v11.2h-27.5v-11.2h8.9c0.6 2.1 2.5 3.7 4.9 3.7z m-8.8-5h-5l5-7.5v6.3h1.3v-6.3h15v6.3h1.2v-6.3l5 7.5h-10c0 2-1.7 3.8-3.7 3.8s-3.8-1.8-3.8-3.8h-5z"></path>
                                                    </g>
                                                </svg>
                                            </a>
                                        </Tooltip>
                                    </li>
                                    {
                                        (this.props.contactInfoSelected.type == "direct" || this.props.contactInfoSelected.type == "comment") && this.props.isSalesContact && link != "#" ?
                                            <li>
                                                <Tooltip placement="bottom" title={`Xem trên 'Facebook'`} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                                    <a href={link} target="_blank">
                                                        <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 40 40" style={{ verticalAlign: "middle", color: "rgb(255, 255, 255)", borderRadius: "13px", padding: "2px", transform: "rotate(45deg)", backgroundColor: "dodgerblue" }}>
                                                            <g>
                                                                <path d="m21.5 33.4h-3.3v-20.4l-9.4 9.3-2.3-2.3 13.4-13.4 13.3 13.4-2.3 2.4-9.4-9.4v20.4z"></path>
                                                            </g>
                                                        </svg>
                                                    </a>
                                                </Tooltip>
                                            </li>
                                            : null
                                    }
                                    {
                                        (this.props.contactInfoSelected.type == "callcenter" || this.props.contactInfoSelected.type == "www")
                                            && (this.props.isSalesContact || this.props.contactInfoSelected.num_care == 0) ?
                                            <li>
                                                <Tooltip placement="bottom" title={'Call'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.handleCallCustomer({
                                                            cus_phone: this.props.contactInfoSelected.num_care == 0 ? null : this.props.contactInfoSelected.user_id,
                                                            contact_id: this.props.contactInfoSelected.contact_id
                                                        });
                                                    }} >
                                                        <i className="far fa-phone"></i>
                                                    </a>
                                                </Tooltip>
                                            </li> : null

                                    }
                                    {
                                        this.props.contactInfoSelected.phone_list_all.length > 0 ?
                                            <li>
                                                <Tooltip placement="bottom" title={'Sms'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ visibleSms: true })
                                                    }} >
                                                        <i className="fa fa-envelope"></i>
                                                    </a>
                                                </Tooltip>
                                            </li> : null
                                    }
                                </ul>
                                <ul className="chat-menu-bar pl-0">
                                    <li className="see-more">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            if (document.querySelector('#chat-menu-bar-tool').classList.contains("d-none")) {
                                                document.querySelector('#chat-menu-bar-tool').classList.add("d-flex");
                                                document.querySelector('#chat-menu-bar-tool').classList.remove("d-none");
                                            } else {
                                                document.querySelector('#chat-menu-bar-tool').classList.remove("d-flex");
                                                document.querySelector('#chat-menu-bar-tool').classList.add("d-none");
                                            }
                                        }}>
                                            <i className="far fa-ellipsis-v" style={{ fontSize: "20px" }}></i>
                                        </a>
                                    </li>
                                    <li className="see-more">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            if (!this.props.history.location.hash) {
                                                this.props.history.push(this.props.history.location.pathname + '#info');
                                            }
                                        }}>
                                            <i className="far fa-info" style={{ fontSize: "20px" }}></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> : <div id="topic-caption-panel" className="caption-panel" style={this.props.statusBarHeight ? { paddingTop: `${this.props.statusBarHeight}px` } : {}}>
                            <Link to="/chat" id="hide-message-view" className="btn-arrow-back"><i className="fal fa-arrow-left"></i></Link>
                            <div id="topic-users"></div>
                            <ul className="chat-menu-bar pl-0">
                                <li className="see-more">
                                    <a href="#" >
                                        <i className="far fa-ellipsis-v" style={{ fontSize: "20px" }}></i>
                                    </a>
                                </li>
                                <li className="see-more">
                                    <a href="#" >
                                        <i className="far fa-info" style={{ fontSize: "20px" }}></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                }
                <div id="messages-container">
                    <WidgetConfirmSales visible={this.props.contactInfoSelected && this.props.contactInfoSelected.type != "callcenter" && this.props.contactInfoSelected.sale && this.props.contactInfoSelected.sale.status == 0}
                        confirmSales={this.props.confirmSales}
                        loading={this.props.loadingConfirmSales}
                        contactInfoSelected={this.props.contactInfoSelected}
                    />
                    <div id="messages-panel" onScroll={(e) => {
                        if (this.state.focusSendMessage) document.querySelectorAll("textarea").forEach((item) => item.blur());

                        this.props.handleScrollBoxMessage(e);
                    }} ref={(evt) => this.props.setRefMessage(evt)}>
                        {this.props.contactInfoSelected ? <SourceContact contact={this.props.contactInfoSelected} /> : null}
                        <ul id="scroller" className="chat-box group">
                            {
                                this.props.contactInfoSelected && this.props.listMessage.map((item, i) => {
                                    let html = [];
                                    if (item.item_type != "handle_sale") {
                                        html.push(<MessageViewItem
                                            Message={item}
                                            key={item.item_id}
                                            contactInfoSelected={this.props.contactInfoSelected}
                                            channelSelected={this.props.channelSelected}
                                            showViewImage={this.showViewImage}
                                            left={(this.props.contactInfoSelected.type != "callcenter" && this.props.contactInfoSelected.user_owner_id != item.user_id) || (this.props.contactInfoSelected.type == "callcenter" && item.call_type == 0)}
                                            handleCommentReply={this.handleCommentReply}
                                            handleDetailUser={this.props.handleDetailUser}
                                            hiddenComment={this.props.hiddenComment}
                                            handleShowFormDirectNew={this.handleShowFormDirectNew}
                                            type={this.props.contactInfoSelected.type}
                                            replaySendMessage={this.replaySendMessage}
                                            isSalesContact={this.props.isSalesContact}
                                            handleCallCustomer={this.props.handleCallCustomer}
                                        />)
                                        if (item.reply) {
                                            // item.reply = item.reply.sort((a,b) => b.timestamp - a.timestamp);
                                            item.reply.forEach((item) => {
                                                html.push(<MessageViewItem
                                                    Message={item}
                                                    key={item.item_id}
                                                    contactInfoSelected={this.props.contactInfoSelected}
                                                    channelSelected={this.props.channelSelected}
                                                    showViewImage={this.showViewImage}
                                                    left={this.props.contactInfoSelected.user_owner_id != item.user_id}
                                                    left_reply={this.props.contactInfoSelected.user_owner_id != item.user_id}
                                                    handleCommentReply={this.handleCommentReply}
                                                    handleDetailUser={this.props.handleDetailUser}
                                                    hiddenComment={this.props.hiddenComment}
                                                    handleShowFormDirectNew={this.handleShowFormDirectNew}
                                                    type={this.props.contactInfoSelected.type}
                                                    replaySendMessage={this.replaySendMessage}
                                                    isSalesContact={this.props.isSalesContact}
                                                    handleCallCustomer={this.props.handleCallCustomer}
                                                    getListMessage={this.props.getListMessage}
                                                />)
                                            })
                                        }
                                    } else {
                                        html.push(<MessageViewItem
                                            Message={item}
                                            key={item.item_id}
                                            contactInfoSelected={this.props.contactInfoSelected}
                                            channelSelected={this.props.channelSelected}
                                            showViewImage={this.showViewImage}
                                            center={true}
                                            UserLogin={this.props.UserLogin}
                                            handleDetailUser={this.props.handleDetailUser}
                                            hiddenComment={this.props.hiddenComment}
                                            handleShowFormDirectNew={this.handleShowFormDirectNew}
                                            type={this.props.contactInfoSelected.type}
                                            replaySendMessage={this.replaySendMessage}
                                            isSalesContact={this.props.isSalesContact}
                                            handleCallCustomer={this.props.handleCallCustomer}
                                        />)
                                    }
                                    return html
                                })
                            }
                        </ul>
                    </div>
                </div>
                <form encType="multipart/form-data" id="send-message-panel" className={`${!this.props.isSalesContact ? "disable-sale" : ""}`}>
                    <FormReplyFast {...this.props}
                        visible={this.state.showReplyFast}
                        hide={() => this.setState({ showReplyFast: false })}
                        selectReply={this.selectReply}
                        typeReplySample={"quick_reply"}
                    />

                    {/* {
                        tag_favourites.length > 0 && this.props.contactInfoSelected ?
                            <div className="list-tags">{tag_favourites.map((item) => {
                                return <ItemTag key={item.tag_id}
                                    active={this.props.contactInfoSelected.tags && this.props.contactInfoSelected.tags.find((tag) => tag.tag_id == item.tag_id) ? true : false}
                                    tag={item}
                                    contactInfoSelected={this.props.contactInfoSelected}
                                    handleUpdateTag={this.props.handleUpdateTag}
                                />
                            })}</div> : null
                    } */}
                    {
                        this.props.settingSystem ? <WidgetListTag tags={this.props.settingSystem.tags} contactInfoSelected={this.props.contactInfoSelected} handleUpdateTag={this.props.handleUpdateTag} /> : null
                    }
                    <div>
                        {
                            this.state.comment_reply && this.props.contactInfoSelected.type == 'comment' ?
                                <div className="alert-reply">
                                    <i className="material-icons reply">reply</i>
                                    Trả lời: <span className="title">{this.state.comment_reply.content}</span>
                                </div> : null
                        }
                        {
                            htmlPreviewAttachment.length > 0 ?
                                <div className="row-preview-image">
                                    {htmlPreviewAttachment}
                                </div> : null
                        }
                        <div className={`form-add-mes-m ${this.props.contactInfoSelected && this.props.contactInfoSelected.sale && this.props.contactInfoSelected.sale.status == 3 || (this.props.contactInfoSelected && (this.props.contactInfoSelected.type == "callcenter" || this.props.contactInfoSelected.type == "www")) ? 'form-send-mes-disabled' : ''}`}>

                            {
                                this.props.contactInfoSelected && this.props.channelSelected.acc_type == 1 && this.props.contactInfoSelected.type == 'direct' ?
                                    <Tooltip placement="top" title={'Chia sẻ bài viết'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                        <a href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    formPostLink: true
                                                }, () => {
                                                    if (this.refInputLinkInsta) {
                                                        this.refInputLinkInsta.focus()
                                                    }
                                                })
                                            }}
                                        >
                                            <i className="material-icons secondary" style={{ fontSize: "24px" }}>attach_file</i>
                                        </a>
                                    </Tooltip> : null
                            }

                            {
                                this.props.isMobile ?
                                    <ButtonShowEmoji
                                        visible={this.state.visibleEmoji}
                                        onVisibleChange={(e) => {
                                            this.setState({ visibleEmoji: e })
                                        }}
                                        onEmojiClick={(e, value) => {
                                            this.setState({
                                                message: this.state.message + value.emoji
                                            })
                                        }}
                                    />
                                    : null
                            }

                            <textarea ref={(evt) => this.textAreaRef = evt}
                                onChange={(e) => this.setState({
                                    message: e.target.value
                                }, () => {
                                    // this.changeMessage();
                                })}
                                id="sendMessage" name="message" placeholder="New message"
                                onKeyDown={(evt) => this.handleKeyUp(evt)}
                                value={this.state.message}
                                onFocus={(e) => {
                                    this.focusSendMessage = true;
                                    this.setState({
                                        focusSendMessage: true
                                    });
                                }}
                                onBlur={(e) => {
                                    setTimeout(() => this.setState({ focusSendMessage: false }), 200);
                                    this.focusSendMessage = false;
                                }}
                            />
                            {
                                this.state.countDownDirectNew ?
                                    <Tooltip placement="top" title={`Nhắn tin cho người lạ sau ${this.state.countDownDirectNew}s`} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                        <a href="#" className="btn-count-down"
                                        >
                                            {this.state.countDownDirectNew}
                                        </a>
                                    </Tooltip> : null
                            }
                            {
                                this.state.countDownCmt ?
                                    <Tooltip placement="top" title={`Bình luận sau ${this.state.countDownCmt}s`} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                        <a href="#" className="btn-manage-tool count-downt"
                                        >
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                width="22px" height="22px" viewBox="0 0 468.873 468.873" style={{ enableBackground: "new 0 0 468.873 468.873" }}
                                                space="preserve">
                                                <g>
                                                    <path fill="red" d="M369.992,10.059c-48.285,0-88.48,34.613-97.145,80.381H13.25C5.932,90.439,0,96.375,0,103.691v242.48
                                                c0,7.32,5.932,13.252,13.25,13.252h202.674v86.138c0,5.359,3.227,10.192,8.18,12.244c1.639,0.678,3.361,1.009,5.068,1.009
                                                c3.447,0,6.838-1.348,9.373-3.883l96.172-95.508h56.975c7.32,0,13.252-5.932,13.252-13.252V201.458
                                                c37.361-14.121,63.93-50.215,63.93-92.52C468.875,54.33,424.604,10.059,369.992,10.059z M369.992,35.059
                                                c15.922,0,30.67,5.079,42.746,13.679L310.504,152.688c-9.037-12.257-14.393-27.387-14.393-43.749
                                                C296.113,68.202,329.256,35.059,369.992,35.059z M369.992,182.821c-13.539,0-26.227-3.677-37.148-10.061L433.197,70.719
                                                c6.771,11.158,10.676,24.242,10.676,38.221C443.875,149.679,410.73,182.821,369.992,182.821z"/>
                                                </g>
                                            </svg>
                                            <span>{this.state.countDownCmt}</span>
                                        </a>
                                    </Tooltip> : null
                            }
                            {
                                !this.props.isMobile ?
                                    <ButtonShowEmoji
                                        visible={this.state.visibleEmoji}
                                        onVisibleChange={(e) => {
                                            this.setState({ visibleEmoji: e })
                                        }}
                                        onEmojiClick={(e, value) => {
                                            this.setState({
                                                message: this.state.message + value.emoji
                                            })
                                        }}
                                    />
                                    : null
                            }

                            <div id="message-toolbar-bottom" className={this.props.isMobile && !this.state.focusSendMessage ? "d-none" : "d-flex"}>
                                <Tooltip placement="top" title={'Tin trả lời lưu sẵn'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                    <a href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (this.props.settingSystem.quick_reply.length == 0) {
                                                this.setState({
                                                    showReplyFast: true
                                                }, () => {
                                                    this.setState({
                                                        showEditQuickReply: true
                                                    })
                                                })
                                            } else {
                                                this.setState({
                                                    showReplyFast: !this.state.showReplyFast
                                                }, () => {
                                                    if (!this.props.isMobile) this.textAreaRef.focus();
                                                })
                                            }
                                        }}
                                        className={cx('btn-manage-tool', this.props.isMobile && this.state.focusSendMessage ? 'd-none' : '')}
                                    >
                                        <i className="far fa-comment-alt-lines"></i>
                                    </a>
                                </Tooltip>
                                <Tooltip placement="top" title={'Gửi sản phẩm'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                    <a href="#"
                                        onClick={(e) => {
                                            console.log(e)
                                            e.preventDefault();
                                            this.setState({
                                                showReplyProduct: !this.state.showReplyProduct
                                            }, () => {
                                                if (!this.props.isMobile) this.textAreaRef.focus();
                                            })
                                        }}
                                        className={cx('btn-manage-tool', this.props.isMobile && this.state.focusSendMessage ? 'd-none' : '')}
                                    >
                                        <i className="far fa-images"></i>
                                    </a>
                                </Tooltip>
                                {/* chia se hinh anh */}
                                {btnUpload}
                            </div>

                            {
                                this.props.isMobile && !this.state.focusSendMessage ?
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        if (document.querySelector('#message-toolbar-bottom').classList.contains("d-none")) {
                                            document.querySelector('#message-toolbar-bottom').classList.add("d-flex");
                                            document.querySelector('#message-toolbar-bottom').classList.remove("d-none");
                                        } else {
                                            document.querySelector('#message-toolbar-bottom').classList.remove("d-flex");
                                            document.querySelector('#message-toolbar-bottom').classList.add("d-none");
                                        }
                                    }} className="btn-manage-tool">
                                        <i className="fa fa-ellipsis-v"></i>
                                    </a> : null
                            }
                            {
                                !this.props.isMobile && this.props.contactInfoSelected?.type == "direct" ?
                                    <Tooltip placement="top" title={'Gửi tin nhắn đính kèm nhãn'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                        <a href="#"
                                            onClick={(evt) => this.handleSendTag(evt)}
                                            className={cx("btn-manage-tool", (!this.state.message.length && !htmlPreviewAttachment.length) && "form-send-mes-disabled")}
                                        >
                                            <i className="far fa-tags"></i>
                                        </a>
                                    </Tooltip> : null
                            }

                            <Tooltip placement="top" title={'Enter để gửi, shift + enter để xuống dòng'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                                <a href="#"
                                    onClick={(evt) => this.handleSend(evt)}
                                    className="btn-manage-tool"
                                >
                                    {/* <i className="material-icons">send</i> */}
                                    <svg fill="#0084FF" xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" viewBox="0 0 535.5 535.5" >
                                        <g>
                                            <g >
                                                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75   " />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </Tooltip>

                            {inputUploadAttachment}
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
