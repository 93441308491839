import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Select, Tooltip, Row, Col, Result, Radio, Pagination, Spin, InputNumber } from 'antd';
import { fetchData } from '../../../lib/apis';
import ItemStaff from './item';
import qs from 'qs';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const key_loading = 'loading';

export default class StaffView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            listHtml: [],
            arrIdData: [],
            visibleForm: false,
            codeJoin: null,
            listRole: [],
            optionValues: [],
            loadingData: true,
            total: 0,
            listRoleAction: []
        }
        this.limit = 20;
        this.page = 1;

        this.inputCodeCompany = null;
        this.timeSearch = null;

        this.updateRoleStaff = this.updateRoleStaff.bind(this);
        this.getListData = this.getListData.bind(this);
    }
    componentWillMount() {
        this.getListRole();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async (option = {}) => {
        try {
            let { type } = option;
            this.setState({ loading: true });
            if (type == 'reset') this.page = 1;
            let params = {
                limit: this.limit,
                page: this.page,
                code_join: 1
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/staff/list?${qs.stringify(params)}`
            });
            this.setState({
                listData: res.data,
                codeJoin: res.code_join,
                total: res.total,
                loading: false
            }, () => {
                this.props.handleChecked(null, document.querySelector('[name="checkall"]'));
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Danh sách nhân viên",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }
    getListRole = () => {
        fetchData({
            url: `api/v1/role/list`
        }).then((res) => {
            this.setState({
                listRole: res.data,
                loadingData: false,
                listRoleAction: res.role_actions
            }, () => {
                this.getListData();
            })
        }).catch((err) => {
            if (err.code && this.props.handleViewError) this.props.handleViewError(err.code);
            this.props.notification({
                title: "Danh sách nhân viên",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        })
    }
    renderHtml = (options = {}) => {
        let data = options['data'] ? options['data'] : [];
        let html = options['type'] == 'reset' ? [] : this.state.listHtml;
        let arrIdData = this.state.arrIdData;
        data.forEach((item, i) => {
            if (arrIdData.indexOf(item.role_user_id) == -1) arrIdData.push(item.role_user_id);
            html.push(<ItemStaff updateRoleStaff={this.updateRoleStaff} listRole={this.state.listRole} staff={item} key={'staff_' + item.role_user_id} UserLogin={this.props.UserLogin} />)
        })
        this.setState({
            listHtml: html,
            arrIdData: arrIdData
        })
    }
    handleCancel = () => {
        if (this.state.confirmLoading) return;
        this.setState({
            visibleForm: false,
            optionValues: []
        }, () => {
            this.formH.resetFields();
        })
    }
    openModal = () => {
        this.setState({
            visibleForm: true,
            id_only_one_time: false
        })
    }
    submitCreateCode = async () => {
        try {
            const values = await this.formH.validateFields();
            // let options = this.state.optionValues;
            // if(options[0]){
            //     values[options[0]] = true;
            // }
            if (typeof values['role_menu'] == 'string' && values['role_menu']) {
                values['role_menu'] = [values['role_menu']];
            }
            this.setState({
                confirmLoading: true
            })
            let response = await fetchData({
                url: `api/v1/company/code`,
                method: 'post',
                data: values
            })
            this.setState({
                confirmLoading: false,
                codeJoin: response.data,
                optionValues: []
            })
            message.success({ content: 'Thao tác thành công', key: 'success_create_code' })
        } catch (err) {
            this.setState({
                confirmLoading: false
            })
            message.warning({ content: 'Đã có lỗi xảy ra, vui lòng thử lại sau!', key: 'error_create_code' });
        }
    }
    copyCodeToClipboard = () => {
        const el = this.inputCodeCompany;
        if (!el) return;
        el.select()
        document.execCommand("copy");
        notification['success']({
            message: 'Đã sao chép mã gia nhập',
            key: 'coppy_code_join_company'
        });
    }
    updateRoleStaff = async (values) => {
        try {
            if (typeof values['role_menu'] == 'string' && values['role_menu']) {
                values['role_menu'] = [values['role_menu']];
            }
            if(values['ext']){
                values['ext'] = values['ext'].split('|').map((item) => item.replace(/\s/gi, ''))   
            }
            message.loading({ content: 'Đang xử lý..', key: key_loading, duration: 10 });
            let response = await fetchData({
                url: `api/v1/staff/${values.user_id}/role`,
                method: 'put',
                data: values
            });
            this.getListData();
            message.success({ content: 'Thành công', key: key_loading, duration: 2 });
        } catch (error) {
            let content = error.message ? error.message : 'Đã có lỗi xảy ra vui lòng thử lại sau!';
            message.warning({ content: content, key: key_loading, duration: 2 });
        }
    }

    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    deleteMany = (options = {}) => {
        let { ids } = options;
        if (!ids || ids.length <= 0) {
            this.props.notification({
                title: "Xóa nhân viên",
                content: "Chọn ít nhất một đối tượng",
                type: "error"
            })
            return
        }
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            // className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await fetchData({
                            url: `api/v1/staff/delete/`,
                            method: 'delete',
                            data: { ids: ids }
                        })
                        _this.props.notification({
                            title: "Xóa nhân viên",
                            content: "Thao tác thành công",
                            type: "success"
                        })
                        _this.getListData();
                        return resolve(true);
                    } catch (err) {
                        _this.props.notification({
                            title: "Xóa nhân viên",
                            content: err.message ? err.message : 'Thao tác thất bại',
                            type: "error"
                        })
                        return reject(false);
                    }
                })
            },
            onCancel() { },
        });
    }

    createUser = async (values) => {
        try {
            this.setState({ loadingForm: true });
            let response = await fetchData({
                url: `api/v1/company/create_user`,
                method: 'post',
                data: values
            });
            this.getListData();
            this.props.notification({
                title: "Thêm nhân viên",
                content: "Thao tác thành công",
                type: "success"
            })
            this.setState({ loadingForm: false, visibleFormCreateUser: false }, () => {
                this.formCreateUser.resetFields()
            });
        } catch (error) {
            this.setState({ loadingForm: false });
            this.props.notification({
                title: "Thêm nhân viên",
                content: error.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    render() {
        if (this.state.loadingData) return null;

        return (
            <div className="list-connect content-m">
                <Modal
                    title={'Thêm nhân viên'}
                    visible={this.state.visibleFormCreateUser}
                    footer={null}
                    onCancel={() => this.setState({ visibleFormCreateUser: false }, () => this.formCreateUser.resetFields())}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formCreateUser = evt}
                        initialValues={{
                            role_menu: "sale"
                        }}
                        onFinish={this.createUser}
                        layout="vertical"
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập' }]}
                            label="Tên đăng nhập"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="first_name"
                            rules={[{ required: true, message: 'Trường này bắt buộc' }]}
                            label="Họ"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            rules={[{ required: true, message: 'Trường này bắt buộc' }]}
                            label="Tên"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                            label="Mật khẩu"
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item name="role_menu" label="Phân quyền"
                            rules={[{ required: true, message: 'Vui lòng chọn 1 quyền' }]}
                        >
                            <Select>
                                {
                                    this.state.listRole.map((role) => {
                                        return <Select.Option value={role.role_name} key={role.role_name}>{role.display_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <div className="text-right" style={{ marginTop: "15px" }}>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingForm} disabled={this.state.loadingForm}>
                                Thêm mới
                            </Button>
                        </div>
                    </Form>
                </Modal>

                <Modal
                    title={'Mã gia nhập'}
                    visible={this.state.visibleForm}
                    onOk={this.submitCreateCode}
                    onCancel={this.handleCancel}
                    className={"form-modal form-code-company"}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Đóng
                    </Button>,
                        <Button key="submit" type="primary" loading={this.state.confirmLoading} onClick={this.submitCreateCode}>
                            Tạo mã mới
                    </Button>,
                    ]}
                >
                    <Form
                        name="basic"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        ref={(evt) => this.formH = evt}
                        initialValues={{
                            time_expired: 1
                        }}
                        layout="vertical"
                    >
                        <Form.Item name="id_only_one_time">
                            <Radio.Group defaultValue={false} onChange={(e) => this.setState({id_only_one_time: e.target.value})}>
                                <Radio value={false}>Sử dụng có thời hạn</Radio>
                                <Radio value={true}>Sử dụng một lần</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="time_expired">
                            <Input type="number" addonAfter="Ngày" disabled={this.state.id_only_one_time}/>
                        </Form.Item>
                        <Form.Item name="role_menu" label="Quyền gia nhập:">
                            <Select
                                placeholder="Phân quyền"
                                // allowClear
                                // mode="multiple"
                                defaultValue={this.state.codeJoin ? this.state.codeJoin.role_menu : []}
                            >
                                {
                                    this.state.listRole.map((role) => {
                                        return <Select.Option value={role.role_name} key={role.role_name}>{role.display_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        {
                            this.state.codeJoin ?
                                <div className="code-show">
                                    <label onClick={this.copyCodeToClipboard}>{this.state.codeJoin.join_code}</label>
                                </div>
                                : null
                        }
                        <div>
                            <i>Lưu ý: Mã gia nhập nên sử dụng 1 lần nhằm đảm bảo tính bảo mật.</i>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title={'Chỉnh sửa quyền'}
                    visible={this.state.visibleFormRole}
                    onCancel={() => this.setState({ visibleFormRole: null })}
                    className={"form-modal form-code-company"}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formUpdateRole = evt}
                        onFinish={this.updateRoleStaff}
                        layout="vertical"
                    >
                        <Form.Item noStyle name="user_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="role_menu" label="Level">
                            <Select
                                placeholder="Phân quyền"
                            // allowClear
                            // mode="multiple"
                            >
                                {
                                    this.state.listRole.map((role) => {
                                        return <Select.Option value={role.role_name} key={role.role_name}>{role.display_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="role_action" label="Thêm quyền">
                            <Select
                                allowClear
                                mode="multiple"
                            >
                                {
                                    this.state.listRoleAction.map((role, i) => {
                                        return <Select.Option value={role.action_name} key={i}>{role.display_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="ext" label="Số máy lẻ">
                            <Input placeholder="801 | 802.."/>
                        </Form.Item>
                        <Form.Item name="mobile_number" label="Số di động">
                            <Input />
                        </Form.Item>
                        {
                            this.state.detailUser ? 
                            <React.Fragment>
                                <Form.Item label="Link callcenter callback">
                                    <Input value={`${window.location.origin}/callcenter/callback?type=2&code=${this.state.detailUser.id_callback}`}/>
                                </Form.Item>
                                <Form.Item label="Link tạo lịch hẹn">
                                    <Input value={`${window.location.origin}/lichhen?ref=${this.state.detailUser.id_callback}`} />
                                </Form.Item>
                            </React.Fragment> : null
                        }
                        <div style={{ textAlign: "right", marginTop: "20px" }}>
                            <Button onClick={() => this.setState({ visibleFormRole: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.confirmLoading}>
                                Cập nhập
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="list-c">
                    <div style={{ marginBottom: "10px" }} className="d-flex">
                        {
                            this.state.codeJoin ?
                                <div style={{ position: 'relative', margin: "0px",  marginRight: "10px", maxWidth: "100%", overflow: "hidden"}} className="d-inline-block">
                                    <textarea style={{ opacity: 0, position: 'absolute', zIndex: 0, width: "1px", height: "1px" }} onChange={(e) => { }}
                                        value={this.state.codeJoin.join_code}
                                        ref={(evt) => this.inputCodeCompany = evt} />
                                    <button className="btn-action" onClick={this.copyCodeToClipboard} style={{ borderRadius: "5px", margin: "0px", maxWidth: "100%", overflow: "hidden" }}>
                                        Mã gia nhập: <span style={{ marginLeft: "10px", overflow: "hidden", textOverflow: "ellipsis" }}>{this.state.codeJoin.join_code}</span>
                                    </button>
                                </div> : null
                        }
                        {
                            this.props.UserLogin.role_action.indexOf("company_code_join") > -1 &&
                            <Tooltip title={'Tạo mã gia nhập mới'} placement={"top"}>
                                <div className="btn-add-role ant-dropdown-trigger" onClick={this.openModal} style={{ margin: "0px" }}>
                                    <i className="fal fa-plus"></i>
                                </div>
                            </Tooltip>
                        }
                    </div>
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý nhân viên: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Thêm nhân viên'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleFormCreateUser: true })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            {
                                this.props.UserLogin.role_action && this.props.UserLogin.role_action.indexOf('full_role') > -1 ?
                                <Tooltip title={'Lịch sử hành động'}>
                                    <a className="btn btn-primary text-white mr-2" onClick={() => {
                                        this.props.history.push('/staff/history')
                                    }}><i className="fal fa-history"></i></a>
                                </Tooltip>
                                : null
                            }
                            <Tooltip title={'Xóa nhân viên'}>
                                <a className="btn btn-danger text-white" onClick={() => {
                                    let ids = []
                                    document.querySelectorAll("[name='check_item']:checked").forEach((item) => {
                                        ids.push(item.value);
                                    })
                                    this.deleteMany({
                                        ids: ids
                                    })
                                }}><i className="far fa-trash"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                role: "collaborator",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="fullname">
                                                <Input placeholder="Nhập tên nhân viên" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Item name="code">
                                                <Input placeholder="Mã nhân viên" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Item name="role">
                                                <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', value: e })} placeholder="Chọn level" allowClear>
                                                    {
                                                        this.state.listRole.map((role) => {
                                                            return <Select.Option value={role.role_name} key={role.role_name}>{role.display_name}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>
                                            <label className="css-control css-control-primary css-checkbox p-0">
                                                <input name="checkall" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked} />
                                                <span className="css-control-indicator"></span>
                                            </label>
                                        </th>
                                        <th>Stt</th>
                                        <th>Avatar</th>
                                        <th>Mã</th>
                                        <th>Tên</th>
                                        <th>Username</th>
                                        <th>Level</th>
                                        <th>Quyền khác</th>
                                        <th>Tham gia</th>
                                        <th className="text-right">Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id}>
                                                    <td>
                                                        <label className={`css-control css-control-primary css-checkbox p-0 ${item._id === this.props.UserLogin._id ? "disabled" : ""}`}>
                                                            <input name="check_item" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked}
                                                                disabled={item._id === this.props.UserLogin._id} value={item._id}
                                                            />
                                                            <span className="css-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>
                                                        <div style={{ width: "35px", height: "35px", position: "relative" }}>
                                                            {
                                                                <Tooltip title={item.roles.is_offline ? 'Bận' : 'Rảnh'}>
                                                                    <span className={`fab fa-facebook-messenger ${item.roles.is_offline ? 'text-danger' : 'text-success'}`} style={{position: "absolute", bottom: "-2px", left: "0", fontSize: "13px"}}></span>
                                                                </Tooltip>
                                                            }
                                                            {
                                                                item.avatar ? <img className="avatar" src={item.avatar} /> : <img className="avatar" src={require('../../../img/user.jpg')} />
                                                            }
                                                            <Tooltip title={item.roles.last_time_connect ? `Online ${moment(item.roles.last_time_connect).fromNow()}` : ''}>
                                                                <span style={{position: "absolute", bottom: "0px", right: "0px"}} className={`badge-status badge ${item.roles.last_time_connect < Date.now() - 30 * 1000 ? "badge-secondary" : "badge-success"}`}></span>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                    <td>{item.code}</td>
                                                    <td>
                                                        <b>{item.fullname}</b>
                                                        <p className="mb-0">{item.last_time_connect ? 'Online: ' + moment(item.last_time_connect).fromNow() : ''}</p>
                                                    </td>
                                                    <td>{item.username}</td>
                                                    <td>
                                                        <div>
                                                            {item.role_menu.map((item, i) => {
                                                                return <span style={{ textTransform: "capitalize", fontSize: "13px", marginRight: "5px" }} className={`badge ${item.color ? 'badge-' + item.color : ''}`}>{item.display_name}</span>
                                                            })}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {item.roles.role_action ? item.roles.role_action.map((action, i) => {
                                                                let find = this.state.listRoleAction.find((item) => item.action_name == action);
                                                                return find ? <span className="badge badge-secondary" style={{ fontSize: "13px", marginRight: "5px" }}>{find.display_name}</span> : null
                                                            }) : null}
                                                        </div>
                                                    </td>
                                                    <td>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</td>
                                                    <td className="text-right">
                                                        <Tooltip title="Xóa">
                                                            <Button disabled={item._id == this.props.UserLogin._id} size="small" type="danger" className="mr-2" onClick={() => this.deleteMany({ ids: [item._id] })}><i className="far fa-trash-alt"></i></Button>
                                                        </Tooltip>
                                                        <Tooltip title="Chỉnh sửa">
                                                            <Button disabled={item._id == this.props.UserLogin._id} size="small" onClick={() => {
                                                                this.setState({
                                                                    visibleFormRole: true,
                                                                    detailUser: item
                                                                }, () => {
                                                                    setTimeout(() => {
                                                                        if (this.formUpdateRole) this.formUpdateRole.setFieldsValue({
                                                                            user_id: item._id,
                                                                            role_menu: item.roles.role_menu,
                                                                            role_action: item.roles.role_action,
                                                                            ext: item.roles.ext ? item.roles.ext.join(' | ') : '',
                                                                            mobile_number: item.roles.mobile_number
                                                                        })
                                                                    }, 100)
                                                                })
                                                            }}><i className="far fa-edit"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
