import React, { Component } from 'react';

import { FromCreateOrder, ItemTag, InfoContact, WidgetChatFormOrderHis, WidgetSwipe, WidgetSearchMedical} from '../../widgets';

import { fetchData } from '../../lib/apis';
import { Spin, Empty } from 'antd';
import { NotificationMessage } from "../../lib/helpers";

import {Picker} from "emoji-mart";

export default class InfoContactView extends Component{
  constructor(props){
    super(props);
    this.state = {
        reload: false,
        list_tags: [],
        active_tab: 1,
        Provinces: [],
        Districts: [],
        Communes: [],
        BranchHis: [],
        loading: false,
        dataSearchMedical: null,
        loadingSearchMedical: false
    }
    this.getCity = this.getCity.bind(this);
  }
  componentWillMount(){
    // this.getCity();
  }
  componentDidMount(){

  }
  componentWillUnmount(){

  }
  componentDidUpdate(prevProps, prevState){

  }
  componentWillReceiveProps(nextProps){
    if(nextProps.contactInfoSelected && !this.props.contactInfoSelected){
        this.setState({
            active_tab: 1
        })
    }
  }
  getCity = async (type = 'provinces', parent_id = 0) => {
    try {
        let res = await fetchData({url: `api/v2/geo/${type}?parent_id=${parent_id}`});
        let data = {};
        if(type == 'provinces') {
            data['Provinces'] = res.data;
        } else if (type == 'districts') {
            data['Districts'] = res.data;
        } else if (type == 'communes') {
            data['Communes'] = res.data;
        }
        this.setState(data);
    } catch (err) {

    }
  }

  getBrachHist = async () => {
    try {
        let res = await fetchData({url: `api/v1/his/branch`, method: 'get'});
        this.setState({
            loading: false,
            BranchHis: res.data
        })
    } catch (err) {
        this.setState({
            loading: false
        })
        this.props.notification({
            title: "Lấy danh sách chi nhánh khám bệnh",
            content: err.message || "Đã có lỗi xảy ra",
            type: "error"
        })
    }
  }

  createBooking = async (values) => {
    try {
        this.setState({
            loading: true,
            alertBooking: null
        })
        if(!values['booking_time']) throw({ message: "Vui lòng chọn thời gian lịch hẹn!"});

        let res = await fetchData({url: `api/v1/chat/booking/create`, method: 'post', data: values});
        let booking = this.props.contactInfoSelected.booking || { data: [] };
        booking['data'].push(res.data);

        let contactInfoSelected = this.props.contactInfoSelected;
        contactInfoSelected.booking = booking;

        this.props.notification({
            title: "Tạo lịch hẹn",
            content: "Tạo lịch hẹn mới thành công",
            type: "success"
        })
        
        this.setState({
            loading: false,
            reload: !this.state.reload,
            alertBooking: {
                success: 1,
                text: "Tạo lịch hẹn thành công"
            }
        }, () => {
            this.props.updateContactSelected(contactInfoSelected);
        })
    } catch (err) {
        this.setState({
            loading: false,
            alertBooking: {
                error: 1,
                text: err.message || "Đã có lỗi xảy ra"
            }
        })
        this.props.notification({
            title: "Tạo lịch hẹn",
            content: err.message || "Đã có lỗi xảy ra",
            type: "error"
        })
    }
  }

  showFormBooking = () => {
    this.setState({
        active_tab: 3,
        // loading: true,
        alertBooking: null
    }, () => {
        // this.getBrachHist()
    })
  }

  showWidgetSearchMedical = async () => {
    try{
        this.setState({
            active_tab: 4,
            loadingSearchMedical: true
        })

        let response = await fetchData({url: `api/v1/${this.props.contactInfoSelected.contact_id}/search-medical`, method: 'get'});

        this.setState({
            dataSearchMedical: response.data
        })
    } catch(err){
        NotificationMessage({ type: "warning", response: err });
    }
    this.setState({
        loadingSearchMedical: false
    })
  }


  render(){
    let html = null;
    if(this.props.contactInfoSelected){
        if(this.state.active_tab == 1){
            html = <InfoContact
                {...this.props}
                showFormBooking={this.showFormBooking}
            />;
        } else if(this.state.active_tab == 2){
            html = <FromCreateOrder 
                Provinces={this.state.Provinces}
                Districts={this.state.Districts}
                Communes={this.state.Communes}
                getCity={this.getCity}
                contactInfoSelected={this.props.contactInfoSelected}
                isPostFaceData={true}
            />
        } else if(this.state.active_tab == 3){
            html = <WidgetChatFormOrderHis 
                {...this.props}
                BranchHis={this.state.BranchHis}
                loading={this.state.loading}
                createBooking={this.createBooking}
                alertBooking={this.state.alertBooking}
            />
        } else if(this.state.active_tab == 4){
            html = <div id="info-view-content" class="scrollable-panel">
                    <Spin spinning={this.state.loadingSearchMedical}>
                        {
                            this.state.dataSearchMedical ? <div id="content_tra_cuu" className="search-medical">
                                <WidgetSearchMedical type={2} data={this.state.dataSearchMedical} />
                            </div> : <Empty description="Chưa có dữ liệu" className='mt-3'/>
                        }
                    </Spin>
                </div>
        }
    }
    return(
        <div id="info-view" className={this.props.history.location.hash == "#info" ? "show" : ""}>
            
            {
                this.props.history.location.hash == "#info" && <WidgetSwipe id="box-touch-right" event="swipeleft" action={() => {
                    this.setState({
                        active_tab: this.state.active_tab == 1 ? 3 : 1
                    })
                }} visible={this.props.deviceType == "iOS"}/> 
            }

            <div className="top-bar-app" style={{paddingTop: `${this.props.statusBarHeight}px`}}></div>
            
            {/* <div className="btn-close border-bottom">
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    document.querySelector('#message-view #info-view').classList.remove("show");
                }}><i className="fal fa-arrow-left"></i> Quay lại</a>
            </div> */}

            <div className="" id="info-caption-panel">
                <div className={this.state.active_tab == 1 ? 'active info-tab' : 'info-tab'}
                    onClick={(e) => this.setState({ active_tab: 1 })}
                >
                    <div className="btn-close btn-arrow-back" onClick={(e) => {
                        document.querySelector('#message-view #info-view').classList.remove("show");
                        if(this.props.history.location.hash) this.props.history.goBack();
                    }}><i className="fal fa-arrow-left"></i></div> Thông tin
                </div>
                <div id="create-booking" className={`${this.state.active_tab == 3 ? 'active info-tab' : 'info-tab'} ${!this.props.isSalesContact ? 'disable-sale' : ''}`}
                    onClick={() => {
                        if(this.props.isSalesContact){
                            this.showFormBooking();
                        }
                    }}
                >
                    Tạo lịch hẹn
                </div>
                <div id="search-medical" className={`${this.state.active_tab == 4 ? 'active info-tab' : 'info-tab'} ${!this.props.isSalesContact ? 'disable-sale' : ''}`}
                    onClick={() => {
                        if(this.props.isSalesContact){
                            this.showWidgetSearchMedical();
                        }
                    }}
                >
                    Lịch sử KCB
                </div>
            </div>
            {
                !this.props.contactInfoSelected ?
                <div style={{minHeight: "100%", paddingTop: "50px", textAlign: "center"}}><Spin spinning={true}></Spin></div> 
                : html
            }
        </div>
    )
  }
}
