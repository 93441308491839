import React, { Component } from 'react';
import { Tooltip, Button, Modal, Progress, Form, Input, InputNumber } from 'antd';

export default class ConvertUid extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentWillMount() {
        
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                title="Convert uid"
                footer={null}
                onCancel={this.props.hide}
                width={600}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    ref={(e) => this.formConvertUid = e}
                    initialValues={{
                        thread: 1
                    }}
                >
                    {
                        this.props.loading ?
                        <Progress
                            strokeColor={{
                                from: '#108ee9',
                                to: '#87d068',
                            }}
                            percent={99.9}
                            status="active"
                            showInfo={false}
                            style={{marginBottom: "15px"}}
                        /> : null
                    }
                    <Form.Item name="thread" label="Luồng convert">
                        <InputNumber min={1} style={{width: "100%"}}/>
                    </Form.Item>
                    <div style={{marginTop: "20px"}}>
                        <p className="text-success">Thành công: {this.props.statusRun ? this.props.statusRun.success : 0}</p>
                        <p>Lỗi: {this.props.statusRun ? this.props.statusRun.error : 0}</p>
                    </div>
                    <div className="d-flex" style={{ marginTop: "20px", justifyContent: "flex-end" }}>
                        {
                            this.props.loading ? 
                            <Button htmlType="button" type="danger" onClick={this.props.stop}>Dừng lại</Button>
                            : <Button htmlType="button" type="primary" onClick={() => {
                                let values = this.formConvertUid.getFieldsValue();
                                this.props.convertUid(values);
                            }}>Thực hiện</Button>
                        }
                    </div>
                </Form>
            </Modal>
        )
    }
}
