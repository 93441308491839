import React, { Component } from 'react';
import { Modal, Button, Form, Input} from 'antd';

export default class ModalAliasChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }

    render() {
        return (
            <Modal
                title={'Quản lý nhóm'}
                visible={this.props.visible}
                footer={null}
                onCancel={this.props.hide}
            >
                <Form 
                    onFinish={this.props.create}
                >
                    <div className="d-flex">
                        <Form.Item name="name" style={{width: "100%"}}>
                            <Input placeholder="Nhập tên alias"/>
                        </Form.Item>
                        <Button type="primary" htmlType="submit">Thêm mới</Button>
                    </div>
                </Form>
                <div className="table-responsive table-fixed" style={{overflow: "auto", maxHeight: "250px"}}>
                    <table className="table table-vcenter">
                        <thead>
                            <tr>
                                <th>Tên nhóm</th>
                                <th className="text-right">Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.data ? this.props.data.map((item) => {
                                    return <tr key={item._id}>
                                        <td>
                                            {
                                                item.edit ?
                                                <Input defaultValue={item.name} name="name"/>
                                                : item.name
                                            }
                                        </td>
                                        <td className="text-right">
                                            {
                                                !item.edit ? <Button size="small" type="primary" className="mr-2" onClick={() => this.props.showEdit(item._id)}><i class="far fa-edit"></i></Button> :
                                                <Button size="small" className="mr-2" type="primary" onClick={(e) => this.props.save(e, item._id)}><i class="far fa-check"></i></Button>
                                            }
                                            <Button size="small" type="danger" onClick={() => this.props.delete(item._id)}><i class="far fa-trash"></i></Button>
                                        </td>
                                    </tr>
                                }) : <tr><td colSpan="12" className="text-center"> Chưa có dữ liệu</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Modal>
        )
    }
}
