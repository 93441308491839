import React, { useRef, useState } from 'react';

import { Switch, message, Modal, Button, Form, Input, Tooltip, DatePicker, Table, Alert } from 'antd';
import { fetchData } from '../../../lib/apis';
import moment from 'moment';

import locale from 'antd/es/date-picker/locale/vi_VN';
import { useEffect } from 'react';

import WidgetDetail from "./widget/detail";
import WidgetFormText from "./widget/formText";

const PCNView = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: []
    })
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 20
    })
    const [ loading, setloading ] = useState(false);
    const [ visibleDetail, setvisibleDetail ] = useState({
        visible: false,
        id: null
    })
    const [ visibleFormText, setvisibleFormText ] = useState(false);

    const formFilter = useRef();
    const searchFilter = useRef();

    const getListData = async () => {
        try{
            setloading(true);

            let params = {
                ...pagination
            }

            if(formFilter.current){
                let values = formFilter.current.getFieldsValue();
                delete values['time_rangers'];
                
                Object.keys(values).map((key) => {
                    if(values[key]){
                        params[key] = values[key]
                    }
                })
            }

            let response = await fetchData({
                url: `api/v1/phong-chuc-nang`,
                params
            });

            setData({
                total: response.total,
                data: response.data
            })

        } catch(err){
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    useEffect(() => {
        getListData();
    }, [ pagination ])

    return (
        <div className="list-connect content-m">
            <WidgetDetail 
                {...visibleDetail}
                onCancel={() => setvisibleDetail({ visible: false })}
            />
            <WidgetFormText 
                visible={visibleFormText}
                onCancel={() => setvisibleFormText(false)}
                company={props.companySelected}
            />
            <div className="list-c">
                <div style={{ marginBottom: "15px" }} className="table-header">
                    <h3>Phòng chức năng: {data.total}</h3>
                    <div className='mb-3'>
                        <Button type="primary" onClick={() => setvisibleFormText(true)}>Text gọi bệnh nhân</Button>
                    </div>
                    <Form
                        ref={formFilter}
                        initialValues={{
                            fullname: "",
                            code: "",
                            start_time: moment().format("YYYY-MM-DD"),
                            end_time: moment().format("YYYY-MM-DD")
                        }}
                        onValuesChange={(e) => {
                            let timeout = 0;
                            if (e.fullname || e.code) {
                                timeout = 300;
                            }
                            if (searchFilter.current) clearTimeout(searchFilter.current)
                            searchFilter.current = setTimeout(() => {
                                getListData()
                            }, timeout)
                        }}
                    >
                        <div className="row">
                            <div className="col-md-2">
                                <Form.Item name="fullname">
                                    <Input placeholder="Họ và tên" />
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item name="code">
                                    <Input placeholder="Mã hồ sơ" />
                                </Form.Item>
                            </div>
                            <Form.Item name="start_time" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="end_time" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item className="col-md-3" name="time_rangers">
                                <DatePicker.RangePicker
                                    locale={locale}
                                    format={'DD-MM-YYYY'}
                                    ranges={{
                                        'Hôm nay': [moment().startOf("day"), moment().endOf("day")],
                                        'Hôm qua': [moment().startOf("day").add(-1, "day"), moment().endOf("day").add(-1, "day")],
                                        'Tuần này': [moment().startOf("week"), moment().endOf("week")],
                                        'Tuần trước': [moment().add(-1, "week").startOf("week"), moment().add(-1, "week").endOf("week")],
                                        'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                        'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                    }}
                                    allowClear={false}
                                    style={{ width: "100%" }}
                                    onOpenChange={(e) => {
                                        if(!e) getListData()
                                    }}
                                    onChange={(values) => {
                                        formFilter.current.setFieldsValue({
                                            start_time: values[0] ? values[0].format("YYYY-MM-DD") : "",
                                            end_time: values[1] ? values[1].format("YYYY-MM-DD") : ""
                                        })
                                    }}
                                    defaultValue={[moment(), moment()]}
                                />
                            </Form.Item>
                            <div className="col-auto">
                                <Button type="primary" onClick={() => {
                                    formFilter.current.resetFields();
                                    getListData();
                                }}>Xóa lọc</Button>
                            </div>
                            <div className="col-auto" >
                                <Tooltip title={'Làm mới'}>
                                    <a className="btn btn-primary text-white mr-2" onClick={getListData}><i className="far fa-sync"></i></a>
                                </Tooltip>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="table-responsive">
                    <Table
                        dataSource={data.data}
                        columns={[
                            {
                                title: "#",
                                index: "#",
                                render: (item, value, index) => {
                                    return (pagination.page * pagination.limit - pagination.limit) + index + 1
                                }
                            },
                            {
                                title: "Mã khám bệnh",
                                dataIndex: "ma_kham_benh"
                            },
                            {
                                title: "Mã hồ sơ",
                                dataIndex: "ma_ho_so"
                            },
                            {
                                title: "Bệnh nhân",
                                dataIndex: "thong_tin",
                                render: (item) => {
                                    return <React.Fragment>
                                        <p className='mb-1'>Họ tên: {item.ho_ten}</p>
                                        <p className='mb-1'>Ngày sinh: {item.ngay_sinh}</p>
                                    </React.Fragment>
                                }
                            },
                            {
                                title: "Dịch vụ",
                                dataIndex: "tong_dich_vu"
                            },
                            {
                                title: "Thời gian",
                                render: (item) => moment(item.created_time).format('HH:mm DD-MM-YYYY')
                            },
                            {
                                title: "Hành động",
                                className: "text-right",
                                render: (item) => {
                                    return <div>
                                        <Tooltip title="Chi tiết dịch vụ">
                                            <Button size="small" type="primary" onClick={() => {
                                                setvisibleDetail({
                                                    visible: true,
                                                    id: item.ma_kham_benh
                                                })
                                            }}>
                                                <i className="far fa-eye"></i>
                                            </Button>
                                        </Tooltip>
                                    </div>
                                }
                            }
                        ]}
                        rowKey="_id"
                        loading={loading}
                        pagination={{
                            total: data.total,
                            pageSize: pagination.limit,
                            onChange: (current, pageSize) => {
                                setpagination({
                                    page: current,
                                    limit: pageSize
                                })
                            },
                            current: pagination.page,
                            showSizeChanger: false
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PCNView;
