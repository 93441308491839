import React from 'react';

import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

const DichTeComponent = (props) => {
    const { exportToCSV, loading, listData, list_trieuchung } = props;

    return <div className="table-responsive">
        <div className="text-right">
            <Button type="primary" onClick={() => {
                exportToCSV({
                    dom: document.querySelector('#statistic_dichte')
                })
            }} className="mb-2" style={{ borderRadius: "5px" }}>
                <i className="far fa-download"></i>
            </Button>
        </div>

        <Table
            id="statistic_dichte"
            bordered
            loading={loading}
            dataSource={listData}
            columns={[
                {
                    title: "Mã lịch hẹn",
                    responsive: ["md"],
                    dataIndex: "booking_code"
                },
                {
                    title: "Họ và tên",
                    dataIndex: "ho_ten"
                },
                {
                    title: "SDT",
                    dataIndex: "so_dien_thoai",
                },
                {
                    title: "Các triệu chứng",
                    render: (item) => {
                        let trieuchung = list_trieuchung.filter((item__) => item[item__.name]);
                        return trieuchung.map((item) => item.title).join("; ");
                    }
                },
                {
                    title: "Tiêm vaccine",
                    responsive: ["md"],
                    dataIndex: "tiem_vaccince_thoigian",
                    render: (item) => {
                        return item ? item.join("; ") : null
                    }
                }
            ]}
            pagination={false}
        />
    </div>
}

export default DichTeComponent;