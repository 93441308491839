import React, { useEffect, useRef, useState } from "react";
import { message, Form, Input, TimePicker, Button, Select, InputNumber, Tooltip, notification, DatePicker, Spin, Alert, TreeSelect } from 'antd';

import moment from 'moment';

import qs from "qs";

import { fetchData } from '../../lib/apis';
import { WidgetFormSelectAddress } from "..";
import WidgetUploadImage from "../upload-image";

const FormBooking = (props) => {
    const { initialValues, onFinish, disabled, loading, reload, onClose, hoursBooking, companyId } = props;
    const [listDoctor, setListDoctor] = useState({
        data: [],
        dataParse: []
    });
    const [listService, setListService] = useState({
        data: [],
        dataParse: []
    });
    const [loadingService, setLoadingService] = useState([]);
    const [loadingDoctor, setLoadingDoctor] = useState([]);
    const [minuteStep, setMinuteStep] = useState(5);

    const form = useRef();

    useEffect(() => {
        if (form.current) {
            form.current.resetFields();
        }
    }, [reload])

    useEffect(() => {
        getDoctor();
        getService();
    }, [])

    const onSubmit = async () => {
        if (onFinish) {
            let values = await form.current.validateFields();

            if (values['date_booking'] && values['time_booking']) {
                values['date_moment'] = moment(values['date_booking'].format('YYYY-MM-DD') + ' ' + values['time_booking'].format('HH:mm'));
            }
            values['booking_time'] = values['date_moment'] && values['date_moment'].format('YYYY-MM-DD HH:mm');
            values['booking_timestamp'] = values['date_moment'] && values['date_moment'].unix() * 1000;

            if (values['bac_sy']) {
                let bacsy = listDoctor.data.find((item) => item.service_id == values['bac_sy']);
                values['bac_sy'] = {
                    id: bacsy.service_id,
                    name: bacsy.name,
                    code_his: bacsy.code_his
                }
            }

            if (values['dich_vu']) {
                let dichvu = listService.data.find((item) => item.service_id == values['dich_vu']);
                values['dich_vu'] = {
                    id: dichvu.service_id,
                    name: dichvu.name,
                    code_his: dichvu.code_his
                }
            }

            onFinish(values);
        }
    }

    const getDoctor = async () => {
        try {
            setLoadingDoctor(true);
            let params = {
                limit: 100,
                type: "LIST_BS",
                company_id: companyId
            }
            let res = await fetchData({ url: `api/v1/service/list?${qs.stringify(params)}`, method: 'get' });

            setListDoctor({
                data: res.data,
                dataParse: parseMenu(res.data)
            });
            setLoadingDoctor(false);
        } catch (err) {
            setLoadingDoctor(false);
        }
    }

    const getService = async () => {
        try {
            setLoadingService(true);
            let params = {
                limit: 100,
                type: "LIST_DV",
                company_id: companyId
            }
            let res = await fetchData({ url: `api/v1/service/list?${qs.stringify(params)}`, method: 'get' });
            setListService({
                data: res.data,
                dataParse: parseMenu(res.data)
            });

            setLoadingService(false);
        } catch (err) {
            setLoadingService(false);
        }
    }

    const parseMenu = (list) => {
        var map = {}, node, i;
        var roots = []

        for (i = 0; i < list.length; i++) {
            map[list[i].service_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.name;
            node['key'] = node.service_id;
            node['value'] = node.service_id;

            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                } catch (err) {
                    // console.log(node);
                }
            } else {
                node['checkable'] = false;
                node['selectable'] = false;
                roots.push(node);
            }
        }
        return roots;
    }

    const parseListTimeRanger = (time, minute_step) => {
        try {
            if (!time || !minute_step) return [];

            let time_rangers = time.replace(/\s/gi, '').split('|');

            let result = [];

            time_rangers.forEach((item) => {
                try {
                    for (let i = moment(item.split('-')[0], "HH:mm"); i < moment(item.split('-')[1], "HH:mm"); i = i.add("minute", minute_step)) {
                        result.push(i.format("HH:mm"));
                    }
                } catch (err) {

                }
            })

            return result.sort((a, b) => a - b);
        } catch (err) {
            return [];
        }
    }

    return <Form
        name="basic"
        initialValues={initialValues}
        layout="vertical"
        ref={form}
    >
        <Form.Item noStyle name="ref">
            <Input type="hidden" />
        </Form.Item>
        <Form.Item name="source" noStyle>
            <Input type="hidden" />
        </Form.Item>

        <Form.Item
            name="fullname"
            rules={[{ required: true, message: 'Tên khách hàng không được để trống' }]}
            onFocus={(e) => {
                e.target.setAttribute('autocomplete', 'registration-select')
            }}
            style={{ width: "100%" }}
            label="Họ tên"
        >
            <Input placeholder="Họ tên" size="large" />
        </Form.Item>
        <Form.Item
            name="address_code"
            style={{ width: "100%" }}
            noStyle
        >
            <Input type="hidden" disabled={true} />
        </Form.Item>
        {
            initialValues.type && <Form.Item
                name="type"
                noStyle
            >
                <Input type="hidden" disabled={true} />
            </Form.Item>
        }
        {
            initialValues.address_name && <Form.Item
                name="address_name"
                style={{ width: "100%" }}
                label="Nơi khám bệnh"
                rules={[{ required: true, message: 'Liên hệ admin để thêm nơi khám bệnh' }]}
            >
                <Input disabled={true} size="large" />
            </Form.Item>
        }
        <Form.Item
            name="phone"
            style={{ width: "100%" }}
            label="Số điện thoại"
            rules={[{ required: true, message: 'SDT không được để trống' }]}
        >
            <Input placeholder="Số điện thoại" size="large" />
        </Form.Item>

        {
            props.isPostFaceData && <>
                <Form.Item label="Face data">
                    <WidgetUploadImage

                    />
                </Form.Item>
            </>
        }
        {
            props.showAddress && <WidgetFormSelectAddress
                refForm={form}
                isAppNative={props.isAppNative}
            />
        }

        <Form.Item label="Dịch vụ" name="dich_vu">
            <Select placeholder="Chọn dịch vụ" size="large" loading={loadingService}>
                {
                    listService.dataParse.map((item) => {
                        return <Select.OptGroup label={item.name}>
                            {
                                item.children.map((item) => {
                                    return <Select.Option value={item.service_id}>{item.name}</Select.Option>
                                })
                            }
                        </Select.OptGroup>
                    })
                }
            </Select>
        </Form.Item>
        <Form.Item label="Bác sỹ" name="bac_sy">
            <Select placeholder="Chọn bác sỹ" size="large" loading={loadingDoctor}>
                {
                    listDoctor.dataParse.map((item) => {
                        return <Select.OptGroup label={item.name}>
                            {
                                item.children.map((item) => {
                                    return <Select.Option value={item.service_id}>{item.name}</Select.Option>
                                })
                            }
                        </Select.OptGroup>
                    })
                }
            </Select>
        </Form.Item>
        <Form.Item label="lịch hẹn"
        >
            <div className="row" style={{ marginRight: "-5px", marginLeft: "-5px" }}>
                <div className="col-md-7" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                    <Form.Item name="date_booking" className="p-0"
                        rules={[{ required: true, message: 'SDT không được để trống' }]}
                    >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" size="large"
                            disabledDate={(current) => current && current < moment().add("day", -1).endOf('day')}
                            inputReadOnly={true}
                            allowClear={false}
                        />
                    </Form.Item>
                </div>
                <div className="col-md-5" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                    <Form.Item name="time_booking" className="p-0"
                        rules={[{ required: true, message: 'không được để trống' }]}
                    >
                        <TimePicker style={{ width: "100%" }} format="HH:mm" size="large"
                            hideDisabledOptions={true}
                            disabledHours={() => {
                                let list_hours = [];
                                let list_time = parseListTimeRanger(hoursBooking, minuteStep);

                                for (let i = 0; i < list_time.length; i++) {
                                    list_hours.push(parseInt(moment(list_time[i], "HH:mm").format("HH")));
                                }

                                let disable_hours = [];

                                if (list_hours.length > 0) {
                                    for (let i = 0; i < 24; i++) {
                                        if (list_hours.indexOf(i) == -1) {
                                            disable_hours.push(i);
                                        }
                                    }
                                }
                                return disable_hours
                            }}
                            disabledMinutes={(hour) => {
                                let list_disabled = [];

                                let list_time = parseListTimeRanger(hoursBooking, minuteStep);

                                if (hour > -1 && list_time.length > 0) {
                                    let list_minute = list_time.filter((item) => moment(item, "HH:mm").get("hour") == hour).map((item) => moment(item, "HH:mm").get("minute"));

                                    for (let i = 0; i < 60; i++) {
                                        if (list_minute.indexOf(i) == -1) {
                                            list_disabled.push(i);
                                        }
                                    }
                                }

                                return list_disabled
                            }}
                            inputReadOnly={true}
                        />
                    </Form.Item>
                </div>
            </div>
        </Form.Item>
        <Form.Item
            style={{ width: "100%" }}
            name="note"
            label="Ghi chú"
        >
            <Input.TextArea placeholder="ghi chú" style={{ minHeight: "150px" }} />
        </Form.Item>
        <Form.Item className="mt-3 text-right">
            {
                onClose && <Button className="mr-2" htmlType="button" onClick={onClose} >
                    Đóng
                </Button>
            }
            <Button type="primary" loading={loading} htmlType="button" disabled={disabled}
                onClick={onSubmit}
            >
                Hoàn tất
            </Button>
        </Form.Item>
    </Form>
}

export default FormBooking;