import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {requestApi} from '../lib/apis';

export default class NotifyAlert extends Component{
  constructor(props){
    super(props);
    this.state = {
      show: false,
      content: 'Notify',
      timeout: 5000,
      background: '#f88502'
    }
    this.myref = null;
    this.timeout = null;
    this.show_time = false;
  }
  componentWillMount(){
    
  }
  componentWillReceiveProps(nextProps){
    if(this.timeout){
      clearTimeout(this.timeout);
    }
    if(!this.show_time){
      this.setState({
        show: nextProps.show,
        content: nextProps.content,
        timeout: nextProps.options && nextProps.options.timeout ? nextProps.options.timeout : 5000,
        background: nextProps.options && nextProps.options.background ? nextProps.options.background : '#f88502',
        icon_noti: nextProps.options && nextProps.options.icon_noti ? nextProps.options.icon_noti : '',
        link: nextProps.options && nextProps.options.link ? nextProps.options.link : ''
      }, () => {
          if(this.state.show){
            this.show_time = true;
            if(this.state.timeout){
              this.clearTimeout = setTimeout(() => {
                this.setState({
                  show: false
                }, () => {
                 this.props.hide();
                 this.show_time = false;
                })
             }, this.state.timeout);
            } else{
              this.show_time = false;
            }
          }
      });
    }
  }
  componentDidMount(){
    
  }
  componentDidUpdate(prevProps, prevState){
      
  }
  render(){
    if(this.state.show){
      return(
        <div ref={(evt) => {this.myref = evt}} 
        className="notify-mes" 
        style={{background: this.state.background}}
        >
          <div className="notify-mes-body">
            {
              this.state.icon_noti ? <i className="material-icons icon-noti">{this.state.icon_noti}</i> : null
            }
            {
              this.state.link ? 
              <a href={this.state.link} target="_blank">{this.state.content}</a>
              : this.state.content
            }
          </div>
          <a href="#" className="close" onClick={(e) => {
            e.preventDefault();
            this.setState({
              show: false
            }, () => {
             this.props.hide();
             this.show_time = false;
            })
          }}>
             <i className="material-icons">close</i>
          </a>
        </div>
      )
    } 
    return null;
  }
}
