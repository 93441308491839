import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

export default class ItemMenu extends Component{
  constructor(props){
    super(props);
    this.state = {

    }
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
  }
  componentDidMount(){
    
  }
  actionMenu = (e, action) => {
    e.preventDefault();
    if(action){
        this.props.AccountMetaData.logOut();
    }
  }
  clickMenu = (e) => {
    if(document.querySelector('.sidebar')) document.querySelector('.sidebar').classList.remove("show");
  }
  render(){
    let menu = this.props.item;
    let menu_html = '';
    if(menu.alias == "khai_bao_khambenh"){
      menu.link = `/${this.props.companyId}/khai-bao-yte`;
    }
    if(menu.alias == "gop_y"){
      menu.link = `/${this.props.companyId}/gop-y`;
    }
    if(menu.link && menu.link.indexOf("$company-id") > -1){
      menu.link = menu.link.replace("$company-id", this.props.companyId) 
    }
    if(menu.link){
      menu_html = <Link to={menu.link} className={this.props.active ? 'active item-menu' : 'item-menu'} target={this.props.isAppNative ? "" : menu.target}>
        <li onClick={this.clickMenu}>
            {
              menu.icon ? <img src={require(`../../../img/${menu.icon}`)} /> : menu.icon_fa ? <i className={menu.icon_fa}></i> 
              : <div className="avatar-c" style={{width: "26px", position: 'relative'}}>
                <div className="lettertile dark-color5"><div>{menu.menu_name.slice(0,1)}</div></div></div>
            }
            <span className="text">{menu.menu_name}</span>
            {menu.type_use == 0 ? <div className="info">beta</div>: ''}
        </li>
    </Link>
    } else if(menu.action){
        menu_html = <a href="#" onClick={(e) => this.actionMenu(e, menu.action)} className={this.props.active ? 'active' : ''}>
        <li>
            {
              menu.icon ? <img src={require(`../../../img/${menu.icon}`)} /> : menu.icon_fa ? <i className={menu.icon_fa}></i> : ''
            }
            <span className="text">{menu.menu_name}</span>
        </li></a>
    }else {
      menu_html = <li className="head-menu">
        <div>{menu.menu_name}</div>
      </li>
    }
    return menu_html;
  }
}
