import React, { Component } from 'react';
import { Button, Input, Form, Modal, Switch, Select, Pagination, Tooltip, Alert, Upload, Table, InputNumber, Tag } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import qs from 'qs';

export default class RegisterEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loading: false,
            listCompany: []
        }
        this.limit = 10;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
        this.getListCompany();
    }
    componentDidMount = () => {

    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await this.props.fetchData({
                url: `api/v1/statistic/register_email?${qs.stringify(params)}`
            });

            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    remove = async (id) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await this.props.fetchData({
                            url: `api/v1/auto_target/${id}`,
                            method: 'delete'
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Xóa',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Xóa',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }

    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    save = async (values) => {
        try {

            this.setState({ loadingForm: true });

            await this.props.fetchData({
                url: `api/v1/register_email/${values['_id']}`,
                data: values,
                method: 'put'
            });
            this.getListData();
            this.setState({ loadingForm: false});

            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Cập nhập',
                type: 'error'
            })
        }
    }

    getListCompany = async () => {
        try {
            let res = await this.props.fetchData({
                url: `api/v1/statistic/company`
            });
            this.setState({
                listCompany: res.data
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Danh sách đăng ký Email: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Link đăng ký'}>
                                <a className="btn btn-primary text-white mr-2" target="_blank" href={`/${this.props.companySelected.company_id}/dangky-email`}>Link đăng ký</a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                type: "",
                                active: ""
                            }}
                            onValuesChange={(e) => {
                                let timout = 0;
                                if (e.full_name) {
                                    timout = 300;
                                }
                                if (this.timeout_getdata) clearTimeout(this.timeout_getdata);

                                this.timeout_getdata = setTimeout(() => {
                                    this.getListData()
                                }, timout)
                            }}
                        >
                            <div className="row">
                                <div className="col-md-2">
                                    <Form.Item name="full_name">
                                        <Input placeholder="Tìm theo tên.." />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2">
                                    <Form.Item name="company_id">
                                        <Select allowClear placeholder="Chọn chi nhánh">
                                            {
                                                this.state.listCompany.map((item) => {
                                                    return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-auto">
                                    <Button type="primary" onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData();
                                    }}>Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Table
                            dataSource={this.state.listData}
                            columns={[
                                {
                                    title: "#",
                                    index: "#",
                                    render: (item, value, index) => {
                                        return (this.page * this.limit - this.limit) + index + 1
                                    }
                                },
                                {
                                    title: "Họ và tên",
                                    dataIndex: "full_name"
                                },
                                {
                                    title: "Bộ phận",
                                    dataIndex: "department"
                                },
                                {
                                    title: "Chức vụ",
                                    dataIndex: "level"
                                },
                                {
                                    title: "Email mong muốn",
                                    dataIndex: "email_note"
                                },
                                {
                                    title: "Email",
                                    render: (item) => {
                                        return <Form
                                            initialValues={{
                                                email: item.email,
                                                _id: item._id
                                            }}
                                            key={item._id}
                                            onFinish={this.save}
                                        >
                                            <Form.Item name="_id" noStyle>
                                                <Input type="hidden" />
                                            </Form.Item>
                                            <Form.Item name="email">
                                                <Input placeholder="Enter để lưu" />
                                            </Form.Item>
                                        </Form>
                                    }
                                },
                                {
                                    title: "Password",
                                    render: (item) => {
                                        return <Form
                                            initialValues={{
                                                password_email: item.password_email,
                                                _id: item._id
                                            }}
                                            key={item._id}
                                            onFinish={this.save}
                                        >
                                            <Form.Item name="_id" noStyle>
                                                <Input type="hidden" />
                                            </Form.Item>
                                            <Form.Item name="password_email">
                                                <Input.Password placeholder="Enter để lưu" />
                                            </Form.Item>
                                        </Form>
                                    }
                                },
                                // {
                                //     title: "Ghi chú",
                                //     dataIndex: "note",
                                //     render: (item) => {
                                //         return <textarea disabled className="form-control" value={item}></textarea>
                                //     }
                                // },
                                {
                                    title: "Ngày tạo",
                                    dataIndex: "created_time",
                                    render: (item) => moment(item).format("HH:MM DD-MM-YYYY")
                                },
                                // {
                                //     title: "Hành động",
                                //     className: "text-right",
                                //     render: (item) => {
                                //         return <div>
                                //             <Tooltip title="Xóa">
                                //                 <Button size="small" type="danger" className="mr-2" onClick={() => {
                                //                     this.remove(item._id)
                                //                 }}><i className="far fa-trash"></i></Button>
                                //             </Tooltip>
                                //         </div>
                                //     }
                                // }
                            ]}
                            dataSource={this.state.listData}
                            rowKey="_id"
                            loading={this.state.loading}
                            pagination={{
                                total: this.state.total,
                                pageSize: this.limit,
                                onChange: (current) => {
                                    this.page = current;
                                    this.getListData();
                                },
                                current: this.page,
                                showSizeChanger: false
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
