import React, { useEffect, useState, useRef } from "react";

import { Form, Input, Button, Alert, DatePicker, Result, Table, Select } from "antd";
import moment from "moment";

import { LoadingComponent, WidgetSearchMedical } from "../../widgets";
import { formatPrice, NotificationMessage } from "../../lib/helpers";

const TraCuu = (props) => {
    const [loading, handleLoading] = useState(false);
    const [error, handleError] = useState(null);
    const [data, setData] = useState();
    const [company, setCompany] = useState();
    const [errorMain, setErrorMain] = useState();
    const [timeOtp, setTimeOtp] = useState(0);

    const timeHandleOtp = useRef();
    const formOtp = useRef();

    const company_id = props.match.params.company_id;

    const onFinish = async (values) => {
        setData(null)
        try {
            handleLoading(true);

            let response = await props.fetchData({
                url: "api/v1/public/company/search-medical",
                data: {
                    ...values,
                    company_id
                },
                method: "post",
            });
            if (timeHandleOtp.current) clearInterval(timeHandleOtp.current);
            setTimeOtp(0);
            handleError(null);

            if (!response.data) throw ({ message: "Không tìm thấy thông tin kết quả khám bệnh" });

            setData(response.data)

        } catch (err) {
            handleError(err.message || "Đã có lỗi xảy ra");
        }
        handleLoading(false);
    };

    useEffect(() => {
        getCompany();
    }, []);

    const getCompany = async () => {
        try {
            let response = await props.fetchData({
                url: `api/v1/public/company/setting?company_id=${company_id}`,
            });
            setCompany(response.data);
        } catch (err) {
            setErrorMain(err.message || "Đã có lỗi xảy ra");
        }
    };

    const sendOtp = async (values) => {
        handleLoading(true);
        try {
            if (timeHandleOtp.current) clearInterval(timeHandleOtp.current);
            setTimeOtp(0);

            let response = await props.fetchData({
                url: `api/v1/sms/otp`,
                method: "post",
                data: {
                    company_id: company_id,
                    phone: values.phone
                }
            })

            let expired_time = 60;

            setTimeOtp(expired_time);

            timeHandleOtp.current = setInterval(() => {
                expired_time = expired_time - 1;
                setTimeOtp(expired_time);
                if (expired_time == 0) clearInterval(timeHandleOtp.current);
            }, 1000);

        } catch (err) {
            NotificationMessage({
                type: "warning",
                response: err
            })
        }
        handleLoading(false);
    }


    return (
        <div
            id="app-container"
            style={{
                background: "#fff",
                overflowY: "auto",
                paddingTop: `${props.statusBarHeight}px`,
            }}
        >
            <div className="container">
                {errorMain ? (
                    <React.Fragment>
                        <Result
                            status="500"
                            title="500"
                            subTitle={errorMain}
                            extra={<Button type="primary">Tải lại trang</Button>}
                        />
                    </React.Fragment>
                ) : company ? (
                    <div
                        style={{
                            margin: "auto",
                        }}
                    >
                        {company.banner && (
                            <div className="container mb-3">
                                <div className="row">
                                    <img
                                        src={company.banner}
                                        className="img-fluid img-thumbnail col-12"
                                        alt="Responsive image"
                                    />
                                </div>
                            </div>
                        )}
                        <div>
                            <div className="container body-content mt-5">
                                <div
                                    className="row border p-0 pt-4 pb-5 p-md-5"
                                    id="content_tra_cuu"
                                >
                                    <div className="col-12">
                                        <h3 className="text-center">
                                            <b>Tra cứu kết quả khám bệnh</b>
                                        </h3>
                                        {error ? <Alert message={error} type="error" className="mb-3" /> : null}
                                    </div>

                                    <div className="col-12">
                                        <Form
                                            ref={formOtp}
                                            onFinish={onFinish}
                                        >
                                            <label className="pl-0 mb-2">Nhập thông tin bên dưới để tra cứu lịch sử khám chữa bệnh</label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Form.Item name="phone">
                                                        <Input
                                                            placeholder="Nhập số điện thoại.."
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Item name="otp_code"
                                                        rules={[
                                                            {
                                                                required: true
                                                            }
                                                        ]}
                                                    >
                                                        <Input placeholder="Nhập mã otp.."
                                                            addonAfter={
                                                                timeOtp ? <div>{timeOtp}s</div> : <a href="javascript:void(0)" type="text" onClick={() => !loading && sendOtp({ phone: formOtp.current.getFieldValue("phone") })}>
                                                                    Gửi mã Otp
                                                                </a>
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <Form.Item className="text-center text-md-left">
                                                <Button htmlType="submit" type="primary" loading={loading}>Tra cứu ngay</Button>
                                            </Form.Item>
                                        </Form>
                                    </div>

                                    <WidgetSearchMedical data={data} />

                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <LoadingComponent />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TraCuu;
