import React, { Component } from 'react';
import {Switch, message, Modal, Button, Form, Input, Checkbox, notification} from 'antd';
import {fetchData} from '../../../lib/apis';

import ItemStaff from './item-staff';

import ListChannel from './list-channel';

const key_loading = 'loading';

export default class ChatRole extends Component{
  constructor(props){
    super(props);
    this.state = {
        listStaff: [],
        staffSelected: null,
        listRoleChat: []
    }
    this.handleSelectedStaff = this.handleSelectedStaff.bind(this);
    this.updateRoleStaff = this.updateRoleStaff.bind(this);
  }
  componentWillMount(){
    this.getStaff();
  }
  componentWillReceiveProps(nextProps){
   
  }
  componentDidMount(){
    
  }
  handleSelectedStaff = (staff) => {
    this.setState({
        staffSelected: staff
    })
  }
  getStaff = async (values) => {
    try {
        let response = await fetchData({
            url: 'api/v2/staff/list/role',
        });
        this.setState({
            listStaff: response.staff,
            listRoleChat: response.channel_role,
            staffSelected: response.staff.length > 0 ? response.staff[0] : null
        })
    } catch (err) {
        if(err.code && this.props.handleViewError) this.props.handleViewError(err.code);
        message.warning({ content: 'Đã có lỗi xảy ra, vui lòng thử lại sau!', key: 'error_api', duration: 3 });
    }
  };
  updateRoleStaff = async (staff) => {
    try {
        message.loading({ content: 'Đang xử lý..', key: key_loading });
        let response = await fetchData({
            url: `api/v2/staff/${staff.id_user_vnp_sw}/role`,
            method: 'put',
            data: {
                role_acc_type: JSON.stringify(staff.role_allow_all_acc_type),
                role_chat_id: JSON.stringify(staff.role_allow_chat_id)
            }
        });
        let listStaff = this.state.listStaff;
        for(let i = 0; i < listStaff.length; i++){
            if(listStaff[i]['role_user_id'] == staff['role_user_id']){
                listStaff[i] = staff;
                break;
            }
        }
        this.setState({
            staffSelected: staff,
            listStaff: listStaff
        }, () => {
            message.success({ content: 'Thành công', key: key_loading, duration: 2 });
        })
    } catch (error) {
        let content = error.message ? error.message : 'Đã có lỗi xảy ra vui lòng thử lại sau!';
        message.warning({ content: content, key: key_loading, duration: 2 });
    }
  }
  render(){
    if(this.state.listStaff.length == 0){
        return ('');
    }
    let list_staff = [];
    this.state.listStaff.forEach((staff, i) => {
        list_staff.push(<ItemStaff staff={staff} staffSelected={this.state.staffSelected} key={i}
              handleSelectedStaff={(staff) => this.handleSelectedStaff(staff)} />)
    })
    return (
        <div className="content-m">
           <div className="list-c">
            <div className="dashboard-role">
                    <div className="list-member">
                        <div className="title">Nhân viên</div>
                        <ul>
                            {list_staff}
                        </ul>
                    </div>
                    <ListChannel updateRoleStaff={this.updateRoleStaff} listRoleChat={this.state.listRoleChat} staffSelected={this.state.staffSelected}/>
                </div>
           </div>
        </div>
    )
  }
}
