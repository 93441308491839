import React from 'react';

import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

const RevenueComponent = (props) => {
    const { exportToCSV, loading, listData } = props;

    return <div className="table-responsive">
        <div className="text-right">
            <Button type="primary" onClick={() => {
                exportToCSV({
                    dom: document.querySelector('#statistic_revenue')
                })
            }} className="mb-2" style={{ borderRadius: "5px" }}>
                <i className="far fa-download"></i>
            </Button>
        </div>
        <Table
            id="statistic_revenue"
            bordered
            loading={loading}
            dataSource={listData}
            columns={[
                {
                    title: "Nguồn",
                    responsive: ["md"],
                    render: (item) => item.alias || item.source
                },
                {
                    title: "Mã lịch hẹn",
                    dataIndex: "booking_code"
                },
                {
                    title: "Dịch vụ",
                    dataIndex: "services",
                    responsive: ["md"],
                    render: (item) => {
                        return item.map((service) => service.name).join(', ')
                    }
                },
                {
                    title: "Bệnh nhân",
                    dataIndex: "fullname"
                },
                {
                    title: "Doanh thu",
                    dataIndex: "total_amount"
                },
                {
                    title: "Nhân viên",
                    dataIndex: "from",
                    render: (value) => {
                        return value ? `${value.fullname} - ${value.code}` : ""
                    },
                    responsive: ["md"]
                },
                {
                    title: "Loại khách",
                    dataIndex: "cus_old",
                    render: (item) => {
                        return item ? "Khách cũ" : "Khách mới"
                    },
                    responsive: ["md"]
                },
            ]}
            pagination={false}
            summary={pageData => {
                let total = 0;
                let total_amount_1 = 0;

                pageData.forEach(({ total_amount }) => {
                    total += 1;
                    total_amount_1 += total_amount;
                });

                return pageData.length > 0 && (
                    <Table.Summary.Row>
                        <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>{total}</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                        <Table.Summary.Cell className="d-none d-md-table-cell text-danger"><b>Tổng tiền</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="d-none d-md-table-cell text-danger"><b>{total_amount_1}</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
        />

    </div>
}

export default RevenueComponent;