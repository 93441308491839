import React, { useEffect, useRef, useState } from "react";
import { Form, Modal, Button, Input, notification, Select } from "antd";
import moment from "moment";
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { NotificationMessage } from "../../lib/helpers";
import { fetchData } from "../../lib/apis";

const FormEdit = (props) => {
    const { visible, hide } = props;
    const form = useRef();
    const [staff, setStaff] = useState([]);
    const [loading, setLoading] = useState(false);

    const timeout_search = useRef();

    const submitForm = async (values) => {
        Modal.confirm({
            title: "Xác nhận trạng thái",
            content: "Trong quá trình trực hộ, các tin nhắn của khách hàng của bạn sẽ chuyển sang cho người trực hộ trả lời. Các cuộc gọi không được chuyển tiếp, xin để ý điện thoại.",
            icon: <ExclamationCircleOutlined />,
            okText: "Tiếp tục",
            okType: 'danger',
            cancelText: "Đóng",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await props.handleOffline(1, values['to_id']);
                        hide();
                        resolve();
                    } catch (err) {
                        NotificationMessage({
                            type: "error",
                            response: err
                        })
                        resolve();
                    }
                })
            },
            onCancel() { },
            centered: true,
            width: "600px"
        });
    }

    const getListStaff = (e = "") => {
        try {
            if (timeout_search.current) clearTimeout(timeout_search.current);
            timeout_search.current = setTimeout(async () => {
                let response = await fetchData({
                    url: `api/v1/chat/staff/list?limit=10&fullname=${e}`
                })
                setStaff(response.data);
            }, 300);
        } catch (err) {
            NotificationMessage({
                type: "error",
                response: err
            })
        }
    }

    useEffect(() => {
        getListStaff();
    }, [])

    useEffect(() => {
        if (visible) {

        } else {
            if (form.current) form.current.resetFields();
        }
    }, [visible])



    return <Modal
        title={'Chuyển nhân viên trực hộ'}
        visible={visible}
        footer={null}
        onCancel={hide}
    >
        <Form
            onFinish={submitForm}
            layout="vertical"
            ref={form}
        >

            <Form.Item name="to_id" >
                <Select
                    showSearch
                    placeholder={"Tên nhân viên"}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={getListStaff}
                    notFoundContent={null}
                    allowClear
                >
                    {
                        staff.map((item) => {
                            return <Select.Option value={item._id} key={item._id}>
                                <div className="demo-option-label-item">
                                    <span role="img">
                                        <img className="img-avatar" src={item.avatar}
                                            onError={(e) => e.target.src = "/user.jpg"}
                                            style={{ width: "25px", height: "25px", marginRight: "15px" }}
                                        />
                                    </span>
                                    {item.fullname} - ({item.code})
                                </div>
                            </Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
            <div className="mb-2">
                    <i>Lưu ý: nếu để trống sẽ không có ai chăm sóc khách hộ bạn.</i>
                </div>
            <div style={{ textAlign: "right" }}>
                <Button onClick={hide} style={{ marginRight: "10px" }}>
                    Đóng
                </Button>
                <Button htmlType="submit" type="primary" loading={loading}>
                    Tiếp tục
                </Button>
            </div>
        </Form>
    </Modal>
}

export default FormEdit;