import React, { Component } from 'react';
import { fetchData } from '../lib/apis';
import { DateRangePicker, LanguageType } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import { Tooltip, Popover, Row, Col, Input, Button, Form, Radio, Select, Checkbox, Modal } from 'antd';
import ContentFilterPost from './popup-filter-post';

import { WidgetChatImportCustomer, WidgetBooking, WidgetFormCareHandle } from './index';
import config from '../lib/config';

export default class SideBarFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_filter: [],
            dateRangePickerI: {
                selection: {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection',
                }
            },
            typeView: this.props.typeView,
            listPosts: [],
            staff: []
        }
        this.hideFiltrerPost = this.hideFiltrerPost.bind(this);
        this.selectFilterPost = this.selectFilterPost.bind(this);
        this.refFilterDate = null;
        this.mouseEnterDelay = 0.02;
        this.mouseLeaveDelay = 0.03;
        this.pageListPost = 1;
        this.nextPageListPost = true;
        this.limitPost = 20;
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {
        if (this.state.typeView != nextProps.typeView) {
            this.setState({
                data_filter: [],
                dateRangePickerI: {
                    selection: {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection',
                    }
                },
                typeView: nextProps.typeView,
                startDate: '',
                endDate: '',
                post_id: null
            })
        }
    }
    handleDataFilter = (filter, e, nochange) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        let data_filter = this.state.data_filter;
        if (filter) {
            if (!nochange) {
                if (data_filter.indexOf(filter) == -1) {
                    data_filter.push(filter);
                } else {
                    data_filter.splice(this.state.data_filter.indexOf(filter), 1);
                }
            }
        } else {
            data_filter = [];
        }
        this.setState({
            data_filter: data_filter
        }, () => {
            let data_filter_obj = [];
            data_filter.forEach((item, i) => {
                switch (item) {
                    case 1:
                        data_filter_obj.push('read_state=1')
                        break;
                    case 4:
                        data_filter_obj.push('phone_is=true')
                        break;
                    default:
                    // code block
                }
            })
            if (data_filter.indexOf(2) != -1 && data_filter.indexOf(3) != -1) {
                data_filter_obj.push('type=comment,direct');
            } else if (data_filter.indexOf(2) != -1) {
                data_filter_obj.push('type=comment');
            }
            else if (data_filter.indexOf(3) != -1) {
                data_filter_obj.push('type=direct');
            }
            if (data_filter.indexOf(6) != -1) {
                data_filter_obj.push(`start_date=${this.state.startDate}`);
                data_filter_obj.push(`end_date=${this.state.endDate}`);
            }
            if (data_filter.indexOf(7) != -1 && this.state.post_id) {
                data_filter_obj.push(`media_id=${this.state.post_id}`);
            }
            if (data_filter.indexOf(8) != -1) {
                let values = this.formFilterOther.getFieldsValue();
                Object.keys(values).forEach((key) => {
                    if (values[key]) data_filter_obj.push(`${key}=${values[key]}`)
                })
            }
            if (this.props.handleFilter) {
                this.props.handleFilter(data_filter_obj.join("&"));
            }
        })

    }
    handleRangeChange(which, payload) {
        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });
    }
    handleShowFilterDate = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let domrect = e.target.getBoundingClientRect();
        let domrect_filterdate = this.refFilterDate.getBoundingClientRect();

        let data_filter = this.state.data_filter;
        if (data_filter.indexOf(6) == -1) {
            data_filter.push(6);
        } else {
            data_filter.splice(this.state.data_filter.indexOf(6), 1);
        }
        this.setState({
            showFilterDate: !this.state.showFilterDate,
            data_filter: data_filter,
            posFilterDate: {
                left: domrect.right + 10,
                top: 100
            }
        })
    }
    filterDate = (action) => {
        let data_filter = this.state.data_filter;
        if (action) {
            if (data_filter.indexOf(6) == -1) {
                data_filter.push(6);
            }
            this.setState({
                showFilterDate: false,
                startDate: new Date(this.state.dateRangePickerI.selection.startDate).getTime(),
                endDate: new Date(this.state.dateRangePickerI.selection.endDate).getTime() + (60 * 1000 * 60 * 24),
                data_filter: data_filter,
                visibleFilterDate: false
            }, () => {
                this.handleDataFilter(true);
            })
        } else {
            data_filter.splice(data_filter.indexOf(6), 1);
            this.setState({
                showFilterDate: false,
                data_filter: data_filter,
                visibleFilterDate: false
            }, () => {
                this.handleDataFilter(true);
            })
        }
    }
    componentWillUnmount = () => {
        this.setState({
            data_filter: []
        })
    }
    filterPost = async (e) => {
        if (e) {
            try {
                let user_owners = this.props.userOwnerFilter ? this.props.userOwnerFilter : [];
                this.pageListPost = 1;
                let posts = await fetchData({
                    url: `api/v1/post/list?limit=${this.limitPost}&user_owners=${user_owners.join(',')}`
                })
                this.setState({
                    visibleFilterPost: true,
                    listPosts: posts.data
                })
                if (posts.data.length != this.limitPost) return this.nextPageListPost = false;
                this.nextPageListPost = true;
            } catch (err) {
                console.log(err);
            }
        } else {
            this.setState({
                visibleFilterPost: false
            })
        }
    }
    getPost = async () => {
        if (!this.nextPageListPost) return;
        this.pageListPost++;
        try {
            let user_owners = this.props.userOwnerFilter ? this.props.userOwnerFilter : [];
            let posts = await fetchData({
                url: `api/v1/post/list?limit=${this.limitPost}&page=${this.pageListPost}&user_owners=${user_owners.join(',')}`
            })
            if (posts.data.length != this.limitPost) this.nextPageListPost = false;

            let listPosts = this.state.listPosts;
            listPosts = listPosts.concat(posts.data);
            this.setState({
                listPosts: listPosts
            })
        } catch (err) {
            console.log(err);
        }
    }
    hideFiltrerPost = () => {
        let data_filter = this.state.data_filter;
        if (data_filter.indexOf(7) != -1) {
            data_filter.splice(data_filter.indexOf(7), 1);
            this.setState({
                visibleFilterPost: false,
                data_filter: data_filter,
                post_id: ''
            }, () => {
                this.handleDataFilter(true);
                this.pageListPost = 1;
                this.nextPageListPost = true;
            })
        } else {
            this.setState({
                visibleFilterPost: false,
                data_filter: data_filter
            })
        }

    }
    selectFilterPost = (post) => {
        let data_filter = this.state.data_filter;
        if (data_filter.indexOf(7) == -1) {
            data_filter.push(7);
        }
        this.setState({
            visibleFilterPost: false,
            data_filter: data_filter,
            post_id: post.media_id
        }, () => {
            this.handleDataFilter(true);
        })
    }
    getListStaff = (e) => {
        try {
            if (this.timeout) clearTimeout(this.timeout);
            this.setState({ loadingGetStaff: true })
            this.timeout = setTimeout(async () => {
                let response = await this.props.fetchData({
                    url: `api/v1/chat/staff/list?limit=10&fullname=${e}`
                })
                this.setState({
                    staff: response.data,
                    loadingGetStaff: false
                })
            }, 300);
        } catch (err) {
            this.setState({ loadingGetStaff: false })
            this.props.notification({
                title: "Tìm nhân viên",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }
    render() {
        const formFilterAdv = <Form
            name="basic"
            layout="vertical"
            ref={(e) => this.formFilterOther = e}
            onFinish={(values) => {
                this.handleDataFilter(8, false, this.state.data_filter.indexOf(8) > -1 ? true : false);
                this.setState({
                    visibleFilterOther: false
                })
            }}
            onReset={(e) => {
                this.handleDataFilter(8, false, this.state.data_filter.indexOf(8) == -1 ? true : false);
                this.setState({
                    visibleFilterOther: false
                })
            }}
            initialValues={{
                has_sales: ""
            }}
        >
            <Form.Item noStyle name="has_sales">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item valuePropName="checked" label="Trạng thái chăm sóc">
                <Checkbox onChange={(e) => {
                    this.setState({
                        has_sales: e.target.checked
                    }, () => {
                        this.formFilterOther.setFieldsValue({
                            has_sales: e.target.checked ? 1 : ""
                        })
                    })
                }}>Chưa có NV chăm sóc</Checkbox>
            </Form.Item>
            <Form.Item name={this.state.has_sales ? "" : "sales_id"} label="Lọc theo NV">
                <Select
                    allowClear={true}
                    showSearch
                    placeholder={"Tìm nhân viên"}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={this.getListStaff}
                    loading={this.state.loadingGetStaff}
                    disabled={this.state.has_sales}
                    style={{ width: "100%" }}
                >
                    {
                        this.state.staff.map((item) => {
                            return <Select.Option value={item._id} key={item._id}>
                                <div className="demo-option-label-item">
                                    <span role="img">
                                        <img className="img-avatar" src={item.avatar}
                                            onError={(e) => e.target.src = "/user.jpg"}
                                            style={{ width: "25px", height: "25px", marginRight: "15px" }}
                                        />
                                    </span>
                                    {item.fullname} - ({item.code})
                                </div>
                            </Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
            <div className="d-flex" style={{ marginTop: "20px", justifyContent: "flex-end" }}>
                <Button htmlType="reset" style={{ marginRight: "10px" }}>Bỏ lọc</Button>
                <Button htmlType="submit" type="primary">Lọc</Button>
            </div>
        </Form>;

        const formFilterDate = <div>
            <DateRangePicker
                onChange={this.handleRangeChange.bind(this, 'dateRangePickerI')}
                className={'PreviewArea'}
                direction="vertical"
                scroll={{ enabled: false }}
                ranges={[this.state.dateRangePickerI.selection]}
                locale={rdrLocales['vi']}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                maxDate={new Date()}
            />
            <div className="action-btn">
                <Button onClick={() => this.filterDate(false)}>Bỏ lọc</Button>
                <Button type="primary" onClick={() => this.filterDate(true)}>Lọc</Button>
            </div>
        </div>;

        return (
            <div id="sidebar-filter" className={`${this.props.contactIdSelected ? 'chat-view' : ''} ${this.props.UserLogin.is_offline == 1 ? "chat-offline" : ""}`}
                style={{
                    marginTop: `${this.props.statusBarHeight}px`
                }}
            >
                <div className="overlay d-none" onClick={() => {
                    document.querySelector('#sidebar-filter').classList.remove('show');
                }}></div>
                <div id="filter-post">

                </div>

                <WidgetChatImportCustomer visible={this.state.visibleImportCustomer} hide={() => this.setState({ visibleImportCustomer: false })} onFinish={() => this.handleDataFilter(true)} />
                <WidgetBooking
                    visible={this.state.visibleBooking}
                    hide={() => this.setState({ visibleBooking: false })}
                    onFinish={() => this.handleDataFilter(true)}
                    treeDataService={this.props.treeDataService}
                    companySelected={this.props.companySelected}
                    UserLogin={this.props.UserLogin}
                    treeDataDoctor={this.props.treeDataDoctor}
                />
                
                <WidgetFormCareHandle visible={this.state.visibleCareHandle} hide={() => this.setState({ visibleCareHandle: false })} 
                    handleOffline={this.props.handleOffline}
                />

                <ul className="sidebar-nav">
                    <Tooltip placement="right" title={'Tất cả'} mouseEnterDelay={this.mouseEnterDelay} mouseLeaveDelay={this.mouseLeaveDelay}>
                        <li className={this.state.data_filter.length == 0 ? "sidebar-selected" : ""}
                            onClick={(e) => this.handleDataFilter(false, e)}
                        >
                            <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40">
                                <g>
                                    <path d="m22.7 28.6l2.1 1.4c-0.4 1.6-2.3 2.5-4.1 2.5h-7c-0.7 0-0.9 0.2-1.1 0.2l-4.1 3.6v-3.8h-1.2c-2.1 0-3.8-1.2-3.8-3.2v-10.1c0-1.9 1.6-3.5 3.6-3.5h0.1v7.9c0 2.6 2.3 4.6 5 4.6h9.2c0.8 0 1.1 0.2 1.3 0.4z m8.4-24.8c2.7 0 4.9 2.1 4.9 4.6v13.2c0 2.5-2.2 4.7-4.9 4.7h-1.3v5l-5.9-4.7c-0.2-0.2-0.6-0.3-1.3-0.3h-7.9c-2.8 0-4.9-2.2-4.9-4.7v-13.2c0-2.5 1.6-4.7 4.3-4.7h17z"></path>
                                </g>
                            </svg>
                        </li>
                    </Tooltip>
                    <Tooltip placement="right" title={'Lọc chưa đọc'} mouseEnterDelay={this.mouseEnterDelay} mouseLeaveDelay={this.mouseLeaveDelay}>
                        <li
                            className={this.state.data_filter.indexOf(1) != -1 ? "sidebar-selected" : ""}
                            onClick={(e) => this.handleDataFilter(1, e)}
                        >
                            <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40">
                                <g>
                                    <path d="m27.9 5c1.7 0 3.1 1.4 3.1 3.1v26.9l-11.2-5-11.3 5v-26.9c0-1.7 1.4-3.1 3.1-3.1h16.3z"></path>
                                </g>
                            </svg>
                        </li>
                    </Tooltip>
                    <Tooltip placement="right" title={'Lọc bình luận'} mouseEnterDelay={this.mouseEnterDelay} mouseLeaveDelay={this.mouseLeaveDelay}>
                        <li
                            className={this.state.data_filter.indexOf(2) != -1 ? "sidebar-selected" : ""}
                            onClick={(e) => this.handleDataFilter(2, e)}
                        >
                            <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40" >
                                <g>
                                    <path d="m9.4 31.3c-3.2 0-5.9-2.5-5.9-5.6v-16.3c0-3.1 2.7-5.6 5.9-5.6h20.7c3.2 0 5.9 2.5 5.9 5.6v16.3c0 3.1-2.7 5.6-5.9 5.6h-0.3v5s-6.2-4.3-6.8-4.7-0.5-0.3-1.6-0.3h-12z"></path>
                                </g>
                            </svg>
                        </li>
                    </Tooltip>
                    <Tooltip placement="right" title={'Lọc tin nhắn'} mouseEnterDelay={this.mouseEnterDelay} mouseLeaveDelay={this.mouseLeaveDelay}>
                        <li
                            className={this.state.data_filter.indexOf(3) != -1 ? "sidebar-selected" : ""}
                            onClick={(e) => this.handleDataFilter(3, e)}
                        >
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 334.5 334.5" width="1em" height="1em">
                                <path d="M332.797,13.699c-1.489-1.306-3.608-1.609-5.404-0.776L2.893,163.695c-1.747,0.812-2.872,2.555-2.893,4.481  s1.067,3.693,2.797,4.542l91.833,45.068c1.684,0.827,3.692,0.64,5.196-0.484l89.287-66.734l-70.094,72.1  c-1,1.029-1.51,2.438-1.4,3.868l6.979,90.889c0.155,2.014,1.505,3.736,3.424,4.367c0.513,0.168,1.04,0.25,1.561,0.25  c1.429,0,2.819-0.613,3.786-1.733l48.742-56.482l60.255,28.79c1.308,0.625,2.822,0.651,4.151,0.073  c1.329-0.579,2.341-1.705,2.775-3.087L334.27,18.956C334.864,17.066,334.285,15.005,332.797,13.699z">
                                </path>
                            </svg>
                        </li>
                    </Tooltip>

                    <Tooltip placement="right" title={'Lọc có số điện thoại'} mouseEnterDelay={this.mouseEnterDelay} mouseLeaveDelay={this.mouseLeaveDelay}>
                        <li
                            className={this.state.data_filter.indexOf(4) != -1 ? "sidebar-selected" : ""}
                            onClick={(e) => this.handleDataFilter(4, e)}
                        >
                            <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40" >
                                <g>
                                    <path d="m32.3 26.3c1.2 1.2 2 2.7 0 5.1-2 2.4-3.3 2.4-5 2.4-1.9 0-4.6-1.1-7.3-3.2-2.5-1.8-3.6-2.6-6.1-5.2-2.7-2.7-4.3-5-6.2-8.4-2.1-3.9-1.9-5.7-1.4-7.2 0.3-0.9 1.1-1.8 2.2-2.5l0.1-0.1c0.4-0.3 1.4-0.9 2.5-0.9 1 0 1.9 0.4 2.6 1.4l0.1 0c0.9 1.1 1.9 2.5 2.5 3.7 0.8 1.6 0.8 3-0.1 4.1-0.7 1-0.9 1.5-0.9 1.8s0.4 0.7 1 1.5l0.1 0c1.2 1.4 1.6 1.8 2 2.3 0.1 0.1 0.3 0.2 0.3 0.2l0.2 0.2c0.5 0.5 0.8 0.9 2.3 2.2l0 0.1c0.6 0.4 0.7 0.6 1 0.6 0.4 0 1.2-0.6 2.2-1.2 0.4-0.2 0.9-0.4 1.4-0.4 2.1 0 5.3 2.3 6.4 3.5z"></path>
                                </g>
                            </svg>
                        </li>
                    </Tooltip>
                    {
                        !this.props.isAppNative ?
                            <Tooltip placement="right" title={'Lọc theo khoảng thời gian'} mouseEnterDelay={this.mouseEnterDelay} mouseLeaveDelay={this.mouseLeaveDelay}>
                                <Popover
                                    content={formFilterDate}
                                    trigger="click"
                                    placement="right"
                                    onVisibleChange={(e) => {
                                        this.setState({ visibleFilterDate: e })
                                    }}
                                    visible={this.state.visibleFilterDate}
                                >
                                    <li
                                        className={this.state.data_filter.indexOf(6) != -1 ? "sidebar-selected" : ""}
                                    >
                                        <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40" >
                                            <g>
                                                <path d="m5 35v-20h30v20h-30z m30-27.5v6.3h-30v-6.3h7.5v3.8h1.3v-3.8h12.5v3.8h1.2v-3.8h7.5z m-22.5 0v-2.5h1.3v2.5h-1.3z m13.8 0v-2.5h1.2v2.5h-1.2z"></path>
                                            </g>
                                        </svg>
                                    </li>
                                </Popover>
                            </Tooltip> : <React.Fragment>
                                <Modal
                                    title={'Lọc theo khoảng thời gian'}
                                    visible={this.state.visibleFilterDate}
                                    footer={null}
                                    onCancel={() => this.setState({ visibleFilterDate: false })}
                                    className="modal-h-antd modal-content-md"
                                    centered

                                >
                                    {formFilterDate}
                                </Modal>
                                <li
                                    className={this.state.data_filter.indexOf(6) != -1 ? "sidebar-selected" : ""}
                                    onClick={() => this.setState({
                                        visibleFilterDate: true
                                    })}
                                >
                                    <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40" >
                                        <g>
                                            <path d="m5 35v-20h30v20h-30z m30-27.5v6.3h-30v-6.3h7.5v3.8h1.3v-3.8h12.5v3.8h1.2v-3.8h7.5z m-22.5 0v-2.5h1.3v2.5h-1.3z m13.8 0v-2.5h1.2v2.5h-1.2z"></path>
                                        </g>
                                    </svg>
                                </li>
                            </React.Fragment>
                    }
                    {
                        !this.props.isAppNative ?
                            <Tooltip placement="right" title={'Lọc theo bài viết'} mouseEnterDelay={this.mouseEnterDelay} mouseLeaveDelay={this.mouseLeaveDelay}>
                                <Popover overlayClassName={'filter-post'} placement="right" title={'Lọc theo bài viết'}
                                    content={<ContentFilterPost
                                        hideFiltrerPost={this.hideFiltrerPost}
                                        data={this.state.listPosts}
                                        selectFilterPost={this.selectFilterPost}
                                        getPost={this.getPost}
                                    />} trigger="click"
                                    onVisibleChange={(e) => this.filterPost(e)}
                                    visible={this.state.visibleFilterPost}
                                >
                                    <li
                                        className={this.state.data_filter.indexOf(7) != -1 ? "sidebar-selected" : ""}
                                    >
                                        <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40">
                                            <g>
                                                <path d="m8.8 5h26.2v27.6c0 1.3-1.1 2.4-2.4 2.4h-25.2c-1.3 0-2.4-1.1-2.4-2.4v-23.8h2.5v22.5h1.3v-26.3z m3.7 3.8v1.2h10v-1.2h-10z m0 12.5v1.2h15v-1.2h-15z m18.8 7.5v-1.3h-18.8v1.3h18.8z m0-12.5v-1.3h-18.8v1.3h18.8z"></path>
                                            </g>
                                        </svg>
                                    </li>
                                </Popover>
                            </Tooltip> : <React.Fragment>
                                <Modal
                                    title={'Lọc theo bài viết'}
                                    visible={this.state.visibleFilterPost}
                                    footer={null}
                                    onCancel={() => this.setState({ visibleFilterPost: false })}
                                    className="modal-h-antd modal-content-md filter-post"
                                    centered

                                >
                                    <ContentFilterPost
                                        hideFiltrerPost={this.hideFiltrerPost}
                                        data={this.state.listPosts}
                                        selectFilterPost={this.selectFilterPost}
                                        getPost={this.getPost}
                                    />
                                </Modal>
                                <li
                                    className={this.state.data_filter.indexOf(7) != -1 ? "sidebar-selected" : ""}
                                    onClick={(e) => this.setState({
                                        visibleFilterPost: true
                                    }, () => {
                                        this.filterPost(e);
                                    })}
                                >
                                    <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40">
                                        <g>
                                            <path d="m8.8 5h26.2v27.6c0 1.3-1.1 2.4-2.4 2.4h-25.2c-1.3 0-2.4-1.1-2.4-2.4v-23.8h2.5v22.5h1.3v-26.3z m3.7 3.8v1.2h10v-1.2h-10z m0 12.5v1.2h15v-1.2h-15z m18.8 7.5v-1.3h-18.8v1.3h18.8z m0-12.5v-1.3h-18.8v1.3h18.8z"></path>
                                        </g>
                                    </svg>
                                </li>
                            </React.Fragment>
                    }
                    {
                        this.props.UserLogin.role_company.indexOf('sale') == -1 ?
                            <React.Fragment>
                                {
                                    !this.props.isAppNative ? <Tooltip placement="right" title={'Lọc nâng cao'} mouseEnterDelay={this.mouseEnterDelay} mouseLeaveDelay={this.mouseLeaveDelay}>
                                        <Popover placement="right" title={'Lọc nâng cao'}
                                            content={
                                                <div style={{ width: "360px" }}>
                                                    {formFilterAdv}
                                                </div>
                                            } trigger="click"
                                            onVisibleChange={(e) => this.setState({ visibleFilterOther: e }, () => {
                                                if (e) this.getListStaff("");
                                            })}
                                            visible={this.state.visibleFilterOther}
                                        >
                                            <li
                                                className={this.state.data_filter.indexOf(8) != -1 ? "sidebar-selected" : ""}
                                            >
                                                <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40" >
                                                    <g>
                                                        <path d="m23.8 18.1c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.9 2.2 4.9 4.7-2.2 4.7-4.9 4.7z m-12.6 0c-2.7 0-4.9-2.1-4.9-4.7s2.2-4.6 4.9-4.6 4.7 2.1 4.7 4.6-2.1 4.7-4.7 4.7z m0 3.5c3.7 0 11.3 1.8 11.3 5.4v4.3h-22.5v-4.3c0-3.6 7.4-5.4 11.2-5.4z m12.6 0.8c3.8 0 11.2 1 11.2 4.6v4.3h-10v-4.3c0-2.3-0.7-3.2-2.5-4.5 0.5-0.1 0.9-0.1 1.3-0.1z"></path>
                                                    </g>
                                                </svg>
                                            </li>
                                        </Popover>
                                    </Tooltip> : <React.Fragment>
                                        <Modal
                                            title={'Lọc nâng cao'}
                                            visible={this.state.visibleFilterOther}
                                            footer={null}
                                            onCancel={() => this.setState({ visibleFilterOther: false })}
                                            className="modal-h-antd modal-content-md "
                                            centered
                                        >
                                            {formFilterAdv}
                                        </Modal>
                                        <li
                                            className={this.state.data_filter.indexOf(8) != -1 ? "sidebar-selected" : ""}
                                            onClick={() => this.setState({
                                                visibleFilterOther: true
                                            })}
                                        >
                                            <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40" >
                                                <g>
                                                    <path d="m23.8 18.1c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.9 2.2 4.9 4.7-2.2 4.7-4.9 4.7z m-12.6 0c-2.7 0-4.9-2.1-4.9-4.7s2.2-4.6 4.9-4.6 4.7 2.1 4.7 4.6-2.1 4.7-4.7 4.7z m0 3.5c3.7 0 11.3 1.8 11.3 5.4v4.3h-22.5v-4.3c0-3.6 7.4-5.4 11.2-5.4z m12.6 0.8c3.8 0 11.2 1 11.2 4.6v4.3h-10v-4.3c0-2.3-0.7-3.2-2.5-4.5 0.5-0.1 0.9-0.1 1.3-0.1z"></path>
                                                </g>
                                            </svg>
                                        </li>
                                    </React.Fragment>
                                }
                            </React.Fragment> : null
                    }
                    {
                        this.props.UserLogin.role_action.indexOf('import_customer') > -1 ?
                            <React.Fragment>
                                <Tooltip placement="right" title={"Tải lên tệp khách hàng"}>
                                    <li onClick={() => this.setState({ visibleImportCustomer: true })}>
                                        <i className="fa fa-upload" style={{ fontSize: "23px", marginLeft: "-5px" }}></i>
                                    </li>
                                </Tooltip>
                                <Tooltip placement="right" title={"Đặt lịch"}>
                                    <li onClick={() => this.setState({ visibleBooking: true })}>
                                        <i className="fa fa-calendar-check" style={{ fontSize: "23px", marginLeft: "-5px" }}></i>
                                    </li>
                                </Tooltip>
                            </React.Fragment> : null
                    }
                    {
                        this.props.UserLogin.role_company.indexOf('sale') > -1 ?
                            <Tooltip placement="right" title={this.props.UserLogin.is_offline == 1 ? "Tắt chế độ bận" : "Kích hoạt chế độ bận"}>
                                <li className={this.props.UserLogin.is_offline == 1 ? "user-off" : ""}
                                    onClick={() => {
                                        if(this.props.UserLogin.is_offline == 0){
                                            this.setState({ visibleCareHandle: true });
                                        } else{
                                            this.props.handleOffline(0);
                                        }
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" width="1em" height="1em" viewBox="0 0 575.969 575.969" fill="currentColor">
                                        <g>
                                            <g>
                                                <path d="M563.472,363.566c-7.456-11.369-17.657-20.234-29.382-26.146c4.009-53.108-12.074-97.496-36.568-99.644l-0.896-0.078    c-1.153-44.898-15.597-89.487-42.517-127.657C410.748,48.562,340.953,11.86,267.407,11.86    c-73.548,0-143.343,36.702-186.707,98.183c-26.937,38.201-41.379,82.816-42.524,127.745l-0.887,0.075    C12.384,240.05-3.938,285.838,0.826,340.122c4.774,54.304,28.837,96.541,53.729,94.356h0.01l81.259-7.132    c6.854-0.604,13.183-3.903,17.601-9.172c4.429-5.28,6.567-12.086,5.965-18.939l-12.724-144.934    c-1.259-14.27-13.839-24.829-28.111-23.568l-21.495,1.891c1.938-31.081,12.427-61.645,31.567-88.783    c32.875-46.61,83.454-73.336,138.779-73.336c55.322,0,105.904,26.728,138.779,73.336c19.128,27.11,29.599,57.645,31.556,88.687    l-21.485-1.881c-14.271-1.261-26.851,9.296-28.111,23.568L375.421,399.15c-0.6,6.853,1.538,13.66,5.967,18.938    c4.419,5.271,10.749,8.572,17.603,9.173l81.259,7.132h0.009c18.312,1.604,36.103-20.925,46.173-54.503    c1.536,1.61,3.094,3.205,4.341,5.116c6.875,10.47,8,23.595,3.009,35.088l-25.804,59.359c-7.837,18.031-25.6,29.676-45.261,29.676    h-34.728c-2.835-9.125-11.026-15.884-21.085-15.884h-64.354c-12.37,0-22.399,10.031-22.399,22.401v26.06    c0,12.37,10.029,22.402,22.399,22.402h64.354c10.059,0,18.25-6.758,21.085-15.883h34.728c35.238,0,67.083-20.886,81.114-53.197    l25.81-59.362C579.89,412.047,577.589,385.09,563.472,363.566z"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </li>
                            </Tooltip> : null
                    }
                    {/* {
                        this.props.UserLogin.role_company.indexOf('sale') == -1 ?
                            <Tooltip placement="right" title={"Convert uid"}>
                                <li onClick={this.props.handleConvertUid}>
                                    <i className="far fa-exchange-alt"></i>
                                </li>
                            </Tooltip> : null
                    } */}

                    {/* <li
                    className={this.state.data_filter.indexOf(7) != -1 ? "sidebar-selected" : ""}
                    onClick={(e) => this.handleDataFilter(7, e)}
                    onMouseMoveCapture={(e) => this.handleMouseIn('Lọc theo bài viết', e)}
                    onMouseOut={(e) => this.handleMouseOut(e)}
                >
                    <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40">
                        <g>
                            <path d="m8.8 5h26.2v27.6c0 1.3-1.1 2.4-2.4 2.4h-25.2c-1.3 0-2.4-1.1-2.4-2.4v-23.8h2.5v22.5h1.3v-26.3z m3.7 3.8v1.2h10v-1.2h-10z m0 12.5v1.2h15v-1.2h-15z m18.8 7.5v-1.3h-18.8v1.3h18.8z m0-12.5v-1.3h-18.8v1.3h18.8z"></path>
                        </g>
                    </svg>
                </li> */}
                    {/* <li>
                        <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40" >
                            <g>
                                <path d="m31.6 1.6c1.8 0 3.4 1.6 3.4 3.4v30c0 1.8-1.6 3.4-3.4 3.4h-16.6c-1.8 0-3.4-1.6-3.4-3.4v-5h3.4v3.4h16.6v-26.8h-16.6v3.4h-3.4v-5c0-1.8 1.6-3.4 3.4-3.4h16.6z m-10 12.1l-6.6 6.6 6.6 6.7-1.6 1.7-6.6-6.7-6.8 6.7-1.6-1.7 6.6-6.7-6.6-6.6 1.6-1.7 6.8 6.7 6.6-6.7z"></path>
                            </g>
                        </svg>
                    </li>
                    <li>
                        <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 40 40" >
                            <g>
                                <path d="m20.1 3.4c9.2 0 16.7 7.5 16.7 16.6s-7.4 16.6-16.7 16.6-16.6-7.5-16.6-16.6 7.4-16.6 16.6-16.6z m0 29.9c7.4 0 13.3-6 13.3-13.3s-5.9-13.3-13.3-13.3-13.2 6-13.2 13.3 5.9 13.3 13.2 13.3z m0.9-21.6v8.7l7.5 4.4-1.2 2.1-8.8-5.3v-9.9h2.5z"></path>
                            </g>
                        </svg>
                    </li> */}
                </ul>


                <div className='version'>v{config.version}</div>
            </div>
        )
    }
}
