import React, { useEffect, useState, useRef } from "react";

import { Form, Input, Button, Alert, DatePicker, Result, Table, Select } from "antd";
import moment from "moment";

const Widget = (props) => {
    return <React.Fragment>
        <Table
                dataSource={props.data}
                columns={[
                    {
                        title: "Nhóm nhãn",
                        dataIndex: "_id",
                        width: 200,
                        render: (value) => value.tag.tag_name,
                        onCell: (_, index) => {
                            // These two are merged into above cell
                            if (!props.data[index - 1] || props.data[index - 1]._id.tag.tag_id !== props.data[index]._id.tag.tag_id) {
                                return { rowSpan: props.data.filter((item) => item._id.tag.tag_id == props.data[index]._id.tag.tag_id).length };
                            } else {
                                return { rowSpan: 0 };
                            }

                            return {};
                        }
                    },
                    {
                        title: "Nhân viên",
                        dataIndex: "sale",
                        render: (value) => value.fullname
                    },
                    {
                        title: "Khách cũ",
                        dataIndex: "cus_old"
                    },
                    {
                        title: "Khách mới",
                        dataIndex: "cus_new"
                    },
                    // {
                    //     title: "Tổng",
                    //     dataIndex: "total"
                    // }
                ]}
                pagination={false}
                scroll={{ x: true }}
                summary={pageData => {
                    let total = pageData.reduce((a, b) => a + b.total, 0);
                    let cus_old = pageData.reduce((a, b) => a + b.cus_old, 0);
                    let cus_new = pageData.reduce((a, b) => a + b.cus_new, 0);

                    return pageData.length > 0 && (
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                            <Table.Summary.Cell className="text-danger"><b>{cus_old}</b></Table.Summary.Cell>
                            <Table.Summary.Cell className="text-danger"><b>{cus_new}</b></Table.Summary.Cell>
                            {/* <Table.Summary.Cell className="text-danger"><b>{total}</b></Table.Summary.Cell> */}
                        </Table.Summary.Row>
                    );
                }}
            />
    </React.Fragment>;
};

export default Widget;
