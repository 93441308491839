import { Button, Form, Input, Alert } from "antd";
import React, { useState } from "react";
import { WidgetSearchMedical } from "../../../widgets";

const SearchMedical = (props) => {
    const [data, setData] = useState();
    const [loading, setloading] = useState(false);
    const [error, handleError] = useState();

    const onFinish = async (values) => {
        setData(null)
        try {
            setloading(true);

            let response = await props.fetchData({
                url: "api/v1/company/search-medical",
                data: values,
                method: "post",
            });

            handleError(null);

            if (!response.data) throw ({ message: "Không tìm thấy thông tin kết quả khám bệnh" });

            setData(response.data)

        } catch (err) {
            handleError(err.message || "Đã có lỗi xảy ra");
        }
        setloading(false);
    };

    return <React.Fragment>
        <div className="list-connect content-m d-flex">
            <div className="list-c pt-5">
                <div className="text-center ">
                    <h3>Tra cứu Bệnh án</h3>
                    {error ? <Alert message={error} type="error" className="mb-3" /> : null}
                    <Form
                        onFinish={onFinish}
                        style={{maxWidth: "600px", margin: "auto"}}
                    >
                        <label className="pl-0 mb-2">Nhập thông tin bên dưới để tra cứu lịch sử khám chữa bệnh</label>
                        <Form.Item name="phone">
                                    <Input
                                        placeholder="Nhập số điện thoại.."
                                    />
                                </Form.Item>
                        <Form.Item className="text-center">
                            <Button htmlType="submit" type="primary" loading={loading}>Tra cứu ngay</Button>
                        </Form.Item>
                    </Form>
                </div>
                <WidgetSearchMedical data={data} />
            </div>

        </div>
    </React.Fragment>
}

export default SearchMedical;