import React, { useState } from "react";

import { Form, Input, Button, Alert} from "antd";

const CallcenterCallback = (props) => {
    const [loading, handleLoading] = useState(false);
    const [error, handleError] = useState(null);
    const [result, handleResult] = useState(null);

    const search = new URLSearchParams(props.history.location.search);

    const onFinish = async (value) => {
        try{
            handleResult(null);
            handleLoading(true);
            if(!value['phone']) throw({ message : "Số điện thoại không được để trống!"});
            let repsonse = await props.fetchData({
                url: "api/v1/callcenter/callback",
                data: value,
                method: "post"
            })
            handleError(null);
            handleLoading(false);
            handleResult("Đang kết nối máy dịch vụ");
        } catch(err){
            handleLoading(false);
            handleError(err.message || "Đã có lỗi xảy ra");
        }
    }

    return <Form
        style={{maxWidth: "500px"}}
        className="m-auto"
        onFinish={onFinish}
        initialValues={{
            code: search.get("code"),
            type: search.get("type")
        }}
    >
        {
            error ? <p className="text-danger text-left">{error}</p> : null
        }
        {
            result ? <p className="text-success text-left">{result}</p> : null
        }
        <Form.Item noStyle name="code">
            <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name="type">
            <Input type="hidden" />
        </Form.Item>
        <Form.Item name="phone">
            <Input placeholder="Nhập số điện thoại"/>
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>Gọi ngay</Button>
        </Form.Item>
    </Form>
}

export default CallcenterCallback;