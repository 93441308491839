import React, { Component } from 'react';
import {Switch, message, Modal, Button, Form, Input, Checkbox, notification} from 'antd';

export default class ItemChannel extends Component{
  constructor(props){
    super(props);
    this.state = {
        checkedAll: false,
        checkedInbox: false,
        checkedCmt: false,
        type: this.props.type,
        acc_type: this.props.accType.acc_type,
        id_acc_chat: this.props.channel ? this.props.channel.id_acc_chat : '',
        disabledAll: false
    };
  }
  componentWillMount(){
    this.renderRole(this.props);
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.role_allow_all_acc_type || nextProps.role_allow_chat_id){
        this.renderRole(nextProps);
    }
  }
  renderRole = (props) => {
    let all = false;
    let inbox = false;
    let cmt = false;
    let disabledAll = false;
    let data_role = props.role_allow_all_acc_type;
    
    if(this.state.type != 'acc_type'){
        data_role = props.role_allow_chat_id;
        let role_allow_all_acc_type = props.role_allow_all_acc_type;
        if(role_allow_all_acc_type){
            let filter = role_allow_all_acc_type.filter((filter) => {
                if(filter.acc_type == this.state.acc_type){
                    if(filter.inbox || filter.comment){
                        return filter;
                    }
                }
            })
            if(filter.length > 0){
                disabledAll = true;
                filter = filter[0];
                // console.log('alo', filter);
                if(filter.inbox && filter.comment){
                    all = true;
                    inbox = false;
                    cmt = false;
                } else if(filter.inbox){
                    all = false;
                    inbox = true;
                    cmt = false;
                } else if(filter.comment){
                    all = false;
                    inbox = false;
                    cmt = true;
                }
            }
        }
    }
    if(!disabledAll){
        for(let i = 0; i < data_role.length; i++){
            if(this.state.type == 'acc_type' && data_role[i].acc_type == this.state.acc_type || this.state.type != 'acc_type' && data_role[i].acc_type == this.state.acc_type && data_role[i]['id_acc_chat'] == this.state.id_acc_chat){
                if(data_role[i]['inbox'] && data_role[i]['comment']){
                    all = true;
                } else if(data_role[i]['inbox']){
                    inbox = true;
                } else if(data_role[i]['comment']){
                    cmt = true;
                }
                break;
            }
        }
    }

    this.setState({
        checkedAll: all,
        checkedInbox: inbox,
        checkedCmt: cmt,
        disabledAll: disabledAll
    })
  }
  componentDidMount(){
    
  }
  onChangeRole = (e) => {
    let data = {
        acc_type: this.state.acc_type
    };
    let type = 'acc_type';
    if(this.state.id_acc_chat){
        type ='chat_id';
        data['id_acc_chat'] = this.state.id_acc_chat;
    };
    let data_state = {};
    switch(e.target.name){
        case 'all':
            data_state = {
                checkedAll: e.target.checked,
                checkedInbox: false,
                checkedCmt: false
            }
            break;;
        case 'inbox':
            data_state = {
                checkedAll: false,
                checkedInbox: e.target.checked,
                checkedCmt: false
            };
            break;
        case 'cmt':
            data_state = {
                checkedAll: false,
                checkedInbox: false,
                checkedCmt: e.target.checked
            }
            break;
        default:
    }
    this.setState(data_state, () => {
        if(this.state.checkedAll){
            data['inbox'] = true;
            data['comment'] = true;
        } else if(this.state.checkedInbox){
            data['inbox'] = true;
            data['comment'] = false;
        } else if(this.state.checkedCmt){
            data['inbox'] = false;
            data['comment'] = true;
        } else{
            data['inbox'] = false;
            data['comment'] = false;
        }
        if(this.props.changeRoleAcc){
            this.props.changeRoleAcc({
                type: type,
                data: data
            })
        }
    })
  }
  render(){
    if(this.props.type =='acc_type'){
        return <tr className="acc_type">
            <td>{this.props.accType.name}</td>
            <td className="text-center">
                <Checkbox name="all" checked={this.state.checkedAll} onChange={this.onChangeRole}></Checkbox>
            </td>
            <td className="text-center">
                <Checkbox name="inbox" checked={this.state.checkedInbox} onChange={this.onChangeRole}></Checkbox>
            </td>
            <td className="text-center">
                <Checkbox name="cmt" checked={this.state.checkedCmt} disabled={this.props.accType.acc_type == 1 || this.props.accType.acc_type == 5 ? false : true} onChange={this.onChangeRole}></Checkbox>
            </td>
        </tr>
    }
    let title = this.props.channel.full_name || this.props.channel.username || this.props.channel.id_acc_chat;
    return (
        <tr className={this.state.disabledAll ? "disable" : ""}>
            <td style={{paddingLeft: "30px"}}>{title}</td>
            <td className="text-center">
                <Checkbox name="all" checked={this.state.checkedAll} onChange={this.onChangeRole}></Checkbox>
            </td>
            <td className="text-center">
                <Checkbox name="inbox" checked={this.state.checkedInbox} onChange={this.onChangeRole}></Checkbox>
            </td>
            <td className="text-center">
                <Checkbox disabled={this.props.accType.acc_type == 1 || this.props.accType.acc_type == 5 ? false : true} name="cmt" checked={this.state.checkedCmt} onChange={this.onChangeRole}></Checkbox>
            </td>
        </tr>
    )
  }
}
