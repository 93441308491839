import React, { Component } from 'react';
import config from '../lib/config';
import {fetchData} from '../lib/apis';
import {Tooltip, Switch} from 'antd';
import moment from 'moment';
require('moment/locale/vi');

export default class AccountWidget extends Component{
  constructor(props){
    super(props);
    this.Account = this.props.Account;
    this.loginAccountSocial = this.loginAccountSocial.bind(this);
    this.state = {
      show: true,
      enableAcc: this.props.Account.enable == 1 ? true : false
    }
    this.select_acc = true;
    this.removeAccount = this.removeAccount.bind(this);
    this.removeSuccess = false;
  }
  componentWillMount(){
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.valConfirm && nextProps.deleteId && nextProps.deleteId == nextProps.Account._id && this.removeSuccess){
      this.removeAccount();
    }
  }
  loginAccountSocial(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.select_acc && this.props.selectedAcc && e.target.className != 'material-icons icon-delete' && e.target.className != 'tool') {
      this.props.selectedAcc(this.props.Account);
    }
  }
  handelConfirm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(this.props.handleDeleteAccount){
      this.removeSuccess = true;
      this.props.handleDeleteAccount(this.props.Account._id);
    }
  }
  removeAccount = () => {
    this.props.onRejectConfirmDelete();
    this.removeSuccess = false;

    fetchData({
      url: `api/v1/owner/delete`,
      method: 'post',
      data: {
        id: this.props.Account._id
      }
    }).then((res) => {
      if(res.code == 200){
        this.setState({
          show: false
        }, () => {
          this.props.showNotiFy('Thao tác thành công!', {
            background: '#2196f3'
          });
          if(this.props.onRejectConfirmDelete){
            this.props.onRejectConfirmDelete();
          }
        })
      } else{
        if(this.props.showNotiFy){
          this.props.showNotiFy(res.message);
        }
      }
    }).catch((err) => {
      this.props.showNotiFy('Đã có lỗi xảy ra vui lòng thử lại sau!');
    })
  }
  updateAccount(e){
    e.preventDefault();
      fetchData({
          url: `api/v1/owner/update/${this.props.Account._id}`,
          method: 'put',
          data: {
            status: 0
          }
      }).then((res) => {
        if(res.code == 200){
          this.props.showNotiFy('Thao tác thành công!', {
            background: '#2196f3'
          });
        } else{
          if(this.props.showNotiFy){
            this.props.showNotiFy(res.message);
          }
        }
      }).catch((err) => {
        console.log(err);
      })
  }
  editAccount(e){
    e.preventDefault();
    if(this.props.editAccount){
      this.props.editAccount(this.props.Account);
    }
  }
  enableAcc = async () => {
    try{
      let enable = this.state.enableAcc ? 0 : 1;
      await fetchData({
        url: `api/v1/owner/enable`,
        method: 'post',
        data: {
          enable: enable,
          user_id: this.props.Account.user_id
        } 
      })
      this.setState({
        enableAcc: enable == 1 ? true : false
      })
      this.props.showNotiFy('Thao tác thành công!', {
        background: '#2196f3'
      });
    } catch(err){
      console.log(err);
      this.props.showNotiFy('Đã có lỗi xảy ra');
    }
  }
  render(){
    if(!this.state.show){
      return(null);
    }
    if(this.props.Account.status != 1){
      this.select_acc = false;
    } else{
      this.select_acc = true;
    }
    let title_name = this.props.Account.username ? this.props.Account.username : this.props.Account.user_id;
    return(
        <li
          className={
            this.props.Account.status == 0 ? "pending" : 
            this.props.Account.status == 2 ? "wrong_pass" :
            this.props.Account.status == 3 ? "checkpoint" :
            [4,5,6,7,8].indexOf(this.props.Account.status) != -1? "wrong" : 
            ""
          }
        >
            <div className="avatar-box">
                {
                    this.props.Account.avatar ? 
                    <img className="avatar" alt="avatar" 
                    src={this.props.Account.avatar}
                    onLoad={(e)=>{
                      e.target.className = "avatar-ready avatar";
                    }}
                    onError={(e) => {
                        e.target.src = "/user.jpg"
                    }}
                    /> 
                    : <div className={`lettertile dark-color5`}>
                        <div>
                        {this.props.Account.username ? this.props.Account.username.trim().slice(0,1) : 'R'}
                        </div>
                      </div>
                }
                <span className="status-account"></span>
            </div>
            <div className="text-box">
                <div>
                    <span className="contact-title">
                      {title_name}
                    </span>
                </div>
                <div className="contact-comment">
                  {config.type_account[this.props.Account.acc_type]}
                  {
                    this.props.Account.status == 0 ? " (Đang chờ)" :
                    this.props.Account.status == 2 ? " (Sai mật khẩu)" :
                    this.props.Account.status == 3 ? " (Throtled)" :
                    this.props.Account.status == 4 ? " (Challenge required)" :
                    this.props.Account.status == 5 ? " (Sai cookie)" :
                    this.props.Account.status == 6 ? " (User has logged out)" :
                    this.props.Account.status == 7 ? " (Feedback required)" :
                    this.props.Account.status == 8 ? " Đã xảy ra lỗi" : 
                    this.props.Account.last_time_connect ? ` - ${moment(this.props.Account.last_time_connect).fromNow()}`
                    : ''
                  }
                </div>
                {
                  this.props.Account.last_time_connect && this.props.Account.status != 1 ?
                  <div className="contact-comment">{moment(this.props.Account.last_time_connect).fromNow()}</div>
                  : ''
                }
                <span></span>
                {
                  this.props.Account.status == 1 ? 
                  <div className="enable-acc">
                    <Switch checked={this.state.enableAcc} size="small" onChange={this.enableAcc}/>
                  </div> : null
                }
            </div>
            <div className="tool">
              {
                this.props.Account.status == 2 || this.props.Account.status == 5 || this.props.Account.status == 6?
                <Tooltip placement="right" title={'Sửa'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                  <a href="#" onClick={(e) => this.editAccount(e)}>
                  <svg style={{width: "18px"}} fill="#2196f3" height="20" viewBox="0 -1 401.52289 401" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"/>
                    <path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"/>
                  </svg>
                  </a>
                </Tooltip>
                : null
              }
              {
                [3,4,7,8].indexOf(this.props.Account.status) != -1 ?
                <Tooltip placement="right" title={'Làm mới'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                <a href="#" onClick={(e) => this.updateAccount(e)}>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512"}} fill="#2196f3">
                  <g>
                    <g>
                      <path d="M493.815,70.629c-11.001-1.003-20.73,7.102-21.733,18.102l-2.65,29.069C424.473,47.194,346.429,0,256,0
                        C158.719,0,72.988,55.522,30.43,138.854c-5.024,9.837-1.122,21.884,8.715,26.908c9.839,5.024,21.884,1.123,26.908-8.715
                        C102.07,86.523,174.397,40,256,40c74.377,0,141.499,38.731,179.953,99.408l-28.517-20.367c-8.989-6.419-21.48-4.337-27.899,4.651
                        c-6.419,8.989-4.337,21.479,4.651,27.899l86.475,61.761c12.674,9.035,30.155,0.764,31.541-14.459l9.711-106.53
                        C512.919,81.362,504.815,71.632,493.815,70.629z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M472.855,346.238c-9.838-5.023-21.884-1.122-26.908,8.715C409.93,425.477,337.603,472,256,472
                        c-74.377,0-141.499-38.731-179.953-99.408l28.517,20.367c8.989,6.419,21.479,4.337,27.899-4.651
                        c6.419-8.989,4.337-21.479-4.651-27.899l-86.475-61.761c-12.519-8.944-30.141-0.921-31.541,14.459l-9.711,106.53
                        c-1.003,11,7.102,20.73,18.101,21.733c11.014,1.001,20.731-7.112,21.733-18.102l2.65-29.069C87.527,464.806,165.571,512,256,512
                        c97.281,0,183.012-55.522,225.57-138.854C486.594,363.309,482.692,351.262,472.855,346.238z"/>
                    </g>
                  </g>
                  </svg>
                </a>
              </Tooltip>: null
              }
              <Tooltip placement="right" title={'Xóa'} mouseEnterDelay={this.props.mouseEnterDelayTooltip} mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}>
                <a href="#" onClick={(e) => this.handelConfirm(e)}>
                  <svg height="20" viewBox="-57 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg" fill="#2196f3">
                    <path d="m156.371094 30.90625h85.570312v14.398438h30.902344v-16.414063c.003906-15.929687-12.949219-28.890625-28.871094-28.890625h-89.632812c-15.921875 0-28.875 12.960938-28.875 28.890625v16.414063h30.90625zm0 0"/>
                    <path d="m344.210938 167.75h-290.109376c-7.949218 0-14.207031 6.78125-13.566406 14.707031l24.253906 299.90625c1.351563 16.742188 15.316407 29.636719 32.09375 29.636719h204.542969c16.777344 0 30.742188-12.894531 32.09375-29.640625l24.253907-299.902344c.644531-7.925781-5.613282-14.707031-13.5625-14.707031zm-219.863282 312.261719c-.324218.019531-.648437.03125-.96875.03125-8.101562 0-14.902344-6.308594-15.40625-14.503907l-15.199218-246.207031c-.523438-8.519531 5.957031-15.851562 14.472656-16.375 8.488281-.515625 15.851562 5.949219 16.375 14.472657l15.195312 246.207031c.527344 8.519531-5.953125 15.847656-14.46875 16.375zm90.433594-15.421875c0 8.53125-6.917969 15.449218-15.453125 15.449218s-15.453125-6.917968-15.453125-15.449218v-246.210938c0-8.535156 6.917969-15.453125 15.453125-15.453125 8.53125 0 15.453125 6.917969 15.453125 15.453125zm90.757812-245.300782-14.511718 246.207032c-.480469 8.210937-7.292969 14.542968-15.410156 14.542968-.304688 0-.613282-.007812-.921876-.023437-8.519531-.503906-15.019531-7.816406-14.515624-16.335937l14.507812-246.210938c.5-8.519531 7.789062-15.019531 16.332031-14.515625 8.519531.5 15.019531 7.816406 14.519531 16.335937zm0 0"/>
                    <path d="m397.648438 120.0625-10.148438-30.421875c-2.675781-8.019531-10.183594-13.429687-18.640625-13.429687h-339.410156c-8.453125 0-15.964844 5.410156-18.636719 13.429687l-10.148438 30.421875c-1.957031 5.867188.589844 11.851562 5.34375 14.835938 1.9375 1.214843 4.230469 1.945312 6.75 1.945312h372.796876c2.519531 0 4.816406-.730469 6.75-1.949219 4.753906-2.984375 7.300781-8.96875 5.34375-14.832031zm0 0"/>
                  </svg>
                </a>
              </Tooltip>
            </div>
        </li>
    )
  }
}
