import React, { useEffect, useState, useRef, useMemo } from "react";

import { Form, Input, Button, Alert, DatePicker, Result, Table, Select, Spin, Divider, Image, Empty, Skeleton } from "antd";
import moment from "moment";
import cx from "classnames";

import { AccountMetaData } from "../../config/settings"
import { ConvertImgBase64 } from "../../lib/helpers";

const FaceIdInfoView = (props) => {
    const { socket, companySelected, socketConnect } = props;

    const [statusJoin, setstatusJoin] = useState(true);
    const [data, setData] = useState(null);
    const [faceData, setfaceData] = useState([]);
    const [selectFaceId, setselectFaceId] = useState(null);

    const refTimerFaceId = useRef();
    const refConnectFaceId = useRef();

    useEffect(() => {
        let company_id = props.match.params.company_id;
        if (companySelected.company_id != company_id && company_id) {
            props.selectCompany({
                company: {
                    company_id: company_id
                }
            }, "/app/faceid-info")
        } else if (!refConnectFaceId.current) {
            refConnectFaceId.current = 1;
            onConnectFaceId();
        }
    }, [])

    const onConnectFaceId = () => {
        socket.emit('join-kbyt-faceid-info');

        socket.on('connect', function () {
            socket.emit('join-kbyt-faceid-info');
        })

        socket.on('join-kbyt-faceid-info', (values) => {
            if (!values.success) {
                setstatusJoin(false);
                setTimeout(() => {
                    AccountMetaData.logOut(window.location.href);
                }, 3000)
            } else {
                setstatusJoin(true);
            }
        })

        // nhận thông tin từ form kb
        socket.on('kbyt-faceid-info', (values) => {
            console.log(values);
            if (values._method == "info") {
                setData(values.data);
            } else if (values._method == "selectFaceId") {
                setselectFaceId(values.data)
            } else if (values._method == "reloadPage") {
                window.location.reload();
            }
        })

        // nhận face data
        socket.on("kbyt-faceid", (values) => {
            if (values.data) {
                faceData.unshift(values.data);
                setfaceData([...faceData.slice(0, 100).map((item) => {
                    if(values.data.wrong_guest_id && values.data.wrong_guest_id != -1){
                        if(item.id == values.data.wrong_guest_id){
                            item.id = values.data.guest_id;
                            item.guest_id = values.data.guest_id;
                        }
                    }
                    return item
                })])
            }
        })
    }

    const faceDataFilter = useMemo(() => {
        let face_datas = [...new Set(faceData)].sort((a, b) => b.timestamp - a.timestamp);

        if (selectFaceId) {
            let index = face_datas.findIndex((item) => item.timestamp == selectFaceId.timestamp);
            if (index > -1) {
                face_datas.splice(index, 1);
            }
            face_datas = [selectFaceId].concat(face_datas);
        }

        let face_data_key = {};

        face_datas = face_datas.filter((item) => {
            if (!face_data_key[item.id]) {
                face_data_key[item.id] = true;
                return true;
            } else {
                return false
            }
        })

        return face_datas;
    }, [faceData, selectFaceId])

    return (
        <div
            id="app-container"
        >
            <div id="app-faceid-info">
                {
                    statusJoin ?
                        <>
                            <div className="app-faceid-content">
                                <div className="head">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            
                                        </div>
                                        <div className="head-item head-item-checkin">
                                            <i className={cx("fa fa-wifi", socketConnect ? "text-success" : "text-danger")}></i> CheckIn: {faceDataFilter[0] ? moment(faceDataFilter[0].timestamp).format("HH:mm DD-MM-YYYY") : "..."}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="head-item"><i className="far fa-user"></i> <div className="head-item-empty">{data ? data.ho_ten : ""}</div></div>
                                        </div>
                                        <div className="col-4">
                                            <div className="head-item"><i className="far fa-phone"></i> <div className="head-item-empty">{data ? data.so_dien_thoai : ""}</div></div>
                                        </div>
                                        <div className="col-4">
                                            <div className="head-item"><i className="far fa-badge-check"></i> <div className="head-item-empty">{data ? data.booking_code : ""}</div></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="head-item"><i className="far fa-calendar-check"></i> <div className="head-item-empty">{data ? data.ngay_sinh : ""}</div></div>
                                        </div>
                                        <div className="col-4">
                                            <div className="head-item"><i className="far fa-mars-double"></i> <div className="head-item-empty">{data ? data.gioi_tinh : ""}</div></div>
                                        </div>
                                    </div>
                                    <div >
                                        <div className="head-item"><i className="far fa-address-card"></i> <div className="head-item-empty">{data ? data.dia_chi_1 : ""}</div></div>
                                    </div>
                                </div>
                                {
                                    faceDataFilter.length ?
                                        <>
                                            <div className="face-data">
                                                {
                                                    faceDataFilter.map((item, i) => {
                                                        return <div className={cx("face-data-item", i == 0 && "active")} key={i}>
                                                            <Image src={ConvertImgBase64(item.images[0])}
                                                                preview={false}
                                                            />
                                                            <div className="info">
                                                                {
                                                                    selectFaceId && selectFaceId.timestamp == item.timestamp ?
                                                                        "Đang khai báo"
                                                                        : item.id
                                                                }
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </> : <div>

                                        </div>
                                }
                            </div>
                        </> :
                        <>
                            <Result
                                title="403"
                                subTitle="Bạn không có quyền truy cập dữ liệu"
                                status={403}
                            />
                        </>
                }
            </div>
        </div>
    );
};

export default FaceIdInfoView;
