import React from 'react';

import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

const KhamBenhComponent = (props) => {
    const { exportToCSV, loading, listData } = props;

    return <div className="table-responsive">
        <div className="text-right">
            <Button type="primary" onClick={() => {
                exportToCSV({
                    dom: document.querySelector('#statistic_khambenh')
                })
            }} className="mb-2" style={{ borderRadius: "5px" }}>
                <i className="far fa-download"></i>
            </Button>
        </div>

        <Table
            id="statistic_khambenh"
            bordered
            loading={loading}
            dataSource={listData}
            columns={[
                {
                    title: "Mã lịch hẹn",
                    responsive: ["md"],
                    dataIndex: "booking_code"
                },
                {
                    title: "Họ và tên",
                    dataIndex: "ho_ten"
                },
                {
                    title: "Năm sinh",
                    dataIndex: "ngay_sinh",
                    responsive: ["md"],
                    render: (item) => {
                        return item ? moment(item).format("DD-MM-YYYY") : null
                    }
                },
                {
                    title: "Tỉnh/ TP",
                    dataIndex: "tinh",
                },
                {
                    title: "Bác sỹ",
                    dataIndex: "bac_sy",
                    responsive: ["md"],
                }
            ]}
            pagination={false}
            summary={pageData => {
                let total = 0;

                pageData.forEach(({ result_rating }) => {
                    total += 1;
                });

                return pageData.length > 0 && (
                    <Table.Summary.Row>
                        <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>{total}</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
        />
    </div>
}

export default KhamBenhComponent;