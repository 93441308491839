import React, { useEffect, useRef, useState } from "react";

import { Modal, Button, Form, Input, Select, InputNumber, DatePicker, Checkbox, Alert, TreeSelect, message } from "antd";

import moment from "moment";
import qs from 'qs';

import { readFileXls, sleep, isTiengViet } from "../../../../lib/helpers";

import WidgetSelectHistory from "./select_history";

import { WidgetSelectSpinText } from "../../../../widgets";

const FormAutoSms = (props) => {
    const { visible, hide, listType, listBrandName } = props;
    const [stateSave, setStateSave] = useState({
        loading: false,
        error: null
    });
    const [type, setType] = useState("1");
    const [content, setContent] = useState("");
    const [visibleSelectHis, setVisibleSelectHis] = useState(false);
    const [listTag, setListTag] = useState([]);
    const [loadingContact, setLoadingContact] = useState(false);
    const [reload, setReload] = useState([]);

    const formRef = useRef();

    useEffect(() => {
        getListTag();
    }, [])

    const onFinish = async (values) => {
        try {
            setStateSave({ loading: true });

            values['list_phone'] = values['list_phone'] ? values['list_phone'].split("\n") : [];
            values['time_start'] = values['time_start'] ? values['time_start'].unix() * 1000 : Date.now();

            let res = await props.fetchData({
                url: `api/v1/auto_sms/create`,
                method: 'post',
                data: values
            });

            setStateSave({ loading: false });

            formRef.current.resetFields();

            props.onFinish();

        } catch (err) {
            setStateSave({
                loading: false,
                error: err.message
            })
        }
    }

    const getListTag = async (values) => {
        try {

            let res = await props.fetchData({
                url: `api/v1/settings/list`,
                method: 'get'
            });

            setListTag(parseMenu(res.settings.tags))

        } catch (err) {

        }
    }

    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    const readFile = async (files) => {
        try {
            let result = await readFileXls(files[0]);
            let list_phone = result.map((item) => {
                return item.split(",")[0]
            })
            formRef.current.setFieldsValue({
                list_phone: list_phone.filter((item) => item).join("\n")
            })
        } catch (err) {

        }
    }

    const parseMenu = (list) => {
        var map = {}, node, i;
        var roots = []

        for (i = 0; i < list.length; i++) {
            map[list[i].tag_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.tag_name;
            node['key'] = node.tag_id;
            node['value'] = node.tag_id;

            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                } catch (err) {
                    // console.log(node);
                }
            } else {
                node['checkable'] = false;
                node['selectable'] = false;
                roots.push(node);
            }
        }
        return roots;
    }

    const getPhoneContact = async (values = {}) => {
        try{
            setLoadingContact(true);

            let params = {
                limit: 50,
                page: 1,
                ...values
            }

            while(true){
                let res = await props.fetchData({
                    url: `api/v1/auto_sms/contact?${qs.stringify(params)}`,
                    method: 'get'
                });

                if(res.data.length == 0) break;

                params['page']++;

                let text_content = formRef.current.getFieldValue("list_phone");

                if(text_content) text_content += "\n";
                
                formRef.current.setFieldsValue({
                    list_phone: text_content+res.data.join("\n")
                })

                setReload(!reload);

                await sleep(2000);
            }

            setLoadingContact(false);
            message.success("Load dữ liệu thành công!");

        } catch(err){
            setLoadingContact(false);
            message.error(err.message);
        }
    }

    return <React.Fragment>
        <WidgetSelectHistory
            visible={visibleSelectHis}
            hide={() => setVisibleSelectHis(false)}
            fetchData={props.fetchData}
            setListPhone={(value) => {
                formRef.current.setFieldsValue({
                    list_phone: value.join("\n")
                })
            }}
        />
        <Modal
            title={'Auto sms'}
            visible={visible}
            footer={null}
            onCancel={hide}
            width={1000}
        >
            <Form
                name="basic"
                initialValues={{
                    type: type,
                    brandname_id: listBrandName[0] && listBrandName[0]['_id'],
                    content: "",
                    list_phone: ""
                }}
                onFinish={onFinish}
                ref={formRef}
                layout="vertical"
            >
                {
                    stateSave.error ? <Alert message={stateSave.error} type="error" /> : null
                }
                <div className="row">
                    <div className="col-md-6">
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="name" label="Tên chiến dịch"
                            rules={[{ required: true, message: 'Không được để trống' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="type" label="Loại chiến dịch" rules={[{ required: true, message: 'Không được để trống' }]}>
                            <Select onChange={(e) => setType(e)}>
                                {
                                    Object.keys(listType).map((key) => {
                                        return <Select.Option value={key}>{listType[key]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="brandname_id" label="Brandname" rules={[{ required: true, message: 'Không được để trống' }]}>
                            <Select >
                                {
                                    listBrandName.map((item) => {
                                        return <Select.Option value={item._id}>{item.brandname || item.user}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item label="Thời gian gửi" name="time_start">
                            <DatePicker showTime
                                disabledDate={(date) => date < moment().add("day", -1)}
                                format="YYYY-MM-DD HH:mm"
                            />
                        </Form.Item>

                        <Form.Item label="Mô tả chiến dịch" name="note" >
                            <Input.TextArea style={{ minHeight: "100px" }} />
                        </Form.Item>

                    </div>
                    <div className="col-md-6">
                        <Form.Item
                            name="content"
                            label="Nội dung"
                            rules={[
                                { required: true, message: 'Nội dung không được để trống!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if(isTiengViet(value) && getFieldValue("content_type") != 1) return Promise.reject(new Error('Vui lòng nhập tiếng Việt không dấu'));
                                      return Promise.resolve();
                                    },
                                })
                            ]}
                        >
                            <Input.TextArea style={{ minHeight: "120px" }} onChange={(e) => {
                                setContent(e.target.value)
                            }} />
                        </Form.Item>
                        <div className="mb-2">Ký tự đã nhập: {content.length}</div>

                        <WidgetSelectSpinText
                            data={[
                                {
                                    value: "#{DATE}",
                                    text: "Ngày tháng năm"
                                },
                                {
                                    value: "#{Spin_1|Spin_2}",
                                    text: "Chọn từ khóa ngẫu nhiên"
                                }
                            ]}
                            setValues={(value) => {
                                formRef.current.setFieldsValue({ content: formRef.current.getFieldValue('content') + value })
                            }}
                            numShow={3}
                        />

                        <Form.Item name="content_type" valuePropName="checked">
                            <Checkbox value={1}>Cho phép gửi tiếng Việt có dấu</Checkbox>
                        </Form.Item>

                        <div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <label>Danh sách cần gửi: {(formRef.current && formRef.current.getFieldValue("list_phone") && formRef.current.getFieldValue("list_phone").split("\n").length) || 0}</label>
                                <Button size="small" onClick={() => setVisibleSelectHis(true)}>Chọn từ lịch sử</Button>
                            </div>
                            {
                                type == 2 ?
                                    <div className="row" style={{ marginRight: "-0.5rem", marginLeft: "-0.5rem"}}>
                                        <div className="col-md-4 pl-2 pr-2">
                                            <Form.Item name="source_contact">
                                                <Select placeholder="Nguồn khách" style={{ width: "100%" }}>
                                                    <Select.Option value={1}>Facebook</Select.Option>
                                                    <Select.Option value={2}>CallCenter</Select.Option>
                                                    <Select.Option value={3}>Web</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-5 pl-2 pr-2">
                                            <Form.Item name="source_tag">
                                                <Select placeholder="Chọn nhãn" >
                                                    {
                                                        listTag.map((item) => {
                                                            return <Select.OptGroup label={item.tag_name}>
                                                            {
                                                                item.children.map((item) => {
                                                                    return <Select.Option value={item.tag_id}>{item.tag_name}</Select.Option>
                                                                })
                                                            }
                                                        </Select.OptGroup>
                                                        })
                                                    }
                                                </Select>
                                                {/* <TreeSelect {...{
                                                    treeData: listTag,
                                                    // treeCheckable: true,
                                                    showCheckedStrategy: TreeSelect.SHOW_PARENT,
                                                    placeholder: 'Chọn Nhãn',
                                                    style: {
                                                        width: "100%"
                                                    },
                                                    showArrow: true,
                                                    treeDefaultExpandAll: true
                                                }} /> */}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3 pl-2 pr-2">
                                            <Button loading={loadingContact} type="primary" style={{ width: "100%"}} onClick={() => {
                                                getPhoneContact({
                                                    source_contact: formRef.current.getFieldValue("source_contact"),
                                                    source_tag: formRef.current.getFieldValue("source_tag")
                                                })
                                            }}>GET</Button>
                                        </div>
                                    </div> : null
                            }
                            <Form.Item name="list_phone">
                                <Input.TextArea style={{ minHeight: "200px" }} onBlur={() => setReload(!reload)}/>
                            </Form.Item>
                            <Input type="file" accept=".xlsx, .xls" onChange={(e) => readFile(e.target.files)} />
                            <div className="mt-2">
                                <a href="/file-autosms.xlsx" target="_blank">File mẫu Xlsx</a> (Tối đa 150 000 thuê bao)
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end mt-4">
                    <Button htmlType="button" style={{ margin: '0 8px' }} onClick={hide}>
                        Đóng
                    </Button>
                    <Button htmlType="submit" type="primary" loading={stateSave.loading}>
                        Tiếp tục
                    </Button>
                </div>
            </Form>
        </Modal>
    </React.Fragment>
}

export default FormAutoSms;