import { Modal } from "antd";
import React from "react";

const PreviewFile = (props) => {
    return <Modal
        visible={props.visible}
        onCancel={props.hide}
        title="Preview"
        footer={null}
        style={{
            maxWidth: "100%",
            minWidth: "80%"
        }}
        centered
    >
        <div className="w-100" style={{ height: "100vh"}}>
            <iframe src={"https://api.vietngagroup.vn/api/v1/uploads/16/other/0xt-2211010042_sid2211010015_xnvs.pdf"}
                style={{
                    width: "100%",
                    height: "100%"
                }}
            ></iframe>
        </div>
    </Modal>
}

export default PreviewFile;