import React, { Component } from 'react';
import { Modal, Form, Input, Button, Alert} from 'antd';


export default class FormEditQuickReply extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ""
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.visible && nextProps.visible != this.props.visible){
            setTimeout(() => {
                if(this.form){
                    if(typeof nextProps.visible == "object"){
                        this.form.setFieldsValue(nextProps.visible)
                    }
                }
            }, 100)
        }
    }

    save = async (values) => {
        try {
            this.setState({
                loading: true,
                error: null
            })
            let url = `api/v1/quick_reply/create`;
            let method = "post";
            if(values['_id']){
                url = `api/v1/quick_reply/${values['_id']}`;
                method = 'put';
            }
            let response = await this.props.fetchData({
                url: url,
                method: method,
                data: values
            })

            this.props.notification({
                title: "Cập nhập trả lời nhanh",
                content: "Thao tác thành công",
                type: "success"
            })
            this.setState({
                loading: false
            })
            this.form.resetFields();
            
            this.props.onFinish(response.data);
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message || "Thao tác không thành công"
            })
        }
    }

    addDefault = (value) => {
        this.form.setFieldsValue({
            message: this.form.getFieldValue('message')+value
        })
    }

    render() {
        return <Modal
            title={'Cập nhập trả lời nhanh'}
            visible={this.props.visible}
            onCancel={() => {
                this.form.resetFields();
                this.props.hide();
            }}
            footer={null}
        >
            <Form
                name="basic"
                layout="vertical"
                ref={(evt) => this.form = evt}
                initialValues={{
                    message: "",
                    type: this.props.typeReplySample == "quick_reply_sms" ? 2 : 1
                }}
                onFinish={this.save}
            >
                {
                    this.state.error ? <Alert message={this.state.error} type="error" /> : null
                }
                <Form.Item name="_id" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="type" noStyle>
                    <Input type="hidden"/>
                </Form.Item>
                <Form.Item name="short_cut" label="Ký tự tắt"
                    rules={[{ required: true, message: 'không được để trống' }]}
                >
                    <Input placeholder="Vd: hello"/>
                </Form.Item>
                <Alert message={<div>Viết tin nhắn với nội dung bắt đầu bằng: "/"+ký tự tắt ( hệ thống sẽ tự đề xuất tin nhắn trả lời nhanh)</div>}/>
                <Form.Item name="message" label="Nội dung"
                    rules={[{ required: true, message: 'không được để trống' }]}
                >
                    <Input.TextArea style={{minHeight: "150px"}} placeholder="Vd: Xin chào #{full_name}"/>
                </Form.Item>
                <div className="modal-form">
                    <div className="form-group">
                        <div style={{ width: '33%' }}>
                            <div className="btn-add-reply-defaul" onClick={() => this.addDefault('#{full_name}')}>{'#{full_name}'}</div>
                        </div>
                        <div>Chèn tên đầy đủ của khách hàng</div>
                    </div>
                    <div className="form-group">
                        <div style={{ width: '33%' }}>
                            <div className="btn-add-reply-defaul" onClick={() => this.addDefault('#{phone}')}>{'#{phone}'}</div>
                        </div>
                        <div>Chèn sdt của khách hàng</div>
                    </div>
                    <div className="form-group">
                        <div style={{ width: '33%' }}>
                            <div className="btn-add-reply-defaul" onClick={() => this.addDefault('#{address}')}>{'#{address}'}</div>
                        </div>
                        <div>Địa chỉ của khách hàng</div>
                    </div>
                    <div className="form-group">
                        <div style={{ width: '33%' }}>
                            <div className="btn-add-reply-defaul" onClick={() => this.addDefault('#{SPIN_1|SPIN_2}')}>{'#{SPIN_1|SPIN_2}'}</div>
                        </div>
                        <div>Lựa chọn ngẫu nhiên giữa các từ</div>
                    </div>
                </div>
                <div style={{ textAlign: "right" }} className="mt-4">
                    <Button onClick={this.props.hide} style={{ marginRight: "10px" }}>
                        Đóng
                </Button>
                    <Button htmlType="submit" type="primary" loading={this.state.loading}>
                        Cập nhập
                </Button>
                </div>
            </Form>
        </Modal>
    }
}
