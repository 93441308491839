import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Select, Tooltip, Row, Col, Result, Radio, Pagination, Spin } from 'antd';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import moment from 'moment';
import { ExclamationCircleOutlined} from '@ant-design/icons';

const key_loading = 'loading';

export default class SystemsUserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            loadingData: true,
            total: 0,
            listRole: [],
            listAction: []
        }
        this.limit = 20;
        this.page = 1;

        this.timeSearch = null;
    }
    componentWillMount() {
        this.getListRole();
        this.getListData();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async (option = {}) => {
        try {
            let { type } = option;
            this.setState({ loading: true });
            if(type == 'reset') this.page = 1;
            let params = {
                limit: this.limit,
                page: this.page
            }
            if(this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue()};

            let res = await fetchData({
                url: `api/v1/admin/user/list?${qs.stringify(params)}`
            });
            this.setState({
                listData: res.data,
                total: res.total,
                loading: false
            }, () => {
                this.props.handleChecked(null, document.querySelector('[name="checkall"]'));
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Danh sách người dùng",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    getListRole = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/role/list`
            });
            this.setState({
                listRole: res.data,
                listAction: res.role_actions
            })
        } catch (err) {

        }
    }

    handleFilterData = ({type, input}) => {
        this.page = 1;
        if(type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text'){
            if(this.timeSearch) clearTimeout(this.timeSearch);
                this.timeSearch = setTimeout(() => {
                    this.getListData({ type: 'reset'});
            }, 300);
        } else{
            this.getListData({ type: 'reset'});
        }
    }

    deleteUser = (id) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            // className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await fetchData({
                            url: `api/v1/admin/user/${id}`,
                            method: 'delete'
                        })
                        _this.props.notification({
                            title: "Xóa người dùng",
                            content: "Thao tác thành công",
                            type: "success"
                        })
                        _this.getListData();
                        return resolve(true);
                    } catch (err) {
                        _this.props.notification({
                            title: "Xóa người dùng",
                            content: err.message ? err.message : 'Thao tác thất bại',
                            type: "error"
                        })
                        return reject(false);
                    }
                })
            },
            onCancel() { },
        });
    }

    resetPassword = (id) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn reset mật khẩu cho tài khoản này',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let response = await fetchData({
                            url: `api/v1/admin/user/${id}/reset_pw/`,
                            method: 'put',
                        })
                        _this.props.notification({
                            title: "Reset mật khẩu",
                            content: `Thao tác thành công mật khẩu mới là: ${response.password}`,
                            type: "success"
                        })
                        return resolve(true);
                    } catch (err) {
                        _this.props.notification({
                            title: "Reset mật khẩu",
                            content: err.message ? err.message : 'Thao tác thất bại',
                            type: "error"
                        })
                        return reject(false);
                    }
                })
            },
            onCancel() { },
        });
    }

    updateRoleUser = async (values) => {
        try {
            message.loading({ content: 'Đang xử lý..', key: key_loading, duration: 10 });
            let response = await fetchData({
                url: `api/v1/admin/user/level/${values.user_id}`,
                method: 'put',
                data: values
            });
            this.getListData();
            this.setState({ visibleFormRole: false })
            message.success({ content: 'Thành công', key: key_loading, duration: 2 });
        } catch (error) {
            let content = error.message ? error.message : 'Đã có lỗi xảy ra vui lòng thử lại sau!';
            message.warning({ content: content, key: key_loading, duration: 2 });
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <Modal
                    title={'Chỉnh sửa quyền'}
                    visible={this.state.visibleFormRole}
                    onCancel={() => this.setState({ visibleFormRole: null })}
                    className={"form-modal"}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formUpdateRole = evt}
                        onFinish={this.updateRoleUser}
                        layout="vertical"
                    >
                        <Form.Item noStyle name="user_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item label="Chọn quyền">
                            <div className="row">
                                <div className="col-6">
                                    <Form.Item name="is_root_company" valuePropName="checked">
                                        <Checkbox>Tạo chi nhánh</Checkbox>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item name="is_root_it" valuePropName="checked">
                                        <Checkbox>Quyền IT</Checkbox>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form.Item>
                        <div style={{ textAlign: "right" }}>
                            <Button onClick={() => this.setState({ visibleFormRole: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.confirmLoading}>
                                Cập nhập
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý người dùng: {this.state.total}</h3>     
                        <div className="d-flex overflow-auto" style={{marginBottom: "15px"}}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                        </div>                       
                        <Form
                            ref={(evt) => this.formFilter = evt }
                            initialValues={{
                                role: "",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="fullname">
                                                <Input placeholder="Nhập tên người dùng"/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Item name="code">
                                                <Input placeholder="Mã người dùng"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading} tip="loading..">
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>
                                            <label className="css-control css-control-primary css-checkbox p-0">
                                                <input name="checkall" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked}/>
                                                <span className="css-control-indicator"></span>
                                            </label>
                                        </th>
                                        <th>Stt</th>
                                        <th>Avatar</th>
                                        <th>Mã</th>
                                        <th>Username</th>
                                        <th>Tên</th>
                                        <th>Chi nhánh tham gia</th>
                                        {
                                            this.props.UserLogin.is_root ?
                                            <th>Tạo chi nhánh</th> : null
                                        }
                                        { this.props.UserLogin.is_root ? <th>IT</th> : null}

                                        <th>Tham gia</th>
                                        <th className="text-right">Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id}>
                                                    <td>
                                                        <label className={`css-control css-control-primary css-checkbox p-0 `}>
                                                            <input name="check_item" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked}
                                                                value={item._id}
                                                            />
                                                            <span className="css-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>
                                                        <div style={{width: "35px", height: "35px"}}>
                                                            {
                                                                item.avatar ? <img className="avatar" src={item.avatar} /> : <img className="avatar" src={require('../../../img/user.jpg')} />
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>{item.code}</td>
                                                    <td>
                                                        <b>{item.username}</b>
                                                    </td>
                                                    <td>
                                                        <b>{item.fullname}</b>
                                                    </td>
                                                    <td>
                                                        <div style={{maxWidth: "300px", gap: 10}} className="flex flex-column"
                                                            
                                                        >
                                                            {item.companys.map((item) => {
                                                                let role;
                                                                if(item.roles){
                                                                    role = this.state.listRole.find((_item) => item.roles.role_menu.includes(_item.role_name))?.display_name;
                                                                }
                                                                return <p className='mb-0' style={{ fontSize: "14px"}}>{role} - {item.company_name}</p>
                                                            })}
                                                        </div>
                                                    </td>
                                                    {
                                                        this.props.UserLogin.is_root ? 
                                                        <td>
                                                            <input type="checkbox" checked={item.is_root_company ? true : false }/>
                                                        </td> : null
                                                    }
                                                    {
                                                        this.props.UserLogin.is_root ? <td>
                                                            <input type="checkbox" checked={item.is_root_it ? true : false }/>
                                                        </td> : null
                                                    }
                                                    <td>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</td>
                                                    <td className="text-right">
                                                        {
                                                            this.props.UserLogin.is_root ?
                                                            <Tooltip title="Xóa">
                                                                <Button disabled={item._id == this.props.UserLogin._id} size="small" type="danger" className="mr-2" onClick={() => this.deleteUser(item._id)}><i className="far fa-trash-alt"></i></Button>
                                                            </Tooltip>
                                                            : null
                                                        }
                                                        <Tooltip title="Reset mật khẩu">
                                                            <Button size="small" className="mr-2" onClick={() => this.resetPassword(item._id)}>
                                                                <i className="far fa-unlock-alt"></i>
                                                            </Button>
                                                        </Tooltip>
                                                        {
                                                            this.props.UserLogin.is_root ? 
                                                            <Tooltip title="Sửa quyền">
                                                                <Button disabled={item._id == this.props.UserLogin._id || item.is_root} size="small" onClick={() => {
                                                                    this.setState({
                                                                        visibleFormRole: true
                                                                    }, () => {
                                                                        setTimeout(() => {
                                                                            if (this.formUpdateRole) this.formUpdateRole.setFieldsValue({
                                                                                user_id: item._id,
                                                                                is_root_company: item.is_root_company,
                                                                                is_root_it: item.is_root_it
                                                                            })
                                                                        }, 100)
                                                                    })
                                                                }}><i className="far fa-user-lock"></i></Button>
                                                            </Tooltip> : null
                                                        }
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table> 
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
